import {
  GetCityListAction,
  SetDestionationPayload,
  City,
  AirSearchPayload,
  GetCityByCodeDto,
} from './types';
import { FlightClassTypes } from '@modules/main';
import { Action } from 'redux-act';
import {
  getCityListSuccess,
  initializeSimpleSearch,
  setDestination,
  setDate,
  setPassengers,
  setTicketClass,
  updateSearchFormState,
  setDestinationBus,
} from './duck';
import { Helper } from '@utils';
import { ApplicationState } from '@modules/index';
import { call, put, all, takeLatest, select, spawn } from 'typed-redux-saga';
import * as simpleSearchManager from './Manager';
import moment from 'moment';
import { getSearchPanelType } from '@modules/ui';
import { trainStationToAviaCity } from '@modules/simpleSearch/utils';

export function* getCityListSaga({ payload }: GetCityListAction) {
  const abortController = new AbortController();
  try {
    const cityList = yield* call(
      simpleSearchManager.getCityListByStr,
      payload,
      abortController,
    );
    yield* put(getCityListSuccess(cityList));
  } catch (err) {
    //
  }
}

export function* initializeWorker({ payload }: GetCityListAction) {
  try {
    const searchPanelType = yield* select(getSearchPanelType);
    const isAvia = searchPanelType === 'avia';
    let originFromLocalStorage: string = '';
    let destinationFromLocalStorage: string = '';
    const queryParams = new URLSearchParams(
      decodeURIComponent(window.location.search),
    );
    const originFromQueryParams = queryParams.get('origin');
    const destinationFromQueryParams = queryParams.get('destination');
    if (isAvia) {
      originFromLocalStorage = window.localStorage.getItem('origin') || '';
      destinationFromLocalStorage =
        window.localStorage.getItem('destination') || '';
    }

    const finalOrigin = originFromQueryParams || originFromLocalStorage;
    if (!finalOrigin) {
      return;
      // finalOrigin = yield* call(simpleSearchManager.findCity);
      // window.localStorage.setItem('origin', finalOrigin as string);
    }

    let cityList: GetCityByCodeDto | null = null;

    if (isAvia) {
      cityList = yield* call(
        simpleSearchManager.getCityByCode,
        finalOrigin as string,
      );
    }

    if (cityList) {
      yield* put(
        setDestination({
          from: cityList,
          withoutChangeQueryParams: true,
        }),
      );
      if (
        originFromQueryParams !== cityList.iataCode &&
        window.location.pathname === '/'
      ) {
        queryParams.set('origin', cityList.iataCode);
        window.history.replaceState(
          { path: window.location.pathname + '?' + queryParams.toString() },
          '',
          window.location.pathname + '?' + queryParams.toString(),
        );
      }
    }

    if (
      (destinationFromLocalStorage && window.location.pathname !== '/') ||
      destinationFromQueryParams
    ) {
      const cityList = yield* call(
        simpleSearchManager.getCityByCode,
        (destinationFromQueryParams || destinationFromLocalStorage) as string,
      );
      if (cityList) {
        yield* put(
          setDestination({
            to: cityList,
            withoutChangeQueryParams: true,
          }),
        );
      }
    }
  } catch (err) {
    //
  }
}

// TODO пока заглушка, надо модуль переработать
function* setDestinationWorker({ payload }: Action<SetDestionationPayload>) {
  if (window.location.pathname !== '/' || payload.withoutChangeQueryParams)
    return;
  const { from, to } = yield* select(
    (state: ApplicationState) => state.searchReducer.simpleSearchForm,
  );
  const origin = (payload.from as City | undefined) || from;
  const destination = (payload.to as City | undefined) || to;
  const url = new URL(window.location.href);
  const prevParams = url.search;

  if (origin && typeof origin === 'object') {
    if (origin.iataCode !== url.searchParams.get('origin')) {
      url.searchParams.set('origin', origin.iataCode);
    }
  } else {
    url.searchParams.delete('origin');
  }

  if (destination && typeof destination === 'object') {
    if (destination.iataCode !== url.searchParams.get('destination')) {
      url.searchParams.set('destination', destination.iataCode);
    }
  } else {
    url.searchParams.delete('destination');
  }

  if (url.search !== prevParams) {
    window.history.replaceState(
      { path: window.location.pathname + url.search },
      '',
      window.location.pathname + url.search,
    );
  }
}

export function* simpleSearchUpdateState(params: AirSearchPayload) {
  const searchPanelType = yield* select(getSearchPanelType);
  const isAvia = searchPanelType === 'avia';
  const isBus = searchPanelType === 'bus';

  if (isAvia) {
    yield* spawn(function* () {
      const city = yield* call(
        simpleSearchManager.getCityByCode,
        params.origin,
      );

      yield* put(
        setDestination({
          from: city,
          withoutChangeQueryParams: true,
        }),
      );
    });

    yield* spawn(function* () {
      const city = yield* call(
        simpleSearchManager.getCityByCode,
        params.destination,
      );

      yield* put(
        setDestination({
          to: city,
          withoutChangeQueryParams: true,
        }),
      );
    });

    yield* put(
      setTicketClass({
        id: params.flightClass,
        description: getFlightClassDesc(params.flightClass),
      }),
    );
  } else if (isBus) {
    yield* spawn(function* () {
      const city = yield* call(
        simpleSearchManager.getCityListByStrBus,
        params.origin,
      );
      yield* put(
        setDestinationBus({
          //@ts-ignore
          from: trainStationToAviaCity(city[0]),
          withoutChangeQueryParams: true,
        }),
      );
    });

    yield* spawn(function* () {
      const city = yield* call(
        simpleSearchManager.getCityListByStrBus,
        params.destination,
      );
      yield* put(
        setDestinationBus({
          //@ts-ignore
          to: trainStationToAviaCity(city[0]),
          withoutChangeQueryParams: true,
        }),
      );
    });
  } else {
    yield* spawn(function* () {
      const city = yield* call(
        simpleSearchManager.getCityListByStrTrain,
        params.origin,
      );

      yield* put(
        setDestination({
          from: trainStationToAviaCity(city[0]),
          withoutChangeQueryParams: true,
        }),
      );
    });

    yield* spawn(function* () {
      const city = yield* call(
        simpleSearchManager.getCityListByStrTrain,
        params.destination,
      );

      yield* put(
        setDestination({
          to: trainStationToAviaCity(city[0]),
          withoutChangeQueryParams: true,
        }),
      );
    });
  }

  if (isAvia) {
    yield* put(
      setDate({
        from: moment(params.forwardDate, 'DDMMYY'),
        to: params.backwardDate ? moment(params.backwardDate, 'DDMMYY') : null,
      }),
    );
  } else {
    yield* put(
      setDate({
        from: moment(params.forwardDate, 'DDMMYYYY'),
        to: params.backwardDate
          ? moment(params.backwardDate, 'DDMMYYYY')
          : null,
      }),
    );
    yield* put(
      setPassengers({
        adults: params.adults,
        children: params.children,
        infants: params.infants,
        source: isAvia ? 'avia' : 'train',
      }),
    );
  }

  // yield* put(
  //   setPassengers({
  //     adults: params.adults,
  //     children: params.children,
  //     infants: params.infants,
  //     source: isAvia ? 'avia' : 'train',
  //   })
  // );
}

function getFlightClassDesc(str: FlightClassTypes) {
  switch (str) {
    case FlightClassTypes.Econom:
      return 'эконом';
    case FlightClassTypes.Comfort:
      return 'комфорт';
    case FlightClassTypes.Bussines:
      return 'бизнес';
    case FlightClassTypes.FirstClass:
      return 'первый класс';
  }
}

function* updateFormSearchStateFlow({ payload }: Action<string>) {
  const params = Helper.parseSearchParams(payload);
  const searchPanelType = yield* select(getSearchPanelType);
  const isAvia = searchPanelType === 'avia';

  yield* spawn(function* () {
    const city = yield* call(simpleSearchManager.getCityByCode, params.origin);
    yield* put(
      setDestination({
        from: city,
        withoutChangeQueryParams: true,
      }),
    );
  });

  yield* spawn(function* () {
    const city = yield* call(
      simpleSearchManager.getCityByCode,
      params.destination,
    );
    yield* put(
      setDestination({
        to: city,
        withoutChangeQueryParams: true,
      }),
    );
  });

  yield* put(
    setDate({
      from: moment(params.forwardDate, 'DDMMYY'),
      to: params.backwardDate ? moment(params.backwardDate, 'DDMMYY') : null,
    }),
  );

  yield* put(
    setPassengers({
      adults: params.adults,
      children: params.children,
      infants: params.infants,
      source: isAvia ? 'avia' : 'train',
    }),
  );

  yield* put(
    setTicketClass({
      id: params.flightClass,
      description: getFlightClassDesc(params.flightClass),
    }),
  );
}

export default function* orderFlow() {
  yield* all([
    takeLatest(initializeSimpleSearch.getType(), initializeWorker),
    takeLatest(setDestination.getType(), setDestinationWorker),
    takeLatest(updateSearchFormState.getType(), updateFormSearchStateFlow),
  ]);
}

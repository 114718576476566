import {
  GetOrderByNumberDto,
  GetCustomerOrdersDto,
  PaymentDto,
  GetOrdersListPayload,
  PayOrderPayload,
  NotifyOrderPayload,
  CancelOrderPositionPayload,
  PrintRefundPayload,
  HistoryDto,
  GetAddictionalServicesDto,
  AddAddictionalServicePayload,
  DeleteAddictionalServicePayload,
  ActiveCalculationsDto,
  GetOrderPaymentsDto,
  CreatePaymentEntity,
  GetQRCodeDto,
} from './types';
import { Api } from '@utils';
import { ORDER_SERVICE_HOST } from '@modules/constants';
import {
  getOrderByNumberNormalizer,
  getOrdersListNormalizer,
  availableInsurancesNormalizer,
} from './normalizer';
import { trackPromise } from 'react-promise-tracker';
import { RailwayReceiptData } from '@screens/Railway/RailwayReceipt';

const { CONTENT_TYPE } = Api;

const ORDER_URI = process.env.REACT_APP_ORDER_API as string;

const END_POINTS = {
  getByNumber: process.env.REACT_APP_GET_ORDER_BY_NUMBER as string,
  getOrderList: process.env.REACT_APP_GET_CUSTOMER_ORDERS as string,
  payment: process.env.REACT_APP_CREATE_ORDER_PAYMENT as string,
  print: process.env.REACT_APP_PRINT_ORDER_E_TICKETS as string,
  notifyPayment: process.env.REACT_APP_NOTIFY_ABOUT_ORDER_PAYMENT as string,
  cancelOrderPosition: process.env.REACT_APP_CANCEL_ORDER_POSITION as string,
  getActiveCalculations: process.env
    .REACT_APP_GET_ACTIVE_CALCULATIONS as string,
};

const createOrderFetchUrl = Api.createFetchUrl(ORDER_SERVICE_HOST, ORDER_URI);

/**
 * action: @orders/detail/setData
 * saga: detailOrderLoaderSaga
 */
export const getOrderByNumber = (orderNumber: number) =>
  Api.request(
    {
      url: createOrderFetchUrl(END_POINTS.getByNumber, {
        OrderNumber: orderNumber,
      }),
      headers: {
        'Content-Type': CONTENT_TYPE.JSON,
      },
    },
    'AUTH'
  )
    .then(Api.statuses)
    .then((response) => Api.json(response) as Promise<GetOrderByNumberDto>)
    .then(getOrderByNumberNormalizer);

/**
 * action: @orders/getList
 * saga: ordersListLoaderSaga
 */
export const getOrdersList = ({
  page,
  pageSize,
  paymentStatuses,
  orderPositionType,
  dateSorting,
  number,
  passangerFIO,
}: GetOrdersListPayload): Promise<GetCustomerOrdersDto> =>
  Api.request(
    {
      url: createOrderFetchUrl(END_POINTS.getOrderList, {
        Page: page,
        PageSize: pageSize,
        'Filter.OrderPaymentStatus': paymentStatuses,
        OrderPositionType: orderPositionType,
        SortByCreateDate: dateSorting,
        'SearchFilter.OrderNumber': number,
        'SearchFilter.PassengerName': passangerFIO,
      }),
      headers: { 'Content-Type': CONTENT_TYPE.JSON },
    },
    'AUTH'
  )
    .then(Api.statuses)
    .then((response) => Api.json(response) as Promise<GetCustomerOrdersDto>)
    .then(getOrdersListNormalizer);

/**
 * action: @orders/payOrderRequest
 * saga: payOrderSaga
 */
export const payOrder = (payload: PayOrderPayload): Promise<PaymentDto> =>
  Api.request(
    {
      url: createOrderFetchUrl(END_POINTS.payment),
      method: 'PUT',
      body: JSON.stringify({
        ...payload,
        correlationId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      }),
      headers: { 'Content-Type': CONTENT_TYPE.JSON },
    },
    'AUTH'
  )
    .then(Api.statuses)
    .then((response) => Api.json(response));

/**
 * Fetching order receipt
 * action: @orders/printOrderRequest
 * saga: printOrderSaga
 */
export const printOrder = (orderId: string, orderPositionId: string) =>
  Api.request(
    {
      url: createOrderFetchUrl(END_POINTS.print, { orderId, orderPositionId }),
      headers: { 'Content-Type': CONTENT_TYPE.TEXT },
    },
    'AUTH'
  )
    .then(Api.statuses)
    .then(Api.text);

/**
 * action: @orders/confirmPaymentRequest
 * saga: paymentConfirmSaga
 */
export const notifyPayment = (payload: NotifyOrderPayload) => {
  return Api.request({
    url: createOrderFetchUrl(END_POINTS.notifyPayment),
    method: 'PUT',
    body: JSON.stringify({
      ...payload,
    }),
    headers: { 'Content-Type': CONTENT_TYPE.JSON },
  });
};

export const cancelOrderPosition = (payload: CancelOrderPositionPayload) =>
  trackPromise(
    Api.request(
      {
        url: createOrderFetchUrl(END_POINTS.cancelOrderPosition),
        method: 'DELETE',
        body: JSON.stringify({
          ...payload,
        }),
        headers: { 'Content-Type': CONTENT_TYPE.JSON },
      },
      'AUTH'
    )
  );

export const printRefund = (payload: PrintRefundPayload) =>
  Api.request(
    {
      url: createOrderFetchUrl('/print-refund-blank', { ...payload }),
      method: 'GET',
      headers: { 'Content-Type': CONTENT_TYPE.JSON },
    },
    'AUTH'
  );
/** api/AdditionalService/additional-services?OrderNumber=30018 */
export const getAddictionalServicesByOrderNumber = (
  orderNumber: number
): Promise<GetAddictionalServicesDto> =>
  Api.request(
    {
      url: `${process.env.REACT_APP_GET_ADDICTIONAL_SERVICES}?orderNumber=${orderNumber}`,
      method: 'GET',
      headers: { 'Content-Type': CONTENT_TYPE.JSON },
    },
    'AUTH'
  )
    .then(Api.statuses)
    .then(Api.json);

export const getHistory = (orderId: string): Promise<HistoryDto> =>
  Api.request(
    {
      url: `${process.env.REACT_APP_GET_ORDER_HISTORY}?eventStreamId=${orderId}`,
      method: 'GET',
      headers: { 'Content-Type': CONTENT_TYPE.JSON },
    },
    'AUTH'
  )
    .then(Api.statuses)
    .then(Api.json);

export const deleteAddictionalService = (
  payload: DeleteAddictionalServicePayload
): Promise<GetAddictionalServicesDto> =>
  Api.request(
    {
      url: `${process.env.REACT_APP_GET_ADDICTIONAL_SERVICES}`,
      method: 'DELETE',
      headers: { 'Content-Type': CONTENT_TYPE.JSON },
      body: JSON.stringify(payload),
    },
    'AUTH'
  )
    .then(Api.statuses)
    .then(Api.json);

export const addAddictionalServicer = (
  payload: AddAddictionalServicePayload
): Promise<GetAddictionalServicesDto> =>
  Api.request(
    {
      url: `${process.env.REACT_APP_GET_ADDICTIONAL_SERVICES}`,
      method: 'POST',
      headers: { 'Content-Type': CONTENT_TYPE.JSON },
      body: JSON.stringify(payload),
    },
    'AUTH'
  )
    .then(Api.statuses)
    .then(Api.json);

export const getActiveCalculations = (
  payload: string
): Promise<ActiveCalculationsDto> =>
  Api.request(
    {
      url:
        process.env.REACT_APP_GET_ACTIVE_CALCULATIONS + '?OrderId=' + payload,
      method: 'GET',
      headers: { 'Content-Type': CONTENT_TYPE.JSON },
    },
    'AUTH'
  )
    .then(Api.statuses)
    .then(Api.json);

export const agentDoPayment = (
  orderId: string
): Promise<{
  creditPaid: boolean;
  creditEnough: boolean;
  errorText: 'string';
}> =>
  Api.request(
    {
      url: `${process.env.REACT_APP_ORDER_SERVICE_HOST2}/agent-do-payment`,
      method: 'POST',
      headers: { 'Content-Type': CONTENT_TYPE.JSON },
      body: JSON.stringify({ orderId }),
    },
    'AUTH'
  )
    .then(Api.statuses)
    .then(Api.json);

export const issueTicketsByCreditOrInvoice = (
  orderId: string,
  typePaid: string,
  userId?: string | null
): Promise<any> =>
  trackPromise(
    Api.request(
      {
        url: `${process.env.REACT_APP_ORDER_SERVICE_HOST2}/issue-tickets-by-credit-or-invoice`,
        method: 'POST',
        headers: { 'Content-Type': CONTENT_TYPE.JSON },
        body: JSON.stringify({ orderId, typePaid, userId }),
      },
      'AUTH'
    )
      .then(Api.statuses)
      .then(Api.json)
  );

export const getAgentBillByOrderId = (
  orderId: string
): Promise<{
  creditPaid: boolean;
  creditEnough: boolean;
  errorText: 'string';
}> =>
  Api.request(
    {
      url: `${process.env.REACT_APP_ORDER_SERVICE_HOST}/Order/get-agent-bill-by-order-id?orderId=${orderId}`,
      method: 'GET',
      headers: { 'Content-Type': CONTENT_TYPE.JSON },
    },
    'AUTH'
  )
    .then(Api.statuses)
    .then(Api.json);

export const getOrderPayments = (
  orderNumber: string
): Promise<GetOrderPaymentsDto> =>
  Api.request(
    {
      url: `${process.env.REACT_APP_ORDER_SERVICE_HOST}/Order/get-order-payments?orderNumber=${orderNumber}`,
      method: 'GET',
      headers: { 'Content-Type': CONTENT_TYPE.JSON },
    },
    'AUTH'
  )
    .then(Api.statuses)
    .then(Api.json);

export const issueOrderPosition = (
  orderNumber: number,
  orderPositionId: string
): Promise<{ correlationId?: string }> =>
  trackPromise(
    Api.request(
      {
        url:
          process.env.REACT_APP_MODE !== 'test'
            ? `${process.env.REACT_APP_ORDER_SERVICE_HOST3}/order/external/order/issue-order-position`
            : `${process.env.REACT_APP_ORDER_SERVICE_HOST}/Order/issue-order-position`,
        method: 'POST',
        headers: { 'Content-Type': CONTENT_TYPE.JSON },
        body: JSON.stringify({
          orderNumber,
          orderPositionId,
        }),
      },
      'AUTH'
    )
      .then(Api.statuses)
      .then(Api.json)
  );

export function getAvailableInsurances(positionId: string) {
  return Api.request(
    {
      url: `${process.env.REACT_APP_ORDER_SERVICE_HOST}/insurances/get-available-insurances?orderPositionId=${positionId}`,
      method: 'GET',
      headers: { 'Content-Type': CONTENT_TYPE.JSON },
    },
    'AUTH'
  )
    .then(Api.statuses)
    .then(Api.json)
    .then(availableInsurancesNormalizer);
}

export function addInsurance(
  orderPositionId: string,
  insuranceProductIds: string[]
) {
  return Api.request(
    {
      url:
        process.env.REACT_APP_MODE !== 'test'
          ? `${process.env.REACT_APP_ORDER_SERVICE_HOST3}/order/external/add-insurances-to-order`
          : `${process.env.REACT_APP_ORDER_SERVICE_HOST3}/external/add-insurances-to-order`,
      method: 'POST',
      headers: { 'Content-Type': CONTENT_TYPE.JSON },
      body: JSON.stringify({
        orderPositionId,
        insuranceProductIds,
      }),
    },
    'AUTH'
  )
    .then(Api.statuses)
    .then(Api.json);
}

export function deleteInsurance(
  orderPositionId: string,
  insuranceProductIds: string[]
) {
  return Api.request(
    {
      url:
        process.env.REACT_APP_MODE !== 'test'
          ? `${process.env.REACT_APP_ORDER_SERVICE_HOST3}/order/external/remove-insurances-from-order`
          : `${process.env.REACT_APP_ORDER_SERVICE_HOST3}/external/remove-insurances-from-order`,
      method: 'DELETE',
      headers: { 'Content-Type': CONTENT_TYPE.JSON },
      body: JSON.stringify({
        orderPositionId,
        insuranceProductIds,
      }),
    },
    'AUTH'
  )
    .then(Api.statuses)
    .then(Api.json);
}

export const createPayment = (payload: CreatePaymentEntity) =>
  Api.request(
    {
      url:
        process.env.REACT_APP_MODE !== 'test'
          ? `${process.env.REACT_APP_ORDER_SERVICE_HOST3}/order/external/create-payment2`
          : `${process.env.REACT_APP_ORDER_SERVICE_HOST3}/payment2/create-payment`,
      method: 'PUT',
      headers: { 'Content-Type': CONTENT_TYPE.JSON },
      body: JSON.stringify(payload),
    },
    'AUTH'
  )
    .then(Api.statuses)
    .then(Api.json);

/** api/payment2/notify-payment */
export const notifyPayment2 = (payload: NotifyOrderPayload, token?: any) =>
  Api.request({
    url:
      process.env.REACT_APP_MODE !== 'test'
        ? `${process.env.REACT_APP_ORDER_SERVICE_HOST3}/order/external/notify-payment`
        : `${process.env.REACT_APP_ORDER_SERVICE_HOST3}/payment2/notify-payment`,
    method: 'PUT',
    body: JSON.stringify({
      ...payload,
    }),
    headers: {
      'Content-Type': CONTENT_TYPE.JSON,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(Api.statuses)
    .then(Api.json);

export const createPaymentTinkoff = (payload: CreatePaymentEntity) =>
  // trackPromise(
  Api.request(
    {
      url:
        process.env.REACT_APP_MODE !== 'test'
          ? `${process.env.REACT_APP_ORDER_SERVICE_HOST3}/order/external/create-payment`
          : `${process.env.REACT_APP_ORDER_SERVICE_HOST3}/order/create-payment`,
      method: 'PUT',
      headers: { 'Content-Type': CONTENT_TYPE.JSON },
      body: JSON.stringify(payload),
    },
    'AUTH'
  )
    .then(Api.statuses)
    .then(Api.json);

export function getQr(data: GetQRCodeDto) {
  return Api.request(
    {
      url:
        process.env.REACT_APP_MODE !== 'test'
          ? `${process.env.REACT_APP_ORDER_SERVICE_HOST3}/order/external/get-qr`
          : `${process.env.REACT_APP_ORDER_SERVICE_HOST3}/order/get-qr`,

      method: 'POST',
      headers: { 'Content-Type': CONTENT_TYPE.JSON },
      body: JSON.stringify(data),
    },
    'AUTH'
  )
    .then(Api.statuses)
    .then(Api.json);
}

// );

export function promocodeApply(data: { orderId: string; promocode: string }) {
  return Api.request(
    {
      url:
        process.env.REACT_APP_MODE !== 'test'
          ? `${process.env.REACT_APP_ORDER_SERVICE_HOST3}/order/external/promo/apply`
          : `${process.env.REACT_APP_ORDER_SERVICE_HOST3}/Promo/apply`,
      method: 'POST',
      headers: { 'Content-Type': CONTENT_TYPE.JSON },
      body: JSON.stringify(data),
    },
    'AUTH'
  )
    .then(Api.statuses)
    .then(Api.json);
}

export function getAncillaryNew(OrderPositionId: string): Promise<any> {
  return Api.request(
    {
      url: `${process.env.REACT_APP_ORDER_SERVICE_HOST}/Order/get-ancillary-services?OrderPositionId=${OrderPositionId}`,
      method: 'GET',
      headers: { 'Content-Type': CONTENT_TYPE.JSON },
    },
    'AUTH'
  )
    .then(Api.statuses)
    .then((response) => Api.json(response));
}

export const addAncillaryNew = (data: any): Promise<any> =>
  trackPromise(
    Api.request(
      {
        url: `${process.env.REACT_APP_ORDER_SERVICE_HOST}/Order/add-ancillary-services`,
        method: 'POST',
        headers: { 'Content-Type': CONTENT_TYPE.JSON },
        body: JSON.stringify(data),
      },
      'AUTH'
    )
      .then(Api.statuses)
      .then(Api.json)
  );

export function getRailwayOrderReceipt(
  orderId: string
): Promise<RailwayReceiptData> {
  return Api.request(
    {
      url:
        process.env.REACT_APP_MODE !== 'test'
          ? `${process.env.REACT_APP_ORDER_SERVICE_HOST3}/order/external/get-railway-order-receipt?orderNumber=${orderId}`
          : `${process.env.REACT_APP_ORDER_SERVICE_HOST3}/order/get-railway-order-receipt?orderNumber=${orderId}`,
      method: 'GET',
      headers: { 'Content-Type': CONTENT_TYPE.JSON },
    },
    'AUTH'
  )
    .then(Api.statuses)
    .then(Api.json);
}

export function getRailwayOrderRefundReceipt(
  orderId: string,
  orderItemBlankId: string
): Promise<RailwayReceiptData> {
  return Api.request(
    {
      url:
        process.env.REACT_APP_MODE !== 'test'
          ? `${process.env.REACT_APP_ORDER_SERVICE_HOST3}/order/external/get-railway-order-refund-receipt?orderNumber=${orderId}&orderItemBlankId=${orderItemBlankId}`
          : `${process.env.REACT_APP_ORDER_SERVICE_HOST3}/order/get-railway-order-refund-receipt?orderNumber=${orderId}&orderItemBlankId=${orderItemBlankId}`,
      method: 'GET',
      headers: { 'Content-Type': CONTENT_TYPE.JSON },
    },
    'AUTH'
  )
    .then(Api.statuses)
    .then(Api.json);
}

import React from 'react';
import { uniqWith, isEqual } from 'lodash';
import styled from 'styled-components';
import { Text } from '@components/ui';
import { FareCondition } from '@components/common';
import { TariffProps } from './types';
import CheckedIconSrc from '@assets/media/tariff-features/check.svg';
import CrossIconSrc from '@assets/media/tariff-features/cross.svg';
import RubleIconSrc from '@assets/media/tariff-features/ruble.svg';
import { ReactComponent as InfoIcon } from '@assets/media/info-icon.svg';
import { useOrderPosition } from './useItemProps';
import { FareFamily, getRoutes } from '@modules/orders';
import { Helper } from '@utils';

interface Route {
  cityName: string;
  cityCode: string;
}

const Title = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #3c3c3c;

  @media (max-width: 767px) {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
  }
`;

const BlueTitle = styled(Title)`
  color: ${({ theme }) => theme.colors.main};
`;

const Feature = styled.div`
  display: flex;
  align-items: flex-start;

  & > span {
    display: block;
    margin-left: 5px;
  }
`;

const FeaturesWrapper = styled.div`
  display: block;

  & > div {
    margin-bottom: 15px;
  }
`;

const DetailButton = styled(Text)`
  color: ${({ theme }) => theme.colors.main};
  cursor: pointer;
  padding-top: 15px;
  display: flex;
  align-items: center;

  & > svg {
    width: 15px;
    height: 15px;
    margin-right: 6.5px;
  }
`;

const RowText = styled(Text)`
  white-space: pre-line;
`;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`;

const FareFamilyItem = styled.div`
  margin-bottom: 15px;

  & + & {
    margin-top: 45px;
  }
`;

const FareFamilyItemHead = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const CheckIcon = () => <img src={CheckedIconSrc} alt="" />;

const CrossIcon = () => <img src={CrossIconSrc} alt="" />;

const RubleIcon = () => <img src={RubleIconSrc} alt="" />;

function getStatusIcon(x: 'Included' | 'NotOffered' | 'AtCharge') {
  switch (x) {
    case 'Included':
      return <CheckIcon />;
    case 'NotOffered':
      return <CrossIcon />;
    case 'AtCharge':
      return <RubleIcon />;
  }
}

const Tariff: React.FC<TariffProps> = (props, isBookingSecondModal) => {
  const fareFamilies = React.useMemo(() => {
    return props.fareFamilies ? getUniqueFareFamilies(props.fareFamilies) : [];
  }, [props]);

  const isMultipleFareFamilies = fareFamilies.length > 1;

  const fareFamilyNames = React.useMemo(() => {
    if (!props.fareFamilies) return '';
    const uniqueFareFamilies = getUniqueFareFamilies(props.fareFamilies);
    return uniqueFareFamilies.length > 1
      ? uniqueFareFamilies.map((fareFamily) => fareFamily.name).join(' + ')
      : uniqueFareFamilies[0]?.name || '';
  }, [props.fareFamilies]);

  const price = Helper.formatPrice(
    props.adultPrice + props.childPrice + props.infantPrice,
  );

  const [isPopup, setPopup] = React.useState(false);

  const orderPosition = useOrderPosition();
  const position = props.position || orderPosition;

  const allRoutes: Route[] = React.useMemo(() => {
    const routes = position?.segmentGroups?.reduce((acc: Route[], group) => {
      group?.segments?.forEach((segment) => {
        acc.push({
          cityName: segment.departureCity.name,
          cityCode: segment.departureAirport.code,
        });
        acc.push({
          cityName: segment.arrivalCity.name,
          cityCode: segment.arrivalAirport.code,
        });
      });
      return acc;
    }, []);
    return uniqWith(routes, isEqual);
  }, [position]);

  const detailButtonContainer = () => {
    return (
      <>
        <DetailButton onClick={() => setPopup(true)}>
          <InfoIcon />
          Посмотреть правила тарифа
        </DetailButton>
        <FareCondition
          open={isPopup}
          onClose={() => setPopup(false)}
          fare={fareFamilyNames || ''}
          routes={getRoutes(position)}
        />
      </>
    );
  };

  const fareFamilyItem = (fareFamily: FareFamily, index: number) => {
    const routeTitles = isMultipleFareFamilies
      ? fareFamily.routes
          ?.map((route) => {
            const departureCity = allRoutes.find(
              (r) => r.cityCode === route.from,
            )?.cityName;
            const arrivalCity = allRoutes.find(
              (r) => r.cityCode === route.to,
            )?.cityName;
            return `${departureCity} — ${arrivalCity}`;
          })
          .join(', ')
      : '';

    return (
      <>
        <FareFamilyItemHead>
          <Title>
            {fareFamily.name}
            {routeTitles ? ` (${routeTitles})` : ''}
          </Title>
        </FareFamilyItemHead>
        <FeaturesWrapper>
          {fareFamily?.features?.map((item, index) => (
            <Feature key={index}>
              {getStatusIcon(item.applicability as any)}
              <RowText>
                {getTitle(item.type)}
                {item.descriptionRus}
              </RowText>
            </Feature>
          ))}
        </FeaturesWrapper>
        {!isMultipleFareFamilies && detailButtonContainer()}
      </>
    );
  };

  return (
    <div>
      <Head>
        <Title>
          Тариф{fareFamilies.length > 1 ? 'ы' : ''} перелёта: {fareFamilyNames}{' '}
          <BlueTitle>{price}</BlueTitle>
          {isMultipleFareFamilies && detailButtonContainer()}
        </Title>
      </Head>
      {fareFamilies?.map((fareFamily, index) => (
        <FareFamilyItem key={index}>
          {fareFamilyItem(fareFamily, index)}
        </FareFamilyItem>
      ))}
    </div>
  );
};

function getTitle(type: string) {
  switch (type) {
    case 'Baggage':
      return 'Багаж: ';
    case 'CarryOn':
      return 'Ручная кладь: ';
    case 'Exchange: ':
      return 'Обмен: ';
    case 'Refund':
      return 'Возврат: ';
    default:
      return '';
  }
}

function getUniqueFareFamilies(fareFamilies: FareFamily[]) {
  const uniqueNames = new Set();
  return fareFamilies.filter((fareFamily) => {
    if (uniqueNames.has(fareFamily.name)) {
      return false;
    }
    uniqueNames.add(fareFamily.name);
    return true;
  });
}

export default Tariff;

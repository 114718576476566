import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Train, CAR_TYPES, openTrainHandler } from '@modules/trainTickets';
import { Text as BaseText } from '@components/ui';
import { Helper } from '@utils';
import { useDispatch } from 'react-redux';

import DetailInfo from './DetailInfo';
import { useMediaQuery } from 'react-responsive';
import { RailwayMainTicketInfo } from '@components/train/TrainSearch/Ticket/RailwayMainTicketInfo';
import { CYPRESS_RAILWAY_ATTRS } from '../../../../cypress/railway';

interface Props {
  trainIndexFromState: number;
  train: Train;
  isFastest?: boolean;
  isCheapest?: boolean;
  hasRoundTrip?: boolean;
  isLoading?: boolean;
}

const Wrapper = styled.div<{ isSelected: boolean }>`
  border: ${({ isSelected }) =>
    isSelected ? '1px solid #4872f2' : '1px solid #dcdcdc'};
  border-radius: 8px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
`;

const Padding = styled.div`
  padding: 0 11px 0 28px;

  @media (max-width: 767px) {
    padding: 14px;
  }
`;

const MainInfo = styled.div`
  display: flex;
  min-height: 195px;

  & > div {
    padding-block: 20px;
  }

  @media (max-width: 767px) {
    & > div {
      padding-block: 0;
    }
  }

  & > :first-child {
    flex-grow: 1;
    padding-right: 18px;
    margin-right: 18px;
    box-sizing: border-box;
    border-right: 1px solid #dcdcdc;

    @media (max-width: 767px) {
      padding-right: 0;
      margin-right: 0;
      border-right: 0;
    }
  }

  & > :last-child {
    width: 235px;
    flex-shrink: 0;
    box-sizing: border-box;
  }
`;

const LeftSideTitle = styled(BaseText)`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #737373;
  display: block;
  margin-bottom: 10px;

  @media (max-width: 767px) {
    color: #3c3c3c;
  }
`;

const CarButtonList = styled.div`
  margin-bottom: 20px;

  @media (max-width: 767px) {
    margin-bottom: 0;
  }

  & > button {
    margin-bottom: 8px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

const CarButton = styled.button<{ active: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  outline: none;
  border-radius: 4px;
  padding: 6px;
  cursor: pointer;
  width: 100%;

  ${({ active }) =>
    active
      ? 'border: 1px solid #4872F2; background: #edf1fe;'
      : 'border: 1px solid #dcdcdc; background: transparent;'}
  &:hover {
    border: 1px solid #9d9b9b;
  }
`;

const CarButtonText = styled(BaseText)`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #3c3c3c;
`;

const CarButtonTextMuted = styled(CarButtonText)`
  color: #737373;
`;

const RightTicketPart = styled.div``;

export function Ticket(props: Props) {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { trainIndexFromState, train } = props;
  const trainIsSelected = trainIndexFromState === train?.index;
  const [selectedCarGroup, setSelectedCarGroup] =
    useState<keyof typeof CAR_TYPES>('Unknown');

  useEffect(() => {
    setSelectedCarGroup('Unknown');
  }, [train]);

  const carsSelectList = () => {
    return (
      <RightTicketPart>
        <LeftSideTitle>Выберите вагон:</LeftSideTitle>
        <CarButtonList>
          {train.carGroups.map((x, key) => (
            <CarButton
              active={selectedCarGroup === x.carType && trainIsSelected}
              disabled={props.isLoading}
              key={key}
              onClick={() => {
                setSelectedCarGroup(x.carType);
                dispatch(
                  openTrainHandler({
                    index: train.index,
                    carType: x.carType,
                  })
                );
              }}
            >
              <CarButtonText
                data-cy={
                  CYPRESS_RAILWAY_ATTRS.TICKET_CAR_TYPE_AND_PLACE_COUNTERS
                }
              >
                {CAR_TYPES[x.carType]}
                {x.placeQuantitiesDetalization.totalPlaceQuantity > 0 && (
                  <CarButtonTextMuted>
                    , мест: {x.placeQuantitiesDetalization.totalPlaceQuantity}
                  </CarButtonTextMuted>
                )}
              </CarButtonText>
              <CarButtonText>
                от {Helper.formatPrice(x.minPrice.totalPrice)}
              </CarButtonText>
            </CarButton>
          ))}
        </CarButtonList>
      </RightTicketPart>
    );
  };

  return (
    <Wrapper isSelected={trainIsSelected}>
      <Padding>
        <MainInfo>
          <RailwayMainTicketInfo
            isCheapest={props.isCheapest}
            isFastest={props.isFastest}
            hasRoundTrip={props.hasRoundTrip}
            train={props.train}
            carsSelectList={carsSelectList}
          />
          {!isMobile && carsSelectList()}
        </MainInfo>
      </Padding>
      {trainIsSelected && <DetailInfo />}
    </Wrapper>
  );
}

import React, { useEffect, useState } from 'react';
import { Wrapper, Input, Plus, Minus } from './styles';
import { CYPRESS_BASE_ATTRS } from '../../../../cypress/base';

export type CounterChangeHandler = (
  value: number,
  name: string | undefined
) => void;

export interface CounterProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  /** Минимальное число инпута */
  min?: number;

  /** максимальное число инпута */
  max?: number;

  /** Коллбек при изменение инпута */
  onCustomChange?: CounterChangeHandler;

  value?: number;

  /** Outlined styles */
  outlined?: boolean;

  /** data-cy attribute */
  postFixDataCy: string;
}

export const Counter: React.FC<CounterProps> = ({
  max = 0,
  min = 0,
  onCustomChange,
  value,
  outlined = false,
  postFixDataCy,
  ...props
}) => {
  const [count, setCount] = useState(value || min);

  useEffect(() => {
    if (value && value !== count) {
      setCount(value);
    }
  }, [value]);

  const handleMinus: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    const minVal = min;
    if (count - 1 < minVal) {
      return;
    }
    typeof onCustomChange === 'function' &&
      onCustomChange(count - 1, props.name);
    setCount(count - 1);
  };

  const handlePlus: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    if (max && count + 1 > max) {
      return;
    }
    typeof onCustomChange === 'function' &&
      onCustomChange(count + 1, props.name);
    setCount(count + 1);
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setCount(parseInt(e.target.value));
    typeof onCustomChange === 'function' &&
      onCustomChange(parseInt(e.target.value), props.name);
  };

  return (
    <Wrapper>
      <Minus
        data-cy={`${CYPRESS_BASE_ATTRS.PASSENGERS_COUNTER_MINUS}${postFixDataCy}`}
        outlined={outlined}
        disabled={count <= min}
        onClick={handleMinus}
      />
      <Input onChange={handleChange} value={count} {...props} />
      <Plus
        data-cy={`${CYPRESS_BASE_ATTRS.PASSENGERS_COUNTER_PLUS}${postFixDataCy}`}
        outlined={outlined}
        disabled={count >= max}
        onClick={handlePlus}
      />
    </Wrapper>
  );
};

import React, { useMemo } from 'react';
import { Text } from '@components/ui';
import styled from 'styled-components';
import { cityIn } from 'lvovich';
import { Helper } from '@utils';
import PlaneIcon from '@assets/images/plane';
import ManIcon from '@assets/images/man';
import { Stop } from '@components/common';
import { TechLandings } from '@components/common/FlightSegment/types';
import Tippy from '@tippyjs/react';
import techInfoIcon from '@assets/media/flight-search/techInfoIcon.svg';

interface Props {
  terminals: string[];
  operatingCompany: string;
  baggage?: string;
  stops: Stop[];
  flightNo: string;
  aircraft: string;
  duration: number;
  airlineCode: string;
  techLandings?: TechLandings[];
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const List = styled.ul`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  padding-left: 0;
  width: 90%;
`;

const DescriptionBlock = styled.div`
  background: #f7f7f7;
  border-radius: 4px;
  padding: 10px 12px;
  //margin-top: 10px;

  & > div {
    margin-bottom: 7px;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    padding: 8px;
  }
`;

const DescriptionText = styled(Text)`
  font-size: 12px;
  line-height: 16px;
  color: #3c3c3c;
`;

const ListItem = styled.li`
  display: flex;
  flex-direction: column;
  border-left: 1px solid #dcdcdc;
  padding-left: 14px;

  & > :first-child {
    border-left: 0;
  }
`;

const Description = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledPlaneIcon = styled(PlaneIcon)`
  width: 20px;
  height: 20px;
  fill: #737373;
  margin-right: 10px;
`;

const StyledManIcon = styled(ManIcon)`
  width: 16px;
  height: 16px;
  fill: #737373;
  margin-right: 14px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const TechLanding = styled.img.attrs({ src: techInfoIcon })`
  margin-right: 5px;
  width: 16px;
  height: 16px;
`;

const TechLandingBlock = styled.div`
  padding: 4px 8px 4px 8px;
  background: #ffd3ca;
  border-radius: 8px;
  width: 165px;
  font-size: 12px;
  cursor: pointer;
`;

const NewAdditionalInfo: React.FC<Props> = (props) => {
  const {
    terminals,
    operatingCompany,
    baggage,
    stops,
    flightNo,
    aircraft,
    duration,
    airlineCode,
    techLandings,
  } = props;
  const formattedDuration = `В пути: ${Helper.formatDuration(duration)}`;

  const Item = (label: string, value: string) => {
    return (
      <ListItem>
        <Text color={'#737373'}>{label}</Text>
        <Text>{value}</Text>
      </ListItem>
    );
  };

  const flightString = useMemo(() => {
    let result: string = '';
    if (flightNo) {
      result += `Рейс ${airlineCode} ${flightNo}`;
    }
    if (aircraft) {
      result += ` • ${aircraft}`;
    }
    return result;
  }, [flightNo, aircraft]);

  return (
    <Wrapper>
      <List>
        {Item(
          'Терминалы: ',
          terminals.length ? terminals.join(', ') : 'Нет информации',
        )}
        {Item('Рейс выполняет: ', operatingCompany)}
        {Item('Багаж: ', baggage || 'Без багажа')}
      </List>
      <DescriptionBlock>
        <Description>
          <Row>
            <StyledPlaneIcon />
            <DescriptionText>{flightString}</DescriptionText>
          </Row>
          <Row>
            <DescriptionText> {formattedDuration}</DescriptionText>
          </Row>
        </Description>
        {props.techLandings && techLandings?.length !== 0 && (
          <Description>
            <Tippy
              content={
                <>
                  Техническая остановка в аэропорту{' '}
                  <b>{props.techLandings[0].city.airport}</b>,{' '}
                  <b>{props.techLandings[0].city.city}</b>. Длительность
                  остановки <b>{props.techLandings[0].duration}</b> минут.
                </>
              }
              theme="light"
              maxWidth={245}
            >
              <TechLandingBlock>
                <Description>
                  <TechLanding />
                  <DescriptionText style={{ color: '#E73C3E', marginRight: 5 }}>
                    Техническая остановка
                  </DescriptionText>
                </Description>
              </TechLandingBlock>
            </Tippy>
          </Description>
        )}

        {stops.length > 0 &&
          stops.map((x, key) => (
            <Description key={key}>
              <Row>
                <StyledManIcon />
                <DescriptionText>
                  Пересадка в {cityIn(x.city.city)}{' '}
                  {Helper.formatDuration(x.duration)}
                </DescriptionText>{' '}
              </Row>
            </Description>
          ))}
      </DescriptionBlock>
    </Wrapper>
  );
};

export default NewAdditionalInfo;

import React, { useMemo } from 'react';
import styled from 'styled-components';
import { SegmentProps } from './types';
import Terminals from './Terminals';
import SegmentInfo from './SegmentInfo';

import AdditionalInfo from './AdditionalInfo';
import { InfoBlock } from '@components/common';
import { Transfer } from '@components/common/FlightSegment';
import { Helper } from '@utils';
import { useOrderPosition } from '../useItemProps';

const Container = styled.div``;

const Body = styled(InfoBlock)`
  display: grid;
  grid-template-columns: 4fr 60px 1.25fr;
  row-gap: 14px;
  border: none;
  padding: 0;
  box-shadow: none;
`;

const StyledTransfer = styled(Transfer)`
  margin: 15px 0 20px 0;
`;

/**
 * Сегмент перелёта в деталях заказа в ЛК
 * @param className
 * @param props
 * @constructor
 */
const Segment: React.FC<SegmentProps> = ({ className, ...props }) => {
  const orderPosition = useOrderPosition();
  const arrivalAirportCode = props.arrivalAirport.code;
  const departureAirportCode = props.departureAirport.code;
  let baggageFeature;
  const fromToRoute = { from: departureAirportCode, to: arrivalAirportCode };

  const fare = orderPosition.tariff.fareFamilies
    ? orderPosition.tariff.fareFamilies.find((fareFamily) => {
        return fareFamily?.routes?.some((route) => {
          return route.from === fromToRoute.from && route.to === fromToRoute.to;
        });
      })
    : null;

  if (fare) {
    baggageFeature = fare.features.find((el) => el.type === 'Baggage');
  }

  const baggageFormatted = baggageFeature
    ? baggageFeature?.descriptionRus
    : 'неизвестно';
  const transferProps = useMemo(() => {
    return props.stopOvers[0]
      ? {
          ...props.stopOvers[0],
          city: props.arrivalCity.name || '',
        }
      : null;
  }, [props.stopOvers]);

  const logoUrl = Helper.getAirlinesLogoUrl(props.operatingAirline.code);
  return (
    <Container className={className}>
      <Body>
        <SegmentInfo {...props} logoUrl={logoUrl} />
        <div />
        <AdditionalInfo {...props} baggage={baggageFormatted} />
        <Terminals {...props} />
      </Body>
      {transferProps && <StyledTransfer {...transferProps} />}
    </Container>
  );
};

export default Segment;

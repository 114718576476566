import React from 'react';
import Text from '@components/ui/Typography';
import styled from 'styled-components';
import { AdditionalInfoProps } from './types';
import { Helper } from '@utils';
import baggageSrc from '@assets/media/shared/baggage.svg';

const Container = styled.div`
  & > span {
    display: block;
  }
  max-width: 204px;
  overflow: hidden;
  & > div {
    margin-bottom: 10px;
  }
  & > :last-child {
    margin-bottom: 0;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  & > :first-child {
    margin-right: 5px;
  }
  & > span {
    font-weight: 600;
  }
`;

const AdditionalInfo: React.FC<AdditionalInfoProps> = (props) => (
  <Container>
    <Row>
      <img
        src={Helper.getAirlinesLogoUrl(
          props.operatingAirline.code,
          160,
          60,
          true
        )}
        width={80}
        height={30}
        alt={props.operatingAirline.code}
      />
    </Row>
    <Row>
      <>
        <img src={baggageSrc as any} alt="" />
        <Text
          fontWeight="600"
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {props.baggage}
        </Text>
      </>
    </Row>
  </Container>
);

export default AdditionalInfo;

import {
  AppealDetailState,
  AppealListState,
  AddAppealState,
  APPEAL_STEP,
} from './types/types';

export const addAppealInitialState: AddAppealState = {
  STEP: APPEAL_STEP.DEFAULT,
  response: null,
};

export const AppealListInitialState: AppealListState = {
  pageCount: 0,
  tickets: [],
  currPage: 0,
  isLoading: false,
};

export const AppealDetailInitialState: AppealDetailState = {
  id: '',
  number: 3,
  orderNumber: 0,
  flight_Type: 'Regular',
  orderId: '',
  segmentId: null,
  passengers: null,
  fileInfos: [],
  calculations: [],
  passengerPriceTotal: 0,
  subject: {
    code: '',
    description: '',
  },
  status: {
    code: '',
    description: '',
  },
  text: '',
  userId: '',
  createdDate: '',
  calculation: null,
  segments: [
    {
      id: '',
      departureCityCode: '',
      arrivalCityCode: '',
      arrivalCityName: '',
      departureDate: '',
      departureCityName: '',
    },
  ],

  selectedTickets: [
    {
      id: '',
      firstName: '',
      lastName: '',
      eTicketNumber: '',
      patronymic: '',
      userId: '',
      passengerType: '',
      price: {
        basePrice: 0,
        taxes: 0,
        subAgentExtraCharge: 0,
        agentExtraCharge: 0,
        discount: 0,
      },
    },
  ],
  supportTicketCalculationItems: [],
  selectedAncillaryServices: [],
  selectedInsurances: [],
  ticketTypes: [],
};

import React from 'react';
import styled from 'styled-components';
import { Field, RadioGroup, DatePicker, Button, Text } from '@components/ui';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { StepTemplateProps, SecondStepSubmitParams } from './types';
import { FormikGaErrorsHandler } from '@components/common';

const StyledForm = styled(Form)`
  display: grid;
  row-gap: 30px;
`;

const StyledField = styled(Field)`
  width: 100%;
`;

const Label = styled(Text)`
  margin-bottom: 4px;
  display: block;
`;

const DesktopTemplate: React.FC<StepTemplateProps<SecondStepSubmitParams>> = ({
  onSubmit,
}) => {
  const initialValues = {
    firstName: '',
    lastName: '',
    patronymic: '',
    gender: 'Male' as 'Male' | 'Female',
    birthDate: null as null | Date,
  };

  const handleSubmit = (values: typeof initialValues) => onSubmit(values);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={yup.object().shape({
        firstName: yup.string().required('Необходимо заполнить поле'),
        lastName: yup.string().required('Необходимо заполнить поле'),
        patronymic: yup.string(),
        birthDate: yup.date().required('Необходимо заполнить поле').nullable(),
      })}
      onSubmit={handleSubmit}
    >
      {({
        handleChange,
        handleBlur,
        setFieldValue,
        errors,
        submitCount,
        values,
      }) => (
        <StyledForm>
          <div style={{ marginBottom: '-10px' }}>
            <Label color="dark-gray">Пол</Label>
            <RadioGroup
              name="gender"
              flex
              defaultValue="Male"
              onChange={(value) => {
                setFieldValue('gender', value);
              }}
              items={[
                { value: 'Male', label: 'Мужской' },
                { value: 'Female', label: 'Женский' },
              ]}
            />
          </div>

          <StyledField
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.firstName && submitCount > 0 ? errors.firstName : undefined
            }
            name="firstName"
            placeholder="Имя"
          />

          <StyledField
            error={
              errors.lastName && submitCount > 0 ? errors.lastName : undefined
            }
            onChange={handleChange}
            onBlur={handleBlur}
            name="lastName"
            placeholder="Фамилия"
          />

          <StyledField
            error={
              errors.patronymic && submitCount > 0
                ? errors.patronymic
                : undefined
            }
            onChange={handleChange}
            onBlur={handleBlur}
            name="patronymic"
            placeholder="Отчество"
          />

          <DatePicker
            error={
              errors.birthDate && submitCount > 0 ? errors.birthDate : undefined
            }
            value={values.birthDate}
            onChange={(date) => {
              setFieldValue('birthDate', date as Date);
            }}
            placeholder="дд.мм.гггг"
          />

          <Button type="primary" htmlType="submit">
            Создать профиль
          </Button>
          <FormikGaErrorsHandler
            event={{
              category: 'Signup',
              action: 'signup_form_validation_fail',
            }}
            initialValues={initialValues}
          />
        </StyledForm>
      )}
    </Formik>
  );
};

export default DesktopTemplate;

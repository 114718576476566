import {
  getCarPlaceGenderCharByGenderType,
  getCompartmentByPlaceNumber,
} from '@components/train/TrainSearch/utils';
import { simpleSearchUpdateState } from '@modules/simpleSearch';
import { trainSearchPayloadToAirSearchPayload } from '@modules/trainTickets/adaptors';
import { TRAIN_SEARCH_STEPS } from '@modules/trainTickets/constants';
import * as Sentry from '@sentry/react';
import moment from 'moment';
import * as _ from 'lodash';
import { useSelector } from 'react-redux';
import { Action } from 'redux-act';
import { all, call, put, select, spawn, takeLatest } from 'typed-redux-saga';

import {
  carsPurify,
  clearChosenCarPlace,
  clearChosenCarPlaceBackward,
  clearTrainRoute,
  getBackCarsSuccess,
  getCarsFailure,
  getCarsRequest,
  getCarsSuccess,
  getTrainRoute,
  setStep,
  openTrainBackward,
  openTrainForward,
  openTrainHandler,
  resetTrainFilter,
  selectBackCar,
  selectCar,
  setBackCarInfo,
  setCarInfo,
  setCarrierTrainFilter,
  setCarTypeTrainFilter,
  setDurationTrainFilter,
  setGenderTypeOnCarPlace,
  setPricesTrainFilter,
  setSearchStep,
  setTimeTrainFilter,
  setTrainBackward,
  setTrainDiscountsTrainFilter,
  setTrainFilter,
  setTrainForward,
  setTrainNameTrainFilter,
  setTrainRoute,
  setTrainSearchRequestState,
  setTrainServicesTrainFilter,
  setTrainTypeFilter,
  trainSearchFailure,
  trainSearchRequest,
  trainSearchSuccess,
  trainSearchSuccessFilter,
  updateTrainForwardCurrentCarCompartment,
  setBedClothesForward,
  setBedClothesBackward,
  clearGenderTypesOnCarPlaces,
} from './duck';
import * as Manager from './manager';
import {
  filteredTrainTicketsListSelector,
  trainBackSelector,
  trainCurrentSearchStepSelector,
  trainForwardSelector,
  trainTicketsListSelector,
} from './selectors';
import {
  CarPlace,
  ClearGenderTypesOnCarPlacesPayload,
  FreePlacesByCompartment,
  GetCarsPayload,
  GetTicketsListDto,
  GetTrainRoutePayload,
  OpenTicketPayload,
  SetGenderTypeOnCarPlacePayload,
  TrainFilter,
  TrainSearchPayload,
  TrainSearchRequestStatus,
} from './types';
import { createTrainFilter } from './manager';
import { ApplicationState } from '@modules/index';
import {
  clearTrainTicket,
  purifyTrainPassengers,
  setAllowedDocumentTypes,
} from '@modules/trainBooking';

export const useTrainSelector = () => {
  const currentStep = useSelector(trainCurrentSearchStepSelector);
  const isBackward = currentStep === TRAIN_SEARCH_STEPS.TICKET_BACKWARD;
  return useSelector(isBackward ? trainBackSelector : trainForwardSelector);
};

function* purifyForwardTrainData() {
  try {
    yield* put(setTrainForward(null as any));
    yield* put(getCarsSuccess(null as any));
    yield* put(setCarInfo(null as any));
    yield* put(clearChosenCarPlace());
  } catch (e) {
    // console.log(e);
  }
}

function* purifyBackwardTrainData() {
  try {
    yield* put(setTrainBackward(null as any));
    yield* put(getBackCarsSuccess(null as any));
    yield* put(setBackCarInfo(null as any));
    yield* put(clearChosenCarPlaceBackward());
  } catch (e) {
    // console.log(e);
  }
}

function* purifyTrainData() {
  try {
    const currentStep = yield* select(trainCurrentSearchStepSelector);
    const isBackward = currentStep === TRAIN_SEARCH_STEPS.TICKET_BACKWARD;
    if (isBackward) {
      yield* call(purifyBackwardTrainData);
    } else {
      yield* call(purifyForwardTrainData);
    }
  } catch (e) {
    // console.log(e);
  }
}

function* purifyAllTrainData() {
  try {
    yield* call(purifyBackwardTrainData);
    yield* call(purifyForwardTrainData);
    yield* put(carsPurify());
  } catch (e) {
    // console.log(e);
  }
}

function* trainRouteSaga(params: Action<GetTrainRoutePayload>) {
  try {
    yield* put(clearTrainRoute());
    const response = yield* call(Manager.getTrainRoute, params.payload);
    yield* put(setTrainRoute(response));
  } catch (e) {
    console.log(e);
  }
}

function* trainSearchSaga(params: Action<TrainSearchPayload>) {
  yield* put(setSearchStep('TICKET_FORWARD'));
  yield* put(clearTrainTicket());
  yield* call(purifyAllTrainData);
  yield* put(
    setTrainSearchRequestState({ status: TrainSearchRequestStatus.initial }),
  );
  try {
    yield* spawn(
      simpleSearchUpdateState,
      trainSearchPayloadToAirSearchPayload(params.payload),
    );
    const response = yield* call(Manager.getTrainTicketsList, params.payload);
    const filter = createTrainFilter(response);
    yield* put(setTrainFilter(filter));
    yield* all([
      yield* put(trainSearchSuccess(_.cloneDeep(response))),
      yield* put(trainSearchSuccessFilter(_.cloneDeep(response))),
    ]);

    yield* put(
      setTrainSearchRequestState({ status: TrainSearchRequestStatus.success }),
    );
  } catch (e) {
    console.log(e);
    yield* put(trainSearchFailure());
    yield* put(
      setTrainSearchRequestState({ status: TrainSearchRequestStatus.failure }),
    );
  }
}

function* runTrainFilterSaga() {
  let filter: TrainFilter;
  const currentStep = yield* select(trainCurrentSearchStepSelector);
  const list = _.cloneDeep(yield* select(trainTicketsListSelector));
  const state = yield* select(filteredTrainTicketsListSelector);
  if (currentStep === 'TICKET_FORWARD') {
    filter = yield* select(
      (state: ApplicationState) => state.trainTickets.trainSearch.filter[0],
    );
  } else {
    filter = yield* select(
      (state: ApplicationState) => state.trainTickets.trainSearch.filter[1],
    );
  }
  const updatedState = trainFilterWorker(state, list, filter);
  yield* put(trainSearchSuccessFilter(updatedState));
}

function trainFilterWorker(
  state: GetTicketsListDto,
  list: GetTicketsListDto,
  filter: TrainFilter,
): GetTicketsListDto {
  const {
    values,
    carTypesTickets,
    trainTypesTickets,
    trainNamesTickets,
    carriersTickets,
    trainServicesTickets,
    discountsTickets,
  } = filter;
  const isForward = filter.filterDestinationType === 'forward';

  console.log(filter, values.prices);

  let keys = filter.trainDurationsList[0].tickets
    .map((_, key) => key)
    .filter((key) => {
      const pricesFilter =
        (filter.prices.tickets[key][0] >= values.prices[0] ||
          filter.prices.tickets[key][1] >= values.prices[0]) &&
        (filter.prices.tickets[key][0] <= values.prices[1] ||
          filter.prices.tickets[key][1] <= values.prices[1]);

      const carTypesFilter = Object.values(values.carTypes).find((x) => x)
        ? carTypesTickets[key].some((val) => values.carTypes[val])
        : true;

      const trainTypesFilter = Object.values(values.trainTypes).find((x) => x)
        ? trainTypesTickets[key].some((val) => values.trainTypes[val])
        : true;

      const trainNamesFilter = Object.values(values.trainNames).find((x) => x)
        ? trainNamesTickets[key].some((val) => values.trainNames[val])
        : true;

      const trainServicesFilter = Object.values(values.trainServices).find(
        (x) => x,
      )
        ? trainServicesTickets[key].some((val) => values.trainServices[val])
        : true;

      const carriersFilter = Object.values(values.carriers).find((x) => x)
        ? carriersTickets[key].some((val) => values.carriers[val])
        : true;

      const discountsFilter = Object.values(values.discounts).find((x) => x)
        ? discountsTickets[key].some((val) => values.discounts[val])
        : true;

      const firstExpression =
        pricesFilter &&
        carTypesFilter &&
        trainTypesFilter &&
        trainNamesFilter &&
        trainServicesFilter &&
        discountsFilter &&
        carriersFilter;
      if (firstExpression) {
        let secondExpression = true;

        // times
        filter.times.forEach((group) => {
          const { from, to } = group;
          secondExpression =
            secondExpression &&
            from.values[0] <= from.tickets[key] &&
            from.values[1] >= from.tickets[key] &&
            to.values[0] <= to.tickets[key] &&
            to.values[1] >= to.tickets[key];
        });
        console.log(firstExpression && secondExpression);

        return firstExpression && secondExpression;
      } else {
        return false;
      }
    });

  console.log(keys);

  // durations
  filter.trainDurationsList.forEach((train, key) => {
    const values = filter.values.trainDurations;
    keys = train.tickets
      .map((val, key) => {
        return { val, key };
      })
      .filter((item, key) => {
        return (
          keys.find((k) => k === key) !== undefined &&
          item.val >= values[0] &&
          item.val <= values[1]
        );
      })
      .map(({ key }) => key);
  });

  const filteredState = [...list.trainsPerRoutes];

  if (isForward) {
    filteredState[0].trains = filteredState[0].trains.filter(
      (_, key) => keys.find((k) => k === key) !== undefined,
    );
  } else {
    filteredState[1].trains = filteredState[1].trains.filter(
      (_, key) => keys.find((k) => k === key) !== undefined,
    );
  }

  return {
    ...state,
    trainsPerRoutes: filteredState,
  };
}

function* openTrainWorker(params: Action<OpenTicketPayload>) {
  try {
    yield* call(purifyTrainData);
    const query = window.location.search;
    const urlParams = new URLSearchParams(query);
    const childrenCount = parseInt(urlParams.get('Children') || '0');
    const toStationCode = urlParams.get('To');
    const fromStationCode = urlParams.get('From');
    const currentStep = yield* select(trainCurrentSearchStepSelector);
    const isBackward = currentStep === TRAIN_SEARCH_STEPS.TICKET_BACKWARD;
    const state = yield* select(filteredTrainTicketsListSelector);
    const currentDirection = isBackward
      ? state.trainsPerRoutes[1]
      : state.trainsPerRoutes[0];
    const currentTrain = currentDirection.trains.filter(
      (train) => train.index === params.payload.index,
    )[0];
    yield* put(
      isBackward
        ? setTrainBackward(currentTrain)
        : setTrainForward(currentTrain),
    );
    const carPayload: GetCarsPayload = {
      CarType: params.payload.carType,
      DepartureDate: moment(currentTrain.departureDateTimeUtc).format(
        'YYYY-MM-DD',
      ),
      From: currentTrain.fromStation?.code || currentDirection.stationFrom.code,
      // в запросе мы используем более общий код станции из адресной строки, так как
      // часть состава поезда может приезжать на другой вокзал в другое время
      To:
        (isBackward ? fromStationCode : toStationCode) ||
        currentTrain.toStation?.code ||
        currentDirection.stationTo.code,
      TrainNumber: currentTrain.trainNumber,
    };
    if (childrenCount > 0) {
      carPayload.TariffType = 'Child';
    }
    yield* put(getCarsRequest(carPayload));
    const response = yield* call(Manager.getCars, carPayload);
    yield* put(
      isBackward
        ? getBackCarsSuccess(response.cars)
        : getCarsSuccess(response.cars),
    );
    yield* put(
      setAllowedDocumentTypes(response.trainInfo.allowedDocumentTypes),
    );
    yield* put(isBackward ? selectBackCar(0) : selectCar(0));
  } catch (e) {
    yield* put(getCarsFailure());
  }
}

function* selectCarWorker(params: Action<number>) {
  try {
    const currentStep = yield* select(trainCurrentSearchStepSelector);
    const isBackward = currentStep === TRAIN_SEARCH_STEPS.TICKET_BACKWARD;
    yield* put(purifyTrainPassengers());
    if (isBackward) {
      yield* put(clearChosenCarPlaceBackward());
      yield* put(setBedClothesBackward(null));
    } else {
      yield* put(clearChosenCarPlace());
      yield* put(setBedClothesForward(null));
    }
    const state = yield* select(
      isBackward ? trainBackSelector : trainForwardSelector,
    );
    const car = state.cars[params.payload];
    yield* put(isBackward ? setBackCarInfo(car) : setCarInfo(car));
    if (
      car.isBeddingSelectionPossible ||
      (!car.isBeddingSelectionPossible && car.services.includes('Bedclothes'))
    ) {
      if (isBackward) {
        yield* put(setBedClothesBackward(true));
      } else {
        yield* put(setBedClothesForward(true));
      }
    }
  } catch (e) {
    yield* put(getCarsFailure());
  }
}

function* openTrainWorkerHandler(action: Action<OpenTicketPayload>) {
  try {
    const currentStep = yield* select(trainCurrentSearchStepSelector);
    const isBackward = currentStep === TRAIN_SEARCH_STEPS.TICKET_BACKWARD;
    if (isBackward) {
      yield* put(openTrainBackward(action.payload));
    } else {
      yield* put(openTrainForward(action.payload));
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

export function* trainSearchSetStepSaga(
  action: Action<{
    step: keyof typeof TRAIN_SEARCH_STEPS;
    needClear: boolean;
  }>,
) {
  try {
    window.scrollTo({
      top: 0,
    });
    const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
    yield* put(setSearchStep(action.payload.step));
    yield* put(
      setTrainSearchRequestState({ status: TrainSearchRequestStatus.loading }),
    );
    yield delay(1500);

    if (action.payload.needClear) {
      if (action.payload.step === TRAIN_SEARCH_STEPS.TICKET_FORWARD) {
        yield* call(purifyForwardTrainData);
      } else if (action.payload.step === TRAIN_SEARCH_STEPS.TICKET_BACKWARD) {
        yield* call(purifyBackwardTrainData);
      }
    }

    yield* put(
      setTrainSearchRequestState({ status: TrainSearchRequestStatus.success }),
    );
  } catch (e) {
    console.log(e);
    Sentry.captureException(e);
  }
}

export function* clearGenderTypesOnCarPlaceSaga(
  params: Action<ClearGenderTypesOnCarPlacesPayload>,
) {
  try {
    const placesForClearGenderType = params.payload.carPlaces;
    const currentStep = yield* select(trainCurrentSearchStepSelector);
    let currentTrain;
    if (currentStep === TRAIN_SEARCH_STEPS.TICKET_FORWARD) {
      currentTrain = yield* select(trainForwardSelector);
    }
    if (currentStep === TRAIN_SEARCH_STEPS.TICKET_BACKWARD) {
      currentTrain = yield* select(trainBackSelector);
    }
    if (currentTrain) {
      const freePlacesByCompartments: FreePlacesByCompartment[] = [
        ...currentTrain.currentCar.data.freePlacesByCompartments,
      ];
      const compartment = getCompartmentByPlaceNumber(
        freePlacesByCompartments,
        parseInt(placesForClearGenderType[0].number),
      );
      // Бежим по местам, у которых надо сбросить пол. В number ставим значение из _oldNumber, а свойство _oldNumber удаляем
      compartment?.places?.forEach((placeForClearGenderType) => {
        if (compartment) {
          const compartmentIndex = freePlacesByCompartments.findIndex(
            (x) => x.compartmentNumber === compartment.compartmentNumber,
          );
          const updatedPlacesArray: CarPlace[] = [...compartment.places];
          const placeIndex = updatedPlacesArray.findIndex(
            (x) => x.number === placeForClearGenderType.number,
          );
          updatedPlacesArray[placeIndex].number =
            updatedPlacesArray[placeIndex]._oldNumber ||
            updatedPlacesArray[placeIndex].number;
          // remove _oldNumber
          delete updatedPlacesArray[placeIndex]._oldNumber;
          const updatedCompartment = {
            ...compartment,
            places: updatedPlacesArray,
          };
          const updatedFreePlaces = [...freePlacesByCompartments]
            .slice(0, compartmentIndex)
            .concat([...freePlacesByCompartments.slice(compartmentIndex + 1)]);
          updatedFreePlaces.push(updatedCompartment);
        }
      });

      yield* put(
        updateTrainForwardCurrentCarCompartment(freePlacesByCompartments),
      );
    }
  } catch (e) {
    console.log(e);
    Sentry.captureException(e);
  }
}

export function* setGenderTypeOnCarPlaceSaga(
  params: Action<SetGenderTypeOnCarPlacePayload>,
) {
  try {
    const currentStep = yield* select(trainCurrentSearchStepSelector);
    let currentTrain;
    if (currentStep === TRAIN_SEARCH_STEPS.TICKET_FORWARD) {
      currentTrain = yield* select(trainForwardSelector);
    }
    if (currentStep === TRAIN_SEARCH_STEPS.TICKET_BACKWARD) {
      currentTrain = yield* select(trainBackSelector);
    }
    if (currentTrain) {
      const freePlacesByCompartments: FreePlacesByCompartment[] = [
        ...currentTrain.currentCar.data.freePlacesByCompartments,
      ];
      const compartment = getCompartmentByPlaceNumber(
        freePlacesByCompartments,
        parseInt(params.payload.carPlace.number),
      );
      if (compartment) {
        const compartmentIndex = freePlacesByCompartments.findIndex(
          (x) => x.compartmentNumber === compartment.compartmentNumber,
        );
        const updatedPlacesArray: CarPlace[] = [...compartment.places];
        const genderTypeChar = getCarPlaceGenderCharByGenderType(
          params.payload.genderType,
        );
        for (let i = 0; i < compartment.places.length; i++) {
          const placeNumber = parseInt(compartment.places[i].number);
          const updatedNumber = `${placeNumber}${genderTypeChar}`;
          updatedPlacesArray[i]._oldNumber =
            updatedPlacesArray[i]._oldNumber || updatedPlacesArray[i].number;
          updatedPlacesArray[i].number = updatedNumber;
        }
        const updatedCompartment = {
          ...compartment,
          places: updatedPlacesArray,
        };
        const updatedFreePlaces = [...freePlacesByCompartments]
          .slice(0, compartmentIndex)
          .concat([...freePlacesByCompartments.slice(compartmentIndex + 1)]);
        updatedFreePlaces.push(updatedCompartment);
        yield* put(updateTrainForwardCurrentCarCompartment(updatedFreePlaces));
      }
    }
  } catch (e) {
    console.log(e);
    Sentry.captureException(e);
  }
}

export default function* trainTicketsFlow() {
  yield* all([
    takeLatest(trainSearchRequest.getType(), trainSearchSaga),
    takeLatest(openTrainForward.getType(), openTrainWorker),
    takeLatest(openTrainBackward.getType(), openTrainWorker),
    takeLatest(selectBackCar.getType(), selectCarWorker),
    takeLatest(selectCar.getType(), selectCarWorker),
    takeLatest(setStep.getType(), trainSearchSetStepSaga),
    takeLatest(openTrainHandler.getType(), openTrainWorkerHandler),
    takeLatest(setGenderTypeOnCarPlace.getType(), setGenderTypeOnCarPlaceSaga),
    takeLatest(
      clearGenderTypesOnCarPlaces.getType(),
      clearGenderTypesOnCarPlaceSaga,
    ),
    takeLatest(getTrainRoute.getType(), trainRouteSaga),
    takeLatest(
      [
        setPricesTrainFilter,
        setDurationTrainFilter,
        setTimeTrainFilter,
        setCarTypeTrainFilter,
        setTrainTypeFilter,
        setTrainNameTrainFilter,
        setCarrierTrainFilter,
        setTrainServicesTrainFilter,
        setTrainDiscountsTrainFilter,
        resetTrainFilter,
      ],
      runTrainFilterSaga,
    ),
  ]);
}

import React from 'react';
import styled from 'styled-components';
import Booking from '@screens/Booking';

import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import NavigationService from '@services/NavigationService';
import PrintOrder from '@screens/Order/PrintOrder';
import OrderPaymentStatus from '@screens/Order/OrderPaymentStatus';
import {
  callUserData,
  checkAuthStatus,
  getAuthStatus,
  getUserState,
  getUserStatusState,
  signInFunSunRequest,
  signInSamoRequest,
  signInSuccess,
} from '@modules/user';
import { useDispatch, useSelector } from 'react-redux';
import { TuiIcon } from '@components/loader';
import ErrorNotification from '@components/ErrorNotification';
import RedirectPayment from '@screens/RedirectPayment';
import {
  getNotification,
  getWarnigs,
  closeBookingWarning,
  showNotification,
} from '@modules/notification';
import FlightsSearch from '@screens/FlightsSearch';
import TrainsSearch from '@screens/TrainsSearch';
import TuiPage from '@screens/TuiPage';
import CalculationPaymentSuccess from '@screens/Appeals/PaymentSuccess';
import OrderPaymentStatusNew from '@screens/Order/OrderPaymentStatusNew';
import { ApplicationState } from '@modules/index';

import CalculationPaymentFailure from '@screens/Appeals/PaymentFailure';
import SignUp from '@screens/SignUp';

import PasswordRecovery from '@screens/PasswordRecovery';
import Page404 from '@screens/Page404';
import { withTemplate } from '../templates';
import { MainModal } from '@components/authModals';
import { BookingWarningModal } from '@components/common/Modals';
import TuiInfo from './TuiInfo';
import ym from 'react-yandex-metrika';
import { usePromiseTracker } from 'react-promise-tracker';
import BookingSecondPage from '@screens/Order/BookingSecondPage';
import TinkoffSignIn from '@screens/TinkoffSignin';
import Cookies from 'js-cookie';
import { UserCookie } from '@services/UserCookies';
import { RailwayReceipt } from '@screens/Railway/RailwayReceipt';
import { RailwayRefundReceipt } from '@screens/Railway/RailwayRefundReceipt';
import BusSearch from '@screens/BusSearch';
import { RailwayPenaltyReceipt } from '@screens/Railway/RailwayPenaltyReceipt';
import IframeTest from '@screens/IframeTest';

const PersonalArea = React.lazy(() => import('./PersonalArea'));
const Questions = React.lazy(() => import('./Questions'));
const QuestionsTrain = React.lazy(() => import('./QuestionsTrain'));

const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  bottom: 0;
  right: 0;
  background: white;
  z-index: 9999;
`;

function MainRouter() {
  const history = useHistory();
  const { pathname } = useLocation();
  const location = useLocation();
  const { promiseInProgress } = usePromiseTracker();
  const booking = useSelector((state: ApplicationState) => state.booking);

  const dispatch = useDispatch();
  const authStatus = useSelector(getAuthStatus);
  const user = useSelector(getUserStatusState);
  const userState = useSelector(getUserState);

  const notification = useSelector(getNotification);
  const warnings = useSelector(getWarnigs);

  React.useEffect(() => {
    NavigationService.setHistory(history);
  }, [history]);

  React.useEffect(() => {
    if (process.env.REACT_APP_MODE !== 'test') ym('hit', pathname);
  }, [pathname]);

  React.useEffect(() => {
    if (pathname !== '/questions/additional-services/') {
      window.scrollTo(0, 0);
    }
    dispatch(checkAuthStatus());
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const tteAuthId = params.get('tteAuthId');
    const refresh_token_tte = Cookies.get('refresh_token_tte');
    const access_token_tte = Cookies.get('access_token_tte');
    const expires_in_tte = Cookies.get('expires_in_tte');

    if (tteAuthId && !user.token) {
      dispatch(signInFunSunRequest({ tteAuthId: tteAuthId }));
    }

    const samoAuthId = params.get('SAMO');
    if (samoAuthId && !user.token) {
      dispatch(signInSamoRequest({ Samo: samoAuthId }));
    }

    if (refresh_token_tte && access_token_tte && expires_in_tte) {
      UserCookie.save(
        access_token_tte,
        refresh_token_tte,
        Number(expires_in_tte),
      );
      dispatch(signInSuccess({ token: access_token_tte }));
      dispatch(callUserData());
    }
  }, [pathname, location]);

  React.useEffect(() => {
    if (
      userState.data?.agentId &&
      userState?.data.agentGroup !== 'Самотур (GDS тур)'
    ) {
      dispatch(
        showNotification({
          code: 497,
          message:
            'Вы будете перенаправлены в раздел агентов FUN&SUN, где сможете работать с авиабилетами.',
        }),
      );
    }
  }, [userState]);

  return (authStatus === null || promiseInProgress) &&
    !booking.ticket.bookingLoader ? (
    <LoaderWrapper>
      <TuiIcon />
    </LoaderWrapper>
  ) : (
    <>
      <React.Suspense
        fallback={
          <LoaderWrapper>
            <TuiIcon />
          </LoaderWrapper>
        }
      >
        <Switch>
          <Route
            exact
            path="/search/:params"
            render={(props) =>
              withTemplate(FlightsSearch, true, false, true)(props)
            }
          />
          <Route
            exact
            path="/train-search"
            render={(props) =>
              withTemplate(TrainsSearch, true, false, true)(props)
            }
          />
          <Route
            exact
            path="/bus-search"
            render={(props) =>
              withTemplate(BusSearch, true, false, true)(props)
            }
          />
          <Route
            exact
            path="/"
            render={(props) =>
              withTemplate(TuiPage, true, undefined, true)(props)
            }
          />
          <Route
            exact
            path="/sign-up"
            render={(props) => withTemplate(SignUp)(props)}
          />

          <Route
            exact
            path="/password-recovery"
            render={(props) => withTemplate(PasswordRecovery)(props)}
          />

          <Route
            exact
            path="/booking/:id"
            render={(props) => withTemplate(Booking)(props)}
          />
          <Route
            exact
            path="/iframeTest"
            render={(props) => withTemplate(IframeTest)(props)}
          />
          <Route
            exact
            path="/bookingDetails/:orderNumber"
            render={(props) => withTemplate(BookingSecondPage)(props)}
          />
          <Route
            path={`/orders/:orderNumber/Success`}
            render={(props) => withTemplate(OrderPaymentStatusNew)(props)}
          />
          <Route
            exact
            path={`/orders/:orderNumber/SuccessNew`}
            render={(props) => withTemplate(OrderPaymentStatusNew)(props)}
          />
          <Route
            path={`/orders/:orderNumber/Fail`}
            render={(props) => withTemplate(OrderPaymentStatusNew)(props)}
          />
          <Route
            path={`/orders/:orderNumber/FailNew`}
            render={(props) => withTemplate(OrderPaymentStatusNew)(props)}
          />
          <Route
            path="/redirect-payment"
            render={(props) =>
              withTemplate(RedirectPayment, false, true)(props)
            }
          />
          <Route
            path={['/profile', '/tickets', '/orders']}
            render={(props) => withTemplate(PersonalArea)(props)}
          />
          <Route
            path={['/questions']}
            render={(props) => withTemplate(Questions)(props)}
          />
          <Route
            path={['/questions-train']}
            render={(props) => withTemplate(QuestionsTrain)(props)}
          />
          <Route exact path={`/print/:id`} component={PrintOrder} />
          <Route
            exact
            path={`/order/additional-payment/success/:number`}
            render={(props) => withTemplate(CalculationPaymentSuccess)(props)}
          />

          <Route
            exact
            path={`/order/additional-payment/failure/:number`}
            render={(props) => withTemplate(CalculationPaymentFailure)(props)}
          />
          <Route
            path="/info"
            render={(props) => withTemplate(TuiInfo)(props)}
          />
          <Route
            path="/signin-tinkoff"
            render={(props) => withTemplate(TinkoffSignIn)(props)}
          />
          <Route
            path="/railway-receipt/:orderId"
            render={(props) => withTemplate(RailwayReceipt, false, true)(props)}
          />
          <Route
            path="/railway-refund-receipt/:orderId/:orderItemBlankId"
            render={(props) =>
              withTemplate(RailwayRefundReceipt, false, true)(props)
            }
          />
          <Route
            path="/get-railway-order-penalty-receipt/:orderId/:orderItemBlankId"
            render={(props) =>
              withTemplate(RailwayPenaltyReceipt, false, true)(props)
            }
          />
          <Route
            exact
            path="*"
            render={(props) => withTemplate(Page404, false, true)(props)}
          />
        </Switch>
      </React.Suspense>
      {notification?.label && <ErrorNotification />}
      {warnings.bookingWarning && (
        <BookingWarningModal
          data={warnings.bookingWarning.data}
          open={!!warnings.bookingWarning}
          onClose={() => dispatch(closeBookingWarning())}
          warnings={warnings}
        />
      )}
      <MainModal />
    </>
  );
}

export default MainRouter;

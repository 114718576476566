import React, { useEffect } from 'react';
import ym from 'react-yandex-metrika';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import * as Sentry from '@sentry/react';
import { TuiLoader } from '@components/loader';
import { InsuranceSuggestionModal } from '@components/common/Modals';
import {
  bookFlightRequest,
  selectFlightRequest,
  bookingPurify,
  PassengerAgeCategory,
  hideInsuranceNotification,
  getBookingTicketState,
  setSeatMap,
  getBookingFaresState,
  setIsMobile,
  FormikPassengerItemEntity,
} from '@modules/booking';
import { FormikGaErrorsHandler, Separator } from '@components/common';
import styled from 'styled-components';
import {
  Contacts,
  Payment,
  Tarifs,
  SignIn,
  AddictionalServicesProvider,
  TariffChangedNotification,
  PriceChangedBlock,
  TimeLimitLeft,
} from '@components/booking';
import {
  CharterShortNotification,
  HasConnectingFlightsNotification,
  LowcosterShortNotification,
} from '@components/common/LowcosterNotifications';
import { Header as H1 } from '@components/ui/Typography';
import { useLocation, useParams } from 'react-router-dom';
import {
  getUserDataState,
  updatePassangersRequest,
  createPassangersRequest,
  aviaPassengersSelector,
} from '@modules/user';
import moment from 'moment';
import AuthorizationBlock from '@components/booking/AuthorizationBlock';
import { ApplicationState } from '@modules/index';
import OnlyRusCardNoty from '@components/common/OnlyRusCardNoty';
import { createSeatMapState } from '@modules/booking/utils';
import AviaServicesMobile from '@components/booking/MobileBooking/AviaServicesMobile';

import {
  setDestination,
  setDate,
  setPassengers,
  setTicketClass,
  updateSearchFormState,
} from '@modules/simpleSearch';
import { FlightClassTypes } from '@modules/main';
import WarningNotification from '@components/WarningNotification';
import AviaPassengerPriceBooking from '@components/booking/PassengerPrice/AviaPassengerPriceBooking';
import AviaPassengersBlockContainer from '@components/booking/PassengersBlock/AviaPassengersBlockContainer';
import { Hooks } from '@utils';
import Navigation from '@components/booking/MobileBooking/Navigation';
import NewFlightDetailContainerStepOne from '@components/booking/FlightDetail/NewDesktop/NewFlightDetailContainerStepOne';
import FlightDetailMobile from '@components/booking/MobileBooking/FlightDetailMobile';
import YourFlightContainerBookingStepOne from '@components/booking/YourFlight/YourFlightContainerBookingStepOne';
import AviaOfferBlock from '@components/booking/AddictionalServices/OfferBlocks/AviaOfferBlock';
import StickyFooter from '@components/booking/MobileBooking/StickyFooter';
import withLogging from '@modules/logs/withLogging';
import { LOGGING_ACTION_IDS } from '@modules/logs/constants';

export const Header = {
  Wrapper: styled.div``,
  Container: styled.div`
    max-width: 1170px;
    margin: 0 auto;
    margin-top: 30px;
    //padding-top: 35px;
    @media (max-width: 1116px) {
      padding: 40px 20px 0 20px;
    }
    @media (max-width: 767px) {
      padding-top: 25px;
      padding-bottom: 0;
      margin-top: 0;
    }
  `,
  Title: styled.h1`
    font-family: ${({ theme }) => theme.fonts.regular};
    color: ${({ theme }) => theme.colors.blackGray};
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
  `,
};

const Body = {
  Wrapper: styled.div`
    background: white;
    padding-top: 32px;
    font-family: ${({ theme }) => theme.fonts.regular};
    color: ${({ theme }) => theme.colors.blackGray};
    @media (max-width: 767px) {
      padding-top: 20px;
    }
  `,
  MainContainer: styled.div`
    display: flex;
    max-width: 1170px;
    justify-content: space-between;
    margin: 0 auto;
    @media (max-width: 767px) {
      display: block;
    }
    @media (max-width: 1116px) {
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;
    }
  `,
  Block: styled.div`
    background: #ffffff;
    border-radius: 8px;
    width: 100%;
    padding: 32px;
    box-sizing: border-box;
  `,
  LeftSide: styled.div`
    width: 730px;

    & > div {
      margin-bottom: 20px;
    }

    & > :last-child {
      margin-bottom: 32px;
    }

    @media (max-width: 1116px) {
      width: 65%;
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  `,
  RightSide: styled.div`
    width: 378px;
    position: relative;

    & > div {
      margin-bottom: 30px;
    }

    @media (max-width: 767px) {
      display: none;
    }

    @media (max-width: 1116px) {
      width: 33%;
    }
  `,
};

const StickyBlock = styled.div`
  position: sticky;
  top: 20px;

  & > div {
    margin-bottom: 20px;
  }
`;

const YourFlightBlock = styled.div`
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  padding: 24px 0;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  & > button {
    width: 100%;
    margin: 0 24px;
  }
`;

const Booking: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const location = useLocation();
  const { isMobile } = Hooks.useMediaSizes();

  const booking = useSelector((state: ApplicationState) => state.booking);
  const passengers = booking.passengers;
  const ticket = booking.ticket;
  const ticketIsLoading = ticket.loading;
  const ticketIsInitialized = ticket.initialized;
  const ticketFlights = ticket.flights;
  const insurance = booking.insurance;
  const items = useSelector(aviaPassengersSelector);
  const isAuthorized = useSelector(
    (state: ApplicationState) => state.user.status.isAuthorized,
  );
  const { loading } = useSelector(getBookingTicketState);
  const [chosenPaymentMethod, setChosenPaymentMethod] =
    React.useState('Российская карта');

  const chosenScreen = useSelector(
    (state: ApplicationState) => state.booking.ticket?.chosenScreen,
  );
  const fares = useSelector(getBookingFaresState);

  const userData = useSelector(getUserDataState);
  const { insuranceNotification, priceChanged } = useSelector(
    getBookingTicketState,
  );
  const [mobileSubmitCount, setMobileSubmitCount] = React.useState(0);

  const [isShowWarning, setIsShowWarning] = React.useState(false);

  const [isShowError, setIsShowError] = React.useState(false);

  let isCharter = false;
  const selectedFare = fares?.selected;
  const notSamo =
    fares?.items?.some((x) => x.gds !== 'SAMO') &&
    userData?.agentGroup !== 'Самотур (GDS тур)';

  if (ticketFlights?.length > 0) {
    isCharter =
      ticketFlights
        ?.map((ticket: any) =>
          ticket?.segments?.find((x: any) => x?.flight_Type === 'Charter'),
        )
        .filter(Boolean).length !== 0;
  }

  useEffect(() => {
    if (selectedFare && passengers) {
      dispatch(setSeatMap(createSeatMapState(selectedFare, passengers)));
    }
  }, [selectedFare]);

  useEffect(() => {
    /** query params for request */
    const params = new URLSearchParams(decodeURIComponent(location.search));
    const flights = params.getAll('flights');
    const group = params.get('group');
    const searchStr = params.get('search');

    dispatch(
      selectFlightRequest({
        flights: flights as any,
        group: group as any,
        id: id,
      }),
    );

    if (searchStr) {
      dispatch(updateSearchFormState(searchStr));
    }

    return () => {
      dispatch(bookingPurify());
    };
  }, []);

  const isInternationalFlight = ticket.isInternationalFlight;

  const BookingValidationSchema = React.useMemo(() => {
    const lastSegmentDate = ticketFlights
      ? new Date(
          ticketFlights[ticketFlights.length - 1].segments[
            ticketFlights[ticketFlights.length - 1].segments.length - 1
          ].toDate,
        )
      : new Date();

    const LOWCOSTERS_WITH_CHILD_ATTR = ['5W', 'W6', 'FR', 'VY', 'V7', 'KC'];

    const IsLowcosterValid = ticketFlights?.some((flight: any) =>
      flight?.segments?.some((segment: any) =>
        LOWCOSTERS_WITH_CHILD_ATTR.includes(segment.airlineCode),
      ),
    );

    const isS7NDC = ticketFlights?.some(
      (flight: any) => flight.gds === 'S7NDC',
    );

    const isCharter =
      ticketFlights
        ?.map((ticket) =>
          ticket?.segments?.find((x: any) => x?.flight_Type === 'Charter'),
        )
        .filter(Boolean).length !== 0;

    const MIN_DATE_OF_BIRTH = new Date();
    MIN_DATE_OF_BIRTH.setFullYear(new Date().getFullYear() - 100);

    return yup.object().shape({
      passengers: yup.array().of(
        yup
          .object()
          .shape({
            citizenship: yup.string().required('Выберите из списка'),
            name: yup
              .string()
              .required(
                `${
                  isInternationalFlight ? 'Заполните на латинице' : 'Заполните'
                }`,
              ),

            surname: yup
              .string()
              .required(
                `${
                  isInternationalFlight ? 'Заполните на латинице' : 'Заполните'
                }`,
              ),

            secondName: yup
              .string()
              .when(['secondNameRequired', 'name'], {
                is: (secondNameRequired: boolean, name: string) => {
                  return secondNameRequired && !!name && !isCharter;
                },
                then: (schema) =>
                  schema.required(
                    `${
                      isInternationalFlight
                        ? 'Заполните на латинице'
                        : 'Заполните'
                    }`,
                  ),
                otherwise: (schema) => schema,
              })
              .nullable(),

            birthDate: yup
              .date()
              .min(MIN_DATE_OF_BIRTH, 'Неправильная дата рождения')
              .when('ageCategory', {
                is: (value: string) =>
                  !!(value === PassengerAgeCategory.Infant),
                then: (schema) =>
                  schema
                    .test(
                      'infant',
                      'Младенцу на момент перелета должно быть до 2 лет',
                      function (value) {
                        if (value === null || value === undefined) {
                          return true;
                        }
                        const years = moment
                          .duration(moment(lastSegmentDate).diff(moment(value)))
                          .years();

                        return years <= 2;
                      },
                    )
                    .required('Заполните')
                    .nullable(),

                //выключить для s7 s7ndc
                otherwise: (schema) =>
                  schema
                    .when('ageCategory', {
                      is: (value: string) =>
                        !!(value === PassengerAgeCategory.Child),
                      then: (schema) =>
                        schema
                          .test(
                            'infant',
                            'Ребенку на момент перелета должно быть от 2 до 12 лет',
                            function (value) {
                              if (value === null || value === undefined) {
                                return true;
                              }
                              const years = moment
                                .duration(
                                  moment(lastSegmentDate).diff(moment(value)),
                                )
                                .years();

                              return isS7NDC ? true : years >= 2 && years <= 12;
                            },
                          )
                          .required('Заполните')
                          .nullable(),
                      otherwise: (schema) =>
                        schema.required('Заполните').nullable(),
                    })
                    .required('Заполните')
                    .nullable(),
              })
              .when(['documentType', 'ageCategory', 'citizenship'], {
                is: (value: string, value2: string, value3: string) =>
                  value === 'BIRTH_CERTIFICATE' &&
                  value2 === 'ADULT' &&
                  value3 === 'RU',
                then: (schema) =>
                  schema.test(
                    'adult',
                    'На момент полета по свидетельству взрослому должно быть от 12 до 14 лет',
                    function (value) {
                      if (value === null || value === undefined) {
                        return true;
                      }
                      const years = moment
                        .duration(moment(lastSegmentDate).diff(moment(value)))
                        .years();
                      return years >= 12 && years <= 14;
                    },
                  ),
              })

              //выключить для s7 s7ndc и для лоукостеров

              .when('ageCategory', {
                is: (value: string) => value === PassengerAgeCategory.Adult,
                then: (schema) =>
                  schema
                    .test(
                      'adult',
                      // IsLowcosterValid
                      //   ? 'У выбранной авиакомпании для пассажира возрастом менее 14 лет, на момент последнего перелета, должен быть выбран тип пассажира Ребенок'
                      //   :
                      'Для пассажира, возрастом менее 12 лет на момент последнего перелета, должен быть выбран тип пассажира Ребенок',
                      function (value) {
                        if (value === null || value === undefined) {
                          return true;
                        }
                        const years = moment
                          .duration(moment(lastSegmentDate).diff(moment(value)))
                          .years();
                        // if (IsLowcosterValid) {
                        //   return years > 13;
                        // } else
                        return IsLowcosterValid ? true : years > 11;
                      },
                    )
                    .required('Заполните')
                    .nullable(),
              })
              .test('not alive', 'Некорректная дата рождения', (v) => {
                return (v as Date)?.getFullYear() > 1900;
              })
              .nullable(),

            documentSeries: yup
              .string()
              .when('citizenship', {
                is: (value: string) => value === 'RU',
                then: (schema) =>
                  schema.when('documentType', {
                    is: (value: string) => {
                      return (
                        !notSamo &&
                        (value === 'INTERNATIONAL_PASSPORT1' ||
                          value === 'INTERNATIONAL_PASSPORT')
                      );
                    },
                    then: (schema) =>
                      schema
                        .trim()
                        .required('Заполните')
                        .matches(/^[0-9]+$/, 'Разрешены только цифры')
                        .min(2, 'Невалидная серия документа')
                        .max(2, 'Невалидная серия документа'),
                  }),
                otherwise: (schema) => schema,
              })
              .test('symbols', 'Спецсимволы запрещены', (v) => {
                return (
                  !v?.includes('@') && !v?.includes('.') && !v?.includes('!')
                );
              }),

            documentInfo: yup
              .string()
              .when('citizenship', {
                is: (value: string) => value === 'RU',
                then: (schema) =>
                  schema.when('documentType', {
                    is: (value: string) => {
                      return (
                        !notSamo &&
                        (value === 'INTERNATIONAL_PASSPORT1' ||
                          value === 'INTERNATIONAL_PASSPORT')
                      );
                    },
                    then: (schema) =>
                      schema
                        .trim()
                        .required('Заполните')
                        .matches(/^[0-9]+$/, 'Разрешены только цифры')
                        .min(7, 'Невалидный номер документа')
                        .max(7, 'Невалидный номер документа'),
                  }),
                otherwise: (schema) => schema,
              })
              .test('symbols', 'Спецсимволы запрещены', (v) => {
                return (
                  !v?.includes('@') && !v?.includes('.') && !v?.includes('!')
                );
              }),

            number: yup
              .string()
              .when('citizenship', {
                is: (value: string) => value === 'RU',
                then: (schema) =>
                  schema.when('documentType', {
                    is: (value: string) => {
                      return notSamo && value === 'PASSPORT';
                    },
                    then: (schema) =>
                      schema
                        .trim()
                        .matches(/\d{4}-?\d{6}/, 'Невалидный номер документа')
                        .required('Заполните'),
                    otherwise: (schema) =>
                      schema.when(['documentType', 'documentInfo'], {
                        is: (value: string, value2: string) =>
                          value === 'INTERNATIONAL_PASSPORT1' &&
                          (value2 === '' || value2 === undefined),
                        then: (schema) => schema.trim().required('Заполните'),
                        otherwise: (schema) =>
                          schema.when('documentType', {
                            is: (value: string) =>
                              value === 'BIRTH_CERTIFICATE',
                            then: (schema) =>
                              schema.trim().required('необходимо заполнить'),
                            otherwise: (schema) => schema,
                          }),
                      }),
                  }),
                otherwise: (schema) => schema,
              })
              .test('not error', 'Номера совпадают', (v) => {
                return !isShowError;
              })
              .test('not email', 'Спецсимволы запрещены', (v) => {
                return (
                  !v?.includes('@') && !v?.includes('.') && !v?.includes('!')
                );
              }),

            dateOfIssue: yup.date().when('documentType', {
              is: (value: string) => !!value,
              then: (schema) =>
                schema
                  .when('dateOfIssue', {
                    is: (value: string) => !!value,
                    then: (schema) =>
                      schema.test(
                        'dateOfIssueError',
                        'Некорректный срок действия',
                        function (value: any) {
                          if (value === null || value === undefined) {
                            return true;
                          }
                          const years = moment
                            .duration(moment(value).diff(moment(new Date())))
                            .years();
                          return years < 50;
                        },
                      ),
                  })
                  .nullable()
                  .when('documentType', {
                    is: (value: string) =>
                      !!(
                        value === 'INTERNATIONAL_PASSPORT1' ||
                        value === 'INTERNATIONAL_PASSPORT'
                      ),
                    then: (schema) =>
                      schema
                        .required('Заполните')
                        .min(
                          lastSegmentDate,
                          'Паспорт истекает раньше последнего перелёта',
                        )
                        .nullable(),
                  }),
              otherwise: (schema) => schema.nullable().notRequired(),
            }),
          })
          .test({
            name: 'equal',
            test: function (this, value) {
              const isEqual =
                (value as any).secondName === (value as any).surname;

              if (isEqual) {
                return this.createError({
                  path: `passengers[${(value as any).key}].secondName`,
                  message: 'Отчество и фамилия одинаковы',
                });
              }
              return true;
            },
          }),
      ),
      phone: yup
        .string()
        .min(11, 'Некорректный номер телефона')
        .required('Некорректный номер телефона'),

      offerta: yup.boolean().when('isOffertaChecked', {
        is: true,
        then: (schema) => schema,
        otherwise: (schema) => schema.oneOf([true], 'Field must be checked'),
      }),
      isHealthDeclarationChecked: yup.boolean(),

      healthDeclaration: yup.boolean().when('isHealthDeclarationChecked', {
        is: true,
        then: (schema) => schema,
        otherwise: (schema) => schema.oneOf([true], 'Field must be checked'),
      }),
      isCharter: yup.boolean(),
      charterTariffs: yup.boolean().when('isCharter', {
        is: true,
        then: (schema) => schema.oneOf([true], 'Field must be checked'),
        otherwise: (schema) => schema,
      }),

      isInsurancesChecked: yup.boolean(),
      isOffertaChecked: yup.boolean(),
      offertaWithInsurances: yup.boolean().when('isInsurancesChecked', {
        is: true,
        then: (schema) => schema.oneOf([true], 'Field must be checked'),
        otherwise: (schema) => schema,
      }),
      email: yup.string().email('Неправильный формат').required('Заполните'),
      name: yup.string().required('Заполните'),
      citizenship: yup.string(),
    });
  }, [ticketFlights, insurance.status, fares, userData, isShowError]);

  const savedFormState = JSON.parse(
    sessionStorage.getItem(`avia-booking-form-${id}`) || '{}',
  );

  if (savedFormState.passengers) {
    savedFormState.passengers = savedFormState.passengers.map(
      (x: FormikPassengerItemEntity, key: number) => ({
        ...x,
        // dateFns не принимает строки, только Date
        dateOfIssue: x.dateOfIssue ? new Date(x.dateOfIssue) : null,
        birthDate: x.birthDate ? new Date(x.birthDate) : null,
      }),
    );
  }

  const initialPassengers = React.useMemo(() => {
    return passengers.map((x: any, key: number) => ({
      ...x,
      number: '',
      dateOfIssue: null as null | Date,
      secondName: '',
      series: '',
      sex: 'm',
      secondNameRequired: isInternationalFlight ? false : true,
      // dateOfIssueRequired: true,
      loyality: [],
      citizenship: 'RU',
      key: key,
      birthDate: null as null | Date,
      customerPassengerId: '',
      documentSeries: '',
      documentInfo: '',
    }));
  }, [passengers.length, ticketFlights]);

  // Если в sessionStorage нет сохраненного состояния формы, используем начальные значения
  const initialValues = savedFormState?.passengers
    ? savedFormState
    : {
        passengers: initialPassengers,
        phone: userData?.phone || '',
        email: userData?.email || '',
        name: userData?.name || '',
        phoneTwo: '',
        nameTwo: '',
        insurance: true,
        return: true,
        policy: true,
        sameContactInfo: false,
        offerta: false,
        isInsurancesChecked: !!insurance.list.offers.find(
          (x: any) => x.checked,
        ),
        charterTariffs: false,
        isCharter: isCharter,
        offertaWithInsurances: false,
        healthDeclaration:
          (fares?.selected?.isHealthDeclarationChecked !== null ||
            fares?.selected?.isHealthDeclarationChecked !== undefined) &&
          !fares?.selected?.isHealthDeclarationChecked,
        emailOfferta: false,
        baggage: [] as string[],
      };

  React.useEffect(() => {
    if (isMobile) {
      dispatch(setIsMobile(true));
    } else dispatch(setIsMobile(false));
  }, [isMobile]);

  function getFlightClassDesc(str: FlightClassTypes) {
    switch (str) {
      case FlightClassTypes.Econom:
        return 'эконом';
      case FlightClassTypes.Comfort:
        return 'комфорт';
      case FlightClassTypes.Bussines:
        return 'бизнес';
      case FlightClassTypes.FirstClass:
        return 'первый класс';
    }
  }

  React.useEffect(() => {
    if (ticket.flightSearchParameters) {
      window.localStorage.setItem(
        'origin',
        ticket.flightSearchParameters.from1,
      );
      window.localStorage.setItem(
        'destination',
        ticket.flightSearchParameters.to1,
      );
      const formatedFromDate =
        moment(ticket.flightSearchParameters.date1).format('YYYY-MM-DD') +
        'T00:00:00';
      window.localStorage.setItem('forwardDate', formatedFromDate);

      dispatch(
        setDate({
          from: moment({
            month:
              parseInt(ticket.flightSearchParameters.date1.substring(5, 7)) - 1,
            days: parseInt(
              ticket.flightSearchParameters.date1.substring(8, 10),
            ),
            year: parseInt(ticket.flightSearchParameters.date1.substring(0, 4)),
          }),
          to: ticket.flightSearchParameters.date2
            ? moment({
                month:
                  parseInt(
                    ticket.flightSearchParameters.date2.substring(5, 7),
                  ) - 1,
                days: parseInt(
                  ticket.flightSearchParameters.date2.substring(8, 10),
                ),
                year: parseInt(
                  ticket.flightSearchParameters.date2.substring(0, 4),
                ),
              })
            : null,
        }),
      );
      dispatch(
        setPassengers({
          adults: ticket.flightSearchParameters.adults,
          children: ticket.flightSearchParameters.children,
          infants: ticket.flightSearchParameters.infants,
          source: 'avia',
        }),
      );
      dispatch(
        setTicketClass({
          id: ticket.flightSearchParameters.class.toLowerCase(),
          description: getFlightClassDesc(
            ticket.flightSearchParameters.class.toLowerCase(),
          ),
        }),
      );
      const city = {
        iataCode: ticket.flightSearchParameters.from1,
        nameEng: ticket.flightSearchParameters.from1NameEng,
        nameRus: ticket.flightSearchParameters.from1NameRus,
        isCity: true,
        id: '',
        isVisible: true,
        country: {
          id: '',
          isoCode: '',
          nameEng: '',
          nameRus: '',
          isVisible: true,
        },
      };
      const city2 = {
        iataCode: ticket.flightSearchParameters.to1,
        nameEng: ticket.flightSearchParameters.to1NameEng,
        nameRus: ticket.flightSearchParameters.to1NameRus,
        isCity: true,
        id: '',
        isVisible: true,
        country: {
          id: '',
          isoCode: '',
          nameEng: '',
          nameRus: '',
          isVisible: true,
        },
      };
      dispatch(
        setDestination({
          from: city,
          to: city2,
          withoutChangeQueryParams: true,
        }),
      );
    }
    if (ticket?.warnings?.length > 0) {
      setIsShowWarning(true);
    }
  }, [ticket]);

  const fareFamiliesTitle = booking.fares?.selected.fareFamilies.map((fare) => fare.name);

  return (
    <>
      {isShowWarning && (
        <WarningNotification
          isShowWarning={isShowWarning}
          setIsShowWarning={setIsShowWarning}
          warnings={ticket.warnings}
          searchRequest={ticket?.searchRequest}
        />
      )}
      {ticketIsLoading && <TuiLoader />}
      {ticketIsInitialized && (
        <Formik
          initialValues={initialValues}
          validationSchema={BookingValidationSchema}
          onSubmit={(values) => {
            try {
              values.passengers.map((passenger: any, i: number) => {
                if (
                  items.find(
                    (pas) =>
                      pas.customerPassengerId === passenger.customerPassengerId,
                  )
                ) {
                  const pas = items.find(
                    (pas) =>
                      pas.customerPassengerId === passenger.customerPassengerId,
                  );
                  if (
                    !pas?.documents.find(
                      (doc) => doc.number === passenger.number,
                    )
                  ) {
                    dispatch(
                      updatePassangersRequest({
                        isBooking: true,
                        customerPassengerId: passenger.customerPassengerId,
                        customerId: passenger.uid,
                        firstName: passenger.name,
                        lastName: passenger.surname,
                        patronymic: passenger.secondName,
                        birthDate: moment(passenger.birthDate).format(),
                        citizen: null,
                        gender: passenger.sex === 'm' ? 'MALE' : 'FEMALE',
                        personType: passenger.ageCategory,
                        correlationId: passenger.customerPassengerId,
                        documents: [
                          //@ts-ignore
                          ...pas.documents,
                          {
                            type:
                              passenger.documentType === 'PASSPORT'
                                ? 'RussianFederationPassport'
                                : passenger.documentType ===
                                    'INTERNATIONAL_PASSPORT1'
                                  ? 'InternationalPassport'
                                  : passenger.documentType ===
                                      'NATIONALPASSPORT'
                                    ? 'NationalPassport'
                                    : passenger.documentType ===
                                        'BIRTH_CERTIFICATE'
                                      ? 'BirthCertificate'
                                      : 'Other',
                            number: passenger.number,
                            issueDate: passenger.dateOfIssue
                              ? moment(passenger.dateOfIssue).format()
                              : passenger.dateOfIssue,
                            citizenship: passenger.citizenship,
                          },
                        ],
                      }),
                    );
                  }
                } else if (
                  !items.find(
                    (pas) =>
                      pas.customerPassengerId === passenger.customerPassengerId,
                  )
                ) {
                  dispatch(
                    createPassangersRequest({
                      isBooking: true,
                      customerPassengerId: passenger.customerPassengerId,
                      customerId: passenger.uid,
                      firstName: passenger.name,
                      lastName: passenger.surname,
                      patronymic: passenger.secondName,
                      birthDate: moment(passenger.birthDate).format(),
                      citizen: null,
                      gender: passenger.sex === 'm' ? 'MALE' : 'FEMALE',
                      personType: passenger.ageCategory,
                      correlationId: passenger.customerPassengerId,
                      documents: [
                        {
                          type:
                            passenger.documentType === 'PASSPORT'
                              ? 'RussianFederationPassport'
                              : passenger.documentType ===
                                  'INTERNATIONAL_PASSPORT1'
                                ? 'InternationalPassport'
                                : passenger.documentType === 'NATIONALPASSPORT'
                                  ? 'NationalPassport'
                                  : passenger.documentType ===
                                      'BIRTH_CERTIFICATE'
                                    ? 'BirthCertificate'
                                    : 'Other',
                          number: passenger.number,
                          issueDate: passenger.dateOfIssue
                            ? moment(passenger.dateOfIssue).format()
                            : passenger.dateOfIssue,
                          citizenship: passenger.citizenship,
                          id: '00000000-0000-0000-0000-000000000000',
                        },
                      ],
                    }),
                  );
                }
              });

              const createOrderPayload = {
                ...values,
                passengers: values.passengers.map((val: any) => ({
                  ...val,
                  number: val.number.replaceAll(/-|№|\s/g, ''),
                  documentType:
                    val.documentType === 'INTERNATIONAL_PASSPORT1'
                      ? 'INTERNATIONAL_PASSPORT'
                      : val.documentType,
                })),
              };
              if (process.env.REACT_APP_MODE !== 'test') {
                ym('reachGoal', 'btn-click-create-booking', createOrderPayload);
              }
              dispatch(bookFlightRequest(createOrderPayload));
            } catch (e) {
              console.log(e, 'E');
              Sentry.captureException(e);
            }
          }}
        >
          {(s) => {
            const form = s.values;
            // чекбокс согласия с офертой не сохраняем, чтобы после восстановления пользователю надо было снова пожать и согласиться с условиями
            const updatedForm = {
              ...form,
              offerta: false,
            };

            sessionStorage.setItem(
              `avia-booking-form-${id}`,
              JSON.stringify(updatedForm),
            );
            return (
              <>
                <Form autoComplete="off">
                  <>
                    <Header.Wrapper>
                      <Header.Container>
                        <Navigation
                          chosenScreen={chosenScreen}
                          from={
                            booking.ticket.flightSearchParameters.from1NameRus
                          }
                          to={booking.ticket.flightSearchParameters.to1NameRus}
                          passengers={booking.passengers.length}
                          fare={fareFamiliesTitle?.join(' + ')}
                          isTwoWay={
                            booking.ticket.flightSearchParameters.isTwoWay
                          }
                        />

                        <H1 size="h2">Бронирование </H1>
                      </Header.Container>
                    </Header.Wrapper>
                    <Body.Wrapper>
                      <AddictionalServicesProvider>
                        <Body.MainContainer>
                          <Body.LeftSide>
                            {!loading && isMobile && (
                              <div style={{ marginBottom: 0 }}>
                                <StickyFooter
                                  chosenScreen={chosenScreen}
                                  submit={s}
                                  chosenPaymentMethod={chosenPaymentMethod}
                                  setMobileSubmitCount={setMobileSubmitCount}
                                />
                              </div>
                            )}
                            {selectedFare?.hasConnectingFlights && (
                              <HasConnectingFlightsNotification />
                            )}

                            {(selectedFare?.flight_Type === 'Lowcoster') &&
                              !isCharter && <LowcosterShortNotification />}
                            {isCharter && <CharterShortNotification />}
                            {isMobile ? (
                              <FlightDetailMobile />
                            ) : (
                              <NewFlightDetailContainerStepOne />
                            )}

                            <Tarifs />

                            <AviaServicesMobile />

                            {/* )} */}
                            {!isAuthorized && <AuthorizationBlock />}
                            <AviaPassengersBlockContainer
                              setIsShowError={setIsShowError}
                            />
                            <Contacts
                              type={'avia'}
                              mobileSubmitCount={mobileSubmitCount}
                            />
                            {/* <AviaPriceDetailContainer /> */}
                            {userData?.agentId && <OnlyRusCardNoty />}
                            <Payment mobileSubmitCount={mobileSubmitCount} />
                          </Body.LeftSide>
                          <Body.RightSide className="right-side">
                            <StickyBlock>
                              {userData?.agentId && <TimeLimitLeft />}
                              <YourFlightBlock>
                                <YourFlightContainerBookingStepOne />
                                <Separator />
                                <AviaPassengerPriceBooking />
                              </YourFlightBlock>
                              <AviaOfferBlock />
                              <TariffChangedNotification />
                              {priceChanged && <PriceChangedBlock />}
                            </StickyBlock>
                          </Body.RightSide>
                        </Body.MainContainer>
                      </AddictionalServicesProvider>
                    </Body.Wrapper>
                  </>

                  <FormikGaErrorsHandler
                    initialValues={initialValues}
                    event={{
                      category: 'Booking',
                      action: 'flights_booking_form_validation_fail',
                    }}
                  />
                </Form>
                <InsuranceSuggestionModal
                  open={!!insuranceNotification && !isMobile}
                  onConfirm={() => {
                    const createOrderPayload = {
                      ...s.values,
                      passengers: s.values.passengers.map((val: any) => ({
                        ...val,
                        number: val.number.replaceAll(/-|№|\s/g, ''),
                        documentType:
                          val.documentType === 'INTERNATIONAL_PASSPORT1'
                            ? 'INTERNATIONAL_PASSPORT'
                            : val.documentType,
                      })),
                    };
                    dispatch(hideInsuranceNotification());
                    if (process.env.REACT_APP_MODE !== 'test') {
                      ym(
                        'reachGoal',
                        'btn-click-create-booking-from-insurance-popup',
                        createOrderPayload,
                      );
                    }
                    dispatch(bookFlightRequest(createOrderPayload));
                  }}
                  onClose={() => {
                    dispatch(hideInsuranceNotification());
                    const scrollElement =
                      document.getElementById('insurances_block');
                    if (scrollElement)
                      scrollElement.scrollIntoView({ behavior: 'smooth' });
                  }}
                />
              </>
            );
          }}
        </Formik>
      )}
      <SignIn />
    </>
  );
};

export default withLogging(Booking, {
  screen: LOGGING_ACTION_IDS.AVIA_BOOKING_FIRST_STEP.NAME,
});

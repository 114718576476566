import { all, call, takeEvery } from 'typed-redux-saga';
import { postSessionLogHandler } from '@modules/logs/duck';
import { Action } from 'redux-act';
import { LogSessionHandleData, LogSessionPostData } from '@modules/logs/types';
import packageJson from '../../../package.json';
import { postLogService } from '@modules/logs/Manager';

export function* postSessionLogHandlerSaga(action: Action<LogSessionHandleData>) {
  const updatedData = yield* call(() => {
    const logSessionId = sessionStorage.getItem('logSessionId') || '';
    const userAgent = navigator.userAgent;
    const appVersion = packageJson.version;
    const upd: LogSessionPostData = {
      ...action.payload,
      logSessionId,
      userAgent,
      appVersion,
    };

    return upd;
  })

  yield* call(postLogService, updatedData);
}

export default function* logsSaga() {
  yield* all([takeEvery(postSessionLogHandler, postSessionLogHandlerSaga)]);
}
import { LOG_SERVICE_HOST } from '@modules/constants';
import { Api } from '@utils';
import { LogSessionPostData } from '@modules/logs/types';

const LOG_SERVICE_URI = process.env.REACT_APP_LOG_SERVICE_URL as string;

const createLogServiceUrl = Api.createFetchUrl(LOG_SERVICE_HOST, LOG_SERVICE_URI);

export const postLogService = (data: LogSessionPostData) => {
  // console.log('postLogService', data);
  // Api.request(
  //   {
  //     url: createLogServiceUrl(''),
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify(data),
  //   },
  // );
};

import React from 'react';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import {
  CalculationModalProps,
  ISupportTicketCalculationItemsEntity,
} from './types';
import { Text, Button, Checkbox } from '@components/ui';
import { ReactComponent as Clock } from '@assets/media/shared/clock.svg';
import moment from 'moment';
import { Helper } from '@utils';
import { Manager } from '@modules/appeals';
import { formatTableRows } from '@app/components/common/FormOfAppeal/Payment/utils';
import Tippy from '@tippyjs/react';

const StyledPopup = styled(Popup)`
  &-content {
    width: 645px;
    border: 1px solid #dcdcdc;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 30px;
    box-sizing: border-box;

    @media (max-width: 767px) {
      width: 100%;
      border-radius: 0;
      height: 100vh; /* Fallback for browsers that do not support Custom Properties */
      height: calc(var(--vh, 1vh) * 100);
    }
  }

  &-overlay {
    overflow: scroll;
    padding: 20px 0;

    @media (max-width: 767px) {
      z-index: 1000 !important;
      padding: 0;
    }
  }
`;

const Title = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #3c3c3c;
  display: block;
  margin-bottom: 20px;

  & > strong {
    color: #4872f2;
  }
`;

const TimeLimit = styled.div`
  padding: 14px 24px;
  background: #fff3cd;
  border: 1px solid #ffb800;
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin: 20px 0 30px 0;

  & > svg {
    margin-right: 12.5px;
    flex-shrink: 0;
  }

  & > span > span {
    font-weight: 600;
  }

  @media (max-width: 767px) {
    align-items: flex-start;
    padding: 14px;

    & > svg {
      width: 25px;
      height: 25px;
      margin-right: 8px;
    }

    & > span > span {
      font-weight: 600;
    }
  }
`;

const PassengersList = styled.div`
  & > div {
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 25px;
    margin-bottom: 25px;
  }

  & > :last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
`;

const DescriberRow = styled(Text)`
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;

  & > strong {
    font-weight: 600;
  }
`;

const DescribeRowBlue = styled(DescriberRow)`
  & > strong {
    color: #4872f2;
  }
`;

const DescribeStrongRowBlue = styled(DescriberRow)`
  font-weight: 600;

  & > strong {
    color: #4872f2;
  }
`;

const TaxRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TaxRowText = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;
`;

const TaxRowPrice = styled(TaxRowText)`
  color: #4872f2;
`;

const TaxList = styled.div`
  background: #edf1fe;
  border-radius: 6px;
  padding: 14px;
  margin: 15px 0;

  ${TaxRow} {
    margin-bottom: 10px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

const Passenger = styled.div`
  ${DescriberRow} {
    margin-bottom: 10px;
  }
`;

const TotalBlock = styled.div`
  margin-top: 40px;
  margin-bottom: 25px;

  ${DescriberRow} {
    margin-bottom: 10px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

const ButtonBlock = styled.div`
  display: flex;
  margin-top: 30px;

  & > button {
    font-size: 16px;
    line-height: 24px;
    padding: 8px 30px;
    margin-right: 20px;
  }

  & > :last-child {
    margin-right: 0;
  }

  @media (max-width: 767px) {
    flex-direction: column-reverse;
    & > button {
      width: 100%;
      margin-bottom: 10px;
    }
  }
`;

interface ConfirmRulesProps {
  error?: string;
}

const ConfirmRules = styled.div<ConfirmRulesProps>`
  margin-top: 18px;
  display: flex;
  align-items: center;

  & > ${Text} {
    display: block;
    margin-left: 10px;
    ${({ error }) => (error ? 'color: red;' : '')}
  }

  @media (max-width: 767px) {
    align-items: flex-start;
  }
`;

const Modal: React.FC<CalculationModalProps> = ({ data, ...props }) => {
  React.useEffect(() => {
    if (props.open) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [props.open]);

  const [{ isRulesAccepted, acceptError }, setRulesState] = React.useState({
    isRulesAccepted: false,
    acceptError: undefined as string | undefined,
  });

  const calculationName =
    data.supportTicketCalculation?.type.code === 'ExtraPayment'
      ? 'обмен'
      : 'возврат';

  const passengersData = (data.selectedTickets || []).map((x) => {
    const calculation: ISupportTicketCalculationItemsEntity | null | undefined =
      data.supportTicketCalculation.supportTicketCalculationItems
        ? data.supportTicketCalculation.supportTicketCalculationItems.find(
            (y) => y.aviaPassengerId === x.id
          )
        : null;
    const ticketPrice = x.price
      ? Object.entries(x.price).reduce((acc, x: any) => {
          const [key, value] = x;
          return key !== 'discount' ? acc + value : acc;
        }, 0)
      : 0;

    const taxRows = calculation
      ? formatTableRows({
          type: data.supportTicketCalculation?.status || {
            code: 'string',
            description: null,
          },
          ...calculation,
        })
      : [];

    const total =
      data.supportTicketCalculation?.type.code === 'ExtraPayment'
        ? data.supportTicketCalculation?.total || 0
        : calculation?.supportTicketCalculationParameter.refundAmount || 0;

    const retention = taxRows.reduce((acc, x) => acc + (x.cost || 0), 0);
    return {
      ...x,
      ticketPrice,
      taxRows,
      retention,
      total,
    };
  });

  const selectedAncillaryServices = (data.selectedAncillaryServices || [])
    .map((x) => {
      const calculation:
        | ISupportTicketCalculationItemsEntity
        | null
        | undefined = data.supportTicketCalculation
        .supportTicketCalculationItems
        ? data.supportTicketCalculation.supportTicketCalculationItems.find(
            (y) => y.ancillaryServiceId === x.ancillaryServicetId
          )
        : null;

      const total =
        data.supportTicketCalculation?.type.code === 'ExtraPayment'
          ? data.supportTicketCalculation?.total || 0
          : calculation?.supportTicketCalculationParameter.refundAmount || 0;

      const taxRows = calculation
        ? formatTableRows({
            type: data.supportTicketCalculation?.status || {
              code: 'string',
              description: null,
            },
            ...calculation,
          })
        : [];

      const retention = taxRows.reduce((acc, x) => acc + (x.cost || 0), 0);
      return {
        ...x,
        taxRows,
        retention,
        total,
      };
    })
    .filter((x) => x.taxRows.length > 0);

  const selectedInsurances = (data.selectedInsurances || [])
    .map((x) => {
      const calculation:
        | ISupportTicketCalculationItemsEntity
        | null
        | undefined = data.supportTicketCalculation
        .supportTicketCalculationItems
        ? data.supportTicketCalculation.supportTicketCalculationItems.find(
            (y) => y?.insurancePolicyId === x.policyId
          )
        : null;

      const total =
        data.supportTicketCalculation?.type.code === 'ExtraPayment'
          ? data.supportTicketCalculation?.total || 0
          : calculation?.supportTicketCalculationParameter.refundAmount || 0;

      const taxRows = calculation
        ? formatTableRows({
            type: data.supportTicketCalculation?.status || {
              code: 'string',
              description: null,
            },
            ...calculation,
          })
        : [];

      const retention = taxRows.reduce((acc, x) => acc + (x.cost || 0), 0);
      return {
        ...x,
        taxRows,
        retention,
        total,
      };
    })
    .filter((x) => x.taxRows.length > 0);

  const total =
    data.supportTicketCalculation?.type.code === 'ExtraPayment'
      ? data.supportTicketCalculation.total
      : data.supportTicketCalculation?.supportTicketCalculationItems?.reduce(
          (acc, x) => acc + x?.supportTicketCalculationParameter?.refundAmount,
          0
        );

  const stillUtc = moment.utc(data.supportTicketCalculation.timelimit).toDate();
  const dateTo = moment(stillUtc).local().format('DD MMMM HH:mm');

  return (
    <StyledPopup {...props}>
      <div>
        <Title>
          Расчет на {calculationName} <strong>№{data.number}</strong>
        </Title>
        {!!data.supportTicketCalculation.timelimit && (
          <TimeLimit>
            <Clock />
            <Text>
              Расчет необходимо согласовать до <span>{dateTo}</span>
            </Text>
          </TimeLimit>
        )}
        <PassengersList>
          {passengersData.map((x, key) => (
            <Passenger key={key}>
              <DescriberRow>
                Пассажир {key + 1},{' '}
                {getPassengerTypeDescription(x.passengerType)}:{' '}
                <strong>
                  {x.lastName + ' ' + x.firstName + ' ' + (x.patronymic || '')}
                </strong>
              </DescriberRow>
              <DescriberRow>
                Номер билета: <strong>{x.eTicketNumber}</strong>
              </DescriberRow>
              <DescriberRow>
                Стоимость билета:{' '}
                <strong>{Helper.formatPrice(x.ticketPrice)}</strong>
              </DescriberRow>
              <DescriberRow>
                Сумма удержаний за {calculationName}:{' '}
                <strong>{Helper.formatPrice(x.retention)}</strong>
              </DescriberRow>
              {!!x.taxRows.length && (
                <TaxList>
                  {x.taxRows.map((y, key) => (
                    <TaxRow key={key}>
                      <TaxRowText>{y.label}</TaxRowText>
                      <TaxRowPrice>
                        {Helper.formatPrice(y.cost || 0)}
                      </TaxRowPrice>
                    </TaxRow>
                  ))}
                </TaxList>
              )}

              <DescribeRowBlue>
                Сумма {calculationName}а по билету: <strong>{x.total}</strong>
              </DescribeRowBlue>
            </Passenger>
          ))}
          {selectedAncillaryServices.map((emd, key) => (
            <Passenger key={key}>
              <DescriberRow>
                Пассажир {key + 1},{' '}
                {getPassengerTypeDescription(emd.passenger.passengerType)}:{' '}
                <strong>
                  {emd.passenger.lastName +
                    ' ' +
                    emd.passenger.firstName +
                    ' ' +
                    (emd.passenger.patronymic || '')}
                </strong>
              </DescriberRow>
              <DescriberRow>
                Название доп. услуги <strong>{emd.name}</strong>
              </DescriberRow>
              <DescriberRow>
                Тип доп. услуги:{' '}
                <strong>{emd.type === 'Meal' ? 'питание' : 'багаж'}</strong>
              </DescriberRow>
              <DescriberRow>
                Номер EMD: <strong>{emd.emd}</strong>
              </DescriberRow>
              <DescriberRow>
                Стоимость доп. услуги:{' '}
                <strong>
                  {Helper.formatPrice(emd.basePrice + emd.agentExtraCharge)}
                </strong>
              </DescriberRow>
              <DescriberRow>
                Сумма удержаний за {calculationName}:{' '}
                <strong>{Helper.formatPrice(emd.retention)}</strong>
              </DescriberRow>
              <TaxList>
                {emd.taxRows.map((y, key) => (
                  <TaxRow key={key}>
                    <TaxRowText>{y.label}</TaxRowText>
                    <TaxRowPrice>{Helper.formatPrice(y.cost || 0)}</TaxRowPrice>
                  </TaxRow>
                ))}
              </TaxList>
              <DescribeRowBlue>
                Сумма {calculationName}а по продукту:{' '}
                <strong>{emd.total}</strong>
              </DescribeRowBlue>
            </Passenger>
          ))}
          {selectedInsurances.map((x, key) => (
            <Passenger key={key}>
              <DescriberRow>
                Пассажир {key + 1},{' '}
                {getPassengerTypeDescription(x.passenger.passengerType)}:{' '}
                <strong>
                  {x.passenger.lastName +
                    ' ' +
                    x.passenger.firstName +
                    ' ' +
                    (x.passenger.patronymic || '')}
                </strong>
              </DescriberRow>
              <DescriberRow>
                Название продукта: <strong>{x.name}</strong>
              </DescriberRow>
              <DescriberRow>
                Стоимость продукта:{' '}
                <strong>
                  {Helper.formatPrice(x.basePrice + x.agentExtraCharge)}
                </strong>
              </DescriberRow>
              {!!x.passenger?.eTicketNumber && (
                <DescriberRow>
                  Номер билета: <strong>{x.passenger?.eTicketNumber}</strong>
                </DescriberRow>
              )}
              <DescriberRow>
                Сумма удержаний за {calculationName}:{' '}
                <strong>{Helper.formatPrice(x.retention)}</strong>
              </DescriberRow>
              {!!x.taxRows.length && (
                <TaxList>
                  {x.taxRows.map((y, key) => (
                    <TaxRow key={key}>
                      <TaxRowText>{y.label}</TaxRowText>
                      <TaxRowPrice>
                        {Helper.formatPrice(y.cost || 0)}
                      </TaxRowPrice>
                    </TaxRow>
                  ))}
                </TaxList>
              )}
              <DescribeRowBlue>
                Сумма {calculationName}а по продукту: <strong>{x.total}</strong>
              </DescribeRowBlue>
            </Passenger>
          ))}
        </PassengersList>
        <TotalBlock>
          <DescribeStrongRowBlue>
            Итоговая сумма к {calculationName}у:{' '}
            <strong>{Helper.formatPrice(total || 0)}</strong>
          </DescribeStrongRowBlue>
          {!!data.text && data.text !== '' && (
            <DescriberRow>
              <strong>Комментарий к расчету:</strong> {data.text}
            </DescriberRow>
          )}
        </TotalBlock>
        <ConfirmRules error={acceptError}>
          <Checkbox
            type="squar"
            error={acceptError}
            checked={isRulesAccepted}
            onChange={(e) => {
              setRulesState((state) => ({
                acceptError: undefined,
                isRulesAccepted: !state.isRulesAccepted,
              }));
            }}
          />

          <Text>
            Я ознакомлен и согласен с условием на {data.subject.description},
            договором{' '}
            <a
              style={{ color: '#4872F2', textDecoration: 'none' }}
              href="/docs/oferta.pdf"
              target="_blank"
            >
              публичной оферты
            </a>{' '}
            и{' '}
            <Tippy
              content={`Подтверждаю ${data.subject.description} по заказу №${
                data.orderNumber
              }. С ${
                calculationName === 'возврат' ? 'возвратом' : 'доплатой'
              } в размере ${Helper.formatPrice(total || 0)} согласен.`}
            >
              <Text color="blue" style={{ cursor: 'pointer' }}>
                текстом заявления{' '}
              </Text>
            </Tippy>{' '}
          </Text>
        </ConfirmRules>

        <ButtonBlock>
          <Button
            type="primary"
            onClick={() => {
              if (isRulesAccepted) {
                Manager.applySupportTicketCalculation(
                  data.supportTicketCalculation.id
                )
                  .then(() => {
                    typeof props.onClose === 'function' && props.onClose();
                  })
                  .catch(() => {});
              } else {
                setRulesState((state) => ({
                  ...state,
                  acceptError: 'ошибочка',
                }));
              }
            }}
          >
            Я подтверждаю расчет
          </Button>
          <Button
            type="outlined"
            onClick={() => {
              Manager.declineSupportTicketCalculation(
                data.supportTicketCalculation.id
              )
                .then(() => {
                  typeof props.onClose === 'function' && props.onClose();
                })
                .catch(() => {});
            }}
          >
            Я отклоняю расчет
          </Button>
        </ButtonBlock>
      </div>
    </StyledPopup>
  );
};

function getPassengerTypeDescription(str: string) {
  switch (str) {
    case 'Adult':
      return 'взрослый';
    case 'Child':
      return 'ребенок';
    default:
      return 'младенец';
  }
}

export default Modal;

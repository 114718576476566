import React from 'react';
import { useDispatch } from 'react-redux';
import Tippy from '@tippyjs/react/';
import { Button } from '@components/ui';
import styled from 'styled-components';
import { Separator } from '@components/common';
import {
  CAR_PLACE_GENDER_TYPES,
  CarPlace,
  setGenderTypeOnCarPlace,
} from '@modules/trainTickets';
import { getCarPlaceGenderTypeByPlaceNumber } from '@components/train/TrainSearch/utils';
import closeIcon from '@assets/images/common/close.svg';

interface Props {
  reference?: Element | null;
  visible?: boolean;
  seat?: CarPlace;
  hasGenderType: boolean;
  hasKupekTariff: boolean;
  hasSingleTariff: boolean;

  onClick(): void;

  onClose?(): void;

  chooseAllSeats?(seat: CarPlace): void;
}

const ContentWrapper = styled.div`
  padding: 14px 10px;
`;

const CloseImgContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;

  & > button + button {
    margin-left: 15px;
  }
`;

export const SeatOptionsTooltip: React.FC<Props> = (props) => {
  const { reference } = props;
  const dispatch = useDispatch();
  if (!props.seat) return null;

  const handleGenderTypeClick = (type: keyof typeof CAR_PLACE_GENDER_TYPES) => {
    if (props.seat) {
      dispatch(
        setGenderTypeOnCarPlace({ carPlace: props.seat, genderType: type })
      );
      props.onClick();
    }
  };

  const handleChooseAllSeats = () => {
    if (props.chooseAllSeats && props.seat) {
      props.chooseAllSeats(props.seat);
      props.onClick();
    }
  };

  const placeGenderType = getCarPlaceGenderTypeByPlaceNumber(props.seat.number);

  const content = (
    <ContentWrapper>
      <CloseImgContainer onClick={props.onClose}>
        <img src={closeIcon} alt="close" />
      </CloseImgContainer>
      {props.hasGenderType && (
        <div>
          <b>Выберите тип купе</b>
          <br></br>
          <br></br>
          <span>
            Первый пассажир может назначить тип купе по гендерному признаку. Мы
            назначим смешанный тип, если вы продолжите без выбора.
          </span>
          <br></br>
          <br></br>
          <ButtonWrapper>
            <Button
              selected={placeGenderType === 'female'}
              onClick={() => {
                handleGenderTypeClick('female');
              }}
              type={'outlined'}
            >
              Женское
            </Button>
            <Button
              selected={placeGenderType === 'male'}
              onClick={() => {
                handleGenderTypeClick('male');
              }}
              type={'outlined'}
            >
              Мужское
            </Button>
            <Button
              selected={placeGenderType === 'mixed'}
              onClick={() => {
                handleGenderTypeClick('mixed');
              }}
              type={'outlined'}
            >
              Смешанное
            </Button>
          </ButtonWrapper>
        </div>
      )}
      {props.hasGenderType &&
        (props.hasKupekTariff || props.hasSingleTariff) && <Separator />}

      {(props.hasKupekTariff || props.hasSingleTariff) && (
        <div>
          <b>
            Купе целиком ({props.hasKupekTariff ? 'скидка 5 - 20%' : ''}
            {props.hasSingleTariff ? 'скидка 20 - 25%' : ''})
          </b>
          <br></br>
          <br></br>
          <ButtonWrapper>
            <Button onClick={handleChooseAllSeats} template={'blue'}>
              {props.hasKupekTariff && 'Выкупить всё купе по тарифу КУПЕК'}
              {props.hasSingleTariff &&
                'Выкупить 2 смежных места по тарифу СИНГЛ'}
            </Button>
          </ButtonWrapper>
        </div>
      )}
    </ContentWrapper>
  );

  return (
    <Tippy
      maxWidth={400}
      appendTo={() => document.body}
      interactive
      visible={props.visible}
      reference={reference}
      theme="light"
      content={content}
    />
  );
};

import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Category from './Category';
import DateRangeWithPlane from './DateRangeWithPlane';
import TimeRangeWithPlane from './TimeRangeWithPlane';
import { CheckboxGroup, TimeRange, PriceRange } from '@components/ui';

import styled from 'styled-components';
import {
  setTimeOneWayFilter,
  setTransferDurationFilter,
  setPricesFilter,
  resetFilter,
  setAirportFilter,
  setAirlineFilter,
  setTransferFilter,
  setTimeFilter,
  setBaggageFilter,
  setFlightTypeFilter,
  setAirGdsFilter,
} from '@modules/main';
import { Helper } from '@utils';
import { ApplicationState } from '@modules/index';
import { getUserDataState } from '@modules/user';
import { TTE_TEST_LOGINS } from '@modules/user/constants';

const { pluralWord } = Helper;

const Wrapper = styled.ul`
  list-style-type: none;
  text-align: center;
  margin: 0;
  padding: 0;

  & > :first-child {
    padding-top: 0;
  }

  width: 100%;
  box-sizing: border-box;
`;

const Filter: React.FC<{ className?: string } & React.PropsWithChildren> = (
  props
) => {
  const {
    filter: {
      flightsDurationsList,
      transfers,
      transferDuration,
      prices,
      airlines,
      airGds, 
      airports,
      values,
      time,
      baggage,
      isDefault,
    },
  } = useSelector((state: ApplicationState) => state.mainReducer.flightSearch);
  const userData = useSelector(getUserDataState);
  const isTTETestUser = TTE_TEST_LOGINS.includes(userData?.email || '');

  const memoizedAirports = useMemo(
    () =>
      airports.map(({ label, airports }) => {
        return {
          name: label,
          values: airports.map((el) => {
            return { value: el.code, label: el.name, checked: el.checked };
          }),
        };
      }),
    [airports]
  );

  const airlinesVals = airlines.map((airline) => {
    return {
      label: airline.name,
      value: airline.code,
      checked: values.airlines[airline.code],
      icon: Helper.getAirlinesLogoUrl(airline.code, 48, 48),
    };
  });

  const airGdsArray = Object.entries(values.airGdsValues).map(([key, value]) => {
    return {
      label: key,
      value: key,
      checked: value,
    };
  });

  const dispatch = useDispatch();



  return (
    <Wrapper {...props}>
      <Category
        name="Цена билета"
        active
        resetAvailable={!isDefault.prices}
        onReset={() => dispatch(resetFilter('prices'))}
      >
        <PriceRange
          min={prices.min}
          max={prices.max}
          values={values.prices}
          onAfterChange={(values) => {
            dispatch(setPricesFilter(values));
          }}
        />
      </Category>
      <Category
        name="Пересадки"
        active
        resetAvailable={!isDefault.transfers}
        onReset={() => dispatch(resetFilter('transfers'))}
      >
        <CheckboxGroup
          onChange={(val) => {
            const values = val as { [key: string]: boolean };
            dispatch(setTransferFilter(values));
          }}
          groupType="default"
          scrollContent
          type="squar"
          values={transfers.list.map((item, key) => {
            return {
              label:
                item.val === 0
                  ? 'Без пересадок'
                  : `${item.val} ${pluralWord(
                      item.val,
                      'пересадка',
                      'пересадки',
                      'пересадок'
                    )}`,
              value: item.val.toString(),
              checked: item.checked,
            };
          })}
        />
      </Category>
      <Category
        name="Тип рейса"
        resetAvailable={!isDefault.flightTypes}
        onReset={() => dispatch(resetFilter('flightTypes'))}
      >
        <CheckboxGroup
          onChange={(val) => {
            const values = val as { [key: string]: boolean };

            dispatch(
              setFlightTypeFilter(
                Object.entries(values)
                  .filter(([key, value]) => value)
                  .map(([key]) => key)
              )
            );
          }}
          groupType="default"
          scrollContent
          type="squar"
          values={[
            {
              value: 'Regular',
              label: 'Регулярные рейсы',
              checked: values.flightTypes.includes('Regular'),
            },
            {
              value: 'Charter',
              label: 'Чартерные рейсы',
              checked: values.flightTypes.includes('Charter'),
            },
          ]}
        />
      </Category>
      <Category
        name="Багаж"
        resetAvailable={!isDefault.baggage}
        onReset={() => dispatch(resetFilter('baggage'))}
      >
        <CheckboxGroup
          type="squar"
          onChange={(val) => {
            dispatch(setBaggageFilter(val as { [key: string]: boolean }));
          }}
          groupType="default"
          scrollContent
          values={baggage.map((b, key) => {
            return {
              label: b.label,
              checked: b.checked,
              value: key.toString(),
            };
          })}
        />
      </Category>
      <Category
        name="Время вылета и прибытия"
        resetAvailable={!isDefault.time}
        onReset={() => dispatch(resetFilter('time'))}
      >
        {time.map(({ from, to }, key) => {
          return (
            <DateRangeWithPlane
              type={'avia'}
              onChange={(type, val) => {
                dispatch(setTimeFilter({ index: key, type, values: val }));
              }}
              key={key}
              {...{ from, to }}
            />
          );
        })}
      </Category>
      <Category
        name="Аэропорты"
        resetAvailable={!isDefault.airport}
        onReset={() => dispatch(resetFilter('airport'))}
      >
        <CheckboxGroup
          onChange={(values) =>
            dispatch(setAirportFilter(values as { [key: string]: boolean }[]))
          }
          groupType="subgroups"
          type="squar"
          scrollSubGroup
          values={memoizedAirports}
        />
      </Category>
      <Category
        name="Авиакомпании"
        resetAvailable={!isDefault.airlines}
        onReset={() => dispatch(resetFilter('airlines'))}
      >
        <CheckboxGroup
          onChange={(val) => {
            const values = val as { [key: string]: boolean };
            dispatch(setAirlineFilter(values));
          }}
          groupType="default"
          type="squar"
          // scrollContent
          values={airlinesVals}
        />
      </Category>
      {(process.env.REACT_APP_MODE === 'test' || isTTETestUser) && (
        <Category
          name="Поставщики"
          resetAvailable={!isDefault.airGds}
          onReset={() => dispatch(resetFilter('airGds'))}
        >
          <CheckboxGroup
            onChange={(val) => {
              const values = val as { [key: string]: boolean };
              dispatch(setAirGdsFilter(values));
            }}
            groupType="default"
            type="squar"
            // scrollContent
            values={airGdsArray}
          />
        </Category>
      )}
      <Category
        name="Длительность пересадки"
        resetAvailable={!isDefault.transferDuration}
        onReset={() => dispatch(resetFilter('transferDuration'))}
        visible={Boolean(transfers.list.find((el) => el.val !== 0))}
      >
        <TimeRange
          min={transferDuration.min}
          max={transferDuration.max}
          values={values.transferDuration}
          onAfterChange={(values) => {
            dispatch(setTransferDurationFilter(values));
          }}
        />
      </Category>
      <Category
        name="Время в пути"
        resetAvailable={!isDefault.flightDuration}
        onReset={() => dispatch(resetFilter('flightDuration'))}
      >
        {flightsDurationsList.map(({ tickets, ...props }, key) => {
          return (
            <TimeRangeWithPlane
              type={'avia'}
              key={key}
              {...props}
              values={values.flightsDuration[key]}
              onAfterChange={(values) => {
                dispatch(setTimeOneWayFilter({ index: key, values }));
              }}
            />
          );
        })}
      </Category>
      {props.children}
    </Wrapper>
  );
};

export default Filter;

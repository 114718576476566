import { RailwayTariff, TrainBookingState } from '@modules/trainBooking/types';

export const RAILWAY_CYRILLIC_PASSENGER_NAME_REGEX =
  /^[а-яА-ЯёЁ]([-_.а-яА-ЯёЁ]*[а-яА-ЯёЁ])?$/;
export const RAILWAY_LATIN_PASSENGER_NAME_REGEX =
  /^[a-zA-Z]([-_.a-zA-Z]*[a-zA-Z])?$/;
export const RAILWAY_LATIN_AND_CYRILLIC_PASSENGER_NAME_REGEX =
  /^[a-zA-Zа-яА-ЯёЁ]([-_.a-zA-Zа-яА-ЯёЁ]*[a-zA-Zа-яА-ЯёЁ])?$/;

export const INIT_TRAIN_BOOKING_PASSENGER = {
  number: '',
  dateOfIssue: null as null | Date,
  secondName: '',
  series: '',
  sex: null as null | string,
  secondNameRequired: true,
  lastNameRequired: true,
  firstNameRequired: true,
  citizenship: 'RU',
  birthDate: null as null | Date,
  customerPassengerId: '',
  documentSeries: '',
  documentInfo: '',
  tariffsPerRoute: [
    {
      originCode: '',
      tariffType: 'Full',
      isNonRefundableTariff: false,
    },
  ],
  otherRailwayBonusCard: null,
  rzhdBonusCard: null,
  email: null,
  phoneNumber: null,
};

export const DOSS_CARRIER = 'ДОСС';
export const FPK_CARRIER = 'ФПК';
export const TVERSK_CARRIER = 'ТВЕРСК';

export const TrainBookingInitState: TrainBookingState = {
  ticket: null,
  passengers: [],
  pricing: {
    priceItems: [],
    total: 0,
  },
  tariffs: {
    forward: 'Full',
    backward: 'Full',
  },
  allowedDocumentTypes: null,
};

export const TRAIN_DISCOUNT_CARD_TYPES = [
  {
    value: 'BusinessTravel',
    label: 'Деловой проездной',
    apiNumber: 14,
  },
  {
    value: 'UniversalRzhdCard',
    label: 'Скидочная карта',
    apiNumber: 6,
  },
  {
    value: 'RzhdBonus',
    label: 'РЖД Бонус',
  },
  {
    value: 'RzhdBonusWithDiscount',
    label: 'РЖД Бонус',
  },
];

// https://confluence.fstravel.com/pages/viewpage.action?pageId=123840773
export const ADULT_RAILWAY_TARIFFS_AND_LOYALTY_CARDS: RailwayTariff[] = [
  // tariffs
  {
    id: 'Full',
    apiValue: 'Full',
    title: 'Полный',
    discountTitle: '',
    description: 'Для пассажиров старше 10 лет, без скидок.',
    fullDiscountDescription: ``,
    fullDescription: `Полный тариф применяется для пассажиров в возрасте старше 10 лет, проезжающих без применения каких-либо скидок и спецтарифов.`,
    canUseWithOtherCards: true,
    isLoyaltyCard: false,
    noSendToBonusCardInApi: true,
  },
  {
    id: 'RoundTrip',
    apiValue: 'RoundTrip',
    title: 'ОБРТ',
    discountTitle: 'Скидка 20%',
    description: 'Скидка при оформлении «Туда/Обратно»',
    fullDescription: `Для пассажиров любого возраста при единовременном оформлении электронных билетов в направлении «туда» и «обратно»`,
    fullDiscountDescription: `- 20% от текущей стоимости проезда в направлении «обратно»`,
    canUseWithOtherCards: true,
    isLoyaltyCard: false,
  },
  {
    id: 'Senior30',
    apiValue: 'Senior',
    title: 'SENIOR',
    discountTitle: 'Скидка 30%',
    description: 'Скидка по тарифу Senior',
    fullDescription: `Для пассажиров являющимися гражданами РФ в возрасте от 60 лет.`,
    fullDiscountDescription: `- 30% от текущей стоимости проезда.`,
    canUseWithOtherCards: false,
    carrier: DOSS_CARRIER,
    isLoyaltyCard: false,
  },
  {
    id: 'Senior15',
    apiValue: 'Senior',
    title: 'SENIOR',
    discountTitle: 'Скидка 15%',
    description: 'Скидка по тарифу Senior',
    fullDescription: `Для пассажиров являющихся гражданами РФ в возрасте от 60 лет

с 01.03 по 31.05.2023 г.
с 16.09 по 30.10 2023 г.,с 7.11 по 21.12.2023 г. с даты начала продажи до 7 дней до даты отправления поезда включительно.`,
    fullDiscountDescription: `- 15% от текущей стоимости проезда`,
    canUseWithOtherCards: false,
    carrier: FPK_CARRIER,
    isLoyaltyCard: false,
  },
  {
    id: 'Junior',
    apiValue: 'Junior',
    title: 'ЮНИОР',
    discountTitle: 'Скидка 30%',
    description: 'Скидка по тарифу Junior',
    fullDescription: `Для пассажиров являющихся гражданами РФ в возрасте от 10 до 21 года (включая 21-й день рождения на момент совершения поездки).`,
    fullDiscountDescription: `- 30% от текущей стоимости проезда`,
    canUseWithOtherCards: false,
    isLoyaltyCard: false,
  },
  {
    id: 'Kupek',
    apiValue: 'Kupek',
    title: 'КУПЕК',
    discountTitle: 'Скидка 5 – 20%',
    description: 'Скидка по тарифу Купек',
    fullDescription: `Применяется при единовременном выкупе целого купе (перевозчик ФПК) отправлением с 10.01 по 21.12.2023г.`,
    fullDiscountDescription:
      '- 5-20%  от текущей стоимости проезда в направлении «обратно»',
    canUseWithOtherCards: true,
    isLoyaltyCard: false,
  },
  {
    id: 'Pupil',
    apiValue: 'Pupil',
    title: 'Школьный',
    discountTitle: 'Скидка 50%',
    description: 'Скидка по школьному тарифу',
    fullDescription: `Применяется для граждан РФ и иностранных граждан, обучающихся и являющихся воспитанниками общеобразовательных учреждений РФ очной формы обучения старше 10 лет в период с 1 января по 31 мая включительно и с 1 сентября по 31 декабря включительно.
      – В поездах дальнего следования (пассажирских и скорых) в общих, плацкартных вагонах и в вагонах с местами для сидения класса 1, 2 и 3 классов поездов дальнего следования 800-й нумерации в размере 50% от установленных государством тарифов.
      При оформлении проезда в фирменном скором поезде или пассажирском поезде пассажир должен оплатить 50% стоимость проезда в обычном скором или пассажирском поезде и полностью оплатить разницу между стоимостью проезда в фирменном скором или фирменном пассажирском поезде и стоимостью проезда в обычном скором или пассажирском поезде.
      
      - В двухэтажных пассажирских вагонах с четырёхместных купе в поездах дальнего следования всех категория в размере 50% от тарифа, установленного на перевозки пассажиров в плацкартных вагонах на аналогичное расстояние проезда.
      При оформлении проезда в двухэтажных пассажирских вагонах фирменных скорых или пассажирских поездах пассажир оплачивает разницу между стоимостью проезда в плацкартном вагоне фирменного скорого или пассажирского поезда и стоимостью проезда в плацкартном вагоне скорого или пассажирского поезда.
       
      Оформление проездных документов в другие типы вагонов и категории поездов с оплатой 50% стоимости проезда – не производится.
      `,
    fullDiscountDescription:
      '- 50% от текущей стоимости проезда (при соблюдении условий)',
    canUseWithOtherCards: true,
    isLoyaltyCard: false,
  },
  {
    id: 'Child',
    apiValue: 'Child',
    title: 'Детский',
    discountTitle: 'Скидка 50%',
    description: 'Скидка по детскому тарифу',
    fullDescription: '',
    fullDiscountDescription: '',
    canUseWithOtherCards: true,
    isLoyaltyCard: false,
  },
  {
    id: 'Child17',
    apiValue: 'Child17',
    title: 'ДЕТ 17',
    discountTitle: 'Скидка 50%',
    description: 'Скидка по детскому тарифу',
    fullDescription: `Применяется для детей в возрасте от 10 до 17 лет (включительно) при проезде во внутреннем сообщении.`,
    fullDiscountDescription: '- 50% от текущей стоимости проезда',
    canUseWithOtherCards: true,
    isLoyaltyCard: false,
  },
  {
    id: 'Birthday30',
    apiValue: 'Birthday',
    title: 'День Рождения',
    discountTitle: 'Скидка 30%',
    description: 'Скидка по тарифу ДеньР (день рождения +-7дней)',
    fullDescription: `Применяется для именинника и до 3х сопровождающих, основной билет оформляется на именинника, возврат билета именинника без возврата сопровождающих - невозможен. Билет по тарифу можно приобрести +- 7 дней от даты дня рождения`,
    fullDiscountDescription: '- 30% от текущей стоимости проезда',
    canUseWithOtherCards: true,
    carrier: DOSS_CARRIER,
    isLoyaltyCard: false,
  },
  {
    id: 'Birthday10',
    apiValue: 'Birthday',
    title: 'День Рождения',
    discountTitle: 'Скидка 10%',
    description: 'Скидка по тарифу ДеньР (день рождения +-7дней)',
    fullDescription: `Применяется для именинника и до 3х сопровождающих (в вагонах класса «Люкс», а также в вагонах с местами для сидения класса обслуживания 1В – не более одного сопровождающего), основной билет оформляется на именинника, возврат билета именинника без возврата сопровождающих - невозможен. Билет по тарифу можно приобрести +- 7 дней от даты дня рождения`,
    fullDiscountDescription: '- 10% от текущей стоимости проезда',
    canUseWithOtherCards: true,
    carrier: FPK_CARRIER,
    isLoyaltyCard: false,
  },
  {
    id: 'Birthday15',
    apiValue: 'Birthday',
    title: 'День Рождения',
    discountTitle: 'Скидка 15%',
    description: 'Скидка по тарифу ДеньР (день рождения +-7дней)',
    fullDescription: `Применяется для именинника и до 3х сопровождающих (в вагонах класса «Люкс», а также в вагонах с местами для сидения класса обслуживания 1В – не более одного сопровождающего), основной билет оформляется на именинника, возврат билета именинника без возврата сопровождающих - невозможен. Билет по тарифу можно приобрести +- 7 дней от даты дня рождения`,
    fullDiscountDescription: '- 15% от текущей стоимости проезда',
    canUseWithOtherCards: true,
    carrier: TVERSK_CARRIER,
    isLoyaltyCard: false,
  },
  {
    id: 'BirthdayAccompanying30',
    apiValue: 'BirthdayAccompanying',
    title: 'День Рождения +1',
    discountTitle: 'Скидка 30%',
    description: 'Скидка по тарифу ДеньС (день рождения +-7дней)',
    fullDescription:
      '<div><p>Применяется для до 3х сопровождающих, основной билет оформляется на именинника, возврат билета именинника без возврата сопровождающих - невозможен. Билет по тарифу можно приобрести +- 7 дней от даты дня рождения</p></div>',
    fullDiscountDescription: '- 30% от текущей стоимости проезда',
    canUseWithOtherCards: true,
    carrier: DOSS_CARRIER,
    isLoyaltyCard: false,
  },
  {
    id: 'BirthdayAccompanying10',
    apiValue: 'BirthdayAccompanying',
    title: 'День Рождения +1',
    discountTitle: 'Скидка 10%',
    description: 'Скидка по тарифу ДеньС (день рождения +-7дней)',
    fullDescription: `Применяется для до 3х сопровождающих (в вагонах класса «Люкс», а также в вагонах с местами для сидения класса обслуживания 1В – не более одного сопровождающего), основной билет оформляется на именинника, возврат билета именинника без возврата сопровождающих - невозможен. Билет по тарифу можно приобрести +- 7 дней от даты дня рождения`,
    fullDiscountDescription: '- 10% от текущей стоимости проезда',
    canUseWithOtherCards: true,
    carrier: FPK_CARRIER,
    isLoyaltyCard: false,
  },
  {
    id: 'BirthdayAccompanying15',
    apiValue: 'BirthdayAccompanying',
    title: 'День Рождения +1',
    discountTitle: 'Скидка 15%',
    description: 'Скидка по тарифу ДеньС (день рождения +-7дней)',
    fullDescription: `Применяется для до 3х сопровождающих (в вагонах класса «Люкс», а также в вагонах с местами для сидения класса обслуживания 1В – не более одного сопровождающего), основной билет оформляется на именинника, возврат билета именинника без возврата сопровождающих - невозможен. Билет по тарифу можно приобрести +- 7 дней от даты дня рождения`,
    fullDiscountDescription: '- 15% от текущей стоимости проезда',
    canUseWithOtherCards: true,
    carrier: TVERSK_CARRIER,
    isLoyaltyCard: false,
  },
  {
    id: 'Single25',
    apiValue: 'Single',
    title: 'СИНГЛ',
    discountTitle: 'Скидка 25%',
    description:
      'Сингл (выкуп одновременно 2-х мест в вагоне СВ на одного человека)',
    fullDescription: `Применяется для проезда одного пассажира на двух смежных креслах`,
    fullDiscountDescription: '- 25% от текущей стоимости проезда',
    canUseWithOtherCards: true,
    carrier: DOSS_CARRIER,
    isLoyaltyCard: false,
  },
  {
    id: 'Single20',
    apiValue: 'Single',
    title: 'СИНГЛ',
    discountTitle: 'Скидка 20%',
    description: 'Для проезда одного пассажира на двух смежных креслах',
    fullDescription: `Сингл (выкуп одновременно 2-х мест в вагоне СВ на одного человека)`,
    fullDiscountDescription: '- 20% от текущей стоимости проезда',
    canUseWithOtherCards: true,
    carrier: FPK_CARRIER,
    isLoyaltyCard: false,
  },
  {
    id: 'Single10',
    apiValue: 'Single',
    title: 'СИНГЛ',
    discountTitle: 'Скидка 10%',
    description: 'Для проезда одного пассажира на двух смежных креслах',
    fullDescription: `Сингл (выкуп одновременно 2-х мест в вагоне СВ на одного человека)`,
    fullDiscountDescription: '- 10% от текущей стоимости проезда',
    canUseWithOtherCards: true,
    carrier: TVERSK_CARRIER,
    isLoyaltyCard: false,
  },
  {
    id: 'Single25Tversk',
    apiValue: 'Single',
    title: 'СИНГЛ',
    discountTitle: 'Скидка 45-55%',
    description:
      'Сингл (выкуп одновременно 2-х мест в вагоне СВ на одного человека)',
    fullDescription: `Применяется для проезда одного пассажира на двух смежных креслах`,
    fullDiscountDescription: '- 45-55% от текущей стоимости проезда',
    canUseWithOtherCards: true,
    carrier: TVERSK_CARRIER,
    isLoyaltyCard: false,
  },
  {
    id: 'NoValue',
    apiValue: 'NoValue',
    title: 'Не определён',
    discountTitle: 'Не определён',
    description: 'Не определён',
    fullDescription: 'Не определён',
    fullDiscountDescription: 'Не определён',
    canUseWithOtherCards: false,
    isLoyaltyCard: false,
  },
  {
    id: 'FreeChild',
    apiValue: 'FreeChild',
    title: 'Детский без места',
    discountTitle: 'Не определён',
    description: 'Не определён',
    fullDescription: 'Не определён',
    fullDiscountDescription: 'Не определён',
    canUseWithOtherCards: false,
    isLoyaltyCard: false,
  },
  {
    id: 'AdditionalFreeChild',
    apiValue: 'AdditionalFreeChild',
    title: 'Дезденежный детский допп',
    discountTitle: 'Не определён',
    description: 'Не определён',
    fullDescription: 'Не определён',
    fullDiscountDescription: 'Не определён',
    canUseWithOtherCards: false,
    isLoyaltyCard: false,
  },
  // loyalty cards
  {
    id: 'BusinessTravelCard',
    apiValue: 'BusinessTravelCard',
    title: 'Деловой проездной',
    discountTitle: '',
    description: '',
    fullDescription: ``,
    fullDiscountDescription: '',
    canUseWithOtherCards: false,
    isLoyaltyCard: true,
  },
  {
    id: 'UniversalCard',
    apiValue: 'UniversalCard',
    title: 'Дорожная карта',
    discountTitle: '',
    description: '',
    fullDescription: ``,
    fullDiscountDescription: '',
    canUseWithOtherCards: false,
    isLoyaltyCard: true,
  },
  {
    id: 'RzhdBonusWithDiscount',
    apiValue: 'RzhdBonusWithDiscount',
    title: 'РЖД Бонус (скидка)',
    discountTitle: '',
    description: '',
    fullDescription: ``,
    fullDiscountDescription: '',
    canUseWithOtherCards: false,
    isLoyaltyCard: true,
  },
];

export const LOYALTY_CARDS_NAMES = [
  'BusinessTravelCard',
  'UniversalCard',
  'RzhdBonusWithDiscount',
];
export const TARIFFS_NAMES = [
  'RoundTrip',
  'Senior30',
  'Senior15',
  'Junior',
  'Kupek',
  'Pupil',
  'Birthday30',
  'Birthday10',
  'BirthdayAccompanying30',
  'BirthdayAccompanying10',
  'Single',
  'NoValue',
];

export const COUNTRIES_WITHOUT_FLAGS = ['AB', 'OS', 'DN', 'LN'];

export const TEST_PASSENGER_ADULT = {
  loyaltyCards: [],
  name: 'Иван',
  surname: 'Иванов',
  secondName: 'Иванович',
  documentType: 'RussianPassport',
  ageCategory: 'ADULT',
  number: '5700-343434',
  dateOfIssue: null,
  sex: 'm',
  citizenship: 'RU',
  birthDate: '1987-10-09T22:00:00.000Z',
};

export const TEST_PASSENGER_CHILD = {
  loyaltyCards: [],
  name: 'Ребёнок',
  surname: 'Ребёнков',
  secondName: 'Ребёнкович',
  documentType: 'BirthCertificate',
  ageCategory: 'CHILD',
  number: 'XXЫЫ123321',
  dateOfIssue: null,
  sex: 'm',
  citizenship: 'RU',
  birthDate: '2016-10-09T22:00:00.000Z',
};

export const TEST_PASSENGER_INFANT = {
  loyaltyCards: [],
  name: 'Младенец',
  surname: 'Младенцев',
  secondName: 'Младенцевич',
  documentType: 'BirthCertificate',
  ageCategory: 'INFANT',
  number: 'XXЫЫ456654',
  dateOfIssue: null,
  sex: 'm',
  citizenship: 'RU',
  birthDate: '2022-10-09T22:00:00.000Z',
};
import React from 'react';
import styled from 'styled-components';
import InputMask from 'react-input-mask';
import Popup from 'reactjs-popup';
import { SmsServiceModalProps } from './types';
import { Text, Field, Checkbox, Button } from '@components/ui';
import { ReactComponent as Cellphone } from '@assets/media/addictional-services/cellphone.svg';

const Cross = styled.a`
  width: 15px;
  height: 15px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  outline: none;
  &:hover {
    &:before,
    &:after {
      background-color: ${({ theme: { colors } }) => colors.darkGray};
    }
  }
  &:before,
  &:after {
    position: absolute;
    content: ' ';
    height: 17px;
    width: 2px;
    right: 3.5px;
    bottom: 0.5px;
    background-color: ${({ theme: { colors } }) => colors.main};
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

const StyledPopup = styled(Popup)`
  &-overlay {
    @media (max-width: 767px) {
      z-index: 1000 !important;
    }
  }
  &-content {
    width: 549px;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 30px;
    position: relative;
    box-sizing: border-box;

    @media (max-width: 767px) {
      width: 100%;
      border-radius: 0px;
      height: 100vh; /* Fallback for browsers that do not support Custom Properties */
      height: calc(var(--vh, 1vh) * 100);
      // padding: 18px 20px;
      border-radius: 0;
      display: flex;
      flex-direction: column;
      & > :last-child {
        flex-grow: 1;
      }
    }
  }
`;

const Title = styled(Text)`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  display: block;

  @media (max-width: 767px) {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    max-width: 280px;
    align-self: center;
  }
`;

const Paragraph = styled(Text)`
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #3c3c3c;
  margin: 20px 0;

  & > strong {
    font-weight: 600;
  }

  & > a {
    font-weight: 600;
    text-decoration: none;
    color: #4872f2;
  }

  @media (max-width: 767px) {
    margin-top: 10px;
  }
`;

const PopupHead = styled.div`
  display: flex;
  margin-bottom: 20px;
  align-items: center;

  & > svg {
    margin-right: 10px;
  }

  @media (max-width: 767px) {
    align-items: flex-end;
    text-align: center;
    padding-bottom: 0;
    padding-left: 0;
    & > a {
      position: absolute;
      top: 18px;
      right: 26px;
    }
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;

  & > :first-child {
    margin-right: 12px;
  }
`;

const CheckboxText = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #737373;
`;

const Inputs = styled.div`
  margin-top: 25px;
  & > div {
    display: block;
    margin-bottom: 20px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

const StyledField = styled(Field)`
  width: 100%;
  background-color: transparent;
  transition: background-color 150ms;

  &:disabled {
    background-color: #f1f1f2;
  }
`;

const ButtonBlock = styled.div`
  margin-top: 30px;
  text-align: right;
`;

const StyledButton = styled(Button)`
  @media (max-width: 767px) {
    width: 100%;
  }
`;

const Modal: React.FC<SmsServiceModalProps & React.PropsWithChildren> = ({
  children,
  setChosenModal,
  user,
  ...props
}) => {
  const [userName, setUserName] = React.useState(user.contactName || undefined);
  const [userPhone, setUserPhone] = React.useState(
    user.contactPhone || undefined
  );
  const [checked, setChecked] = React.useState(false);

  const formatedPhone = React.useMemo(() => {
    let result = '';
    if (user.contactPhone !== null) {
      const x = (user.contactPhone || '')
        .replace(/\D/g, '')
        .match(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/);
      if (x) {
        result = `+${x[1]} (${x[2]}) ${x[3]}-${x[4]}-${x[5]}`;
      }
    }
    return result;
  }, [user.contactPhone]);

  React.useEffect(() => {
    if (props.open) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [props.open]);

  const handleSubmit = () => {
    props.onSubmit({
      type: 'sms',
      name: userName || '',
      phone: userPhone || '',
    });
  };

  return (
    <StyledPopup {...props}>
      <Cross
        href="/sde"
        onClick={(e) => {
          e.preventDefault();

          typeof props.onClose === 'function' && props.onClose();
        }}
      />
      <PopupHead>
        <Cellphone width={40} height={40} />
        <Title>SMS-информирование</Title>
      </PopupHead>
      <Paragraph>
        Вы добавили услугу <strong>SMS-информирование</strong>. Уведомления по
        заказу автоматически будут присылаться на указанный номер телефона:{' '}
        <a href={`tel:${formatedPhone}`}>{formatedPhone}</a>.
      </Paragraph>
      <Paragraph>
        Если вам необходимо получать уведомления на другой номер, поставьте
        галочку и укажите его в поле ниже.
      </Paragraph>
      <CheckboxWrapper>
        <Checkbox
          type="squar-blue"
          checked={checked}
          onChange={() => {
            if (checked) {
              setUserName(user.contactName || undefined);
              setUserPhone(user.contactPhone || undefined);
            } else {
              setUserName('');
              setUserPhone('');
            }
            setChecked(!checked);
          }}
        />
        <CheckboxText>Я хочу получать уведомления на другой номер</CheckboxText>
      </CheckboxWrapper>
      <Inputs>
        <InputMask
          mask="+7 (999) 999-99-99"
          onChange={(e) => setUserPhone(e.target.value)}
          value={userPhone}
          autoComplete="anyrandomstring9639"
          disabled={!checked}
          maskChar={null}
        >
          <StyledField label="Номер для SMS-уведомлений" />
        </InputMask>
        <StyledField
          label="Имя"
          value={userName}
          disabled={!checked}
          onChange={(e) => setUserName(e.target.value)}
        />
      </Inputs>
      <ButtonBlock>
        <StyledButton htmlType="button" onClick={handleSubmit}>
          Готово
        </StyledButton>
      </ButtonBlock>
    </StyledPopup>
  );
};

export default Modal;

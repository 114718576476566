import React from 'react';
import styled from 'styled-components';
import InfoIcon from '@app/assets/images/info';
import { Text } from '@app/components/ui';
import { Link as ReactLink } from 'react-router-dom';

const Wrapper = styled.div`
  padding: 14px;
  background: #fff3cd;
  border: 1px solid #ffb800;
  border-radius: 6px;
`;

const Description = styled(Text)`
  display: block;
`;

const Link = styled(ReactLink)`
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  text-decoration: none;
`;

const LinkText = styled(Text)`
  color: #4872f2;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
`;

const PassengerEditWarning = ({ isAppeal }: { isAppeal?: boolean }) => {
  return (
    <Wrapper>
      <Description>
        <span style={{ fontWeight: 'bold' }}>
          Обращаем внимание, что изменение личных данных возможно только через
          возврат.
        </span>{' '}
        {isAppeal
          ? 'Если вам необходимо изменить личные данные в заказе, оформите возврат из заказа.'
          : 'Пожалуйста, внимательно заполните данные на каждого пассажира в точности так, как в документе, по которому поедете.'}
      </Description>
      {isAppeal && (
        <Link to="/questions-train/return-policy" target="_blank">
          <InfoIcon />
          <LinkText>Как оформить возврат из заказа</LinkText>
        </Link>
      )}
    </Wrapper>
  );
};

export default PassengerEditWarning;

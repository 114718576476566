import { createAction, createReducer } from 'redux-act';
import { combineReducers } from 'redux';
import { SEARCH_PANEL_TYPES } from '@modules/ui/types';

export enum AUTH_MODAL_STATUSES {
  Registration = 'registration',
  EMPTY = '',
  AUTH = 'auth',
  RESET_PASSWORD = 'reset-password',
}

export const openAuthModal =
  createAction<AUTH_MODAL_STATUSES>('@@ui/openAuthModal');

export const setMobileHeadVisibility = createAction<boolean>(
  '@@ui/setMobileHeadState'
);

export const changeSearchPanelType = createAction<SEARCH_PANEL_TYPES>(
  '@@ui/changeSearchPanelType'
);

const authStatus = createReducer<AUTH_MODAL_STATUSES>(
  {},
  AUTH_MODAL_STATUSES.EMPTY
);

authStatus.on(openAuthModal, (_, payload) => {
  return payload;
});

const mobileHeadVisibility = createReducer({}, true);

mobileHeadVisibility.on(setMobileHeadVisibility, (_, payload) => payload);

const searchPanelType = createReducer({}, 'avia' as SEARCH_PANEL_TYPES);
searchPanelType.on(
  changeSearchPanelType,
  (_, value: SEARCH_PANEL_TYPES) => value
);

export const ui = combineReducers({
  authStatus,
  isMobileHeadVisible: mobileHeadVisibility,
  searchPanelType,
});

import { v4 as uuidv4 } from 'uuid';
import {
  AviaTickets,
  BusOrderListPosition,
  BusOrderSegment,
  OrderListPosition,
  OrderPosition,
  Passenger,
  PassengerInfo,
  Seats,
  SegmentGroup,
  SegmentGroupItem,
  Tariff,
  TrainOrderListPosition,
  TrainOrderSegment,
} from '@modules/orders';
import { Bus, Train } from '@modules/trainTickets';
import { Helper } from '@utils';
import moment from 'moment/moment';

export const DocumentTypesTitleArray = [
  'Паспорт РФ', // RussianPassport
  'Общегражданский заграничный паспорт', // RussianForeignPassport
  'Национальный паспорт', // ForeignPassport
  'Свидетельство о рождении', // BirthCertificate
  'Военный билет военнослужащего и курсантов военных образовательных организаций', // MilitaryCard
  'Удостоверение личности для военнослужащих', // MilitaryOfficerCard
  'Свидетельство на возвращение', // ReturnToCisCertificate
  'Дипломатический паспорт', // DiplomaticPassport
  'Служебный паспорт', // ServicePassport
  'Удостоверение личности моряка', // SailorPassport
  'Удостоверение личности лица без гражданства', // StatelessPersonIdentityCard
  'Вид на жительство', // ResidencePermit
  'Временное удостоверение личности', // RussianTemporaryIdentityCard
  'Паспорт СССР', // UssrPassport
  'Медицинское свидетельство о рождении (для детей не старше 1 мес)', // MedicalBirthCertificate
  'Справка об утере паспорта', // LostPassportCertificate
  'Справка об освобождении из мест лишения свободы', // PrisonReleaseCertificate
  'Свидетельство о предоставлении временного убежища (водный транспорт)', // CertificateOfTemporaryAsylum
  'Временное удостоверение, выданное взамен военного билета', // MilitaryTemporaryCard
  'Военный билет офицера запаса', // ReserveOfficerMilitaryCard
  'Загранпаспорт СССР', // UssrForeignPassport
  'Удостоверение беженца', // RefugeeIdentity
  'Свидетельство о предоставлении временного убежища на территории РФ (ЖД); Свидетельство о рассмотрении ходатайства о признании лица беженцем (водный транспорт)', // RefugeeCertificate
  'Разрешение на временное проживание в Российской Федерации', // RussianTemporaryLivingCertificate
  'Удостоверение личности офицера', // OfficerCertificate
  'Паспорт Минморфлота', // MinistryMarineFleetPassport
  'Иностранное свидетельство о рождении', // ForeignBirthCertificate
  'Иные документы, выдаваемые органами МВД', // Other
  'Документ, удостоверяющий личность осужденного', // ConvictedPersonIdentity
  'Заключение об установлении личности иностранного гражданина или лица без гражданства', // AcknowledgmentOfIdentityOfAForeignCitizenOrStatelessPerson
  'Справка для следования в дипломатическое представительство иностранного государства в Российской Федерации', // CertificateIssuedForAForeignCitizenGoingToDiplomaticOffice
  'Иной документ, установленный федеральным законом или признаваемый в соответствии с международным договором Российской Федерации в качестве документа, удостоверяющего личность иностранного гражданина и (или) лица без гражданства', // AnotherDocumentOfAForeignCitizenRecognizedInTheRussianFederation
];

export const RailwayDocumentTypesIdsArray = [
  'RussianFederationPassport',
  'RussianForeignPassport',
  'ForeignPassport',
  'BirthCertificate',
  'MilitaryCard',
  'MilitaryOfficerCard',
  'ReturnToCisCertificate',
  'DiplomaticPassport',
  'ServicePassport',
  'SailorPassport',
  'StatelessPersonIdentityCard',
  'ResidencePermit',
  'RussianTemporaryIdentityCard',
  'UssrPassport',
  'MedicalBirthCertificate',
  'LostPassportCertificate',
  'PrisonReleaseCertificate',
  'CertificateOfTemporaryAsylum',
  'MilitaryTemporaryCard',
  'ReserveOfficerMilitaryCard',
  'UssrForeignPassport',
  'RefugeeIdentity',
  'RefugeeCertificate',
  'RussianTemporaryLivingCertificate',
  'OfficerCertificate',
  'MinistryMarineFleetPassport',
  'ForeignBirthCertificate',
  'Other',
  'ConvictedPersonIdentity',
  'AcknowledgmentOfIdentityOfAForeignCitizenOrStatelessPerson',
  'CertificateIssuedForAForeignCitizenGoingToDiplomaticOffice',
  'AnotherDocumentOfAForeignCitizenRecognizedInTheRussianFederation',
];

// адаптер для преобразования детальных данных из билета на поезд в данные для билета на самолет
export const trainOrderPositionToAviaPositionAdapter = (
  transportOrderPosition: TrainOrderListPosition | BusOrderListPosition,
  isTrainOrder: boolean,
  isBusOrder: boolean
): OrderPosition => {
  const segmentGroups: SegmentGroup[] = transportOrderPosition.segments.map(
    (segment, index) => {
      return {
        segments: [
          {
            departureAirport: {
              code:
                'departureStationCode' in segment
                  ? segment.departureStationCode
                  : segment.departureCityCode,
              name:
                'departureStationName' in segment
                  ? segment.departureStationName
                  : segment.departureCityName,
              terminal: '', // null or empty string
            },
            arrivalAirport: {
              code:
                'departureStationCode' in segment
                  ? segment.departureStationCode
                  : segment.departureCityCode,
              name:
                'departureStationName' in segment
                  ? segment.departureStationName
                  : segment.departureCityName,
              terminal: '', // null or empty string
            },
            departureCity: {
              code: segment.departureCityCode,
              name: segment.departureCityName,
            },
            arrivalCity: {
              code: segment.arrivalCityCode,
              name: segment.arrivalCityName,
            },
            airplane: {
              code: 'segments.airplaneCode', // map
              name: 'segments.airplaneName', // map
              nameEn: 'segments.airplaneNameEn', // map
            },
            departureDateTime:
              'localDepartureDate' in segment
                ? segment.localDepartureDate
                : segment.departureDate,
            arrivalDateTime:
              'localArrivalDate' in segment
                ? segment.localArrivalDate
                : segment.arrivalDate,
            departureDateTimeUtc:
              'departureDateUtc' in segment
                ? segment.departureDateUtc
                : segment.departureDate,
            arrivalDateTimeUtc:
              'arrivalDateUtc' in segment
                ? segment.arrivalDateUtc
                : segment.arrivalDate,
            bookingClass: 'segments.bookingClass', // map
            duration: parseInt(segment.tripDuration || '0'),
            marketingAirline: {
              code: 'segments.marketingAirlineCode', // map
              name: 'segments.marketingAirlineName', // map
              logoUrl: '', // map
            },
            operatingAirline: {
              code: 'segments.marketingAirlineCode', // map
              name: 'segments.marketingAirlineName', // map
              logoUrl: '', // map
            },
            flightNumber: 'segments.flightNumber', // map
            logoUrl: '', // map
            stopOvers: [], // map,
            id: 'id', // map
            isLowcoster: false, // map
          },
        ],
        duration: parseInt(segment.tripDuration || '0'), // map
        departureAirport: {
          code:
            'departureStationCode' in segment
              ? segment.departureStationCode
              : segment.departureCityCode,
          name:
            'departureStationName' in segment
              ? segment.departureStationName
              : segment.departureCityName,
          terminal: '', // null or empty string
        },
        arrivalAirport: {
          code:
            'departureStationCode' in segment
              ? segment.departureStationCode
              : segment.departureCityCode,
          name:
            'departureStationName' in segment
              ? segment.departureStationName
              : segment.departureCityName,
          terminal: '', // null or empty string
        },
        departureCity: {
          code: segment.departureCityCode,
          name: segment.departureCityName,
        },
        arrivalCity: {
          code: segment.arrivalCityCode,
          name: segment.arrivalCityName,
        },
        departureDateTime:
          'localDepartureDate' in segment
            ? segment.localDepartureDate
            : segment.departureDate,
        arrivalDateTime:
          'localArrivalDate' in segment
            ? segment.localArrivalDate
            : segment.arrivalDate,
      };
    }
  );
  const seatsAssigment: Seats[] = transportOrderPosition.passengers.map(
    (passenger, index) => {
      const seat: Seats = {
        passengerId: uuidv4(), // map
        items: passenger.priceDetailsPerRoute.map((priceDetail, index) => {
          return {
            id: priceDetail.segmentId,
            aviaOrderPositionId: 'seatsAssigment.aviaOrderPositionId', // map
            aviaPassengerId: 'seatsAssigment.aviaPassengerId', // map
            aviaSegmentIds: [priceDetail.segmentId], // map
            value: null,
            // quantity: null,
            name: 'seatsAssigment.name', // map
            type: 'seatsAssigment.type', // map
            gds: 'seatsAssigment.gds', // map
            // seatNumber: null,
            carrier: 'seatsAssigment.carrier', // map
            isRefundable: false, // map
            isEchangeable: false, // map
            // emDcode: null,
            rfic: 'seatsAssigment.rfic', // map
            rfisc: 'seatsAssigment.rfisc', // map
            basePrice: priceDetail.basePrice, // map
            commission: 1, // map
            discount: priceDetail.discount,
            agentExtraCharge: priceDetail.agentExtraCharge,
            price: 1201, // map
            ancillaryServiceAmount: {
              priceType: 0, // map
              description: 'description', // map
              value: 1202, // map
              quantity: null, // map
              detalization: [
                {
                  priceType: 0, // map
                  description: 'description', // map
                  value: 1203, // map
                  quantity: null, // map
                },
              ], // map
            },
            status: {
              code: 'Active', // map
              description: 'seatsAssigment.statusDescription', // map
            },
          };
        }),
      };
      return seat;
    }
  );
  const tariff: Tariff = {
    fareFamilies: null, // map
    adultPrice: transportOrderPosition.tariff.adultPrice.totalPrice,
    childPrice: transportOrderPosition.tariff.childPrice.totalPrice,
    infantPrice: transportOrderPosition.tariff.infantPrice.totalPrice,
    description: 'tariff.description', // map
    gds: transportOrderPosition.tariff.gds,
    serviceClass: null,
    currencyCode: 'tariff.currencyCode', // map
    isExchangeable: false, // map
    isRefundable: false, // map
    hasConnectingFlights: false,
  };
  let amount = tariff.adultPrice + tariff.childPrice + tariff.infantPrice;
  if (transportOrderPosition.isAnnulatedOrderPosition) {
    amount = 0;
    transportOrderPosition.segments.forEach((segment) => {
      amount += segment.price.totalPrice;
    });
  }
  const luggages: [] = [];
  const meals: [] = [];
  const otherAncillaryServices: [] = [];
  const passengers: Passenger[] = transportOrderPosition.passengers.map(
    (passenger, index) => {
      const aviaTickets: AviaTickets[] = passenger.priceDetailsPerRoute.map(
        (priceDetail, index) => {
          return {
            arrivalAirportCode: 'passengers.arrivalAirportCode', // map,
            departureAirportCode: 'passengers.departureAirportCode', // map,
            eTicketNumber: 'passengers.eTicketNumber', // map,
            id: 'passengers.id', // map,
          };
        }
      );
      return {
        passengerType: passenger.passengerCategoryType,
        aviaTickets: aviaTickets,
        phone: 'passengers.phone', // map
        email: 'passengers.email', // map
        firstName: passenger.firstName,
        lastName: passenger.lastName,
        patronymic: passenger.patronomic,
        gender: passenger.gender,
        citizen: passenger.citizenshipLabel,
        birthDate: passenger.birthDay,
        passengerId: 'passengers.passengerId', // map
        document: {
          type: passenger.document.documentType,
          number: passenger.document.documentNumber,
          issueDate: passenger.document.issueDate,
          issueDateExist: false,
        },
        loyalityCard: null, // map
        passengerPriceDetalization: {
          basePrice: 20, // map
          taxes: 21, // map
          subAgentExtraCharge: 22, // map
          agentExtraCharge: 23, // map
          discount: 24, // map
        },
      };
    }
  );

  return {
    id: transportOrderPosition.internalOrderPositionId,
    amount: amount,
    index: parseInt(transportOrderPosition.orderId),

    statusDescription: transportOrderPosition.segments[0].simpleOperationStatus, // map
    bookingCode: transportOrderPosition.orderId,
    validationAirline: 'orderPosition.validationAirline', // map
    segmentGroups: segmentGroups,
    cancellationTime:
      'Tue Sep 26 2023 13:28:57 GMT+0300 (Eastern European Summer Time)', // map
    status: transportOrderPosition.status,
    paymentStatus: transportOrderPosition.paymentStatus,
    isAnnulatedOrderPosition: transportOrderPosition.isAnnulatedOrderPosition,
    flight_Type: 'Regular', // map
    aviaOrderPosition: {
      externalId: null, // map
      issuedTime: null, // map
      code: 'orderPosition.code', // map
      description: 'orderPosition.description', // map
    },
    seatsAssignment: seatsAssigment,
    createdDate:
      'Tue Sep 26 2023 13:28:57 GMT+0300 (Eastern European Summer Time)', // map
    tariff: tariff,
    luggages: luggages, // map
    meals: meals, // map
    otherAncillaryServices: otherAncillaryServices, // map
    passengers: passengers, // map
    isActive: transportOrderPosition.isActive,
    extraCharge: {
      agentExtraCharge: 40, // map
      basePrice: 41, // map
      discount: 42, // map
      subAgentExtraCharge: 43, // map
      taxes: 44, // map
    },
    airlineOnlineBookingCode: {
      fullName: null,
      bookingCodes: null,
    },
    availableActions: transportOrderPosition.availableActions,
    isTrainOrder,
    isBusOrder,
    orderPositionAmount: {
      description: 'orderPositionAmount.description', // map
      detalization: [
        {
          priceType: 0, // map
          description: 'orderPositionAmount.description', // map
          value: 0, // map
          quantity: null, // map
          // @ts-ignore
          detalization: null, // map
        },
      ], // map
      priceType: 0, // map
      quantity: null, // map
      value: 0, // map
    }, // map
  };
};

// адаптер для преобразования данных для заказа в списке заказов из билета на поезд в данные для билета на самолет
export const trainOrderListPositionToAviaListPositionAdapter = (
  trainOrderPosition: TrainOrderListPosition
): OrderListPosition => {
  const passengerInfo: PassengerInfo[] = trainOrderPosition.passengers.map(
    (passenger) => {
      return {
        firstName: passenger.firstName,
        lastName: passenger.lastName,
        passengerType: passenger.passengerCategoryType,
        patronymic: passenger.patronomic,
      };
    }
  );

  const segmentGroupInfos: SegmentGroupItem[] = trainOrderPosition.segments.map(
    (segment, index) => {
      return {
        duration: Helper.formatDuration(
          moment.duration(segment.tripDuration).asMinutes()
        ),
        departureAirport: {
          code: segment.departureStationCode,
          name: segment.departureStationName,
          terminal: '', // null or empty string
        },
        arrivalAirport: {
          code: segment.arrivalStationCode,
          name: segment.arrivalStationName,
          terminal: null, // null or empty string
        },
        departureCity: {
          code: segment.departureCityCode,
          name: segment.departureCityName,
        },
        arrivalCity: {
          code: segment.arrivalCityCode,
          name: segment.arrivalCityName,
        },
        departureDateTime: segment.localDepartureDate,
        arrivalDateTime: segment.localArrivalDate,
        stopOvers: null,
      };
    }
  );

  let amount =
    trainOrderPosition.tariff.adultPrice.totalPrice +
    trainOrderPosition.tariff.childPrice.totalPrice +
    trainOrderPosition.tariff.infantPrice.totalPrice;
  if (trainOrderPosition.isAnnulatedOrderPosition) {
    amount = 0;
    trainOrderPosition.segments.forEach((segment) => {
      amount += segment.price.totalPrice;
    });
  }
  return {
    amount: amount,
    bookingCode: trainOrderPosition.orderId,
    arrivalDate: trainOrderPosition.segments[0].localArrivalDate, // map
    departureDate: trainOrderPosition.segments[0].localDepartureDate, // map
    createdDate:
      'Tue Sep 26 2023 13:28:57 GMT+0300 (Eastern European Summer Time)', // map
    extraCharge: null, // пока null
    index: parseInt(trainOrderPosition.orderId),
    isActive: true, // пока true
    serviceClass: '', // пока пустая строка
    validationAirline: {
      code: 'validationAirline', // map
      name: trainOrderPosition.segments[0]?.train?.carrier, // map
      logoUrl: '', // пока пустая строка
    },
    passengerInfos: passengerInfo,
    segmentGroupInfos: segmentGroupInfos,
  };
};

// адаптер для преобразования данных для заказа в списке заказов из билета на автобус в данные для билета на самолет
export const busOrderListPositionToAviaListPositionAdapter = (
  //  TODO добавить типизацию, когда задача TTE-9177 будет замержена
  busOrderPosition: any
): OrderListPosition => {
  const passengerInfo: PassengerInfo[] = busOrderPosition.passengers.map(
    (passenger: any) => {
      return {
        firstName: passenger.firstName,
        lastName: passenger.lastName,
        passengerType: passenger.passengerCategoryType,
        patronymic: passenger.patronomic,
      };
    }
  );

  const segmentGroupInfos: SegmentGroupItem[] = busOrderPosition.segments.map(
    (segment: any) => {
      return {
        duration: Helper.formatDuration(
          moment.duration(segment.tripDuration).asMinutes()
        ),
        departureAirport: {
          code: segment.departureCityCode,
          name: segment.departureCityName,
          terminal: '', // null or empty string
        },
        arrivalAirport: {
          code: segment.arrivalCityCode,
          name: segment.arrivalCityName,
          terminal: null, // null or empty string
        },
        departureCity: {
          code: segment.departureCityCode,
          name: segment.departureCityName,
        },
        arrivalCity: {
          code: segment.arrivalCityCode,
          name: segment.arrivalCityName,
        },
        departureDateTime: segment.departureDate,
        arrivalDateTime: segment.arrivalDate,
        stopOvers: null,
      };
    }
  );

  let amount =
    busOrderPosition.tariff.adultPrice.totalPrice +
    busOrderPosition.tariff.childPrice.totalPrice +
    busOrderPosition.tariff.infantPrice.totalPrice;
  if (busOrderPosition.isAnnulatedOrderPosition) {
    amount = 0;
    busOrderPosition.segments.forEach((segment: any) => {
      amount += segment.price.totalPrice;
    });
  }
  return {
    amount: amount,
    bookingCode: busOrderPosition.orderId,
    arrivalDate: busOrderPosition.segments[0].arrivalDate, // map
    departureDate: busOrderPosition.segments[0].departureDate, // map
    createdDate:
      'Tue Sep 26 2023 13:28:57 GMT+0300 (Eastern European Summer Time)', // map
    extraCharge: null, // пока null
    index: parseInt(busOrderPosition.orderId),
    isActive: true, // пока true
    serviceClass: '', // пока пустая строка
    validationAirline: {
      code: 'validationAirline', // map
      name: busOrderPosition.segments[0]?.bus?.carrier, // map
      logoUrl: '', // пока пустая строка
    },
    passengerInfos: passengerInfo,
    segmentGroupInfos: segmentGroupInfos,
  };
};

/**
 * Адаптер для преобразования данных для заказа в списке заказов из билета на поезд в данные для билета на самолет
 * Часть полей как заглушки
 * @param trainOrderSegment
 */
export const createOrderTrainFromOrderSegment = (
  trainOrderSegment: TrainOrderSegment
): Train | undefined => {
  if (!trainOrderSegment) {
    return;
  }
  const tripDuration = moment
    .utc()
    .startOf('day')
    .add(parseInt(trainOrderSegment.tripDuration), 'minutes')
    .format(`PTH[H]mm[M]`);

  return {
    localArrivalDate: trainOrderSegment.localArrivalDate,
    arrivalDateTimeUtc: trainOrderSegment.arrivalDateUtc,
    carGroups: [],
    carServices: [],
    carrierDisplayNames: [trainOrderSegment.train.carrier],
    carriers: [trainOrderSegment.train.carrier],
    localDepartureDate: trainOrderSegment.localDepartureDate,
    departureDateTimeUtc: trainOrderSegment.departureDateUtc,
    displayTrainNumber: trainOrderSegment.train.trainNumber,
    fromStation: {
      city: {
        id: parseInt(trainOrderSegment.departureCityCode),
        name_ru: trainOrderSegment.departureCityName,
      },
      code: trainOrderSegment.departureStationCode,
      country: null,
      description: trainOrderSegment.departureStationName,
      id: parseInt(trainOrderSegment.departureStationCode),
      isActive: true, // map
      isSuburban: false, // map
      isVisible: true, // map
      name_en: trainOrderSegment.departureCityName,
      name_ru: trainOrderSegment.departureCityName,
      region: null,
    },
    hasDynamicPricingCars: false, // map
    hasElectronicRegistration: false, // map
    hasTwoStoreyCars: false, // map
    isTransit: trainOrderSegment.train.isTransit,
    index: trainOrderSegment.segmentIndex, // map
    isBranded: false, // map
    toStation: {
      city: {
        id: parseInt(trainOrderSegment.arrivalCityCode),
        name_ru: trainOrderSegment.arrivalCityName,
      },
      code: trainOrderSegment.arrivalStationCode,
      country: null,
      description: trainOrderSegment.arrivalStationName,
      id: parseInt(trainOrderSegment.arrivalStationCode),
      isActive: true, // map
      isSuburban: false, // map
      isVisible: true, // map
      name_en: trainOrderSegment.arrivalCityName,
      name_ru: trainOrderSegment.arrivalCityName,
      region: null,
    },
    trainDescription: ` / ${[
      trainOrderSegment.train.carGenderKindDescription,
      trainOrderSegment.train.serviceClass,
      trainOrderSegment.train.additionalInformation?.description,
    ].join(' / ')}`,
    trainName: trainOrderSegment.train.trainName,
    trainNumber: trainOrderSegment.train.trainNumber,
    transportType: 'transportType', // map
    tripDuration: tripDuration,
  };
};

/**
 * Адаптер для преобразования данных для заказа в списке заказов из билета на автобус в данные для билета на самолет
 * Часть полей как заглушки
 * @param busOrderSegment
 */
export const createOrderBusFromOrderSegment = (
  busOrderSegment: BusOrderSegment
): Bus => {
  const tripDuration = busOrderSegment?.tripDuration
    ? moment
        .utc()
        .startOf('day')
        .add(parseInt(busOrderSegment?.tripDuration), 'minutes')
        .format(`PTH[H]mm[M]`)
    : null;

  return {
    localArrivalDate: busOrderSegment.arrivalDate,
    arrivalDateTimeUtc: busOrderSegment.arrivalDate,
    carrierDisplayNames: [busOrderSegment.bus.carrier],
    carriers: [busOrderSegment.bus.carrier],
    localDepartureDate: busOrderSegment.departureDate,
    departureDateTimeUtc: busOrderSegment.departureDate,
    displayBusNumber: busOrderSegment.bus.raceNumber,
    fromStation: {
      city: {
        id: parseInt(busOrderSegment.departureCityCode),
        name_ru: busOrderSegment.departureCityName,
      },
      code: busOrderSegment.departureCityCode,
      country: null,
      description: busOrderSegment.departureCityName,
      id: parseInt(busOrderSegment.departureCityCode),
      isActive: true, // map
      isSuburban: false, // map
      isVisible: true, // map
      name_en: busOrderSegment.departureCityName,
      name_ru: busOrderSegment.departureCityName,
      region: null,
    },
    isTransit: busOrderSegment.bus.isTransit,
    index: busOrderSegment.segmentIndex, // map
    toStation: {
      city: {
        id: parseInt(busOrderSegment.arrivalCityCode),
        name_ru: busOrderSegment.arrivalCityName,
      },
      code: busOrderSegment.arrivalCityCode,
      country: null,
      description: busOrderSegment.arrivalCityName,
      id: parseInt(busOrderSegment.arrivalCityCode),
      isActive: true, // map
      isSuburban: false, // map
      isVisible: true, // map
      name_en: busOrderSegment.arrivalCityName,
      name_ru: busOrderSegment.arrivalCityName,
      region: null,
    },
    busDescription: ` / ${[
      busOrderSegment.bus.additionalInformation?.description,
    ].join(' / ')}`,
    busNumber: busOrderSegment.bus.raceNumber,
    busID: busOrderSegment.bus.raceId,
    transportType: 'transportType', // map
    tripDuration,
  };
};

import React from 'react';
import styled from 'styled-components';
import Text from '@components/ui/Typography';
import { SegmentInfoProps } from './types';
import moment from 'moment';
import { Helper } from '@utils';
import { Point } from '@components/common/FlightSegment';

const Time = styled(Text)`
  font-size: 24px;
  line-height: 32px;
  display: inline-block;
  margin-bottom: 9px;
`;

const ProgressInfo = styled.div`
  text-align: center;
  width: 100%;
  margin: 0 10px;
  span {
    margin-bottom: 7px;
  }
`;

const ProgressLine = styled.div`
  margin-top: 20px;
  height: 2px;
  background-color: #dcdcdc;
  position: relative;
  & > div {
    top: -8px;
  }

  & > :last-child {
    right: 0;
  }
`;

const PlaceTimeWrapper = styled.div`
  display: flex;
`;

const PlaceDateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  & > :last-child {
    text-align: right;
  }
`;

const PointsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
`;

const PlaceContainer = styled.div``;

const Description = styled.span`
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme: { colors } }) => colors.darkGray};
`;

const HighlitedDesctiption = styled.span`
  display: inline;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  color: ${({ theme: { colors } }) => colors.main};
`;

const SegmentInfo: React.FC<SegmentInfoProps> = (props) => {
  const travelTime = Helper.formatDuration(props.duration);

  return (
    <div>
      <PlaceTimeWrapper>
        <Time color="#3C3C3C" bold>
          {moment(props.departureDateTime).format('HH:mm')}
        </Time>
        <ProgressInfo>
          <ProgressLine>
            <PointsContainer>
              <Point />

              <Point />
            </PointsContainer>
          </ProgressLine>
        </ProgressInfo>
        <Time color="#3C3C3C" bold>
          {moment(props.arrivalDateTime).format('HH:mm')}
        </Time>
      </PlaceTimeWrapper>
      <PlaceDateWrapper>
        <PlaceContainer>
          <Description>
            <HighlitedDesctiption>
              {props.departureAirport.code}
            </HighlitedDesctiption>{' '}
            {props.departureCity.name}
          </Description>
          <Description>
            {moment(props.departureDateTime).format('DD MMMM YYYY, dd')}
          </Description>
        </PlaceContainer>
        <PlaceContainer style={{ textAlign: 'center' }}>
          <Description> {travelTime}</Description>
        </PlaceContainer>
        <PlaceContainer>
          <Description>
            <HighlitedDesctiption>
              {props.arrivalAirport.code}
            </HighlitedDesctiption>{' '}
            {props.arrivalCity.name}
          </Description>
          <Description>
            {moment(props.arrivalDateTime).format('DD MMMM YYYY, dd')}
          </Description>
        </PlaceContainer>
      </PlaceDateWrapper>{' '}
    </div>
  );
};

export default SegmentInfo;

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getFlights } from '@modules/booking';
import NewFlightDetail from '@components/booking/FlightDetail/NewDesktop/NewFlightDetail';
import DetailButtonWrapper from '@components/booking/FlightDetail/NewDesktop/DetailButtonWrapper';
import { ApplicationState } from '@modules/index';
import { getMinBaggageDescription } from '@modules/orders/utils';

const NewFlightDetailContainerStepOne: React.FC = () => {
  const dataFromStore = useSelector(getFlights);
  const [data, setData] = React.useState(dataFromStore);
  const [isActive, setActive] = React.useState(false);
  const selectedFare = useSelector(
    (state: ApplicationState) => state.booking.fares?.selected,
  );
  const [baggageDescr, setBaggageDescr] = React.useState('Неизвестно');

  useEffect(() => {
    if (selectedFare) {
      const minBaggageDescription = getMinBaggageDescription(selectedFare.fareFamilies);

      if (minBaggageDescription !== null) {
        setBaggageDescr(minBaggageDescription);
      }

      const copyData = [...dataFromStore];

      copyData.forEach((flight, indexFlight) => {
        const segments = flight.segments;
        segments.forEach((segment, segmentIndex) => {
          const arrivalAirport = segment.to.code;
          const departureAirport = segment.from.code;
          const fromToRoute = { from: departureAirport, to: arrivalAirport };
          const currentFareFamily = selectedFare.fareFamilies.find(
            (fareFamily) => {
              return fareFamily?.routes?.some((route) => {
                return (
                  route.from === fromToRoute.from && route.to === fromToRoute.to
                );
              });
            },
          );
          if (currentFareFamily) {
            const baggageFeature = currentFareFamily.features.find(
              (feature) => feature.type === 'Baggage',
            );
            if (baggageFeature) {
              const description =
                baggageFeature.shortDescriptionRus ||
                baggageFeature.descriptionRus;
              if (
                description.toLowerCase().includes('платный') ||
                description === 'Не включен'
              ) {
                copyData[indexFlight].segments[
                  segmentIndex
                ].luggageDescription = description;
              } else {
                copyData[indexFlight].segments[
                  segmentIndex
                ].luggageDescription = description;
              }
            }
          }
        });
      });
      setData(copyData);
    }
  }, [selectedFare, dataFromStore]);

  return (
    <>
      <NewFlightDetail
        baggageDescr={baggageDescr}
        isActive={isActive}
        data={data}
        setActive={setActive}
      />
      <DetailButtonWrapper
        onClick={() => {
          setActive(!isActive);
        }}
        isOpen={isActive}
      />
    </>
  );
};

export default NewFlightDetailContainerStepOne;

import React from 'react';
import { PassegerListProps } from './types';
import moment from 'moment/moment';
import man1 from '@assets/media/avia-passengers/man-1.svg';
import woman1 from '@assets/media/avia-passengers/woman-1.svg';
import boy1 from '@assets/media/avia-passengers/boy-1.svg';
import girl1 from '@assets/media/avia-passengers/girl-1.svg';
import styled from 'styled-components';
import pasArrow from '@assets/media/order-detail/pasArrow.svg';
import Text from '@components/ui/Typography';
import arrowSrc from '@assets/media/order-detail/arrow1.svg';

const Wrapper = styled.div`
  margin-top: 35px;
  padding: 20px;
  border: 1px solid #dcdcdc;
  box-shadow: 0px 5px 10px 0px #0000000d;
  border-radius: 10px;

  @media (max-width: 767px) {
    padding: 15px;
  }
`;

const Heading = styled.div`
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 10px;
`;

const BookingHeading = styled.div`
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;

  @media (max-width: 767px) {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }
`;

const RowSB = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Label = styled(Text)`
  font-size: 14px;
  line-height: 20px;
  color: #737373;
  @media (max-width: 1024px) {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }
`;

const Description = styled(Text)`
  color: #3c3c3c;
  font-weight: 400;
  line-height: 20px;
  white-space: nowrap;
  @media (max-width: 1024px) {
    font-weight: normal;
  }
`;
const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-radius: 8px;
  background-color: #dcdcdc;
  margin: 20px 0 0 0;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 20px;
  width: 245px;
  min-width: 245px;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Icon = styled.img<{ reverse: boolean }>`
  transition: transform 0.2s ease-in-out;
  margin-top: 15px;
  cursor: pointer;
  transform: ${({ reverse }) =>
    !reverse ? 'rotate (0deg)' : 'rotate(180deg)'};
  @media (max-width: 1024px) {
    display: none;
    width: 24px;
    height: 24px;
  }
`;

const IconMobile = styled.img<{ reverse: boolean }>`
  transition: transform 0.2s ease-in-out;
  margin-top: 15px;
  cursor: pointer;
  transform: ${({ reverse }) => (reverse ? 'rotate (0deg)' : 'rotate(180deg)')};
  margin-left: 10px;
  margin-bottom: 12px;
`;

const InsideDivider = styled.div`
  width: 90%;
  height: 1px;
  border-radius: 8px;
  background-color: #dcdcdc;

  @media (min-width: 767px) {
    display: none;
  }
`;

const SubText = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #737373;
  margin-top: 20px;
`;

const NewPassangersBlock: React.FC<PassegerListProps> = React.memo(
  ({ items, itemWrapper, isSecondStep }: PassegerListProps) => {
    const isBookingSecondPage =
      window.location.pathname.includes('/bookingDetails') || isSecondStep;

    const [open, setOpen] = React.useState<number[]>([]);
    const [allOpen, setAllOpen] = React.useState(false);
    const [allPassengersTypes, setAllPassengersTypes] = React.useState<any>([]);

    React.useEffect(() => {
      const allPassengersTypes: string[] = [];
      items?.map((passenger: any) =>
        allPassengersTypes.push(passenger.passengerType),
      );

      setAllPassengersTypes(allPassengersTypes);
    }, [items]);

    function getPassengerTypeDescription(str: string) {
      switch (str) {
        case 'Adult':
          return 'взрослый';
        case 'Child':
          return 'ребенок';
        default:
          return 'младенец';
      }
    }

    function getImage(gender: string, birthDate: string) {
      const birth = moment(birthDate);
      const duration = moment.duration(moment().diff(birth));
      const age = duration.years();
      if (gender === 'Inessential') return undefined;
      if (gender === 'Male' && age > 18) {
        return man1;
      } else if (gender === 'Male' && age < 18) {
        return boy1;
      } else if (gender !== 'Male' && age < 18) {
        return girl1;
      } else return woman1;
    }

    function getPassportDescription(type: string) {
      switch (type) {
        case 'RussianPassport':
        case 'RussianFederationPassport':
          return 'Паспорт РФ';
        case 'BirthCertificate':
          return 'Свидетельство ';
        case 'InternationalPassport':
          return 'Загран. паспорт';
        case 'NationalPassport':
          return 'Нац. паспорт';
        default:
          return 'Другой документ';
      }
    }

    function getGenderDescription(type: string) {
      if (type === 'Inessential') return '';

      return type === 'Male' ? 'Мужской' : 'Женский';
    }

    const toggleOpen = (i: number) => {
      if (open.includes(i)) {
        setOpen(open.filter((x: any) => x !== i));
      } else {
        const newOpen = [...open];
        newOpen.push(i);
        setOpen(newOpen);
      }
    };

    function declOfNum(number?: number, titles?: string[]) {
      const cases = [2, 0, 1, 1, 1, 2];
      return titles && number
        ? number.toString() +
            titles[
              number % 100 > 4 && number % 100 < 20
                ? 2
                : cases[number % 10 < 5 ? number % 10 : 5]
            ]
        : '';
    }

    return (
      <Wrapper>
        {isBookingSecondPage ? (
          <>
            <RowSB>
              <BookingHeading>Данные пассажиров</BookingHeading>
              <Icon
                reverse={allOpen}
                src={pasArrow}
                alt="arrow"
                style={{ marginTop: 0, display: 'block' }}
                onClick={() => {
                  setAllOpen(!allOpen);
                  setOpen([]);

                  if (items.length === 1) {
                    toggleOpen(0);
                  }
                }}
              />
            </RowSB>
            {!allOpen && (
              <>
                <SubText>
                  {declOfNum(items?.length, [
                    ' пассажир ',
                    ' пассажира ',
                    ' пассажиров ',
                  ])}
                  по заказу
                </SubText>
                <SubText
                  style={{ marginTop: 5, color: '#3C3C3C', fontWeight: 600 }}
                >
                  {(allPassengersTypes.includes('Adult')
                    ? declOfNum(
                        allPassengersTypes.filter((x: any) => x === 'Adult')
                          ?.length,
                        [' взрослый ', ' взрослых ', ' взрослых '],
                      )
                    : '') +
                    (allPassengersTypes.includes('Child')
                      ? declOfNum(
                          allPassengersTypes.filter((x: any) => x === 'Child')
                            ?.length,
                          [' ребенок ', 'ребенка', 'детей'],
                        )
                      : '') +
                    (allPassengersTypes.includes('Infant')
                      ? declOfNum(
                          allPassengersTypes.filter((x: any) => x === 'Infant')
                            ?.length,
                          [' младенец ', 'младенца', 'младенцев'],
                        )
                      : '')}
                </SubText>
              </>
            )}
          </>
        ) : (
          <Heading>Пассажиры по заказу</Heading>
        )}
        {items?.map((pas, i) => (
          <>
            <Row
              onClick={() => toggleOpen(i)}
              style={{
                display: isBookingSecondPage && !allOpen ? 'none' : 'flex',
                cursor: 'pointer',
              }}
            >
              <Flex
                style={{
                  width: 'auto',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {getImage(pas.gender, pas.birthDate) && (
                  <img
                    width={30}
                    height={30}
                    style={{ borderRadius: 8, marginRight: 7 }}
                    src={getImage(pas.gender, pas.birthDate)}
                  />
                )}
                {pas.lastName +
                  ' ' +
                  pas.firstName +
                  (pas.patronymic ? ' ' + pas.patronymic + ', ' : ', ')}
                {getPassengerTypeDescription(
                  pas.passengerType || pas.passengerCategoryType,
                )}
                <IconMobile reverse={open.includes(i)} src={arrowSrc} />
              </Flex>

              {!isBookingSecondPage && (
                <Icon reverse={open.includes(i)} src={pasArrow} alt="arrow" />
              )}
            </Row>
            {((!isBookingSecondPage && open.includes(i)) ||
              (isBookingSecondPage && open.includes(i) && allOpen)) && (
              <>
                <Row style={{ justifyContent: 'normal' }}>
                  <Flex>
                    <Label>Пол:&nbsp;</Label>
                    <Description>
                      {getGenderDescription(pas.gender)}
                    </Description>
                  </Flex>
                  <InsideDivider />
                  <Flex>
                    <Label>Дата рождения:&nbsp;</Label>
                    <Description>
                      {moment(pas.birthDate).format('DD.MM.YYYY')}
                    </Description>
                  </Flex>
                  <InsideDivider />

                  <Flex>
                    <Label>Документ:&nbsp;</Label>
                    <Description>
                      {getPassportDescription(
                        pas.document.type || pas.document.documentType || '',
                      )}
                    </Description>
                  </Flex>
                  <InsideDivider />
                </Row>
                <Row style={{ justifyContent: 'normal' }}>
                  <Flex>
                    <Label>№ документа:&nbsp;</Label>
                    <Description>
                      {pas.document.number || pas.document.documentNumber}
                    </Description>
                  </Flex>
                  <InsideDivider />
                  <Flex>
                    <Label>Гражданство:&nbsp;</Label>
                    <Description>
                      {pas.citizen || pas.citizenshipLabel}
                    </Description>
                  </Flex>
                  <InsideDivider />
                  {pas.document.issueDate && (
                    <Flex>
                      <Label>Срок действия:&nbsp;</Label>
                      <Description>
                        {moment(pas.document.issueDate).format('DD.MM.YYYY')}
                      </Description>
                    </Flex>
                  )}
                  <InsideDivider />
                </Row>
                <Row>
                  {pas?.aviaTickets?.[0]?.status?.description && (
                    <>
                      <Flex>
                        <Label>Статус билета:&nbsp;</Label>
                        <Description>
                          {pas?.aviaTickets?.[0]?.status?.description}
                        </Description>
                      </Flex>
                      <InsideDivider />
                    </>
                  )}
                  {pas?.aviaTickets?.[0]?.eTicketNumber && (
                    <Row style={{ justifyContent: 'normal' }}>
                      <Flex>
                        <Label>№ билета:&nbsp;</Label>
                        <Description>
                          {pas?.aviaTickets[0]?.eTicketNumber}
                        </Description>
                      </Flex>
                      <InsideDivider />
                    </Row>
                  )}
                  {pas?.loyalityCard && (
                    <Row style={{ justifyContent: 'normal' }}>
                      <Flex>
                        <Label>Бонусная карта:&nbsp;</Label>
                        <Description>{pas?.loyalityCard}</Description>
                      </Flex>
                      <InsideDivider />
                    </Row>
                  )}
                </Row>
              </>
            )}
            {((i !== items.length - 1 && open.length !== 0) ||
              (allOpen && i !== items.length - 1)) && <Divider />}
          </>
        ))}
      </Wrapper>
    );
  },
);

export default NewPassangersBlock;

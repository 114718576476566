import React from 'react';
import { SegmentProps } from './types';
import styled from 'styled-components';
import FlightSegment, {
  FlightSegmentProps,
} from '@components/common/FlightSegment';
import { Helper, Hooks } from '@utils';
import { useMediaQuery } from 'react-responsive';
import FLighTypeIcon from '@components/common/FlightTypeIcon';
import { Text as BaseText } from '@components/ui';
import NewAdditionalInfo from '@components/booking/FlightDetail/NewDesktop/NewAdditionalInfo';
import baggageSrc from '@assets/media/shared/baggage.svg';
import noBaggageSrc from '@assets/media/shared/no-baggage.svg';

const Container = styled.div`
    @media (max-width: 1024px) {
        margin-bottom: 30px;
    }
    @media (max-width: 767px) {
        margin-bottom: 0;
    }
`;
const Content = styled.div`
    & > div {
        margin-bottom: 16px;
    }

    & > :last-child {
        margin-bottom: 0;
    }
`;

const Row = styled.div`
    display: grid;
    grid-template-columns: 1fr;
`;

const StyledFLighTypeIcon = styled(FLighTypeIcon)`
    margin-bottom: 17px;
    width: min-content;
`;

const FromToText = styled(BaseText)`
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #737373;
  display: block;
  margin-top: 15px;
  margin-bottom: 10px;
`;

const MobileSeparator = styled.div`
  width: 100%;
  height: 1px;
  background: #f2f2f2;
  margin: 14px 0;
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.colors.lightGray};
  margin: 26px 0 42px 0;
  @media (max-width: 1024px) {
    margin: 20px 0 10px 0;
  }
`;

const AdditionalInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  & > div {
    width: 33%;
  }

  & > div:nth-child(2) {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & > :last-child {
    text-align: right;
  }
`;

const AdditionalInfoItem = styled.div`
  display: flex;
  align-items: center;

  & > img {
    margin-right: 7px;
  }
`;

const AirlineNormalized = styled.img.attrs({ width: 25, height: 25 })`
  border-radius: 50%;
`;

const Segment: React.FC<SegmentProps> = ({
                                           separator,
                                           transfer,
                                           baggageDescr,
                                           ...props
                                         }) => {
  const { isLaptopOrBigTablet } = Hooks.useMediaSizes();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const detailProps: FlightSegmentProps = {
    from: {
      ...props.from,
      date: `${props.fromDate} ${props.fromTime}`,
      city: { name: props.from.city, code: '' },
      airport: { name: props.from.airport, code: props.from.code },
    },
    to: {
      ...props.to,
      date: `${props.toDate} ${props.toTime}`,
      city: { name: props.to.city, code: '' },
      airport: { name: props.to.airport, code: props.to.code },
    },
    duration: props.duration,
    airlines: [{ name: props.airline, code: props.airlineCode }],
    active: props.active,
    flightNo: `${props.airlineCode} ${props.flightNo} • ${props.airplane}`,
    stops: transfer
      ? [
        {
          duration: transfer.duration,
          time: transfer.time,
          city: {
            ...transfer.city,
            terminal: '',
          },
        },
      ]
      : [],
    company: props.operatingAirlineCode,
  };

  const terminals: string[] = [];
  if (props.from.terminal) {
    terminals.push(`вылет – ${props.from.terminal}`);
  }
  if (props.to.terminal) {
    terminals.push(`прилёт – ${props.to.terminal}`);
  }
  const isBookingSecondPage =
    window.location.pathname.includes('/bookingDetails');

  return isLaptopOrBigTablet ? (
    <div>
      <Container>
        {/* <FLighTypeIcon flightType={props.flight_Type} /> */}
        {props.fromTo && <StyledFLighTypeIcon flightType={props.flightType} />}
        {props.fromTo && props.active && (
          <FromToText>{props.fromTo}</FromToText>
        )}
        <FlightSegment {...detailProps} />
        {separator && <MobileSeparator />}
      </Container>
      {!isMobile && separator && <Separator />}
    </div>
  ) : (
    <Container>
      <Content>
        <Row>
          <FlightSegment {...detailProps} />
        </Row>
        {props.active && !isBookingSecondPage && (
          <NewAdditionalInfo
            terminals={terminals}
            operatingCompany={props.operatingAirline}
            baggage={baggageDescr}
            flightNo={props.flightNo}
            stops={detailProps.stops}
            aircraft={props.airplane}
            duration={props.duration}
            airlineCode={props.airlineCode}
            techLandings={props.techLandings}
          />
        )}
        {props.active && isBookingSecondPage && (
          <AdditionalInfoContainer>
            <AdditionalInfoItem>
              {baggageDescr === 'Без багажа' ? (
                <img src={noBaggageSrc} />
              ) : (
                <img src={baggageSrc} />
              )}
              <BaseText>{baggageDescr}</BaseText>
            </AdditionalInfoItem>
            {props.operatingAirlineCode && (
              <AdditionalInfoItem>
                <AirlineNormalized
                  src={Helper.getAirlinesLogoUrl(
                    props.operatingAirlineCode,
                    50,
                    50,
                  )}
                  alt={props.operatingAirlineCode}
                />
                {props.operatingAirline}
              </AdditionalInfoItem>
            )}
            <AdditionalInfoItem></AdditionalInfoItem>
          </AdditionalInfoContainer>
        )}
      </Content>
      {separator && <Separator />}
    </Container>
  );
};

export default Segment;

import { FlightSegmentPlace } from '@modules/main';
import {
  CAR_PLACE_GENDER_TYPES,
  CAR_TYPES,
  TRAIN_SEARCH_STEPS,
} from '@modules/trainTickets/constants';
import { Car, CarPlace } from '@modules/trainTickets/dto/GetCarsDto';
import { CombinedState } from 'redux';
import { Train } from '@modules/trainTickets/dto/GetTicketsListDto';

export type TrainDirectionType = 'forward' | 'backward';
export type PlaceReservationTypes =
  | 'FourPlacesAtOnce'
  | 'TwoPlacesAtOnce'
  | 'Usual';

export type TrainState = CombinedState<{
  cars: Car[];
  chosenCarPlaces: CarPlace[];
  currentCar: {
    data: Car;
    index: number;
  };
  index: number;
  train: Train;
}>;

export interface TrainSearchPayload {
  Adults: number;
  Children: number;
  DepartureDate: string;
  DepartureDateBack: null | string;
  From: string;
  Infants: number;
  To: string;
}

export interface GetCarsPayload {
  CarType: string;
  DepartureDate: string;
  From: string;
  To: string;
  TrainNumber: string;
  TariffType?: 'Child';
}

export interface GetTrainRoutePayload {
  TrainNumber: string;
  Origin: string;
  Destination: string;
  DepartureDate: string;
  Provider: string;
}

export interface OpenTicketPayload {
  carType: string;
  index: number;
}

export interface SetGenderTypeOnCarPlacePayload {
  carPlace: CarPlace;
  genderType: keyof typeof CAR_PLACE_GENDER_TYPES;
}

export interface ClearGenderTypesOnCarPlacesPayload {
  carPlaces: CarPlace[];
}

/** train search State */
export enum TrainSearchRequestStatus {
  initial = 'initial',
  failure = 'failure',
  loading = 'loading',
  success = 'success',
  tryToCreateOrder = 'tryToCreateOrder',
}

export interface ITrainSearchState {
  carSchemeStatus?: TrainSearchRequestStatus | null;
  carsStatus: TrainSearchRequestStatus | null;
  currentSearchStep: keyof typeof TRAIN_SEARCH_STEPS;
  filter: TrainFilter[];
  filterIsOpen: boolean;
  sortBy: 'PRICE' | 'TIME' | 'TIME_ARRIVAL' | 'TIME_DEPARTURE';
  status: TrainSearchRequestStatus;
  passengers: [number, number, number];
}

export * from './dto/GetTicketsListDto';
export * from './dto/GetCarsDto';

export interface FilterTrainDuration {
  city: string;
  label: string;
  min: number;
  max: number;
  tickets: number[];
}

export interface FilterTrainTime {
  city: string;
  label: string;
  min: number;
  max: number;
  values: number[];
  tickets: number[];
}

export interface TrainTimesItem {
  from: FilterTrainTime;
  to: FilterTrainTime;
}

export interface FilterTrainDurationItem {
  from: FilterTrainDuration;
  to: FilterTrainDuration;
  max: number;
  min: number;
  tickets: number[];
}

export interface TrainFilterItem {
  label: string;
  value: string;
  checked: boolean;
}

export interface TrainFilterCarType {
  value: keyof typeof CAR_TYPES;
  label: string;
  checked: boolean;
}

export interface TrainFilterTrainName extends TrainFilterItem {
  icon: string;
}

export interface TrainFilterDurationsItem {
  from: FlightSegmentPlace;
  to: FlightSegmentPlace;
  max: number;
  min: number;
  tickets: number[];
}

export interface TrainFilterPrices {
  min: number;
  max: number;
  tickets: number[][];
}

export interface TrainDefaultFilter {
  carTypes: boolean;
  carriers: boolean;
  discounts: boolean;
  prices: boolean;
  times: boolean;
  trainDurations: boolean;
  trainNames: boolean;
  trainServices: boolean;
  trainTypes: boolean;
}

// keys from TrainDefaultFilter
export type TrainDefaultFilterKeys = keyof TrainDefaultFilter;

export interface TrainFilter {
  carTypes: TrainFilterCarType[];
  carTypesTickets: string[][];
  carriers: TrainFilterItem[]; // наименование перевозчика
  carriersTickets: string[][];
  discounts: TrainFilterItem[];
  discountsTickets: string[][];
  filterDestinationType: TrainDirectionType;
  isFiltered: boolean; // применён ли фильтр
  prices: TrainFilterPrices; // диапазоны цен по поездам и типам купе
  times: TrainTimesItem[]; // время в пути
  trainDurationsList: FilterTrainDurationItem[];
  trainNames: TrainFilterTrainName[]; // в фигме это "Арктика", "Сапсан" и так далее
  trainNamesTickets: string[][];
  trainServices: TrainFilterItem[]; // услуги в поезде (или поезд + вагон)
  trainServicesTickets: string[][]; // услуги в поезде (или поезд + вагон)
  trainTypes: TrainFilterItem[]; // скоростной, фирменный, двухэтажный
  trainTypesTickets: string[][];
  values: {
    arrivalDates: number[];
    carTypes: { [key: string]: boolean };
    carriers: { [key: string]: boolean };
    departureDates: number[];
    discounts: { [key: string]: boolean };
    prices: number[];
    times: number[];
    trainDurations: number[];
    trainNames: { [key: string]: boolean };
    trainServices: { [key: string]: boolean };
    trainTypes: { [key: string]: boolean };
  };
  isDefault: TrainDefaultFilter;
}

export interface LoyaltyCardOption {
  label: string;
  value: string;
}

import {
  BusSearchDto,
  CitySearchDTO,
  GetCityByCodeDto,
  StationSearchDto,
} from './types';
import { Api } from '@utils';
import { AVIA_CITY_BY_CODE_GET, AVIA_SERVICE_HOST } from '@modules/constants';

const URI = process.env.REACT_APP_CITIES_API as string;
const createAviaUrl = Api.createFetchUrl(AVIA_SERVICE_HOST, URI);
const createAirportsUrl = Api.createFetchUrl(AVIA_CITY_BY_CODE_GET, '');

const cityCollection = {} as { [key: string]: CitySearchDTO };
const cityCollectionForTrain = {} as { [key: string]: StationSearchDto };
const cityCollectionForBus = {} as { [key: string]: BusSearchDto };

/**
 * Поиск авиа городов по строке
 * @param str
 * @param abortController
 */
export const getCityListByStr = async (
  str: string,
  abortController?: AbortController,
) => {
  if (cityCollection[str]) return cityCollection[str];
  const response = await fetch(
    `${createAviaUrl(process.env.REACT_APP_CITY_SEARCH as string, {
      value: str,
    })}`,
    {
      signal: abortController ? abortController.signal : undefined,
    },
  );
  if (!response.ok) {
    throw new Error('connection error');
  }
  cityCollection[str] = (await response.json()) as CitySearchDTO;
  return cityCollection[str];
};

export const getCityListByStrDefault = async () => {
  if (process.env.REACT_APP_MODE === 'test') {
    const response = await fetch(
      `${createAviaUrl(process.env.REACT_APP_CITY_SEARCH_DEFAULT as string)}`,
    );
    if (!response.ok) {
      throw new Error('connection error');
    }
    const data = (await response.json()) as CitySearchDTO;

    return data.data;
  } else {
    const response = await fetch(
      `https://avia-new.fstravel.com/api/avia/external/cities/get-popular-cities`,
    );
    if (!response.ok) {
      throw new Error('connection error');
    }
    const data = (await response.json()) as CitySearchDTO;

    return data.data;
  }
};

export const getCityByCode = function (
  value: string,
): Promise<GetCityByCodeDto> {
  return Api.request({
    url: createAirportsUrl(
      process.env.REACT_APP_CITY_SEARCH_BY_CODE as string,
      {
        value,
      },
    ),
  })
    .then(Api.statuses)
    .then(Api.json);
};
/**
 * Поиск ЖД станций по строке
 * @param str
 * @param abortController
 */
export const getCityListByStrTrain = async (
  str: string,
  abortController?: AbortController,
) => {
  if (cityCollectionForTrain[str]) return cityCollectionForTrain[str];
  const response = await fetch(
    `${process.env.REACT_APP_TRAIN_SERVICE_HOST}${
      process.env.REACT_APP_TRAIN_STATION_SEARCH
    }/${encodeURIComponent(str)}`,
    {
      signal: abortController ? abortController.signal : undefined,
    },
  );
  if (!response.ok) {
    throw new Error('connection error');
  }
  cityCollectionForTrain[str] = (await response.json()) as StationSearchDto;

  return cityCollectionForTrain[str];
};

export const getCityListByStrBus = async (
  str: string,
  abortController?: AbortController,
) => {
  const response = await fetch(
    `${process.env.REACT_APP_REFERENCE_SERVICE_HOST}${
      process.env.REACT_APP_BUS_CITIES_SEARCH
    }/${encodeURIComponent(str)}`,
    {
      signal: abortController ? abortController.signal : undefined,
    },
  );
  if (!response.ok) {
    throw new Error('connection error');
  }
  cityCollectionForBus[str] = (await response.json()) as BusSearchDto;
  return cityCollectionForBus[str];
};

export const getCityByTrain = function (
  value: string,
): Promise<GetCityByCodeDto> {
  return Api.request({
    url: createAirportsUrl(
      process.env.REACT_APP_CITY_SEARCH_BY_CODE as string,
      {
        value,
      },
    ),
  })
    .then(Api.statuses)
    .then(Api.json);
};

import React from 'react';
import styled from 'styled-components';
import { Text, Header } from '@components/ui/Typography';
import Popup from 'reactjs-popup';
import { Scrollbars } from 'react-custom-scrollbars';
import { FareConditionsProps } from './types';

const StyledPopup = styled(Popup)`
  &-content {
    width: 610px;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 40px;
    border-radius: 8px;

    @media (max-width: 767px) {
      width: 100%;
      height: 100vh;
      box-sizing: border-box;
      border-radius: 0;
      padding: 20px;
    }
  }

  &-overlay {
    @media (max-width: 767px) {
      z-index: 1001 !important;
    }
  }
`;

const PopupFieldsWrapper = styled.div`
  & > span {
    display: block;
    margin-bottom: 10px;
  }

  & > :last-child {
    margin-bottom: 35px;
  }
`;

const Thumb = styled.div`
  width: 2px;
  background-color: ${({ theme: { colors } }) => colors.main};
`;
const Track = styled.div`
  height: 100%;
  top: 0;
  right: 0;
  background-color: ${({ theme: { colors } }) => colors.lightGray};
`;

const FareDescription = styled(Text)`
  white-space: pre-line;
`;

const PopupHead = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const Cross = styled.a`
  width: 15px;
  height: 15px;
  cursor: pointer;
  position: relative;
  outline: none;

  &:hover {
    &:before,
    &:after {
      background-color: ${({ theme: { colors } }) => colors.darkGray};
    }
  }

  &:before,
  &:after {
    position: absolute;
    content: ' ';
    height: 17px;
    width: 2px;
    right: 3.5px;
    bottom: 0.5px;
    background-color: ${({ theme: { colors } }) => colors.main};
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`;

// const RoutesWrapper = styled.div`
//   display: flex;
//   width: 70%;
//   border-bottom: 1px solid #f0f0f0;
//   & > button {
//     margin-right: 4px;
//   }
//   margin-bottom: 35px;
// `;

// interface RouteProps {
//   active: boolean;
// }
// const Route = styled.button<RouteProps>`
//   min-width: 105px;
//   height: 40px;
//   font-weight: 500;
//   font-size: 14px;
//   line-height: 22px;
//   padding: 9px 16px;
//   border: 1px solid #f0f0f0;
//   border-radius: 2px 2px 0px 0px;
//   border-bottom: none;
//   background: white;
//   position: relative;
//   cursor: pointer;
//   transition: all 250ms;
//   &:focus {
//     outline: none;
//   }
//   ${({ active }) =>
//     active
//       ? `
//         color: #4872F2;
//         &:after {
//             content: ' ';
//             height: 1px;
//             width: 100%;
//             background: white;
//             position: absolute;
//             top: 100%;
//             left: 0;
//         }
//     `
//       : `
//       color: #737373;
//       background: #FAFAFA;
//     `}
// `;

const FareConditions: React.FC<FareConditionsProps> = ({
  fare,
  routes,
  open,
  onClose,
}) => {
  const [routeIndex, setRoute] = React.useState(0);

  React.useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [open]);

  React.useEffect(() => {
    setRoute(0);
  }, [routes]);

  return (
    <StyledPopup open={open} onClose={onClose}>
      <PopupHead>
        <Header size="h3">Условия тарифа</Header>
        <Cross
          href="/sde"
          onClick={(e) => {
            e.preventDefault();
            onClose();
          }}
        />
      </PopupHead>
      {/* {routes.length > 1 ? (
        <RoutesWrapper>
          {routes.map(({ name }, index) => (
            <Route
              key={name}
              onClick={() => setRoute(index)}
              active={index === routeIndex}
            >
              {name}
            </Route>
          ))}
        </RoutesWrapper>
      ) : ( */}
      <PopupFieldsWrapper>
        {/* <Text size="big">
          <Text size="big" fontWeight="600">
            Условия применения тарифа:{' '}
          </Text>
          взрослый
        </Text> */}
        <Text size="big">
          <Text size="big" fontWeight="600">
            Тариф:{' '}
          </Text>
          {fare}
        </Text>
      </PopupFieldsWrapper>

      <Scrollbars
        autoHeight
        hideTracksWhenNotNeeded
        autoHeightMax={456}
        autoHeightMin={456}
        renderThumbVertical={({ style, ...props }) => (
          <Thumb {...props} style={{ ...style, width: 2, right: 1 }} />
        )}
        renderTrackVertical={({ style, ...props }) => (
          <Track {...props} style={{ ...style, width: 1 }} />
        )}
        renderTrackHorizontal={() => <div style={{ display: 'none' }} />}
      >
        <FareDescription>
          {routes[routeIndex] ? (
            <div
              dangerouslySetInnerHTML={{
                __html: routes[routeIndex].description || '',
              }}
            ></div>
          ) : (
            <div>Нет информации.</div>
          )}
        </FareDescription>
      </Scrollbars>
    </StyledPopup>
  );
};

export default FareConditions;

import React from 'react';
import styled from 'styled-components';
import Text from '@components/ui/Typography';
import { TerminalsProps } from './types';

const DetailDescription = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DetailDescritionRow = styled.div`
  & > span {
    display: block;
    margin-bottom: 10px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

const DetailDescriptionText = styled(Text)`
  font-size: 14px;
  line-height: 18px;
  color: #737373;

  & > strong {
    color: #3c3c3c;
  }
`;

const Terminals: React.FC<TerminalsProps> = (props) => (
  <DetailDescription>
    <DetailDescritionRow>
      <DetailDescriptionText>
        Рейс:{' '}
        <strong>
          {props.marketingAirline.code} {props.flightNumber} •{' '}
          {props.airplane?.name}
        </strong>
      </DetailDescriptionText>
      <DetailDescriptionText>
        Терминалы:{' '}
        <strong>
          вылет-{props.departureAirport.terminal}, прилет-
          {props.arrivalAirport.terminal}
        </strong>
      </DetailDescriptionText>
    </DetailDescritionRow>
    <DetailDescritionRow>
      <DetailDescriptionText>
        Авиакомпания: <strong>{props.marketingAirline.code}</strong>
      </DetailDescriptionText>
      <DetailDescriptionText>
        Рейс выполняет: <strong>{props.operatingAirline.code}</strong>
      </DetailDescriptionText>
    </DetailDescritionRow>
  </DetailDescription>
);

export default Terminals;

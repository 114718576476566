import React, { useEffect } from 'react';
import { LogSessionHandleData } from '@modules/logs/types';
import { useDispatch } from 'react-redux';
import { postSessionLogHandler } from '@modules/logs/duck';

const withLogging = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
  logParams: LogSessionHandleData,
): React.FC<P> => {
  const WithLogging: React.FC<P> = (props) => {
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(
        postSessionLogHandler({
          eventType: 'ENTER',
          screen: logParams.screen,
        }),
      );
      return () => {
        dispatch(
          postSessionLogHandler({
            eventType: 'LEAVE',
            screen: logParams.screen,
          }),
        );
      };
    }, [logParams]);

    return <WrappedComponent {...props} />;
  };

  return WithLogging;
};

export default withLogging;

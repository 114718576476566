import { createAction, createReducer } from 'redux-act';
import { combineReducers } from 'redux';
import {
  OrderDetailState,
  PrintOrderPayload,
  PayOrderPayload,
  NotifyOrderPayload,
  CancelOrderPositionPayload,
  HistoryState,
  AddictionalServicesState,
  AddictionalServiceTypes,
  CalculationLoadStatus,
  CalculationState,
  ActiveCalculationsDto,
  GetOrderPaymentsDto,
  CreatePaymentEntity,
  CreatePaymentSuccessEntity,
  CreatePaymentTinkoffEntity,
  CreatePaymentTinkoffSuccessEntity,
  GetQRCodeDto,
} from '../types';
import { availableInsurancesNormalizer } from '../normalizer';
import { DetailInitialState } from '../constants';

type AvailableInsurancesState = ReturnType<
  typeof availableInsurancesNormalizer
> | null;

/** ACTIONS BEGIN */

export const getOrderByNumber = createAction<number>('@orders/detail/setData');
export const getOrderByNumberMobileBooking = createAction<number>(
  '@orders/detail/setData',
);

export const setDetailData = createAction<OrderDetailState>(
  '@orders/detail/getByNumber',
);

export const setDataWithoutNormalize = createAction<OrderDetailState>(
  '@orders/setCleanedDetails',
);

export const payOrderRequest = createAction<PayOrderPayload>(
  '@orders/payOrderRequest',
);
export const confirmPaymentRequest = createAction<NotifyOrderPayload>(
  '@orders/confirmPaymentRequest',
);

export const confirmPayment2Request = createAction<NotifyOrderPayload>(
  '@orders/confirmPayment2Request',
);

export const confirmPayment2Success = createAction<boolean>(
  '@orders/confirmPayment2Success',
);

export const confirmPayment2inProccess = createAction<boolean>(
  '@orders/confirmPayment2inProccess',
);

export const printOrderRequest = createAction<PrintOrderPayload>(
  '@orders/printOrderRequest',
);
export const printOrderSuccess = createAction<string>(
  '@orders/printOrderSuccess',
);

export const cancelOrderPositionRequest =
  createAction<CancelOrderPositionPayload>('@orders/cancelPositionRequest');

export const cancelOrderPositionSuccess = createAction(
  '@orders/cancelPositionSuccess',
);
export const cancelOrderPositionFailure = createAction(
  '@orders/cancelPositionFailure',
);

export const printRefundRequest = createAction<PrintOrderPayload>(
  '@orders/printRefundRequest',
);
export const printRefundSuccess = createAction<string>(
  '@orders/printRefundSuccess',
);
export const printRefundFailure = createAction<string>(
  '@orders/printRefundFailure',
);

export const getHistoryRequest = createAction('@orders/getHistoryRequest');
export const getHistorySuccess = createAction<HistoryState>(
  '@orders/getHistorySuccess',
);
export const historyPurify = createAction('@orders/historyPurify');

export const updateAddictionalServices = createAction<AddictionalServicesState>(
  '@orders/detail/addAddictionalServicesSuccess',
);

export const purifyAddictionalServices = createAction(
  '@orders/detail/purifyAddictionalServicesSuccess',
);

export const cancelNotification = createAction<string>(
  '@orders/detail/cancelNotification',
);
export const approveNotification = createAction<string>(
  '@orders/detail/approveNotification',
);

export const deleteAddictionalService = createAction<AddictionalServiceTypes[]>(
  '@orders/detail/deleteAddictionalService',
);
export const addAddictionalService = createAction<{
  services: AddictionalServiceTypes[];
  additionalContactNameInfo?: string;
  additionalContactPhoneInfo?: string;
}>('@orders/detail/addAddictionalService');

export const setCalculationStatus = createAction<CalculationLoadStatus>(
  '@orders/detail/setCalculationStatus',
);

export const getCalcutionSuccess = createAction<CalculationState>(
  '@orders/detail/getCalcutionSuccess',
);

export const addictionalServiceActionsSuccess = createAction<
  AddictionalServiceTypes[]
>('@orders/detail/addictionalServiceActionsSuccess');

export const refreshOrder = createAction('@orders/detail/refreshOrder');

export const getActiveCalculationsRequest = createAction<string>(
  '@orders/getActiveCalculationsRequest',
);
export const getActiveCalculationsSuccess = createAction<ActiveCalculationsDto>(
  '@orders/getActiveCalculationsSuccess',
);
export const clearActiveCalculations = createAction(
  '@orders/clearActiveCalculations',
);

export const issueTicketsRequest = createAction<{
  orderId: string;
  typePaid: string;
  userId?: string | null;
}>('@orders/issueTicketsRequest');

export const issueTicketsSuccess = createAction<{
  ticketIssue: boolean;
  errorText: string;
}>('@orders/issueTicketsSuccess');

export const issueTicketsPurify = createAction('@orders/issueTicketsPurify');

export const changeStatusOfActiveCalculation = createAction<{
  id: string;
  status: string;
}>('@orders/changeStatusOfActiveCalculation');

export const getOrderPaymentsRequest = createAction<{
  orderNumber: string;
}>('@orders/getOrderPayments');

export const getOrderPaymentsSuccess = createAction<GetOrderPaymentsDto>(
  '@orders/getOrderPaymentsSuccess',
);

export const issueOrderPositionRequest = createAction<{
  orderNumber: number;
  orderPositionId: string;
}>('@order/issueOrderPositionRequest');
export const issueOrderPositionSuccess = createAction<{
  correlationId?: string;
}>('@order/issueOrderPositionSuccess');
export const setAvailableInsurances = createAction<
  Exclude<AvailableInsurancesState, null>
>('@order/setAvailableInsurances');

export const purifyAvailableInsurances = createAction(
  '@order/purifyAvailableInsurances',
);

export const addInsurances = createAction<string[]>('@order/addInsurances');
export const deleteInsurances = createAction<string[]>(
  '@order/deleteInsurances',
);
export const deleteInsurancesSuccess = createAction<boolean>(
  '@order/deleteInsurancesSuccess',
);

export const getModalErrorText = createAction<string>(
  '@@order/getModalErrorText',
);

export const showModalError = createAction<boolean>('@@order/showModalError');

export const createPaymentRequest = createAction<CreatePaymentEntity>(
  '@@order/createPaymentRequest',
);

export const createPaymentSuccess = createAction<CreatePaymentSuccessEntity>(
  '@@order/createPaymentRequest',
);

export const createPaymentTinkoffRequest =
  createAction<CreatePaymentTinkoffEntity>(
    '@@order/createPaymentTinkoffRequest',
  );

export const createPaymentTinkoffSuccess =
  createAction<CreatePaymentTinkoffSuccessEntity>(
    '@@order/createPaymentTinkoffSuccess',
  );

export const getQrRequest = createAction<GetQRCodeDto>('@@order/getQrRequest');

export const getQrSuccess = createAction<any>('@@order/getQrSuccess');

export const stickyFooterHide = createAction<boolean>(
  '@@order/stickyFooterHide',
);

export const promocodeApply = createAction<{
  orderId: string;
  promocode: string;
}>('@@order/promocodeApply');

export const promocodeApplySuccess = createAction<any>(
  '@@order/promocodeApplySuccess',
);

export const toggleCanPay = createAction<boolean>('@order/toggleCanPay');
export const getAncillaryNew = createAction<{
  OrderPositionId: string;
}>('@@order/getAncillaryNew');

export const getAncillaryNewSuccess = createAction<any>(
  '@@order/getAncillaryNewSuccess',
);

export const addAncillaryNew = createAction<any>('@@order/addAncillaryNew');

export const addAncillaryNewSuccess = createAction<any>(
  '@@order/addAncillaryNewSuccess',
);

export const setIsBusOrder = createAction<boolean>(
  '@order/details/setIsBusOrder',
);

/** ACTIONS FINISH */

/** REDUCERS BEGIN */

const orderDetail = createReducer({}, DetailInitialState);
const orderDetailsCleaned = createReducer({}, {} as Record<string, any>);
orderDetail.on(setDetailData, (_, payload) => ({ ...payload }));

orderDetail.on(setIsBusOrder, (state: OrderDetailState, payload: boolean) => {
  return { ...state, isBusOrder: payload };
});

orderDetailsCleaned.on(
  setDataWithoutNormalize,
  (state: Record<string, any>, payload) => {
    return { ...payload };
  },
);

const printedOrder = createReducer({}, '');
printedOrder.on(printOrderSuccess, (_, payload) => payload);
printedOrder.on(printOrderRequest, () => '');

const orderPositionCancelStatus = createReducer<boolean>({}, false);
orderPositionCancelStatus.on(cancelOrderPositionRequest, () => true);
orderPositionCancelStatus.on(cancelOrderPositionSuccess, () => false);
orderPositionCancelStatus.on(cancelOrderPositionFailure, () => false);

const history = createReducer<HistoryState>({}, []);
history.on(getHistorySuccess, (_, payload) => payload);
history.on(historyPurify, () => []);

const addictionalServices = createReducer<AddictionalServicesState>({}, []);

const addictionalServicesMobile = createReducer<AddictionalServicesState>(
  {},
  [],
);

addictionalServices.on(updateAddictionalServices, (s, p) => [...s, ...p]);
addictionalServices.on(addAddictionalService, (s, p) =>
  s.map((x) => {
    if (p.services.includes(x.type)) {
      return { ...x, isLoading: true };
    }
    return x;
  }),
);

addictionalServicesMobile.on(updateAddictionalServices, (s, p) => [...s, ...p]);
addictionalServicesMobile.on(addAddictionalService, (s, p) =>
  s.map((x) => {
    if (p.services.includes(x.type)) {
      return { ...x, isLoading: true };
    }
    return x;
  }),
);
addictionalServicesMobile.on(deleteAddictionalService, (s, p) =>
  s.map((x) => {
    if (p.includes(x.type)) {
      return { ...x, isLoading: true };
    }
    return x;
  }),
);

addictionalServicesMobile.on(addictionalServiceActionsSuccess, (s, p) =>
  s.map((x) => ({
    ...x,
    checked: p.includes(x.type) ? !x.checked : x.checked,
    isLoading: p.includes(x.type) ? false : x.isLoading,
  })),
);

addictionalServices.on(deleteAddictionalService, (s, p) =>
  s.map((x) => {
    if (p.includes(x.type)) {
      return { ...x, isLoading: true };
    }
    return x;
  }),
);
addictionalServices.on(addictionalServiceActionsSuccess, (s, p) =>
  s.map((x) => ({
    ...x,
    checked: p.includes(x.type) ? !x.checked : x.checked,
    isLoading: p.includes(x.type) ? false : x.isLoading,
  })),
);
addictionalServices.on(purifyAddictionalServices, () => []);

const calculation = createReducer<CalculationState>({}, null as any);
const calculationLoadStatus = createReducer<CalculationLoadStatus>({}, 'Idle');
calculation.on(getCalcutionSuccess, (_, p) => p);
calculationLoadStatus.on(setCalculationStatus, (_, p) => p);

const activeCalculations = createReducer<ActiveCalculationsDto>(
  {},
  { totalAmount: 0, items: [], totalAmountWithSubAgentExtraCharge: 0 },
);

activeCalculations.on(getActiveCalculationsSuccess, (_, p) => p);
activeCalculations.on(changeStatusOfActiveCalculation, (s, p) => {
  return {
    ...s,
    items: [
      ...(s.items || []).map((x) => {
        if (x.supportTicketCalculation.id === p.id) {
          x.supportTicketCalculation.status = {
            code: p.status,
            description: '',
          };
        }
        return { ...x };
      }),
    ],
  };
});
activeCalculations.on(clearActiveCalculations, () => ({
  totalAmount: 0,
  items: [],
  totalAmountWithSubAgentExtraCharge: 0,
}));

const getOrderPayments = createReducer<GetOrderPaymentsDto | null>({}, null);

getOrderPayments.on(getOrderPaymentsSuccess, (_, s) => ({ ...s }));

const issueStatus = createReducer<any>({}, null as any);
issueStatus.on(issueTicketsSuccess, (_, payload) => payload);
issueStatus.on(issueTicketsPurify, () => null as any);

const availableInsurances = createReducer<AvailableInsurancesState>({}, null);
availableInsurances.on(setAvailableInsurances, (_, p) => ({ ...p }));
availableInsurances.on(addInsurances, (s, p) => {
  if (!s) {
    return null;
  }
  return {
    items: s.items.map((x) => {
      if (p.includes(x.product.id)) {
        return { ...x, loading: true };
      }
      return x;
    }),
    total: s.total,
  };
});

availableInsurances.on(deleteInsurances, (s, p) => {
  if (!s) {
    return null;
  }
  return {
    items: s.items.map((x) => {
      if (p.includes(x.product.id)) {
        return { ...x, loading: true };
      }
      return x;
    }),
    total: s.total,
  };
});

availableInsurances.on(purifyAvailableInsurances, () => null);

export const modalErrorVisible = createReducer<boolean | null>({}, null);
modalErrorVisible.on(showModalError, (_, payload) => payload);

export const modalErrorText = createReducer<string | null>({}, null);
modalErrorText.on(getModalErrorText, (_, payload) => payload);

const additionsLoading = createReducer<boolean>({}, false);
additionsLoading.on(deleteInsurances, () => true);
additionsLoading.on(deleteInsurancesSuccess, () => false);
additionsLoading.on(deleteAddictionalService, () => true);
additionsLoading.on(addictionalServiceActionsSuccess, () => false);

export const paymentUrl = createReducer<string | null>({}, null);
paymentUrl.on(createPaymentSuccess, (_, payload) => payload.paymentURL);
paymentUrl.on(createPaymentRequest, (_, payload) => null);
paymentUrl.on(createPaymentTinkoffRequest, (_, payload) => null);

export const paymentTinkoffUrl = createReducer<string | null>({}, null);
paymentTinkoffUrl.on(
  createPaymentTinkoffSuccess,
  (_, payload) => payload.paymentURL,
);
paymentTinkoffUrl.on(createPaymentTinkoffRequest, (_, payload) => null);
paymentTinkoffUrl.on(createPaymentRequest, (_, payload) => null);

export const paymentTinkoffQrNumber = createReducer<number | null>({}, null);
paymentTinkoffQrNumber.on(
  createPaymentSuccess,
  (_, payload) => payload.paymentNumber,
);

export const paymentQr = createReducer<{ svg: string } | null>({}, null);
paymentQr.on(getQrSuccess, (_, payload) => payload);

export const notifyPaymentCheck = createReducer<boolean | null>({}, null);
notifyPaymentCheck.on(confirmPayment2Success, (_, payload) => true);

export const notifyPaymentInProccess = createReducer<boolean | null>({}, null);
notifyPaymentInProccess.on(confirmPayment2inProccess, (_, payload) => payload);

export const stickyFooterStatus = createReducer<boolean | null>({}, null);
stickyFooterStatus.on(stickyFooterHide, (_, payload) => payload);

export const promocodeApplyStatus = createReducer<string | null>({}, '');
promocodeApplyStatus.on(promocodeApplySuccess, (_, payload) => payload);

export const canPay = createReducer<boolean>({}, true);
canPay.on(toggleCanPay, (_, payload) => payload);
export const newAncillary = createReducer<any | null>({}, {});
newAncillary.on(getAncillaryNewSuccess, (_, payload) => payload);

/** REDUCERS END */

export default combineReducers({
  data: orderDetail,
  orderDetailsCleaned,
  addictionalServices,
  printedOrder,
  orderPositionCancelStatus,
  history,
  calculation,
  calculationLoadStatus,
  activeCalculations,
  issueStatus,
  getOrderPayments,
  availableInsurances,
  modalErrorText,
  modalErrorVisible,
  additionsLoading,
  paymentUrl,
  paymentTinkoffUrl,
  notifyPaymentCheck,
  notifyPaymentInProccess,
  addictionalServicesMobile,
  stickyFooterStatus,
  promocodeApplyStatus,
  paymentTinkoffQrNumber,
  paymentQr,
  canPay,
  newAncillary,
});

import React, { useRef, useState } from 'react';
import {
  loadFlightSearchDataByInterval,
  selectFlightSearchInterval,
} from '@modules/main';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { Helper } from '@utils';

import styled from 'styled-components';
import Icon from '../../assets/images/calendareArrow';
import { ApplicationState } from '@modules/index';

const Wrapper = styled.div`
  width: 100%;
  background-color: #f7f7f7;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  @media (max-width: 1024px) {
    margin-bottom: 20px;
  }
`;

const Container = styled.div`
  width: 1170px;
  height: 68px;
  background-color: #f7f7f7;
  font-family: ${({ theme: { fonts } }) => fonts.regular};
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  & span {
    font-family: ${({ theme: { fonts } }) => fonts.regular};
  }
  @media (max-width: 1024px) {
    width: 100%;
    padding: 0 0 0 20px;
  }
`;

const Title = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  margin-right: 60px;
  color: ${({ theme: { colors } }) => colors.blackGray};
  @media (max-width: 1024px) {
    margin-right: 10px;
  }
`;

const SelectWrapper = styled.div`
  width: 847px;
  overflow: hidden;
  height: 100%;
  @media (max-width: 1024px) {
    overflow: scroll;
  }
`;

const SelectContainer = styled.div`
  white-space: nowrap;
  transition: transform 200ms;
`;

const TimeItemWrapper = styled.button`
  width: 121px;
  height: 68px;
  outline: none;
  border: none;
  display: inline;
  padding: 0;
  background: transparent;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
  &.selected {
    border: 1px solid #4872f2;
    border-radius: ${({ theme: { borderRadius } }) => borderRadius};
    background: ${({ theme: { colors } }) => colors.white};
  }
`;

const TimeItemContainer = styled.div`
  text-align: left;
  display: inline-grid;
`;

const TimeItemDate = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: inline-block;
  color: ${({ theme: { colors } }) => colors.darkGray};
`;

const TimeItemPrice = styled.span`
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  margin-top: 4px;
`;

/** Arrow */

const ArrowWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 68px;
`;

const ArrowButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: transparent;
  border: 1px solid ${({ theme: { colors } }) => colors.blue};
  outline: none;
  background: #ffffff;
`;

interface ArrowButtonIconProps {
  reverse?: boolean;
}
const ArrowButtonIcon = styled(Icon)<ArrowButtonIconProps>`
  width: 6px;
  fill: ${({ theme: { colors } }) => colors.blue};
  transform: translateX(1px);
  &.reverse {
    transform: rotate(180deg) translateX(2px);
  }
`;

const DateLine: React.FC = () => {
  const dispatch = useDispatch();
  const {
    flightSearchIntervals: {
      list: { items, length },
      selected,
    },
  } = useSelector((store: ApplicationState) => store.mainReducer);
  const ref = useRef(null as null | HTMLDivElement);
  const [offset, setOffset] = useState(0);
  const [isArrowVisible, setArrowVisibility] = useState(true);

  const handleArrowClick = () => {
    const scrollWidth = ref.current?.scrollWidth || 847;
    setOffset(scrollWidth - 847);
    setArrowVisibility(false);
  };

  return (
    <Wrapper>
      <Container>
        <Title>Цены на ближайшие даты</Title>
        <SelectWrapper>
          <SelectContainer
            style={{ transform: `translateX(-${offset}px)` }}
            ref={ref}
          >
            {items &&
              Object.keys(items).map((key, index) => {
                const { from, to, price } = items[key];

                const day = moment(from);
                const dayTo = moment(to);
                const dayFromFormated =
                  day.format('DD ') +
                  day.format('MMM').replace('.', '').substring(-1, 3);
                const dayToFormated =
                  dayTo.format('DD ') +
                  dayTo.format('MMM').replace('.', '').substring(-1, 3);
                const priceFormated = Helper.formatPrice(Math.floor(price));
                const today = moment();
                const dateStr = dayTo.isBefore(today)
                  ? day.format('DD MMMM')
                  : `${dayFromFormated} - ${dayToFormated}`;
                const className = selected === key ? 'selected' : '';
                const handleClick = () => {
                  dispatch(selectFlightSearchInterval(key));
                  dispatch(loadFlightSearchDataByInterval());
                };
                return (
                  <TimeItemWrapper
                    onClick={handleClick}
                    className={className}
                    key={key}
                  >
                    <TimeItemContainer>
                      <TimeItemDate> {dateStr}</TimeItemDate>
                      <TimeItemPrice>{priceFormated}</TimeItemPrice>
                    </TimeItemContainer>
                  </TimeItemWrapper>
                );
              })}
          </SelectContainer>
        </SelectWrapper>
        {length > 7 && isArrowVisible && (
          <ArrowWrapper>
            <ArrowButton onClick={handleArrowClick}>
              <ArrowButtonIcon />
            </ArrowButton>
          </ArrowWrapper>
        )}
      </Container>
    </Wrapper>
  );
};

export default DateLine;

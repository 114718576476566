import React from 'react';
import styled from 'styled-components';
import arrowPath from '@assets/media/train-tickets/arrow-breadcrumbs.svg';
import { BreadCrumbItem } from '@screens/TrainsSearch';

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 40px;
`;

const Img = styled.img.attrs({ src: arrowPath })`
  width: 18px;
  height: 18px;
  margin-right: 22px;
  margin-left: 22px;

  @media (max-width: 767px) {
    margin-right: 10px;
    margin-left: 10px;
  }
`;

const Item = styled.div<{ selected: boolean; enabled: boolean }>`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #c4c4c4;

  @media (max-width: 767px) {
    font-size: 12px;
  }

  @media (max-width: 767px) {
    &:after {
      bottom: -10px !important;
    }
  }

  ${({ enabled }) =>
    enabled
      ? `
    color: #3C3C3C;
    position: relative;
    &:hover {
      cursor: pointer;
    }
 
  `
      : ''}

  ${({ selected }) =>
    selected
      ? `
    color: #3C3C3C;
    position: relative;
    &:after {
      content: '';
      width: 100%;
      display: block;
      height: 3px;
      background: #4872F2;
      bottom: -18px;
      position: absolute;
    }
  `
      : ''}
`;

export default function Breadcrumbs(props: { items: BreadCrumbItem[] }) {
  const items = props.items.map((x, key, arr) => [
    <Item
      onClick={x.onClick}
      key={key}
      enabled={x.uiStatus === 'enabled'}
      selected={x.uiStatus === 'selected'}
    >
      {x.text}
    </Item>,
    key !== arr.length - 1 ? <Img key={`${key}_1`} /> : null,
  ]);
  return <Wrapper>{items}</Wrapper>;
}

import { put, call } from 'redux-saga/effects';
import { Action } from 'redux-act';
import { SagaIterator } from 'redux-saga';
import * as Manager from '../../Manager';
import { UserCookie } from '@services/UserCookies';

import { showModalError, getModalErrorText } from '@modules/orders';
export function* getAgentOrderBillSaga(
  action: Action<{
    type: 'createBill' | 'credit';
    orderId?: string;
  }>
): SagaIterator {
  const { payload } = action;
  try {
    if (payload.type === 'createBill') {
      yield call(Manager.getAgentOrderBill, payload.orderId, UserCookie.token);
    } else {
      yield call(Manager.issueTicketByCredit, payload.orderId, UserCookie.token);
    }
  } catch (err) {
    yield put(getModalErrorText(err.message));
    yield put(showModalError(true));
  }
}

import React from 'react';
import { FlightSegmentProps } from './types';
import DesktopTemplate from './Desktop/Template';
import MobileTemplate from './Mobile/Segment';
import { Hooks } from '@utils';

const Segment: React.FC<FlightSegmentProps> = (props) => {
  const { isLaptopOrBigTablet } = Hooks.useMediaSizes();

  return isLaptopOrBigTablet ? (
    <MobileTemplate {...props} />
  ) : (
    <DesktopTemplate {...props} />
  );
};

export default Segment;

import { put, call, select } from 'typed-redux-saga';
import { showNotification } from '@modules/notification';
import * as Sentry from '@sentry/react';
import {
  setInitializeState,
  setStatus,
  updateAncillaryServices,
  updatePassengers,
  updateFares,
  selectFlightSuccess,
  setSeatMap,
} from '../../duck';
import * as Manager from '../../Manager';
import { AirCheckAvailabilityPayload } from '../../types';
import { createSeatMapState } from '../../utils';

import { initPriceDetailWorker } from './priceDetailWorkers';
import { NavigationService } from '@services/index';
import { clearSimpleSearch } from '@modules/simpleSearch';
import { getPluginToken } from '@app/modules/main';

export function* checkAvailabilityRequestWorker(
  args: AirCheckAvailabilityPayload
) {
  yield put(setInitializeState(false));
  const pluginToken = yield* select(getPluginToken);

  try {
    yield put(setStatus(true));
    const response = yield* call(
      Manager.checkAvailablity,
      args.id,
      args.group,
      args.flights,
      pluginToken
    );
    if (
      (response?.errors?.find((err) => err.code === 'RESEARCH_EXCEPTION') &&
        response?.searchRequest?.salesChannel) ||
      (response?.errors?.find((err) => err.code === 'ERROR_EXCEPTION') &&
        response?.searchRequest?.salesChannel)
    ) {
      yield* put(
        showNotification({
          code: 499,
          message: response?.errors?.find(
            (err) => err.code === 'RESEARCH_EXCEPTION'
          )?.description,
          searchRequest: response.searchRequest,
        })
      );
      yield* put(setStatus(false));
    } else if (
      response?.errors?.find(
        (err) => err.code === 'NO_FLIGHTS_AT_RESEARCH_EXCEPTION'
      ) &&
      response?.searchRequest?.salesChannel
    ) {
      yield* put(
        showNotification({
          code: 498,
          message: 'Но мы уже нашли для Вас новые выгодные варианты перелета.',
          searchRequest: response.searchRequest,
        })
      );
      yield* put(setStatus(false));
    } else {
      yield put(updateAncillaryServices(response.ancillaryServices));
      yield put(updatePassengers(response.passengers));
      yield put(
        updateFares({
          selected: response.fares[0],
          items: response.fares,
          notificationVisibility: false,
        })
      );
      yield put(
        setSeatMap(createSeatMapState(response.fares[0], response.passengers))
      );
      yield put(selectFlightSuccess(response));
      yield call(initPriceDetailWorker);
      yield put(setStatus(false));
      yield put(setInitializeState(true));

      if (response.priceChanged) {
        yield* put(showNotification({ code: 999, message: '' }));
      }

      const isCharter = response.flights[0].flight_Type === 'Charter';

      return {
        responseId: response.responseId,
        isCharter: isCharter,
      };
    }
  } catch (e) {
    const search = window?.location?.search;
    const searchParse = search.match(/search=(.*)/);
    const searchResult = Array.isArray(searchParse) ? searchParse[1] : null;
    Sentry.captureException(e);
    yield put(setStatus(false));

    if (searchResult) {
      yield* put(clearSimpleSearch());
      yield* call(NavigationService.navigate, `/search/${searchResult}`);
    } else {
      yield* put(
        showNotification({
          code: e.name,
          message: e.message,
        })
      );
    }
    return null;
  }
}

import React from 'react';
import { ItemProps } from './types';
import { PositionContextProvider } from './useItemProps';
import Mobile from './Mobile';
import Desktop from './Desktop';
import { useMediaQuery } from 'react-responsive';

const OrderPositionItem: React.FC<ItemProps> = ({
  defaultOpen = false,
  ...props
}) => {
  const [isOpen, setOpen] = React.useState(defaultOpen);
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  return (
    <PositionContextProvider {...props} isOpen={isOpen} setOpen={setOpen}>
      {isMobile ? <Mobile /> : <Desktop />}
    </PositionContextProvider>
  );
};

export default OrderPositionItem;

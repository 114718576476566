import React from 'react';
import styled from 'styled-components';
import Text from '@components/ui/Typography';
import { InfoBlock, Separator } from '@components/common';
import { Helper } from '@utils';
import { useDispatch } from 'react-redux';
import { PriceItem } from '@modules/booking';
import { setCommissionStatus } from '@modules/user';
import commissionEye from '@assets/media/booking/commissionEye.svg';
import commissionEyeOpen from '@assets/media/booking/commissionEyeOpen.svg';

import AnimatedNumber from 'animated-number-react';
import TrainDiscountNoty from '@components/train/Booking/DiscountNoty';

interface Props {
  total: number;
  subagentTotal: number | null;
  detailList?: PriceItem[];
  noTimes?: boolean;
  needShowDiscountNoty?: boolean;
  userAgentId?: string | null | undefined;
  userCommissionStatus?: unknown;
}

const Head = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

const DetailContent = styled.div`
  margin-top: 24px;

  & > div {
    margin-bottom: 22px;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    margin-top: 15px;
    & > div {
      margin-bottom: 10px;
    }
  }
`;

const DetailRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeaderWrapper = styled.div`
  display: flex;
`;

const Title = styled(Text)`
  font-size: 24px;
  font-weight: 600;
  @media (max-width: 1024px) {
    font-size: 20px;
    font-weight: 600;
  }
  @media (max-width: 767px) {
    font-size: 16px;
  }
`;

const StyledText = styled(Text)`
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

const StyledTextBig = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const StyledTextBigBlue = styled(StyledTextBig)`
  color: #4872f2;
`;

const ShowCommissionBlock = styled.div`
  display: flex;
  align-items: center;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #4872f2;
  cursor: pointer;
  max-width: 250px;
`;

const CommissionEye = styled.img.attrs({ src: commissionEye })`
  margin-right: 5px;
`;

const CommissionEyeOpen = styled.img.attrs({ src: commissionEyeOpen })`
  margin-right: 5px;
`;

const DiscountNotyContainer = styled.div`
  margin-top: 20px;
`;

const AdditionalItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PriceDetail: React.FC<Props> = (props: Props) => {
  const {
    total,
    subagentTotal,
    detailList,
    noTimes,
    needShowDiscountNoty,
    userCommissionStatus,
    userAgentId,
  } = props;

  const dispatch = useDispatch();

  const PriceItem = (priceItem: PriceItem, key: string, subLevel: boolean) => {
    return (
      <div key={key}>
        <DetailRow
          style={{
            marginTop: subLevel ? 20 : 0,
          }}
        >
          <StyledText
            style={{
              fontWeight: priceItem.type === 'subagent-cost' ? 600 : 400,
              color: subLevel ? '#737373' : '#3C3C3C',
              marginLeft: subLevel ? 10 : 0,
            }}
          >
            {priceItem.description}
          </StyledText>
          <StyledText
            data-cy={priceItem.dataCy || ''}
            style={{
              fontWeight: priceItem.type === 'subagent-cost' ? 600 : 400,
              color: subLevel ? '#737373' : '#3C3C3C',
            }}
          >
            {priceItem.quantity > 1 && !noTimes && `${priceItem.quantity} X `}
            {Helper.formatPrice(priceItem.cost)}
          </StyledText>
        </DetailRow>
        {!!priceItem?.additionalPriceItems?.length &&
          priceItem.additionalPriceItems.map((additionalItem, addKey) => {
            return (
              <AdditionalItemContainer key={`${key}-${addKey}`}>
                {PriceItem(additionalItem, `${key}-${addKey}-1`, true)}
              </AdditionalItemContainer>
            );
          })}
      </div>
    );
  };

  return (
    <InfoBlock>
      <Head>
        <HeaderWrapper>
          <Title size={24} style={{ marginRight: 10 }}>
            Стоимость заказа
          </Title>
        </HeaderWrapper>
        <Title
          data-cy={'totalPrice'}
          size={24}
          fontWeight="600"
          color="blue"
          bold
        >
          <AnimatedNumber
            duration={500}
            value={total}
            formatValue={(value: number) => Helper.formatPrice(value)}
          />
        </Title>
      </Head>
      <DetailContent>
        {!!detailList?.length &&
          detailList
            .sort((a, b) => typeToNumber(a.type) - typeToNumber(b.type))
            .map((priceItem, index) => (
              <React.Fragment key={index}>
                {(priceItem.id === 'subagent' && userCommissionStatus) ||
                priceItem.type === '20' ? (
                  <></>
                ) : (
                  priceItem.quantity > 0 &&
                  PriceItem(priceItem, `${index}`, false)
                )}
              </React.Fragment>
            ))}
        {userAgentId && (
          <ShowCommissionBlock
            onClick={() => dispatch(setCommissionStatus(!userCommissionStatus))}
          >
            {!userCommissionStatus ? <CommissionEyeOpen /> : <CommissionEye />}{' '}
            <div>
              {!userCommissionStatus
                ? 'Скрыть детали для агента'
                : 'Открыть детали для агента'}{' '}
            </div>
          </ShowCommissionBlock>
        )}

        {!!subagentTotal && !userCommissionStatus && (
          <>
            <Separator />
            <DetailRow>
              <StyledTextBig>Сумма оплаты для агента</StyledTextBig>
              <StyledTextBigBlue>
                {Helper.formatPrice(subagentTotal)}
              </StyledTextBigBlue>
            </DetailRow>
          </>
        )}
      </DetailContent>
      {needShowDiscountNoty && (
        <DiscountNotyContainer>
          <TrainDiscountNoty />
        </DiscountNotyContainer>
      )}
    </InfoBlock>
  );
};

function typeToNumber(type: string) {
  return type === 'subagent-cost' ? 1 : 0;
}

export default PriceDetail;

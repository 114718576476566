import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import Item from './Item';
import { RadioButtonCategoryGroupProps, ItemClickHandler } from './types';
import { useMediaQuery } from 'react-responsive';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 30px;
  margin-bottom: -30px;
  padding-left: 24px;
  @media (max-width: 767px) {
    flex-direction: column;
    padding: 0 14px;
    padding-bottom: 0;
    margin-bottom: 0;
    overflow-x: hidden;
  }
`;

const Wrapper = styled.div`
  overflow: hidden;
  position: relative;
`;

function Group<T, V, R>(props: RadioButtonCategoryGroupProps<T, V, R>) {
  const [selectedIndex, setSelectedIndex] = useState(
    props.upsellIndex === null ? 0 : props.upsellIndex + 1,
  );

  const handleClick: ItemClickHandler<T> = useCallback((data, index) => {
    setSelectedIndex(index);
    props.onChange(data);
  }, []);

  const ref = React.useRef(null as null | HTMLDivElement);

  const isNotMobile = useMediaQuery({ minWidth: 769 });

  const [lastDivWidth, setLastDivWidth] = React.useState(0);

  React.useEffect(() => {
    if (ref.current) {
      const widht = isNotMobile
        ? ref.current.clientWidth - (240 + 12 + 24)
        : ref.current.clientWidth - (240 + 12 + 12);
      setLastDivWidth(widht);
    }
  }, [ref]);

  return (
    <Wrapper>
      <Container ref={ref}>
        {props.items.map((itemProps, index) => (
          <Item
            type={props.type}
            key={index}
            {...itemProps}
            checked={index === selectedIndex}
            index={index}
            renderFeature={props.renderFeature}
            renderRouteTitle={props.renderRouteTitle}
            onClick={handleClick}
            renderLabel={props.renderLabel}
          />
        ))}
      </Container>
    </Wrapper>
  );
}

export default Group;

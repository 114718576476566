export interface PaymentDto {
  paymentURL: string;
}

export enum tinkoffQrTypes {
  QRCode = 0,
  Link = 1,
}

export interface GetQRCodeDto {
  paymentNumber: number;
  tinkoffSettingType: number;
  tinkoffQrType: tinkoffQrTypes;
}
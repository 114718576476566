import React from 'react';
import styled from 'styled-components';
import { Text } from '@components/ui';
import { Title, GrayText } from './shared';
import { ReactComponent as FireIcon } from '@assets/media/main-page/fire.svg';
import { ReactComponent as RedCircleIcon } from '@assets/media/main-page/red-circle.svg';
import { NavLink } from 'react-router-dom';
import { Helper } from '@utils';
import { useSelector } from 'react-redux';
import { ApplicationState } from '@modules/index';

const Wrapper = styled.div`
  background: #fef5f5;
  padding: 45px 0;
  position: relative;

  @media (max-width: 1169px) {
    padding: 30px 50px;
  }

  @media (max-width: 767px) {
    padding: 30px 20px;
    margin: 0 20px;
    border-radius: 16px;
  }
`;

const Container = styled.div`
  max-width: 1170px;
  margin: 0 auto;
  position: relative;
  z-index: 2;

  & > span {
    display: block;
  }

  ${GrayText} {
    margin-bottom: 8px;
  }

  ${Title} {
    margin-bottom: 35px;
  }
`;

const Fire = styled(FireIcon)`
  position: absolute;
  top: -76px;
  right: 44px;
  z-index: 1;

  @media (max-width: 1169px) {
    width: 156px;
    height: 156px;
    top: -55px;
    right: 25px;
  }

  @media (max-width: 767px) {
    width: 108px;
    height: 108px;
    top: -31px;
    right: -10px;
  }
`;

const RedCircle = styled(RedCircleIcon)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  @media (max-width: 1169px) {
    display: none;
  }

  @media (min-width: 768px) {
    widht: 251px;
    height: 251px;
  }
`;

const RedCircleMobileWrapper = styled.div`
  width: 194px;
  height: 194px;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;

  @media (min-width: 1170px) {
    display: none;
  }

  @media (min-width: 768px) {
    widht: 251px;
    height: 251px;
  }
`;

const RedCircleMobile = styled.div`
  border-radius: 50%;
  background: #d40e14;
  opacity: 0.1;
  position: absolute;
  z-index: 1;
  right: -59%;
  top: -33px;
  width: 194px;
  height: 194px;
  @media (min-width: 768px) {
    width: 251px;
    height: 251px;
    right: -35%;
    top: -72px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 20px;
  column-gap: 37px;

  @media (max-width: 1169px) {
    grid-template-columns: 1fr 1fr;
    column-gap: 27px;

    & > :nth-child(5),
    > :nth-child(6) {
      display: none;
    }
  }

  @media (max-width: 767px) {
    display: block;
    & > a {
      margin-bottom: 20px;
    }
    margin-bottom: -20px;
  }
`;

const Flight = styled(NavLink)`
  padding: 16px;
  box-sizing: border-box;
  height: 80px;
  background: #ffffff;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  box-sizing: border-box;
  border: 1px solid rgba(223, 227, 248, 1);
  transition: 0.2s;
  &:hover {
    border-color: rgb(184 191 231);
    box-shadow: 0 0 5px 3px rgb(0, 0, 0, 5%);
  }
`;

const FlightInfo = styled.div`
  & > :first-child {
    display: block;
    margin-bottom: 4px;
  }
`;

const FlightName = styled(Text)`
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: #3c3c3c;
`;

const FlightInfoText = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #737373;

  & > strong {
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    color: #4872f2;
  }
`;

export default function () {
  const items = useSelector(
    (s: ApplicationState) => s.mainReducer.promotions.data
  );

  if (items.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      <RedCircle />
      <RedCircleMobileWrapper>
        <RedCircleMobile />
      </RedCircleMobileWrapper>
      <Fire />
      <Container>
        <GrayText>Не упустите шанс</GrayText>
        <Title>Спецпредложения авиакомпаний</Title>
        <Grid>
          {/*@ts-ignore*/}
          {items.map((item, key) => (
            <Flight
              key={key}
              to={item.url}
              // onClick={() => {
              //   ReactGA.event({
              //     category: 'promotions',
              //     action: 'select_promotion',
              //   });
              // }}
            >
              <FlightInfo>
                <FlightName>{item.fromTo}</FlightName>
                <FlightInfoText>
                  {item.date} —{' '}
                  <strong> от {Helper.formatPrice(item.price)}</strong>
                </FlightInfoText>
              </FlightInfo>
              <img
                width={64}
                height={48}
                src={Helper.getAirlinesLogoUrl(item.code, 128, 96, true)}
                alt={item.code}
              ></img>
            </Flight>
          ))}
        </Grid>
      </Container>
    </Wrapper>
  );
}

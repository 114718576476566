import * as Sentry from '@sentry/react';
import { parseInt } from 'lodash';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import {
  CAR_PLACE_GENDER_TYPES_SHORT,
  CAR_TYPES,
  CarFloor,
  CarPlace,
  clearGenderTypesOnCarPlaces,
  setCarSchemeRequestState,
  setChosenCarPlace,
  setChosenCarPlaceBackward,
  TRAIN_SEARCH_STEPS,
  trainBackChosenCarPlacesSelector,
  trainBackCurrentCarSelector,
  trainCurrentSearchStepSelector,
  trainForwardChosenCarPlacesSelector,
  trainForwardCurrentCarSelector,
  TrainSearchRequestStatus,
} from '@modules/trainTickets';
import SeatTooltip from '@components/train/TrainSearch/Ticket/SeatTooltip';
import CarSkeleton from '@components/train/TrainSearch/Ticket/CarSkeleton';
import { SeatOptionsTooltip } from '@components/train/TrainSearch/Ticket/SeatOptionsTooltip';
import {
  getCarPlaceGenderTypeByPlaceNumber,
  getCompartmentByPlaceNumber,
  makeSVGEl,
} from '@components/train/TrainSearch/utils';
import CustomCarScheme from '@components/train/TrainSearch/Ticket/CustomCarScheme';
import { ApplicationState } from '@modules/index';
import {
  convertCarPlaceNumberFromScheme,
  getTrainPlaceByNumber,
} from '@modules/trainTickets/utils';
import { setTariffBackward, setTariffForward } from '@modules/trainBooking';
import { CYPRESS_RAILWAY_ATTRS } from '../../../../cypress/railway';

const GET_CAR_SCHEME_API = process.env.REACT_APP_TRAIN_GET_CAR_SCHEME as string;
const TRAIN_API_URL = process.env.REACT_APP_TRAIN_SERVICE_HOST as string;
const MAX_SEATS_KUPEK = 4;
const MAX_SEATS_SINGLE = 2;

const SeatMapContainer = styled.div`
  &:has(svg) {
    min-height: 100px;
    max-height: 115px;
    height: 100%;
  }

  & > svg {
    height: 100%;
  }

  @media (max-width: 767px) {
    width: 750px;
    overflow-x: auto;
    & > svg {
      width: 100%;
    }
  }
`;

interface Props {
  type: CarFloor;
}

export default function CarScheme(props: Props) {
  const dispatch = useDispatch();
  const currentStep = useSelector(trainCurrentSearchStepSelector);
  const passengers = useSelector(
    (state: ApplicationState) => state.searchReducer.simpleSearchForm.passengers
  );
  let maxSeats = passengers.adults.count + passengers.children.count;
  const tariffForward = useSelector(
    (state: ApplicationState) => state.trainBooking.tariffs.forward
  );
  const tariffBackward = useSelector(
    (state: ApplicationState) => state.trainBooking.tariffs.backward
  );
  const isKupekTariffForward = tariffForward === 'Kupek';
  const isKupekTariffBackward = tariffBackward === 'Kupek';
  const isSingleTariffForward = tariffForward === 'Single';
  const isSingleTariffBackward = tariffBackward === 'Single';

  const isBackward = currentStep === TRAIN_SEARCH_STEPS.TICKET_BACKWARD;
  const isForward = currentStep === TRAIN_SEARCH_STEPS.TICKET_FORWARD;

  if (
    (isKupekTariffForward && isForward) ||
    (isKupekTariffBackward && isBackward)
  ) {
    maxSeats = MAX_SEATS_KUPEK;
  }

  if (
    (isSingleTariffForward && isForward) ||
    (isSingleTariffBackward && isBackward)
  ) {
    maxSeats = MAX_SEATS_SINGLE;
  }

  const data = useSelector(
    isBackward ? trainBackCurrentCarSelector : trainForwardCurrentCarSelector
  );
  const chosenCarPlacesForward = useSelector(
    trainForwardChosenCarPlacesSelector
  );
  const chosenCarPlacesFromState = useSelector(
    isBackward
      ? trainBackChosenCarPlacesSelector
      : trainForwardChosenCarPlacesSelector
  );

  // Выбрана переговорка в Сапсане
  const allPlacesIsFourPlacesAtOnceType =
    chosenCarPlacesFromState?.length === 4 &&
    chosenCarPlacesFromState.every(
      (place) => place.placeReservationType === 'FourPlacesAtOnce'
    );

  if (isBackward) {
    // в обратном направлении максимальное количество мест равно количеству выбранных мест в прямом направлении
    // не для тарифов Купек и Сингл
    if (!isKupekTariffBackward && !isSingleTariffBackward) {
      maxSeats = chosenCarPlacesForward.length;
    }
  }
  const car = data.data;
  const discounts = car.discounts;
  const carSchemeRequestStatus = useSelector(
    (state: ApplicationState) => state.trainTickets.trainSearch.carSchemeStatus
  );
  const [hoveredSeatRef, setHoveredSeatRef] = useState<Element | null>(null);
  const [optionsSeatTypeRef, setOptionsSeatTypeRef] = useState<Element | null>(
    null
  );
  const [hoveredSeat, setHoveredSeat] = useState<CarPlace | null>(null);
  const [activeSeat, setActiveSeat] = useState<CarPlace | null>(null);
  const [activeSeatHasGenderType, setActiveSeatHasGenderType] =
    useState<boolean>(false);
  const [activeSeatHasKupekTariff, setActiveSeatHasKupekTariff] =
    useState<boolean>(false);
  const [activeSeatHasSingleTariff, setActiveSeatHasSingleTariff] =
    useState<boolean>(false);
  useState<boolean>(false);
  useState<boolean>(false);
  const ref = React.useRef(null as HTMLDivElement | null);

  const hoverSeatListener = (el: Element, number: string) => {
    if (number) {
      el.addEventListener('mouseover', () => {
        setHoveredSeatRef(el);
        setHoveredSeat(
          getTrainPlaceByNumber(parseInt(number), car.freePlacesByCompartments)
        );
      });
      el.addEventListener('mouseout', () => {
        setHoveredSeatRef(null);
        setHoveredSeat(null);
      });
    }
  };

  const handleSeatClick = (seatNumber: number) => {
    const place = getTrainPlaceByNumber(
      seatNumber,
      car.freePlacesByCompartments
    );
    const chosenSeats = [...chosenCarPlacesFromState];
    const compartment = getCompartmentByPlaceNumber(
      car.freePlacesByCompartments,
      seatNumber
    );
    const compartmentPlaceNumbers = compartment?.places.map((x) => x.number);

    if (place) {
      const chosenNumbers = chosenSeats.map((x) => parseInt(x.number) || 0);
      // если место уже было выбрано
      if (chosenNumbers.includes(parseInt(place.number))) {
        if (
          place.placeReservationType === 'FourPlacesAtOnce' ||
          place.placeReservationType === 'TwoPlacesAtOnce'
        ) {
          setChoseCarPlaces([]);
          if (place?._oldNumber) {
            dispatch(clearGenderTypesOnCarPlaces({ carPlaces: [place] }));
          }
        } else {
          const filteredSeats = chosenSeats.filter(
            (x) => parseInt(x.number) !== parseInt(place.number)
          );
          // если среди filteredSeats нет мест из compartment, то сбрасываем гендеры в этом купе
          if (
            compartmentPlaceNumbers &&
            !compartmentPlaceNumbers.some((x) =>
              filteredSeats.map((x) => x.number).includes(x)
            )
          ) {
            dispatch(clearGenderTypesOnCarPlaces({ carPlaces: [place] }));
          }
          setChoseCarPlaces(filteredSeats);
        }

        if (currentStep === 'TICKET_FORWARD') {
          dispatch(setTariffForward('Full'));
        } else {
          dispatch(setTariffBackward('Full'));
        }
        // если место не выбрано
      } else {
        if (
          place.placeReservationType === 'FourPlacesAtOnce' ||
          place.placeReservationType === 'TwoPlacesAtOnce'
        ) {
          const compartment = getCompartmentByPlaceNumber(
            car.freePlacesByCompartments,
            parseInt(place.number)
          );
          const allPlaces = compartment?.places;
          setChoseCarPlaces(allPlaces || []);
        } else {
          chosenSeats.push(place);
          setChoseCarPlaces(chosenSeats);
        }
      }
    }
  };

  const setChoseCarPlaces = (seats: CarPlace[]) => {
    if (currentStep === 'TICKET_FORWARD') {
      dispatch(setChosenCarPlace(seats));
    } else {
      dispatch(setChosenCarPlaceBackward(seats));
    }
  };

  const handleChooseAllSeats = (seat: CarPlace) => {
    const compartment = getCompartmentByPlaceNumber(
      car.freePlacesByCompartments,
      parseInt(seat.number)
    );
    const seats = compartment?.places;
    setChoseCarPlaces(seats || []);
    if (currentStep === 'TICKET_FORWARD') {
      if (seats?.length === MAX_SEATS_KUPEK && activeSeatHasKupekTariff) {
        dispatch(setTariffForward('Kupek'));
      }
      if (seats?.length === MAX_SEATS_SINGLE && activeSeatHasSingleTariff) {
        dispatch(setTariffForward('Single'));
      }
    } else {
      if (seats?.length === MAX_SEATS_KUPEK && activeSeatHasKupekTariff) {
        dispatch(setTariffBackward('Kupek'));
      }
      if (seats?.length === MAX_SEATS_SINGLE && activeSeatHasSingleTariff) {
        dispatch(setTariffBackward('Single'));
      }
    }
  };

  const seatIsPassedAdditionalChecks = (seat: CarPlace) => {
    let valid = true;
    if (seat?.type === 'WithChild') {
      // Если среди пассажиров нет детей или младенцев, то блокируем место
      if (passengers.children.count === 0 && passengers.infants.count === 0) {
        valid = false;
      }

      // Если среди пассажиров больше двух взрослых, то блокируем место
      if (passengers.adults.count > 2) {
        valid = false;
      }

      // Если среди выбранных мест нет мест с типом withChild, то нельзя выбрать место с типом withChild
      if (
        !chosenCarPlacesFromState.find((x) => x.type === 'WithChild') &&
        chosenCarPlacesFromState.length > 0
      ) {
        valid = false;
      }

      // Если пассажира всего два (Взрослый + Ребёнок) и среди выбранных мест уже есть место с типом withChild, то новое место можно выбрать, но только если оно в том же compartment
      if (
        passengers.children.count + passengers.adults.count === 2 &&
        chosenCarPlacesFromState.find((x) => x.type === 'WithChild') &&
        chosenCarPlacesFromState.length > 0
      ) {
        const compartment = getCompartmentByPlaceNumber(
          car.freePlacesByCompartments,
          parseInt(seat.number)
        );
        const compartmentPlaceNumbers = compartment?.places.map(
          (x) => x.number
        );
        if (
          !compartmentPlaceNumbers?.some((x) =>
            chosenCarPlacesFromState.map((x) => x.number).includes(x)
          )
        ) {
          valid = false;
        }
      }
    }

    // Если среди выбранных мест уже есть место с типом withChild, а новое место не withChild, то его нельзя выбрать
    if (
      seat.type !== 'WithChild' &&
      chosenCarPlacesFromState.find((x) => x.type === 'WithChild') &&
      chosenCarPlacesFromState.length > 0
    ) {
      valid = false;
    }

    if (seat?.type === 'MotherAndBaby') {
      if (passengers.adults.count !== 1 || passengers.infants.count !== 1) {
        valid = false;
      }
    }

    if (seat?.placeReservationType === 'TwoPlacesAtOnce') {
      if (passengers.adults.count > 2) {
        valid = false;
      }
    }

    return valid;
  };

  const setStylesForPlace = (el: Element, freePlaces: number[]) => {
    const number = convertCarPlaceNumberFromScheme(el.id);
    const hasMaxSeats = maxSeats === chosenCarPlacesFromState.length;
    let seat: CarPlace | null = null;

    let additionalChecksIsPassed = true;
    let isFree = false;
    let isBusy = false;
    let isChosen = false;

    if (number) {
      isFree = freePlaces.includes(parseInt(number)) && !hasMaxSeats;

      seat = getTrainPlaceByNumber(
        parseInt(number),
        car.freePlacesByCompartments
      );
      if (seat) {
        additionalChecksIsPassed = seatIsPassedAdditionalChecks(seat);
      }

      isBusy =
        !isFree ||
        (isFree && !additionalChecksIsPassed) ||
        hasMaxSeats ||
        allPlacesIsFourPlacesAtOnceType;
      isChosen = chosenCarPlacesFromState
        ?.map((x) => parseInt(x.number) || 0)
        .includes(parseInt(number));

      if (isBusy) {
        // цвет занятого места
        (el as any).style.fill = '#C4C4C4';
        (el as any).style.cursor = 'default';
      }

      if (
        isFree &&
        additionalChecksIsPassed &&
        !allPlacesIsFourPlacesAtOnceType
      ) {
        // цвет свободного места
        (el as any).style.fill = '#ca96db';
        (el as any).style.cursor = 'pointer';
        if (seat && seat.prices[0].hasNonRefundableTariff) {
          // цвет свободного места с невозвратным тарифом шириной в 2px
          (el as any).style.strokeWidth = '4px';
          (el as any).style.stroke = '#E73C3E';
          (el as any).style.cursor = 'pointer';
        }
      }

      if (isChosen) {
        // цвет выбранного места
        (el as any).style.fill = '#F57269';
        (el as any).style.cursor = 'pointer';
      }

      (el as any).style.outline = 'none';
      let cyAttr = `${CYPRESS_RAILWAY_ATTRS.CAR_SCHEME_PLACE_NUMBER}-${number}`;
      if (isChosen) {
        cyAttr = `${cyAttr}-chosen`;
      } else if (isFree) {
        cyAttr = `${cyAttr}-free`;
      } else if (isBusy) {
        cyAttr = `${cyAttr}-busy`;
      }
      el.setAttribute('data-cy', cyAttr);
    }
  };

  const handleOptionsSeatClick = useCallback(
    (seatNumber: number, el: Element) => {
      setOptionsSeatTypeRef(null);
      setActiveSeat(null);
      setActiveSeatHasGenderType(false);
      setActiveSeatHasKupekTariff(false);
      setActiveSeatHasSingleTariff(false);
      let hasGenderType = true;
      let hasKupekTariff = false;
      let hasSingleTariff = false;
      const checkPassengersCountForSingleTariff =
        passengers.adults.count === 1 ||
        passengers.adults.count + passengers.infants.count === 2;

      const carPlace = getTrainPlaceByNumber(
        seatNumber,
        car.freePlacesByCompartments
      );

      const compartment = getCompartmentByPlaceNumber(
        car.freePlacesByCompartments,
        seatNumber
      );

      const carHasKupekTariff =
        carPlace?.type !== 'WithChild' &&
        discounts.find((comp) => comp.discountType === 'Kupek');
      const carHasSingleTariff =
        carPlace?.type !== 'WithChild' &&
        discounts.find((comp) => comp.discountType === 'Single');
      const seatsCount = carHasKupekTariff
        ? MAX_SEATS_KUPEK
        : carHasSingleTariff
        ? MAX_SEATS_SINGLE
        : 0;

      const allPlacesInCompartmentIsFree = compartment
        ? compartment.places.length === seatsCount
        : false;
      if (allPlacesInCompartmentIsFree && carHasKupekTariff) {
        hasKupekTariff = true;
      }
      if (
        allPlacesInCompartmentIsFree &&
        carHasSingleTariff &&
        checkPassengersCountForSingleTariff
      ) {
        hasSingleTariff = true;
      }

      if (!car.hasGenderCabins) {
        hasGenderType = false;
      }

      if (carPlace) {
        if (
          (getCarPlaceGenderTypeByPlaceNumber(carPlace.number) !== 'canChose' &&
            !carPlace._oldNumber) ||
          (getCarPlaceGenderTypeByPlaceNumber(carPlace.number) !== 'canChose' &&
            carPlace._oldNumber &&
            getCarPlaceGenderTypeByPlaceNumber(carPlace._oldNumber) !==
              'canChose')
        ) {
          hasGenderType = false;
        }
      }

      if (
        !compartment ||
        compartment.places.length !==
          car.freePlacesByCompartments.find(
            (comp) => comp.compartmentNumber === compartment.compartmentNumber
          )?.places?.length
      ) {
        hasGenderType = false;
      }

      const placeIsChosen = chosenCarPlacesFromState.find(
        (x) => parseInt(x.number) === seatNumber
      );

      if (placeIsChosen) {
        setActiveSeat(null);
        setOptionsSeatTypeRef(null);
      } else {
        if (hasGenderType || hasKupekTariff || hasSingleTariff) {
          setOptionsSeatTypeRef(el);
          setActiveSeatHasGenderType(hasGenderType);
          setActiveSeatHasKupekTariff(hasKupekTariff);
          setActiveSeatHasSingleTariff(hasSingleTariff);
        }

        setActiveSeat(
          getTrainPlaceByNumber(seatNumber, car.freePlacesByCompartments)
        );
      }
    },
    [activeSeat, car, car?.freePlacesByCompartments, chosenCarPlacesFromState]
  );

  // добавляем в svg подписи с гендерным типом в каждое купе
  const fillGenderTypeTextOnScheme = () => {
    const isLuxury = car.carType === 'Luxury';
    const isCompartment = car.carType === 'Compartment';
    const numberPattern = /\d+/g;
    const transformRegexStart = /\d(.*?)\s/g;
    let nodeForSearch: Element | null = null;

    // чистим все g элементы GenderType
    const test = ref.current?.querySelectorAll('#GenderType');
    if (test?.length) {
      test[0].remove();
    }

    // создаём будущий g-элемент
    const gElement = makeSVGEl('g', { id: 'GenderType' });
    // забираем свободные места в разрезе каждого купе
    const placeByCompartments = car.freePlacesByCompartments;
    // забираем все ноды с номерами в svg
    const listOfNumbersNodes = ref.current?.querySelectorAll('#Numbers > text');
    // бежим по купешкам
    placeByCompartments.forEach((compartment, index) => {
      nodeForSearch = null;
      // определяем номер первого свободного места в купе
      const number = compartment.places[0].number.match(numberPattern);
      // проверяем, что все места, которые были свободны, остались свободными
      const freePlaces = compartment.places
        .map((x) => x.number.match(numberPattern))
        .filter((x) => x !== null)
        .map((x) => parseInt(x as any));
      let allPlacesIsFree = true;
      freePlaces.forEach((place) => {
        if (
          chosenCarPlacesFromState.length > 0 &&
          !chosenCarPlacesFromState
            ?.map((seat) => parseInt(seat.number))
            .includes(place)
        ) {
          allPlacesIsFree = false;
        }
      });

      // находим нужную ноду среди нод с номерами мест
      !!listOfNumbersNodes?.length &&
        listOfNumbersNodes.forEach((node) => {
          const nodeId = node?.innerHTML?.match(numberPattern);
          if (nodeId && number && nodeId[0] === number[0]) {
            nodeForSearch = node;
          }
        });
      if (number && listOfNumbersNodes && nodeForSearch) {
        const genderType = getCarPlaceGenderTypeByPlaceNumber(
          allPlacesIsFree
            ? compartment.places[0]._oldNumber || compartment.places[0].number
            : compartment.places[0].number
        );
        const genderText =
          genderType !== null
            ? `${CAR_PLACE_GENDER_TYPES_SHORT[genderType]}`
            : '';
        //@ts-ignore
        let transform = nodeForSearch.getAttribute('transform');
        const matchStart = transform.match(transformRegexStart);

        if (matchStart && parseInt(matchStart[0])) {
          if (isLuxury) {
            if (parseInt(number[0]) % 2 === 0) {
              transform = `translate(${parseInt(matchStart[0]) - 62} 140)`;
            } else {
              transform = `translate(${parseInt(matchStart[0]) + 62} 140)`;
            }
          }

          if (isCompartment) {
            if (
              parseInt(number[0]) % 4 === 1 ||
              parseInt(number[0]) % 4 === 2
            ) {
              if (props.type === CarFloor.UPPER) {
                transform = `translate(${parseInt(matchStart[0]) + 62} 220)`;
              } else if (props.type === CarFloor.LOWER) {
                transform = `translate(${parseInt(matchStart[0]) + 62} 140)`;
              }
            } else {
              transform = `translate(${parseInt(matchStart[0]) - 62} 140)`;
            }
          }
        }

        const gTextNode = makeSVGEl('text', {
          class: 'gender-node',
          transform: transform,
        });
        gTextNode.style.fontFamily = 'Open Sans';
        gTextNode.style.fontSize = '30px';
        gTextNode.style.color = '#333333';
        gTextNode.style.transform = transform;
        gTextNode.innerHTML = genderText;

        gElement.appendChild(gTextNode);
      }
    });

    return gElement;
  };

  React.useEffect(() => {
    const listeners: Array<() => void> = [];

    // TODO: убрать в саги
    if (ref !== null && ref.current !== null && car) {
      const url = new URL(`/api${GET_CAR_SCHEME_API}`, TRAIN_API_URL);
      url.searchParams.append('Id', car.scheme.id as any);
      url.searchParams.append('Type', `${props.type}`);
      if (ref.current.querySelectorAll('svg').length > 0) {
        const svg2 = ref.current.querySelectorAll('svg');
        if (svg2 && svg2[0]) {
          svg2[0].insertAdjacentElement(
            'beforeend',
            fillGenderTypeTextOnScheme()
          );
          return;
        }
      }
      if (car.scheme.id === null) return;
      try {
        dispatch(
          setCarSchemeRequestState({
            carSchemeStatus: TrainSearchRequestStatus.loading,
          })
        );
        fetch(url.toString())
          .then((response) => response.text())
          .then((svg) => {
            dispatch(
              setCarSchemeRequestState({
                carSchemeStatus: TrainSearchRequestStatus.success,
              })
            );
            return svg;
          })
          .then((svg) => {
            if (ref.current !== null) {
              ref.current.insertAdjacentHTML('afterbegin', svg);
              if (car.hasGenderCabins) {
                const svg2 = ref.current.querySelectorAll('svg');
                if (svg2 && svg2[0]) {
                  svg2[0].insertAdjacentElement(
                    'beforeend',
                    fillGenderTypeTextOnScheme()
                  );
                }
              }
              const numberPattern = /\d+/g;

              const freePlaces = car.freePlaces
                .map((x) => x.match(numberPattern))
                .filter((x) => x !== null)
                .map((x) => parseInt(x as any));

              const hasMaxSeats = maxSeats === chosenCarPlacesFromState.length;

              ref.current
                .querySelectorAll(
                  '#Seats > path, #Seats > rect, #Seats > polygon'
                )
                .forEach((el) => {
                  const number = convertCarPlaceNumberFromScheme(el.id);
                  const isNumber = !!number;
                  let additionalChecksIsPassed = true;

                  const seat = isNumber
                    ? getTrainPlaceByNumber(
                        parseInt(number),
                        car.freePlacesByCompartments
                      )
                    : null;

                  if (seat) {
                    additionalChecksIsPassed =
                      seatIsPassedAdditionalChecks(seat);
                  }

                  const isFree =
                    isNumber && freePlaces.includes(parseInt(number));

                  if (
                    isNumber &&
                    isFree &&
                    !hasMaxSeats &&
                    additionalChecksIsPassed &&
                    !allPlacesIsFourPlacesAtOnceType
                  ) {
                    hoverSeatListener(el, number);

                    const listener = () => {
                      handleOptionsSeatClick(parseInt(number), el);
                      handleSeatClick(parseInt(number));
                    };

                    el.addEventListener('click', listener);
                    listeners.push(() =>
                      el.removeEventListener('click', listener)
                    );
                  }

                  setStylesForPlace(el, freePlaces);
                });

              ref.current.querySelectorAll('.cls-3').forEach((el) => {
                (el as any).style.pointerEvents = 'none';
              });

              ref.current.querySelectorAll('title').forEach((el) => {
                el.innerHTML = '';
              });

              ref.current.querySelectorAll('#Numbers > text').forEach((el) => {
                const seatNumber = parseInt(el.innerHTML);
                const isFree = freePlaces.includes(seatNumber);
                let additionalChecksIsPassed = true;

                const seat = seatNumber
                  ? getTrainPlaceByNumber(
                      seatNumber,
                      car.freePlacesByCompartments
                    )
                  : null;

                if (seat) {
                  additionalChecksIsPassed = seatIsPassedAdditionalChecks(seat);
                }

                if (
                  isFree &&
                  !hasMaxSeats &&
                  additionalChecksIsPassed &&
                  !allPlacesIsFourPlacesAtOnceType
                ) {
                  hoverSeatListener(el, seatNumber.toString());
                  const listener = () => {
                    handleOptionsSeatClick(seatNumber, el);
                    handleSeatClick(seatNumber);
                  };

                  el.addEventListener('click', listener);
                  listeners.push(() =>
                    el.removeEventListener('click', listener)
                  );
                  (el as any).style.cursor = 'pointer';
                  (el as any).style.outline = 'none';
                  (el as any).style.fill = '#000000';
                }
                (el as any).style.fontSize = '28px';
                (el as any).style.fontFamily = 'Open Sans';
              });

              const contour = ref.current.querySelectorAll<HTMLElement>(
                '#Compartment > path'
              );

              const contour2 = ref.current.querySelectorAll<HTMLElement>(
                '#Railway_carriage > path:last-child'
              );

              if (contour && contour[0]) {
                contour[0].style.fill = '#CAD6FF';
                contour[0].style.stroke = '#CAD6FF';
              }

              if (contour2 && contour2[0]) {
                contour2[0].style.fill = '#CAD6FF';
                contour2[0].style.stroke = '#CAD6FF';
              }
            }
          });
      } catch (e) {
        Sentry.captureException(e);
      }
    }
    return () => {
      listeners.forEach((removeListener) => removeListener());
    };
  }, [ref, car, chosenCarPlacesFromState]);

  React.useEffect(() => {
    const listeners: Array<() => void> = [];

    const numberPattern = /\d+/g;
    if (ref !== null && ref.current !== null && car) {
      const freePlaces = car.freePlaces
        .map((x) => x.match(numberPattern))
        .filter((x) => x !== null)
        .map((x) => parseInt(x as any));

      ref.current.querySelectorAll('#Numbers > text').forEach((el) => {
        const number = parseInt(el.innerHTML);
        let placeIsChosen = false;
        if (number) {
          placeIsChosen = !!chosenCarPlacesFromState.find(
            (x) => parseInt(x.number) === number
          );
        }
        let additionalChecksIsPassed = true;

        const seat = number
          ? getTrainPlaceByNumber(number, car.freePlacesByCompartments)
          : null;

        if (seat) {
          additionalChecksIsPassed = seatIsPassedAdditionalChecks(seat);
        }

        const isFree = number && freePlaces.includes(number);
        if (number) {
          el.addEventListener('mouseover', () => {
            setHoveredSeatRef(el);
            setHoveredSeat(
              getTrainPlaceByNumber(number, car.freePlacesByCompartments)
            );
          });
          el.addEventListener('mouseout', () => {
            setHoveredSeatRef(null);
            setHoveredSeat(null);
          });

          const clickListenerHandler = () => {
            if (
              (isFree &&
                maxSeats !== chosenCarPlacesFromState.length &&
                additionalChecksIsPassed &&
                !allPlacesIsFourPlacesAtOnceType) ||
              placeIsChosen
            ) {
              handleOptionsSeatClick(number, el);
              handleSeatClick(number);
            }
          };

          el.addEventListener('click', clickListenerHandler);
          listeners.push(() =>
            el.removeEventListener('click', clickListenerHandler)
          );

          // setStylesForPlace(el, freePlaces);
        }
      });

      ref.current.querySelectorAll('path, rect, polygon').forEach((el) => {
        const number = convertCarPlaceNumberFromScheme(el.id);
        const isFree = number && freePlaces.includes(parseInt(number));
        let placeIsChosen = false;
        if (number) {
          placeIsChosen = !!chosenCarPlacesFromState.find(
            (x) => parseInt(x.number) === parseInt(number)
          );
        }

        let additionalChecksIsPassed = true;

        const seat = number
          ? getTrainPlaceByNumber(
              parseInt(number),
              car.freePlacesByCompartments
            )
          : null;

        if (seat) {
          additionalChecksIsPassed = seatIsPassedAdditionalChecks(seat);
        }

        if (number) {
          el.addEventListener('mouseover', () => {
            setHoveredSeatRef(el);
            setHoveredSeat(
              getTrainPlaceByNumber(
                parseInt(number),
                car.freePlacesByCompartments
              )
            );
          });
          el.addEventListener('mouseout', (e) => {
            setHoveredSeatRef(null);
            setHoveredSeat(null);
          });
          const clickListenerHandler = () => {
            if (
              (isFree &&
                maxSeats !== chosenCarPlacesFromState.length &&
                additionalChecksIsPassed &&
                !allPlacesIsFourPlacesAtOnceType) ||
              placeIsChosen
            ) {
              handleOptionsSeatClick(parseInt(number), el);
              handleSeatClick(parseInt(number));
            }
          };

          el.addEventListener('click', clickListenerHandler);
          listeners.push(() =>
            el.removeEventListener('click', clickListenerHandler)
          );

          setStylesForPlace(el, freePlaces);
        }
      });
    }
    return () => {
      listeners.forEach((removeListener) => removeListener());
    };
  }, [chosenCarPlacesFromState, car?.freePlacesByCompartments]);

  return (
    <React.Fragment>
      {carSchemeRequestStatus === 'loading' ? (
        <CarSkeleton isSmall />
      ) : car?.scheme?.id ? (
        <SeatMapContainer id={`seat-map-${props.type}`} ref={ref} />
      ) : (
        <CustomCarScheme
          onClick={(el, placeNumber) => {
            handleSeatClick(parseInt(placeNumber));
            handleOptionsSeatClick(parseInt(placeNumber), el);
          }}
          onMouseEnter={(el, placeNumber) => {
            setHoveredSeatRef(el);
            setHoveredSeat(
              getTrainPlaceByNumber(
                parseInt(placeNumber),
                car.freePlacesByCompartments
              )
            );
          }}
          onMouseLeave={() => {
            setHoveredSeatRef(null);
            setHoveredSeat(null);
          }}
          chosenSeats={chosenCarPlacesFromState}
          carType={CAR_TYPES[car?.carType]}
          freePlaces={car?.freePlacesByCompartments || []}
        />
      )}
      <SeatTooltip
        visible={!!hoveredSeat}
        reference={hoveredSeatRef}
        seat={hoveredSeat}
        maxSeats={maxSeats}
      />
      <SeatOptionsTooltip
        visible={!!activeSeat && optionsSeatTypeRef !== null}
        seat={activeSeat !== null ? activeSeat : undefined}
        hasGenderType={activeSeatHasGenderType}
        hasKupekTariff={activeSeatHasKupekTariff}
        hasSingleTariff={activeSeatHasSingleTariff}
        reference={optionsSeatTypeRef}
        onClick={() => {
          setOptionsSeatTypeRef(null);
          setActiveSeat(null);
        }}
        chooseAllSeats={(seat) => {
          handleChooseAllSeats(seat);
        }}
        onClose={() => {
          setOptionsSeatTypeRef(null);
          setActiveSeat(null);
        }}
      />
    </React.Fragment>
  );
}

import React from 'react';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';
import { Separator } from '@components/common';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { getUserDataState } from '@modules/user';
import { ApplicationState } from '@modules/index';
import { FormikPassengerItemEntity } from '@modules/booking';
import moment from 'moment/moment';
import { Formik, Form } from 'formik';
import { Header as H1 } from '@components/ui/Typography';
import { Header } from '@screens/Booking';
import { Contacts, SignIn } from '@components/booking';
import AuthorizationBlock from '@components/booking/AuthorizationBlock';
import {
  bookTrainRequest,
  enterInBooking,
  INIT_TRAIN_BOOKING_PASSENGER,
} from '@modules/trainBooking';
import { railwayBookingValidationSchema } from './validationSchema';
import TrainPriceDetailContainer from '@components/booking/PriceDetail/TrainPriceDetailContainer';
import TrainPaymentBlock from '@components/train/Booking/PaymentBlock';
import {
  TrainCustomer,
  SelectedPlacePayload,
  RailwayBonusCard,
} from '@modules/trainBooking/dto/BookTrainTicketDto';
import { getCarPlaceGenderForBookingTypeByPlaceNumber } from '@modules/trainBooking/utils';
import YourTrip from '@components/train/Booking/YourTrip';
import TrainPassengerPriceContainer from '@components/booking/PassengerPrice/TrainPassengerPriceContainer';
import BookingRouteDetailContainer from '@components/train/RouteDetail/BookingRouteDetailContainer';
import TrainPassengersBlockContainer from '@components/booking/PassengersBlock/TrainPassengersBlockContainer';
import { TrainDiscountNotyWithIcon } from '@components/train/Booking/DiscountNoty';
import {
  bedClothsBackwardSelector,
  bedClothsForwardSelector,
  CarPlace,
} from '@modules/trainTickets';
import {
  convertSeatPlaceWithALetter,
  revertConvertSeatPlaceWithALetter,
} from '@modules/trainTickets/utils';
import {
  checkDuplicateDocuments,
  formatPhoneNumber,
} from '@components/train/Booking/utils';

const Wrapper = styled.div<{ isMobile: boolean }>`
  padding: ${({ isMobile }) => (isMobile ? '0' : '20px 0')};
  border-radius: 0 0 8px 8px;
  border: 0;

  @media (max-width: 767px) {
    border: 0;
  }
`;

const Body = {
  Wrapper: styled.div`
    background: white;
    padding-top: 32px;
    font-family: ${({ theme }) => theme.fonts.regular};
    color: ${({ theme }) => theme.colors.blackGray};
    @media (max-width: 767px) {
      padding-top: 20px;
    }
  `,
  MainContainer: styled.div`
    display: flex;
    max-width: 1170px;
    justify-content: space-between;
    margin: 0 auto;
  `,
  Block: styled.div`
    background: #ffffff;
    border-radius: 8px;
    width: 100%;
    padding: 32px;
    box-sizing: border-box;
  `,
  LeftSide: styled.div`
    width: 730px;

    & > div {
      margin-bottom: 20px;
    }

    & > :last-child {
      margin-bottom: 32px;
    }

    @media (max-width: 1116px) {
      width: 65%;
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  `,
  RightSide: styled.div`
    width: 380px;
    position: relative;

    & > div {
      margin-bottom: 30px;
    }

    @media (max-width: 767px) {
      display: none;
    }

    @media (max-width: 1116px) {
      width: 33%;
    }
  `,
};

const StickyBlock = styled.div`
  position: sticky;
  top: 20px;

  & > div {
    margin-bottom: 20px;
  }
`;

const YourFlightBlock = styled.div`
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  padding: 24px 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
`;

export default function Booking() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({
    maxWidth: 767,
  });
  const userData = useSelector(getUserDataState);
  const isAuthorized = useSelector(
    (state: ApplicationState) => state.user.status.isAuthorized,
  );
  const passengers = useSelector(
    (state: ApplicationState) => state.trainBooking.passengers,
  );
  const trainForward = useSelector(
    (state: ApplicationState) => state.trainTickets.trainForward,
  );
  const trainBackward = useSelector(
    (state: ApplicationState) => state.trainTickets.trainBack,
  );
  const bedClothesForward = useSelector(bedClothsForwardSelector);
  const bedClothesBackward = useSelector(bedClothsBackwardSelector);

  const railwayBookingValidationSchemaMemo = React.useMemo(
    () =>
      railwayBookingValidationSchema(trainForward?.train, trainBackward?.train),
    [passengers?.length, trainForward?.train, trainBackward?.train],
  );

  const initialPassengers = React.useMemo(() => {
    const tariffsPerRoute = [
      {
        originCode: trainForward.train.fromStation.code,
        tariffType: 'Full',
        isNonRefundableTariff: false,
      },
    ];
    if (trainBackward.train) {
      tariffsPerRoute.push({
        originCode: trainBackward.train.fromStation.code,
        tariffType: 'Full',
        isNonRefundableTariff: false,
      });
    }
    return passengers.map((x: any, key: number) => ({
      ...x,
      ...INIT_TRAIN_BOOKING_PASSENGER,
      tariffsPerRoute: tariffsPerRoute,
      key: key,
    }));
  }, [passengers.length, trainForward.train, trainBackward.train]);

  const initialValues = React.useMemo(() => {
    return {
      passengers: initialPassengers,
      phone: userData?.phone || '',
      email: userData?.email || '',
      name: userData?.name || '',
      needShareContacts: false,
      phoneTwo: '',
      nameTwo: '',
      insurance: true,
      return: true,
      policy: true,
      sameContactInfo: false,
      offerta: false,
      offertaWithInsurances: false,
      emailOfferta: false,
    };
  }, [userData, initialPassengers]);

  const handleDuplicateDocuments = (
    duplicateIndexes: number[],
    setFieldError: any,
  ) => {
    duplicateIndexes.forEach((index) => {
      const errorField = `passengers.${index}.number`;
      setFieldError(
        errorField,
        'У нескольких детей не может быть одинаковый номер документа',
      );
    });

    // Если есть дети с одинаковыми номерами документов, устанавливаем ошибку
    const minIndex = Math.min(...duplicateIndexes);
    const errorElement = document.querySelector(
      `[id="passengers.${minIndex}.number"]`,
    );
    if (errorElement) {
      window.scrollTo({
        top:
          errorElement.getBoundingClientRect().top + window.pageYOffset - 100,
        behavior: 'smooth',
      });
    }
  };

  React.useEffect(() => {
    dispatch(enterInBooking());
  }, []);

  return (
    <>
      <Wrapper isMobile={isMobile}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={railwayBookingValidationSchemaMemo}
          onSubmit={(values, { setFieldError }) => {
            try {
              const duplicateIndexes = checkDuplicateDocuments(
                values.passengers,
              );

              if (duplicateIndexes.length > 0) {
                handleDuplicateDocuments(duplicateIndexes, setFieldError);
                return;
              } else {
                // TODO: разобраться с типами, убрать костыли (customer: any)
                const trainFrom = trainForward?.train;
                const trainBack = trainBackward?.train;
                const customers: TrainCustomer[] = values.passengers.map(
                  (customer) => {
                    const railwayBonusCards: RailwayBonusCard[] = [];
                    const selectedPlaces: SelectedPlacePayload[] = [];
                    if (customer.rzhdBonusCard) {
                      railwayBonusCards.push(customer.rzhdBonusCard);
                    }
                    if (customer.otherRailwayBonusCard) {
                      railwayBonusCards.push(customer.otherRailwayBonusCard);
                    }
                    let ageCategory: any = 'Adult';

                    if (customer.ageCategory === 'INFANT') {
                      ageCategory = 'BabyWithoutPlace';
                    }
                    if (customer.ageCategory === 'CHILD') {
                      ageCategory = 'Child';
                    }
                    if (customer.ageCategory === 'ADULT') {
                      ageCategory = 'Adult';
                    }

                    if (customer.carPlaces?.length > 0) {
                      customer.carPlaces.map((place: CarPlace) => {
                        const hasALetter = place.number.match(/А/g);
                        let placeNumber = hasALetter
                          ? convertSeatPlaceWithALetter(place.number)
                          : parseInt(place.number);
                        let train = trainFrom;
                        let carNumber = trainForward.currentCar.data.carNumber;
                        let carType = trainForward.currentCar.data.carType;
                        if (place._direction === 'backward') {
                          train = trainBack;
                          carNumber = trainBackward.currentCar.data.carNumber;
                          carType = trainBackward.currentCar.data.carType;
                        }
                        if (placeNumber > 2000) {
                          placeNumber = parseInt(
                            revertConvertSeatPlaceWithALetter(
                              placeNumber,
                            ).replace(/\D/g, ''),
                          );
                        }

                        selectedPlaces.push({
                          originCode: train.fromStation.code,
                          carrier: train.carriers[0],
                          trainDescription: train.trainDescription,
                          destinationCode: train.toStation.code,
                          departureDate: train.departureDateTimeUtc,
                          trainNumber: train.trainNumber,
                          carNumber: carNumber,
                          carType: carType,
                          carGenderKind:
                            getCarPlaceGenderForBookingTypeByPlaceNumber(
                              place.number,
                            ),
                          serviceClass: place.serviceClass,
                          placeRange: {
                            from: placeNumber,
                            to: placeNumber,
                          },
                          placeType: place.type,
                          originCityId: train.fromStation?.city?.id,
                          destinationCityId: train.toStation.city.id,
                          arrivingDateUtc: train.arrivalDateTimeUtc,
                          includeBedding: bedClothesForward,
                          placeReservationType: place.placeReservationType,
                        });
                      });
                    }

                    const passengerContactPhone = customer.phoneNumber
                      ? customer.phoneNumber[0] === '+'
                        ? customer.phoneNumber.replace(/[\s()-]/g, '')
                        : `+${customer.phoneNumber.replace(/[\s()-]/g, '')}`
                      : null;

                    return {
                      category: ageCategory,
                      documentNumber: customer.number.replace(/[-\s]/g, ''),
                      documentType: customer.documentType,
                      documentValidTill: customer.dateOfIssue
                        ? moment(customer.dateOfIssue).format()
                        : customer.dateOfIssue,
                      citizenshipCode: customer.citizenship,
                      birthPlace: 'RF' || customer.citizenship,
                      firstName: customer.name,
                      middleName: customer.secondName,
                      lastName: customer.surname,
                      sex: customer.sex === 'm' ? 'Male' : 'Female',
                      birthday: moment(customer.birthDate).format(),
                      email: customer.email,
                      phoneNumber: passengerContactPhone,
                      selectedPlaces: selectedPlaces,

                      tariffsPerRoute: customer.tariffsPerRoute,
                      loyaltyCards:
                        railwayBonusCards.length > 0 ? railwayBonusCards : null,
                    };
                  },
                );

                const contactPhone = formatPhoneNumber(values.phone);
                // console.log({
                //   contactPhone: contactPhone,
                //   contactEmail: values.email,
                //   contactName: values.name,
                //   customers: customers,
                // });
                dispatch(
                  bookTrainRequest({
                    contactPhone: contactPhone,
                    contactEmail: values.email,
                    contactName: values.name,
                    customers: customers,
                  }),
                );
              }
            } catch (e) {
              console.log(e);
              Sentry.captureException(e);
            }
          }}
        >
          {(s) => {
            let hasNotFullTariff = false;
            s.values.passengers.forEach(
              (pass: FormikPassengerItemEntity, index) => {
                if (
                  pass.tariffsPerRoute.some(
                    (tariff) => tariff.tariffType !== 'Full',
                  )
                ) {
                  hasNotFullTariff = true;
                  return;
                }
              },
            );
            return (
              <>
                <Form autoComplete={'off'}>
                  <Header.Wrapper>
                    <Header.Container>
                      <H1 size="h2">Бронирование </H1>
                    </Header.Container>
                  </Header.Wrapper>
                  <Body.Wrapper>
                    <Body.MainContainer>
                      <Body.LeftSide>
                        <BookingRouteDetailContainer />
                        {!isAuthorized && <AuthorizationBlock />}
                        <TrainPassengersBlockContainer />
                        <Contacts type={'train'} />
                        <TrainPriceDetailContainer />
                        <TrainPaymentBlock />
                      </Body.LeftSide>
                      <Body.RightSide className="right-side">
                        <StickyBlock>
                          <YourFlightBlock>
                            <YourTrip />
                            <Separator />
                            <TrainPassengerPriceContainer />
                          </YourFlightBlock>
                          {hasNotFullTariff && <TrainDiscountNotyWithIcon />}
                        </StickyBlock>
                      </Body.RightSide>
                    </Body.MainContainer>
                  </Body.Wrapper>
                </Form>
              </>
            );
          }}
        </Formik>
      </Wrapper>
      <SignIn />
    </>
  );
}

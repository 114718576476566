import React from 'react';
import styled from 'styled-components';
import {
  Field,
  Checkbox,
  PasswordField,
  Button,
  Text,
  ButtonLoader,
} from '@components/ui';
import { FormikProps, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { getUserStatusState } from '@modules/user';
import { AUTH_MODAL_STATUSES, openAuthModal } from '@modules/ui';
import FormikGaErrorsHandler from '@components/common/FormikGaErrorsHandler';
import { useHistory } from 'react-router-dom';

const StyledField = styled(Field)`
  width: 100%;
`;

const StyledPassword = styled(PasswordField)`
  width: 100%;
`;

const Row = styled.div`
  margin-bottom: 32px;
  display: grid;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: center;

  & > button {
    width: 100%;
    height: 41px;
  }

  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
`;

const ForgetPasswordRow = styled.div`
  text-align: right;
  margin-bottom: 4px;
  @media (max-width: 767px) {
    margin-bottom: 17px;
  }
`;

const PasswordRow = styled(Row)`
  margin-bottom: 15px;
`;

const ForgetPasswordText = styled(Text)`
  color: #4872f2;

  &:hover {
    cursor: pointer;
  }
`;

interface FormValues {
  password: string;
  login: string;
  remember: boolean;
}

const SignInForm = (
  props: FormikProps<FormValues> & {
    notModal?: boolean;
    tteAuthId?: string | null;
  }
) => {
  const { handleChange, handleBlur, errors, submitCount } = props;
  const dispatch = useDispatch();
  const { error, isFetching } = useSelector(getUserStatusState);
  const history = useHistory();
  const user = useSelector(getUserStatusState);

  return (
    <Form>
      <Row>
        <StyledField
          onChange={handleChange}
          onBlur={handleBlur}
          name="login"
          error={errors.login && submitCount > 0 ? errors.login : undefined}
          placeholder="Email"
        />
      </Row>
      <PasswordRow>
        <StyledPassword
          onChange={handleChange}
          onBlur={handleBlur}
          name="password"
          error={
            errors.password && submitCount > 0 ? errors.password : undefined
          }
          placeholder="Пароль"
        />
      </PasswordRow>
      {(error || (props.tteAuthId && !user.token && error)) && (
        <Text color="red">
          {props.tteAuthId
            ? error?.message.includes('tteAuthId')
              ? 'Авторизация не прошла. Просьба авторизоваться повторно.'
              : error?.message
            : error?.message}
        </Text>
      )}
      <ForgetPasswordRow>
        <ForgetPasswordText
          onClick={() => {
            if (props.notModal) {
              history.push('/password-recovery');
            } else {
              dispatch(openAuthModal(AUTH_MODAL_STATUSES.RESET_PASSWORD));
            }
          }}
        >
          Я забыл пароль
        </ForgetPasswordText>
      </ForgetPasswordRow>
      <Row>
        <Checkbox
          type="squar"
          onChange={handleChange}
          onBlur={handleBlur}
          name="remember"
          label="Запомнить меня на этом устройстве"
        />
      </Row>
      <ButtonWrapper>
        <Button
          disabled={isFetching}
          htmlType="submit"
          color="#4872F2"
          data-cy="loginYellowButton"
        >
          <ButtonLoader isLoading={isFetching}>Войти</ButtonLoader>
        </Button>
      </ButtonWrapper>
      <FormikGaErrorsHandler
        initialValues={props.initialValues}
        event={{
          category: 'Login',
          action: 'signin_form_validation_fail',
        }}
      />
    </Form>
  );
};

export default SignInForm;

export const LOGGING_ACTION_IDS = {
  SEARCH_PANEL: {
    NAME: 'SEARCH_PANEL',

    SEARCH_BTN: 'SEARCH_BTN',
  },
  AVIA_FLIGHTS_LIST: {
    NAME: 'AVIA_FLIGHTS_LIST',
  },
  AVIA_BOOKING: {
    NAME: 'AVIA_BOOKING',

  },
  AVIA_BOOKING_FIRST_STEP: {
    NAME: 'AVIA_BOOKING_FIRST_STEP',

    ROUTE_DETAILS_EXPAND_TOP_BTN: 'ROUTE_DETAILS_EXPAND_TOP_BTN',
    ROUTE_DETAILS_EXPAND_BOTTOM_BTN: 'ROUTE_DETAILS_EXPAND_BOTTOM_BTN',

    ADD_FROM_PASSENGERS_BTN: 'ADD_FROM_PASSENGERS_BTN',
    SELECT_SEAT: 'SELECT_SEAT',
    PASSENGER_SURNAME_INPUT: 'PASSENGER_SURNAME_INPUT',
    PASSENGER_NAME_INPUT: 'PASSENGER_NAME_INPUT',
    PASSENGER_SECOND_NAME_INPUT: 'PASSENGER_SECOND_NAME_INPUT',
    PASSENGER_BIRTHDAY_INPUT: 'PASSENGER_BIRTHDAY_INPUT',
    PASSENGER_NO_SECOND_NAME_CHECKBOX: 'PASSENGER_NO_SECOND_NAME_CHECKBOX',
    PASSENGER_GENDER_CHECKBOX: 'PASSENGER_GENDER_CHECKBOX',
    PASSENGER_CITIZENSHIP_SELECT: 'PASSENGER_CITIZENSHIP_SELECT',
    PASSENGER_DOCUMENT_SELECT: 'PASSENGER_DOCUMENT_SELECT',
    PASSENGER_DOCUMENT_NUMBER_INPUT: 'PASSENGER_DOCUMENT_NUMBER_INPUT',
    PASSENGER_BONUS_CARD_ADD_BTN: 'PASSENGER_BONUS_CARD_ADD_BTN',
    PASSENGER_LOYALTY_CARD_COMPANY_SELECT: 'PASSENGER_LOYALTY_CARD_COMPANY_SELECT',
    PASSENGER_LOYALTY_CARD_NUMBER_INPUT: 'PASSENGER_LOYALTY_CARD_NUMBER_INPUT',
  },
  AVIA_BOOKING_SECOND_STEP: {
    NAME: 'AVIA_BOOKING_SECOND_STEP',
  },
  TRAIN_BOOKING: {
    NAME: 'TRAIN_BOOKING',
  },
  BUS_BOOKING_FIRST_STEP: {
    NAME: 'BUS_BOOKING_FIRST_STEP',
  },
  ORDER_DETAILS: {
    NAME: 'ORDER_DETAILS',
  },
  ORDERS_LIST: {
    NAME: 'ORDERS_LIST',
  },
  CONTACTS: {
    CONTACTS_NAME_INPUT: 'CONTACTS_NAME_INPUT',
    CONTACTS_PHONE_INPUT: 'CONTACTS_PHONE_INPUT',
    CONTACTS_EMAIL_INPUT: 'CONTACTS_EMAIL_INPUT',
  }
}
import React from 'react';
import styled from 'styled-components';
import { Helper } from '@utils';
import { EnhancedFlightSegment, Stop } from '@modules/main';
import Place from '../../Place';
import { Point } from '@components/common/FlightSegment';
import moment from 'moment';
import { Text } from '@components/ui';
import PlaneIcon from '@assets/images/plane';
import techInfoIcon from '@assets/media/flight-search/techInfoIcon.svg';
import Tippy from '@tippyjs/react';

import ManIcon from '@assets/images/man';
import { cityIn } from 'lvovich';

const Container = styled.div``;

const LineWrapper = styled.div`
  display: flex;
`;

const Time = styled(Text)`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;

  @media (max-width: 767px) {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }
`;

const PlaceDateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  & > :last-child {
    text-align: right;
  }
  margin-top: 3px;
`;

const PointsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
`;

const ProgressLine = styled.div`
  margin-top: 20px;
  height: 2px;
  background-color: #dcdcdc;
  position: relative;
  & > div {
    top: -8px;
  }

  & > :last-child {
    right: 0;
  }

  @media (max-width: 767px) {
    margin-top: 13px;
    & > div {
      top: -6px;
    }
  }
`;

const ProgressInfo = styled.div`
  text-align: center;
  width: 100%;
  margin: 0 10px;
  span {
    margin-bottom: 7px;
  }
`;

const CompanyLogo = styled.img.attrs({ width: 24, height: 24 })`
  border-radius: 50%;
  position: absolute;
  top: -23px;
  right: 50%;
  transform: translateX(50%);

  @media (max-width: 767px) {
    display: none;
  }
`;

const DescriptionBlock = styled.div`
  background: #f7f7f7;
  border-radius: 4px;
  padding: 10px 12px;
  margin-top: 10px;

  & > div {
    margin-bottom: 7px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
  @media (max-width: 767px) {
    padding: 8px;
  }
`;

const Description = styled.div`
  display: flex;
  align-items: center;
`;

const StyledPlaneIcon = styled(PlaneIcon)`
  width: 20px;
  height: 20px;
  fill: #737373;
  margin-right: 10px;
`;

const StyledManIcon = styled(ManIcon)`
  width: 16px;
  height: 16px;
  fill: #737373;
  margin-right: 14px;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 767px) {
    display: inherit;
  }
`;

const CompanyLogoMobile = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12.5px;

  & > :first-child {
    margin-right: 5px;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

const LogoDescription = styled(Text)`
  font-size: 12px;
  line-height: 16px;
  color: #3c3c3c;
`;

const Image = styled.img.attrs({ width: 21.45, height: 21.45 })`
  border-radius: 50%;
  background: #9097ce;
`;

const DescriptionText = styled(Text)`
  font-size: 12px;
  line-height: 16px;
  color: #3c3c3c;
`;

const DesktopDescription = styled(DescriptionText)`
  @media (max-width: 767px) {
    display: none;
  }
`;

const PathTime = styled(Text)`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #737373;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  bottom: 3px;

  @media (min-width: 768px) {
    display: none;
  }
`;

const TechLanding = styled.img.attrs({ src: techInfoIcon })`
  margin-right: 5px;
  width: 16px;
  height: 16px;
`;

const TechLandingBlock = styled.div`
  padding: 4px 8px 4px 8px;
  background: #ffd3ca;
  border-radius: 8px;
  width: 165px;
  font-size: 12px;
  cursor: pointer;
`;

type SegmentInfoProps = EnhancedFlightSegment & { stop: Stop | undefined };

const SegmentInfo: React.FC<SegmentInfoProps> = (props) => {
  const fromDate = moment(`${props.fromDate} ${props.fromTime}`);
  const toDate = moment(`${props.toDate} ${props.toTime}`);

  return (
    <Container>
      <LineWrapper>
        <Time>{fromDate.format('HH:mm')}</Time>
        <ProgressInfo>
          <ProgressLine>
            <PointsContainer>
              <Point />
              <CompanyLogo
                src={Helper.getAirlinesLogoUrl(
                  props.operatingAirlineCode,
                  48,
                  48,
                )}
              />
              <PathTime>{Helper.formatDuration(props.duration)}</PathTime>
              <Point />
            </PointsContainer>
          </ProgressLine>
        </ProgressInfo>
        <Time>{toDate.format('HH:mm')}</Time>
      </LineWrapper>
      <PlaceDateWrapper>
        <Place {...props.from} date={fromDate} />
        <Place {...props.to} date={toDate} />
      </PlaceDateWrapper>
      <CompanyLogoMobile>
        <Image
          src={Helper.getAirlinesLogoUrl(
            props.airlineInfo.operatingAirlineCode,
            65,
            65,
          )}
          alt={props.airlineInfo.operatingAirlineName}
        />{' '}
        <LogoDescription>
          {props.airlineInfo.operatingAirlineName}
        </LogoDescription>
      </CompanyLogoMobile>
      <DescriptionBlock>
        <Description>
          <StyledPlaneIcon />
          <Flex>
            <DescriptionText>
              Рейс: {props.airlineCode} {props.flightNo} • {props.airplane}{' '}
            </DescriptionText>
            <DesktopDescription>
              В пути {Helper.formatDuration(props.duration)}
            </DesktopDescription>
          </Flex>
        </Description>
        {props.techLandings && props.techLandings.length > 0 && (
          <Tippy
            content={
              <>
                Техническая остановка в аэропорту{' '}
                <b>{props.techLandings[0].city.airport}</b>,{' '}
                <b>{props.techLandings[0].city.city}</b>. Длительность остановки{' '}
                <b>{props.techLandings[0].duration}</b> минут.
              </>
            }
            theme="light"
            maxWidth={245}
          >
            <TechLandingBlock>
              <Description>
                <TechLanding />
                <DesktopDescription style={{ color: '#E73C3E' }}>
                  Техническая остановка
                </DesktopDescription>
              </Description>
            </TechLandingBlock>
          </Tippy>
        )}
        {props.stop && (
          <Description>
            <StyledManIcon />
            <Flex>
              <DescriptionText>
                Пересадка в {cityIn(props.stop.city.city)}
              </DescriptionText>{' '}
              <DescriptionText>
                {Helper.formatDuration(props.stop.duration)}
              </DescriptionText>
            </Flex>
          </Description>
        )}
      </DescriptionBlock>
    </Container>
  );
};

export default SegmentInfo;

import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Separator } from '@components/common';
import { PassengerListProps, FormikFields } from './types';
import { useFormikContext } from 'formik';
import { SeatPanel } from '@components/train/Booking/CarPlacesPanel';
import TrainPassengerItem from '@components/booking/PassengersBlock/TrainPassengerItem';
import { TariffsPanel } from '@components/train/Panels/TariffsPanel';
import { useSelector } from 'react-redux';
import {
  bedClothsBackwardSelector,
  bedClothsForwardSelector,
  CAR_PLACE_GENDER_TYPES,
  CAR_SEAT_TYPES,
  CAR_TYPES,
  Discount,
  LoyaltyCardOption,
  trainBackSelector,
  trainForwardSelector,
} from '@modules/trainTickets';
import {
  filterOnlyLoyaltyCardsInTariffs,
  getSameDiscountsByDiscountType,
  getTariffsByDiscountTypes,
} from '@modules/trainBooking/utils';
import { Text } from '@components/ui/Typography';
import { ApplicationState } from '@modules/index';
import { isBoolean } from 'lodash';
import { getCarPlaceGenderTypeByPlaceNumber } from '@components/train/TrainSearch/utils';

const Item = styled.div``;

const Container = styled.div`
  & > ${Item} {
    margin: 24px 0 0 0;
  }
`;

const MutedText = styled(Text)`
  color: #737373;
`;

const TrainPassengerList: React.FC<PassengerListProps> = ({
  onChange,
  countriesList,
}) => {
  const formikContext = useFormikContext<FormikFields>();
  const trainForward = useSelector(trainForwardSelector);
  const trainBackward = useSelector(trainBackSelector);
  const trainPassengers = useSelector(
    (state: ApplicationState) => state.trainBooking.passengers,
  );
  const bedClothesForward = useSelector(bedClothsForwardSelector);
  const bedClothesBackward = useSelector(bedClothsBackwardSelector);
  const allowedDocumentTypes = useSelector(
    (state: ApplicationState) => state.trainBooking.allowedDocumentTypes,
  );

  const loyaltyCards = useMemo(() => {
    const currentCarForward = trainForward.currentCar?.data;
    let currentCarBackward;

    const discountsFrom = currentCarForward.discounts;
    let sameDiscounts = discountsFrom;
    let discountsTo: Discount[] = [];
    if (trainBackward.index !== null) {
      currentCarBackward = trainBackward.currentCar?.data;
      discountsTo = currentCarBackward.discounts;
      sameDiscounts = getSameDiscountsByDiscountType(
        discountsFrom,
        discountsTo,
      );
    }
    const discountTypes = sameDiscounts.map(
      (discount) => discount.discountType,
    );
    const tariffs = getTariffsByDiscountTypes(discountTypes);
    const loyaltyCards: LoyaltyCardOption[] = filterOnlyLoyaltyCardsInTariffs(
      tariffs,
    ).map((tariff) => {
      return {
        value: tariff.id,
        label: tariff.title,
      };
    });

    return loyaltyCards;
  }, []);

  const carPlaceNodes = React.useMemo(() => {
    const result: React.ReactNode[][] = [];
    trainPassengers.forEach((passenger, index) => {
      result[index] = [];
    });

    // render places for each passenger
    !!trainPassengers?.length &&
      trainPassengers.forEach((passenger, index) => {
        if (passenger.carPlaces.length > 0) {
          passenger.carPlaces.forEach((carPlace) => {
            const isForward = carPlace._direction === 'forward';
            const train = isForward ? trainForward : trainBackward;
            const isSapsan = train.train.trainName === 'САПСАН';
            const isFourPlaceAtOnce =
              carPlace.placeReservationType === 'FourPlacesAtOnce';
            let placeTitle = CAR_SEAT_TYPES[carPlace.type];
            if (isSapsan && isFourPlaceAtOnce) {
              placeTitle = 'Место в купе-переговорной';
            }
            const genderType = getCarPlaceGenderTypeByPlaceNumber(
              carPlace.number,
            );
            const genderText =
              genderType !== null
                ? `${CAR_PLACE_GENDER_TYPES[genderType]}`
                : '';
            result[index].push(
              <Text>
                Место {carPlace.number}, {genderText ? `${genderText}, ` : ''}{' '}
                {placeTitle}{' '}
                <MutedText>
                  {' '}
                  / {train.currentCar.data.carNumber} вагон,{' '}
                  {CAR_TYPES[train.currentCar.data.carType]},{' '}
                  {train.train.carriers[0]}
                  {isForward && isBoolean(bedClothesForward)
                    ? bedClothesForward
                      ? ', с бельём'
                      : ', без белья'
                    : ''}
                  {!isForward && isBoolean(bedClothesBackward)
                    ? bedClothesBackward
                      ? ', с бельём'
                      : ', без белья'
                    : ''}
                  {carPlace._direction === 'forward' ? ', туда' : ', обратно'}
                </MutedText>
              </Text>,
            );
          });
        }
      });

    return result;
  }, [
    trainForward,
    trainBackward,
    trainPassengers,
    bedClothesBackward,
    bedClothesBackward,
  ]);

  return (
    <Container>
      {formikContext.values.passengers.map((passenger, index) => {
        const hasNonRefundableTariffForward =
          (trainPassengers[index]?.carPlaces.length > 0 &&
            trainPassengers[index]?.carPlaces[0].prices[0]
              ?.hasNonRefundableTariff) ||
          false;
        const hasNonRefundableTariffBackward =
          (trainPassengers[index]?.carPlaces.length > 1 &&
            trainPassengers[index]?.carPlaces[1].prices[0]
              ?.hasNonRefundableTariff) ||
          false;

        const hasOnlyNonRefundableTariffForward =
          (trainPassengers[index]?.carPlaces.length > 0 &&
            trainPassengers[index]?.carPlaces[0].prices[0]
              ?.onlyNonRefundableTariff) ||
          false;
        const hasOnlyNonRefundableTariffBackward =
          (trainPassengers[index]?.carPlaces.length > 1 &&
            trainPassengers[index]?.carPlaces[1].prices[0]
              ?.onlyNonRefundableTariff) ||
          false;
        return (
          <React.Fragment key={passenger.uid}>
            <Item>
              <TrainPassengerItem
                index={index}
                countriesList={countriesList}
                onChange={(data) => {
                  onChange(data, index);
                }}
                loyaltyCards={loyaltyCards}
                allowedDocumentTypes={allowedDocumentTypes}
                {...passenger}
              />
            </Item>

            {carPlaceNodes &&
              carPlaceNodes[index] &&
              carPlaceNodes[index].length > 0 && (
                <SeatPanel carPlacesNodes={carPlaceNodes[index] || []} />
              )}
            <Item>
              <TariffsPanel
                hasNonRefundableTariffForward={hasNonRefundableTariffForward}
                hasNonRefundableTariffBackward={hasNonRefundableTariffBackward}
                hasOnlyNonRefundableTariff={hasOnlyNonRefundableTariffForward}
                hasOnlyNonRefundableTariffBackward={
                  hasOnlyNonRefundableTariffBackward
                }
                passengerIndex={index}
                passenger={passenger}
              />
            </Item>

            {index !== formikContext.values.passengers.length - 1 && (
              <Separator />
            )}
          </React.Fragment>
        );
      })}
    </Container>
  );
};
export default TrainPassengerList;

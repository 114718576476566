import React from 'react';
import styled from 'styled-components';
import { useOrderPosition } from '../../../useItemProps';
import { InfoBlock } from '@components/common';
import { getFlightsInfo, getOrderDetailsCleaned } from '@modules/orders';
import FlightList from './FlightList';
import Title from './Title';
import { ReactComponent as LuggageIcon } from '@assets/media/flight-search/luggage-round-icon.svg';
import { Text as BaseText } from '@components/ui';
import ActionSelector from '../../Head/ActionSelector';

import mobileArrowPath from '@assets/media/flight-search/mobile-arrow.svg';
import OrderRouteDetailContainer from '@components/train/RouteDetail/OrderRouteDetailContainer';
import { useSelector } from 'react-redux';
import { transformOrderToBusForRouteDetail } from '@modules/orders/normalizer';
import RouteDetail from '@components/bus/Booking/components/TripDetails/components/RouteDetail';
import { Divider } from '@components/bus/Booking/components/TripDetails/styles';

const Wrapper = styled.div`
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  border-radius: 10px;

  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  position: relative;
  overflow: hidden;
  padding: 0;
`;

const TripDetailsContainer = styled.div`
  padding: 0 20px 20px;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const FlexSpaceBetween = styled(Flex)`
  justify-content: space-between;
`;

const Bottom = styled(FlexSpaceBetween)`
  padding: 14px;
  border-top: 1px solid #f2f2f2;

  @media (min-width: 768px) {
    display: none;
  }
`;

const BaggageWrapper = styled(Flex)`
  & > :first-child {
    margin-right: 5px;
  }
`;

const Text = styled(BaseText)`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #3c3c3c;
`;

const MobileDetailButton = styled.button`
  @media (min-width: 768px) {
    display: none;
  }

  text-decoration: none;
  border: none;
  padding: 0;
  outline: none;
  display: block;
  background: transparent;
  position: relative;
  margin-right: 14px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #4872f2;
`;

const MobileArrow = styled.img<{ reverse: boolean }>`
  position: absolute;
  right: -16px;
  top: 6px;
  width: 10px;
  transition: transform 0.2s ease-in-out;

  ${({ reverse }) => (reverse ? 'transform: rotate(180deg);' : '')}
`;

const Body: React.FC = React.memo(() => {
  const props = useOrderPosition();
  const orderDetails = useSelector(getOrderDetailsCleaned);
  const [forward, backward] = transformOrderToBusForRouteDetail(orderDetails);
  const { isTrainOrder, isBusOrder } = props;
  const flightListProps = getFlightsInfo(props);
  const handleClick = () => props.setOpen(!props.isOpen);

  const baggage = (props.tariff?.fareFamilies || [])[0]?.features.find(
    (x) => x.type === 'Baggage',
  );

  return (
    <Wrapper
      className={`order-poisition__mobile-body${
        !props.isOpen ? '--closed' : ''
      }`}
    >
      {props.isMobile !== true && <Title />}
      {isTrainOrder || isBusOrder ? (
        <OrderRouteDetailContainer orderId={props.id} />
      ) : (
        <FlightList
          active={props.isOpen}
          onClick={handleClick}
          {...flightListProps}
        />
      )}
      {!isTrainOrder && !isBusOrder && (
        <Bottom>
          <BaggageWrapper>
            <LuggageIcon />
            <Text>{baggage && formatBaggage(baggage)}</Text>
          </BaggageWrapper>
          <MobileDetailButton onClick={handleClick}>
            Детали перелета{' '}
            <MobileArrow src={mobileArrowPath} reverse={props.isOpen} />
          </MobileDetailButton>
        </Bottom>
      )}
      {isBusOrder && (
        <TripDetailsContainer>
          {forward !== undefined && (
            <RouteDetail
              bus={forward}
              direction="forward"
              transportNumber={forward.busNumber}
              places={[forward.place]}
              busAddresses={{
                forward: {
                  address: forward.address,
                  geolocation: forward.geolocation,
                },
                backward: {
                  address: backward?.address || null,
                  geolocation: backward?.geolocation || null,
                },
              }}
            />
          )}
          {backward !== undefined && (
            <>
              <Divider />
              <RouteDetail
                bus={backward}
                direction="backward"
                transportNumber={backward.busNumber}
                places={backward?.place ? [backward.place] : ['']}
                busAddresses={{
                  forward: {
                    address: forward.address,
                    geolocation: forward.geolocation,
                  },
                  backward: {
                    address: backward.address,
                    geolocation: backward.geolocation,
                  },
                }}
              />
            </>
          )}
        </TripDetailsContainer>
      )}
    </Wrapper>
  );
});

function formatBaggage(x: {
  applicability: string;
  type: string;
  descriptionEng: string;
  descriptionRus: string;
}) {
  switch (x.applicability) {
    case 'NotOffered':
    case 'AtCharge':
      return 'Багаж не включен';
    case 'Included':
      return x.descriptionRus;
  }
}

export default Body;

import {
  APPLY_TO,
  FLIGHT_CLASSES,
  FLIGHT_TYPES,
  ROUTE_TYPES,
  TYPE_AMOUNT,
} from './constants';
import {
  GetAgentFeeResponse,
  ResponseAgentTax,
} from '@modules/agents/agent-fees/types';
import { FormIncomeValue } from '@components/agents/agent-rules/types';

interface NameValue {
  name?: string;
  value: string;
  label?: string;
}

interface KeyValue {
  [key: string]: string;
}

export const convertToRequestData = (values: FormIncomeValue) => {
  const result = values;
  if (values.saleStart) {
    // result.saleStart = values.saleStart.toDate() as any;
  }
  if (values.saleEnd) {
    // result.saleEnd = values.saleEnd.toDate() as any;
  }
  if (values.departureStart) {
    // result.departureStart = values.departureStart.toDate() as any;
  }
  if (values.departureEnd) {
    // result.departureEnd = values.departureEnd.toDate() as any;
  }
  if (values && Array.isArray(values.applyTo) && values.applyTo.length === 1) {
    const [value] = values.applyTo;

    result.applyTo = value;
  } else if (Array.isArray(values?.applyTo) && values?.applyTo?.length === 2) {
    result.applyTo = 'all';
  }
  if (values?.applyTo) {
    result.passengerTypes = [
      {
        type: 'ADT',
        price: Number(values?.amount),
      },
      { type: 'CLD', price: Number(values?.amount) },
    ];
  }

  if (values.departure && Array.isArray(values.departure)) {
    result.departure = values.departure?.join(', ');
  }

  if (values.arrival && Array.isArray(values.arrival)) {
    result.arrival = values.arrival?.join(', ');
  }

  result.amount = Number(values.amount);

  return result;
};

export const convertFeeToInitialValues = (values: GetAgentFeeResponse) => {
  const result: any = values;

  if (values?.applyTo) {
    result.applyTo = values?.applyTo;
  }

  if (values.saleStart) {
    result.saleStart = new Date(values.saleStart);
  }
  if (values.saleEnd) {
    result.saleEnd = new Date(values.saleEnd);
  }
  if (values.departureStart) {
    result.departureStart = new Date(values.departureStart);
  }
  if (values.departureEnd) {
    result.departureEnd = new Date(values.departureEnd);
  }
  if (values.amount) {
    result.amount = Number(result.amount);
  }
  if (values.passengerTypes) {
    result.passengerTypes = values?.passengerTypes;
  }

  if (values.isCharter) {
    result.isCharter = 'charter';
  } else {
    result.isCharter = 'regular';
  }

  return result as FormIncomeValue;
};

const converUiToKeyValue = (data: NameValue[]) => {
  return data.reduce(
    (acc, x) => ({ ...acc, [x.value]: x?.name || x.label }),
    {}
  );
};

//
const flightTypesValues: KeyValue = converUiToKeyValue(FLIGHT_TYPES);
const flightClassesValues: KeyValue = converUiToKeyValue(FLIGHT_CLASSES);
const routeTypesValues: KeyValue = converUiToKeyValue(ROUTE_TYPES);
const applyToValues: KeyValue = {
  ...converUiToKeyValue(APPLY_TO),
  all: 'Сегменты, пассажиры',
};
const typeAmountValues: KeyValue = converUiToKeyValue(TYPE_AMOUNT);

export const feesUiMapper = (data: GetAgentFeeResponse[]) => {
  return data.map((x) => {
    const applyToUi = x?.applyTo ? applyToValues[x.applyTo] : null;
    const flightClassesUi = x?.flightClass
      ? flightClassesValues[x.flightClass]
      : null;
    const typeAmountUi = x?.calculateType
      ? typeAmountValues[x.calculateType]
      : null;
    const routeTypeUi = x?.routeType ? routeTypesValues[x.routeType] : null;
    const flightTypeUi = x?.flightType ? flightTypesValues[x.flightType] : null;

    return {
      ...x,
      applyToUi,
      flightClassesUi,
      typeAmountUi,
      routeTypeUi,
      flightTypeUi,
    };
  });
};

const FORM_DEFAULT = {
  SmsService: 0,
  ReturnGuaranteeService: 0,
  PaymentReceipt: 0,
  AviaAdditionalService1: 0,
  AviaAdditionalService2: 0,
  AviaAdditionalService3: 0,
};

export function feesFormNormalizer(data: ResponseAgentTax[] | null) {
  if (data?.length) {
    const ReturnGuaranteeService = data.find(
      (x) => x.taxType === 'ReturnGuaranteeService'
    );
    const SmsService = data.find((x) => x.taxType === 'SmsService');
    const PaymentReceipt = data.find((x) => x.taxType === 'PaymentReceipt');
    const AviaAdditionalService1 = data.find(
      (x) => x.taxType === 'AviaAdditionalService1'
    );
    const AviaAdditionalService2 = data.find(
      (x) => x.taxType === 'AviaAdditionalService2'
    );
    const AviaAdditionalService3 = data.find(
      (x) => x.taxType === 'AviaAdditionalService3'
    );

    return {
      SmsService: SmsService?.taxValue,
      ReturnGuaranteeService: ReturnGuaranteeService?.taxValue,
      PaymentReceipt: PaymentReceipt?.taxValue,
      AviaAdditionalService1: AviaAdditionalService1?.taxValue,
      AviaAdditionalService2: AviaAdditionalService2?.taxValue,
      AviaAdditionalService3: AviaAdditionalService3?.taxValue,
    };
  } else {
    return FORM_DEFAULT;
  }
}

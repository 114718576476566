import React from 'react';
import styled from 'styled-components';
import { ReactComponent as BackgroundSVG1 } from '@assets/media/booking/flightBookImg.svg';
import planeIcon from '@assets/media/booking/flightBookImg2.svg';
import timeLimitIcon from '@assets/media/booking/timeLimitIcon.svg';
import { useSelector } from 'react-redux';
import { ApplicationState } from '@modules/index';
import moment from 'moment';
import { Hooks } from '@utils';

const Wrapper = styled.div`
  padding: 15px 25px;
  background: #edf1fe;
  box-sizing: border-box;

  border-radius: 8px;
  position: relative;
  margin-top: 30px;
  height: 118px;

  @media (max-width: 767px) {
    padding: 15px;
    height: 162px;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    height: 135px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const BackgroundRight = styled(BackgroundSVG1)`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    opacity: 0.3;
  }
`;

const Title = styled.div`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #3c3c3c;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 16px;
    margin-top: 15px;
    max-width: 70%;
  }
`;

const PlaneIcon = styled.img.attrs({ src: planeIcon })`
  @media (max-width: 767px) {
    width: 21px;
    height: 21px;
  }
`;

const TimeLimitIcon = styled.img.attrs({ src: timeLimitIcon })`
  margin-right: 5px;
  margin-top: 5px;
`;

const PlaneContainer = styled.div`
  background: white;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 7px;

  @media (max-width: 767px) {
    height: 30px;
    width: 30px;
  }
`;

const Paragraph = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #3c3c3c;
  margin-left: 47px;
  @media (max-width: 767px) {
    margin-left: 0px;
    margin-top: 10px;
  }
`;

const TimeLimitText = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #4872f2;
  margin-top: 6px;
`;

const FlightBookedNotification: React.FC = () => {
  const order = useSelector((x: ApplicationState) => x.orders.orderDetail);

  function dateToLocal(date: string) {
    const stillUtc = moment.utc(date).toDate();
    return moment(stillUtc).local();
  }
  const { isMobile } = Hooks.useMediaSizes();

  const startTime = moment(Date());
  const endTime = moment(dateToLocal(order.data.cancellationTime));
  const duration = moment.duration(endTime.diff(startTime));

  const hours = duration.hours().toString();
  const minutes = duration.minutes().toString();
  const days = duration.days().toString();

  return (
    <Wrapper style={{ marginBottom: 30 }}>
      <BackgroundRight />
      <Row>
        <PlaneContainer>
          <PlaneIcon />{' '}
        </PlaneContainer>
        <Title>Авиабилеты забронированы и ждут оплаты</Title>
      </Row>

      <Paragraph>
        Осталось только добавить страховку, дополнительные услуги и оплатить
        заказ.
      </Paragraph>

      <Row style={{ marginLeft: !isMobile ? 47 : 0, marginTop: 5 }}>
        <TimeLimitIcon />{' '}
        <TimeLimitText>
          {' '}
          Оплатите заказ в течение{' '}
          {(days !== '0' ? days + ' д ' : ' ') +
            (hours !== '0' ? hours + ' ч ' : ' ') +
            minutes +
            ' м '}
        </TimeLimitText>
      </Row>
    </Wrapper>
  );
};

export default FlightBookedNotification;

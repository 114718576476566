import { ResponseError } from '@modules/main';
import {
  TRAIN_SEARCH_STEPS,
  TrainSearchState,
} from '@modules/trainTickets/constants';
import { setGeneralTrainsSort } from '@modules/trainTickets/utils';
import { combineReducers } from 'redux';
import { createAction, createReducer } from 'redux-act';

import {
  Car,
  CarPlace,
  ClearGenderTypesOnCarPlacesPayload,
  FreePlacesByCompartment,
  GetCarsPayload,
  GetTicketsListDto,
  GetTrainRoutePayload,
  OpenTicketPayload,
  SetGenderTypeOnCarPlacePayload,
  Train,
  TrainDirectionType,
  TrainFilter,
  TrainSearchPayload,
  TrainSearchRequestStatus,
} from './types';
import { GetTrainRouteDto } from '@modules/trainTickets/dto/GetTrainRouteDto';

/** loading statuses */
export const setTrainSearchRequestState = createAction<{
  errors?: ResponseError[] | null;
  status: TrainSearchRequestStatus;
}>('@@trainTickets/trainSearchRequestState');

// car scheme
export const setCarSchemeRequestState = createAction<{
  carSchemeStatus: TrainSearchRequestStatus;
  errors?: ResponseError[] | null;
}>('@@trainTickets/setCarSchemeRequestState');

// train request statuses
export const trainSearchRequest = createAction<TrainSearchPayload>(
  '@trainTickets/searchRequest'
);
export const trainSearchSuccess = createAction<GetTicketsListDto>(
  '@trainTickets/searchSuccess'
);
export const trainSearchSuccessFilter = createAction<GetTicketsListDto>(
  '@trainTickets/searchSuccessFilter'
);

export const trainSearchFailure = createAction('@trainTickets/searchFailure');

// car request statuses
export const getCarsRequest = createAction<GetCarsPayload>(
  '@trainTickets/getCarsRequest'
);
export const getCarsSuccess = createAction<any>('@trainTickets/getCarsSuccess');
export const getBackCarsSuccess = createAction<any>(
  '@trainTickets/getBackCarsSuccess'
);
export const getCarsFailure = createAction('@trainTickets/getCarsFailure');

/** purify data */
export const trainPurify = createAction('@trainTickets/trainPurify');
export const carsPurify = createAction('@trainTickets/carsPurify');

/** set train sort */
export const setTrainSort = createAction<
  'PRICE' | 'TIME' | 'TIME_ARRIVAL' | 'TIME_DEPARTURE'
>('@trainTickets/setTrainSort');

/** set search step */
export const setSearchStep = createAction<keyof typeof TRAIN_SEARCH_STEPS>(
  '@@trainTickets/setSearchStep'
);

export const setPassengersCounters = createAction<[number, number, number]>(
  '@@trainTickets/setPassengersCounters'
);

/** list of trains */
const list = createReducer({}, null as unknown as GetTicketsListDto);
list.on(trainSearchSuccess, (_, payload) => payload);

/** filtered list of trains */
const filteredList = createReducer({}, null as unknown as GetTicketsListDto);
filteredList.on(trainSearchSuccessFilter, (_, payload) => payload);
filteredList.on(setTrainSort, (state, sortBy) => {
  const sortedTrains: Train[] = setGeneralTrainsSort(
    state?.trainsPerRoutes[0]?.trains,
    sortBy
  );
  let sortedTrainsBack: Train[] = [];
  if (state?.trainsPerRoutes?.length > 1) {
    sortedTrainsBack = setGeneralTrainsSort(
      state?.trainsPerRoutes[1]?.trains,
      sortBy
    );
  }
  return {
    ...state,
    trainsPerRoutes: [
      {
        ...state?.trainsPerRoutes[0],
        trains: sortedTrains,
      },
      {
        ...state?.trainsPerRoutes[1],
        trains: sortedTrainsBack,
      },
    ],
  };
});

/** open trains */
export const openTrainHandler = createAction<OpenTicketPayload>(
  '@trainTickets/openTrainHandler'
);

export const openTrainBackward = createAction<OpenTicketPayload>(
  '@trainTickets/openTrainBackward'
);

/** train forward */
export const openTrainForward = createAction<OpenTicketPayload>(
  '@trainTickets/openTrainForward'
);
export const setTrainForward = createAction<Train>('@trainTickets/setTrain');
export const setBedClothesForward = createAction<boolean | null>(
  '@trainTickets/setBedClothesForward'
);

const trainForwardDir = createReducer({}, null as unknown as Train);
const bedClothesForward = createReducer({}, null as unknown as boolean | null);
const trainIndexForward = createReducer({}, null as unknown as number);
trainIndexForward.on(openTrainForward, (_, payload) => payload.index);
trainIndexForward.on(carsPurify, (_, payload) => null as any);
trainForwardDir.on(setTrainForward, (_, payload) => payload);
bedClothesForward.on(setBedClothesForward, (_, payload) => payload);

/** train backward */
const trainIndexBackward = createReducer({}, null as unknown as number);
export const setTrainBackward = createAction<Train>(
  '@trainTickets/setBackTrain'
);
export const setBedClothesBackward = createAction<boolean | null>(
  '@trainTickets/setBedClothesBackward'
);
const bedClothesBackward = createReducer({}, null as unknown as boolean | null);

const trainBackwardDir = createReducer({}, null as unknown as Train);
trainIndexBackward.on(openTrainBackward, (_, payload) => payload.index);
trainIndexBackward.on(carsPurify, (_, payload) => null as any);
trainBackwardDir.on(setTrainBackward, (_, payload) => payload);
bedClothesBackward.on(setBedClothesBackward, (_, payload) => payload);

/** cars forward */
const carsForward = createReducer({}, null as unknown as Car[]);
carsForward.on(getCarsSuccess, (state, payload) => payload);
carsForward.on(carsPurify, (state, payload) => null as unknown as Car[]);

/** cars backward */
const carsBackward = createReducer({}, null as unknown as Car[]);
carsBackward.on(getBackCarsSuccess, (state, payload) => payload);
carsBackward.on(carsPurify, (state, payload) => null as unknown as Car[]);
carsBackward.on(getBackCarsSuccess, (state, payload) => payload);

/** filter */
export const runTrainFilter = createAction<TrainFilter>(
  '@trainTickets/runTrainFilter'
);

export const setTrainFilter = createAction<TrainFilter[]>(
  '@trainTickets/setTrainFilter'
);
export const openTrainSearchFilter = createAction(
  '@trainTickets/openTrainSearchFilter'
);
export const closeTrainSearchFilter = createAction(
  '@trainTickets/closeTrainSearchFilter'
);
export const toggleTrainSearchFilter = createAction(
  '@trainTickets/toggleTrainSearchFilter'
);
export const resetTrainFilter = createAction<{
  value: null | string;
  type: TrainDirectionType;
}>('@trainTickets/resetTrainFilter');
export const setPricesTrainFilter = createAction<{
  values: number[];
  type: TrainDirectionType;
}>('@trainTickets/setPricesTrainFilter');
export const setTimeTrainFilter = createAction<{
  timeType: 'from' | 'to';
  values: number[];
  type: TrainDirectionType;
}>('@trainTickets/setTimeTrainFilter');
export const setCarTypeTrainFilter = createAction<{
  values: { [key: string]: boolean };
  type: TrainDirectionType;
}>('@trainTickets/setCarTypeTrainFilter');

export const setTrainTypeFilter = createAction<{
  values: { [key: string]: boolean };
  type: TrainDirectionType;
}>('@trainTickets/setTrainTypeFilter');

export const setTrainNameTrainFilter = createAction<{
  values: { [key: string]: boolean };
  type: TrainDirectionType;
}>('@trainTickets/setTrainNameTrainFilter');
export const setCarrierTrainFilter = createAction<{
  values: { [key: string]: boolean };
  type: TrainDirectionType;
}>('@trainTickets/setCarrierTrainFilter');
export const setTrainServicesTrainFilter = createAction<{
  values: { [key: string]: boolean };
  type: TrainDirectionType;
}>('@trainTickets/setTrainServicesTrainFilter');
export const setTrainDiscountsTrainFilter = createAction<{
  values: { [key: string]: boolean };
  type: TrainDirectionType;
}>('@trainTickets/setTrainDiscountsTrainFilter');
export const setDurationTrainFilter = createAction<{
  values: number[];
  type: TrainDirectionType;
}>('@trainTickets/setDurationTrainFilter');

/** train search state */
const trainSearch = createReducer({}, TrainSearchState);
trainSearch.on(setPassengersCounters, (state, payload) => {
  return { ...state, passengers: payload };
});
trainSearch.on(trainPurify, () => TrainSearchState);
trainSearch.on(setTrainSearchRequestState, (state, payload) => {
  return { ...state, ...payload };
});
trainSearch.on(setTrainSort, (state, payload) => {
  return { ...state, sortBy: payload };
});
trainSearch.on(setSearchStep, (state, payload) => {
  return { ...state, currentSearchStep: payload };
});
trainSearch.on(getCarsRequest, (state, payload) => {
  return { ...state, carsStatus: TrainSearchRequestStatus.loading };
});
trainSearch.on(getCarsFailure, (state, payload) => {
  return { ...state, carsStatus: TrainSearchRequestStatus.failure };
});
trainSearch.on(getCarsSuccess, (state, payload) => {
  return { ...state, carsStatus: TrainSearchRequestStatus.success };
});
trainSearch.on(getBackCarsSuccess, (state, payload) => {
  return { ...state, carsStatus: TrainSearchRequestStatus.success };
});
trainSearch.on(setCarSchemeRequestState, (state, payload) => {
  return { ...state, carSchemeStatus: payload.carSchemeStatus };
});
trainSearch.on(toggleTrainSearchFilter, (state) => {
  return { ...state, filterIsOpen: !state.filterIsOpen };
});
trainSearch.on(closeTrainSearchFilter, (state) => {
  return { ...state, filterIsOpen: false };
});
trainSearch.on(openTrainSearchFilter, (state) => {
  return { ...state, filterIsOpen: true };
});
trainSearch.on(setTrainFilter, (state, payload) => {
  return {
    ...state,
    filter: payload,
  };
});
trainSearch.on(resetTrainFilter, (state, payload) => {
  let filter: TrainFilter;
  if (payload.type === 'forward') {
    filter = state.filter[0];
  } else {
    filter = state.filter[1];
  }

  // TODO: отрефакторить, повтор кода
  if (payload.value === null) {
    filter.isDefault.prices = true;
    filter.values.prices = [filter.prices.min, filter.prices.max];
    filter.isDefault.trainDurations = true;
    filter.values.trainDurations = [
      filter.trainDurationsList[0].min,
      filter.trainDurationsList[0].max,
    ];
    filter.isDefault.times = true;
    filter.times[0].from = {
      ...filter.times[0].from,
      values: [filter.times[0].from.min, filter.times[0].from.max],
    };
    filter.times[0].to = {
      ...filter.times[0].to,
      values: [filter.times[0].to.min, filter.times[0].to.max],
    };
    filter.isDefault.carTypes = true;
    Object.entries(filter.values.carTypes).forEach(([key, val]) => {
      filter.values.carTypes[key] = false;
    });
    filter.isDefault.trainTypes = true;
    Object.entries(filter.values.trainTypes).forEach(([key, val]) => {
      filter.values.trainTypes[key] = false;
    });
    filter.isDefault.trainNames = true;
    Object.entries(filter.values.trainNames).forEach(([key, val]) => {
      filter.values.trainNames[key] = false;
    });
    filter.isDefault.carriers = true;
    Object.entries(filter.values.carriers).forEach(([key, val]) => {
      filter.values.carriers[key] = false;
    });
    filter.isDefault.trainServices = true;
    Object.entries(filter.values.trainServices).forEach(([key, val]) => {
      filter.values.trainServices[key] = false;
    });
    filter.isDefault.discounts = true;
    Object.entries(filter.values.discounts).forEach(([key, val]) => {
      filter.values.discounts[key] = false;
    });
  } else {
    switch (payload.value) {
      case 'prices': {
        filter.isDefault.prices = true;
        filter.values.prices = [filter.prices.min, filter.prices.max];
        break;
      }
      case 'trainDuration': {
        filter.isDefault.trainDurations = true;
        filter.values.trainDurations = [
          filter.trainDurationsList[0].min,
          filter.trainDurationsList[0].max,
        ];
        break;
      }
      case 'times': {
        filter.isDefault.times = true;
        filter.times[0].from = {
          ...filter.times[0].from,
          values: [filter.times[0].from.min, filter.times[0].from.max],
        };
        filter.times[0].to = {
          ...filter.times[0].to,
          values: [filter.times[0].to.min, filter.times[0].to.max],
        };
        break;
      }
      case 'carTypes': {
        filter.isDefault.carTypes = true;
        Object.entries(filter.values.carTypes).forEach(([key, val]) => {
          filter.values.carTypes[key] = false;
        });
        break;
      }
      case 'trainTypes': {
        filter.isDefault.trainTypes = true;
        Object.entries(filter.values.trainTypes).forEach(([key, val]) => {
          filter.values.trainTypes[key] = false;
        });
        break;
      }
      case 'trainNames': {
        filter.isDefault.trainNames = true;
        Object.entries(filter.values.trainNames).forEach(([key, val]) => {
          filter.values.trainNames[key] = false;
        });
        break;
      }
      case 'carriers': {
        filter.isDefault.carriers = true;
        Object.entries(filter.values.carriers).forEach(([key, val]) => {
          filter.values.carriers[key] = false;
        });
        break;
      }
      case 'trainServices': {
        filter.isDefault.trainServices = true;
        Object.entries(filter.values.trainServices).forEach(([key, val]) => {
          filter.values.trainServices[key] = false;
        });
        break;
      }
      case 'discounts': {
        filter.isDefault.discounts = true;
        Object.entries(filter.values.discounts).forEach(([key, val]) => {
          filter.values.discounts[key] = false;
        });
        break;
      }
    }
  }

  return state;
});

trainSearch.on(setPricesTrainFilter, (state, payload) => {
  const isForward = payload.type === 'forward';
  const stateFilter = [...state.filter];
  let currFilter: TrainFilter = isForward
    ? { ...stateFilter[0] }
    : { ...stateFilter[1] };
  const isDefault =
    currFilter.prices.max === payload.values[1] &&
    currFilter.prices.min === payload.values[0];

  currFilter = {
    ...currFilter,
    isDefault: {
      ...currFilter.isDefault,
      prices: isDefault,
    },
    values: {
      ...currFilter.values,
      prices: payload.values,
    },
  };

  if (isForward) {
    stateFilter[0] = currFilter;
  } else {
    stateFilter[1] = currFilter;
  }

  return {
    ...state,
    filter: stateFilter,
  };
});

trainSearch.on(setDurationTrainFilter, (state, payload) => {
  const isForward = payload.type === 'forward';
  const stateFilter = [...state.filter];
  let currFilter: TrainFilter = isForward
    ? { ...stateFilter[0] }
    : { ...stateFilter[1] };
  const isDefault =
    currFilter.trainDurationsList[0].min === payload.values[0] &&
    currFilter.trainDurationsList[0].max === payload.values[1];

  currFilter = {
    ...currFilter,
    isDefault: {
      ...currFilter.isDefault,
      trainDurations: isDefault,
    },
    values: {
      ...currFilter.values,
      trainDurations: payload.values,
    },
  };

  if (isForward) {
    stateFilter[0] = currFilter;
  } else {
    stateFilter[1] = currFilter;
  }

  return {
    ...state,
    filter: stateFilter,
  };
});

trainSearch.on(setTimeTrainFilter, (state, payload) => {
  const isForward = payload.type === 'forward';
  const stateFilter = [...state.filter];
  let currFilter: TrainFilter = isForward
    ? { ...stateFilter[0] }
    : { ...stateFilter[1] };
  currFilter.times[0][payload.timeType].values = payload.values;
  const isDefault = currFilter.times.reduce((prev, next) => {
    return (
      prev &&
      next.from.min === next.from.values[0] &&
      next.from.max === next.from.values[1] &&
      next.to.min === next.to.values[0] &&
      next.to.max === next.to.values[1]
    );
  }, true);

  currFilter = {
    ...currFilter,
    isDefault: {
      ...currFilter.isDefault,
      times: isDefault,
    },
    values: {
      ...currFilter.values,
      times: payload.values,
    },
  };

  if (isForward) {
    stateFilter[0] = currFilter;
  } else {
    stateFilter[1] = currFilter;
  }

  return {
    ...state,
    filter: stateFilter,
  };
});

trainSearch.on(setCarTypeTrainFilter, (state, payload) => {
  const isForward = payload.type === 'forward';
  const stateFilter = [...state.filter];
  let currFilter: TrainFilter = isForward
    ? { ...stateFilter[0] }
    : { ...stateFilter[1] };
  const isDefault =
    Object.entries(payload.values).find(([_, val]) => !val) === undefined;

  currFilter = {
    ...currFilter,
    isDefault: {
      ...currFilter.isDefault,
      carTypes: isDefault,
    },
    values: {
      ...currFilter.values,
      carTypes: payload.values,
    },
  };

  if (isForward) {
    stateFilter[0] = currFilter;
  } else {
    stateFilter[1] = currFilter;
  }

  return {
    ...state,
    filter: stateFilter,
  };
});

trainSearch.on(setTrainTypeFilter, (state, payload) => {
  const isForward = payload.type === 'forward';
  const stateFilter = [...state.filter];
  let currFilter: TrainFilter = isForward
    ? { ...stateFilter[0] }
    : { ...stateFilter[1] };
  const isDefault =
    Object.entries(payload.values).find(([_, val]) => !val) === undefined;

  currFilter = {
    ...currFilter,
    isDefault: {
      ...currFilter.isDefault,
      trainTypes: isDefault,
    },
    values: {
      ...currFilter.values,
      trainTypes: payload.values,
    },
  };

  if (isForward) {
    stateFilter[0] = currFilter;
  } else {
    stateFilter[1] = currFilter;
  }

  return {
    ...state,
    filter: stateFilter,
  };
});

trainSearch.on(setTrainNameTrainFilter, (state, payload) => {
  const isForward = payload.type === 'forward';
  const stateFilter = [...state.filter];
  let currFilter: TrainFilter = isForward
    ? { ...stateFilter[0] }
    : { ...stateFilter[1] };
  const isDefault =
    Object.entries(payload.values).find(([_, val]) => !val) === undefined;

  currFilter = {
    ...currFilter,
    isDefault: {
      ...currFilter.isDefault,
      trainNames: isDefault,
    },
    values: {
      ...currFilter.values,
      trainNames: payload.values,
    },
  };

  if (isForward) {
    stateFilter[0] = currFilter;
  } else {
    stateFilter[1] = currFilter;
  }

  return {
    ...state,
    filter: stateFilter,
  };
});

trainSearch.on(setTrainServicesTrainFilter, (state, payload) => {
  const isForward = payload.type === 'forward';
  const stateFilter = [...state.filter];
  let currFilter: TrainFilter = isForward
    ? { ...stateFilter[0] }
    : { ...stateFilter[1] };
  const isDefault =
    Object.entries(payload.values).find(([_, val]) => !val) === undefined;

  currFilter = {
    ...currFilter,
    isDefault: {
      ...currFilter.isDefault,
      trainServices: isDefault,
    },
    values: {
      ...currFilter.values,
      trainServices: payload.values,
    },
  };

  if (isForward) {
    stateFilter[0] = currFilter;
  } else {
    stateFilter[1] = currFilter;
  }

  return {
    ...state,
    filter: stateFilter,
  };
});

trainSearch.on(setCarrierTrainFilter, (state, payload) => {
  const isForward = payload.type === 'forward';
  const stateFilter = [...state.filter];
  let currFilter: TrainFilter = isForward
    ? { ...stateFilter[0] }
    : { ...stateFilter[1] };
  const isDefault =
    Object.entries(payload.values).find(([_, val]) => !val) === undefined;

  currFilter = {
    ...currFilter,
    isDefault: {
      ...currFilter.isDefault,
      carriers: isDefault,
    },
    values: {
      ...currFilter.values,
      carriers: payload.values,
    },
  };

  if (isForward) {
    stateFilter[0] = currFilter;
  } else {
    stateFilter[1] = currFilter;
  }

  return {
    ...state,
    filter: stateFilter,
  };
});

trainSearch.on(setTrainDiscountsTrainFilter, (state, payload) => {
  const isForward = payload.type === 'forward';
  const stateFilter = [...state.filter];
  let currFilter: TrainFilter = isForward
    ? { ...stateFilter[0] }
    : { ...stateFilter[1] };
  const isDefault =
    Object.entries(payload.values).find(([_, val]) => !val) === undefined;

  currFilter = {
    ...currFilter,
    isDefault: {
      ...currFilter.isDefault,
      discounts: isDefault,
    },
    values: {
      ...currFilter.values,
      discounts: payload.values,
    },
  };

  if (isForward) {
    stateFilter[0] = currFilter;
  } else {
    stateFilter[1] = currFilter;
  }

  return {
    ...state,
    filter: stateFilter,
  };
});

/** search steps */
export const setStep = createAction<{
  step: keyof typeof TRAIN_SEARCH_STEPS;
  needClear: boolean;
}>('@trainTickets/setStep');

/** car forward */
export const selectCar = createAction<number>('@trainTickets/selectCar');
export const updateTrainForwardCurrentCarCompartment = createAction<
  FreePlacesByCompartment[]
>('@trainTickets/updateTrainForwardCurrentCarPlaces');
export const setCarInfo = createAction<Car>('@trainTickets/setCarInfo');
const car = createReducer({}, null as unknown as Car);
const carIndex = createReducer({}, null as unknown as number);
carIndex.on(selectCar, (_, payload) => payload);
carIndex.on(carsPurify, (state, payload) => null as any);
car.on(carsPurify, (state, payload) => null as any);
car.on(setCarInfo, (_, payload) => payload);
car.on(
  updateTrainForwardCurrentCarCompartment,
  (state, freePlacesByCompartment) => {
    return {
      ...state,
      freePlacesByCompartments: freePlacesByCompartment,
    };
  }
);

/** car backward */
export const selectBackCar = createAction<number>(
  '@trainTickets/selectBackCar'
);
export const setBackCarInfo = createAction<Car>('@trainTickets/setBackCarInfo');
const carBack = createReducer({}, null as unknown as Car);
const carIndexBack = createReducer({}, null as unknown as number);
carIndexBack.on(selectBackCar, (_, payload) => payload);
carBack.on(setBackCarInfo, (_, payload) => payload);

/** chose car place */
export const setChosenCarPlace = createAction<CarPlace[]>(
  '@trainTickets/setChosenCarPlace'
);
export const setChosenCarPlaceBackward = createAction<CarPlace[]>(
  '@trainTickets/setChosenCarPlaceBackward'
);
export const clearChosenCarPlace = createAction(
  '@trainTickets/clearChosenCarPlace'
);
export const clearChosenCarPlaceBackward = createAction(
  '@trainTickets/clearChosenCarPlaceBackward'
);
const chosenCarPlacesForward = createReducer({}, [] as unknown as CarPlace[]);
const chosenCarPlacesBackward = createReducer({}, [] as unknown as CarPlace[]);
chosenCarPlacesForward.on(setChosenCarPlace, (_, payload) => payload);
chosenCarPlacesForward.on(clearChosenCarPlace, (state, payload) => []);
chosenCarPlacesBackward.on(setChosenCarPlaceBackward, (_, payload) => payload);
chosenCarPlacesBackward.on(clearChosenCarPlaceBackward, (state, payload) => []);

export const setGenderTypeOnCarPlace =
  createAction<SetGenderTypeOnCarPlacePayload>(
    '@trainTickets/setGenderTypeOnCarPlace'
  );

export const clearGenderTypesOnCarPlaces =
  createAction<ClearGenderTypesOnCarPlacesPayload>(
    '@trainTickets/clearGenderTypesOnCarPlaces'
  );

/** train route */
const trainRoute = createReducer({}, null as unknown as GetTrainRouteDto);
export const getTrainRoute = createAction<GetTrainRoutePayload>(
  '@trainTickets/getTrainRoute'
);
export const setTrainRoute = createAction<GetTrainRouteDto>(
  '@trainTickets/setTrainRoute'
);
export const clearTrainRoute = createAction('@trainTickets/clearTrainRoute');
trainRoute.on(setTrainRoute, (_, payload) => payload);
trainRoute.on(
  clearTrainRoute,
  (_, payload) => null as unknown as GetTrainRouteDto
);

const currentCarForward = combineReducers({
  data: car,
  index: carIndex,
});

const currentCarBackward = combineReducers({
  data: carBack,
  index: carIndexBack,
});

const trainForward = combineReducers({
  cars: carsForward,
  chosenCarPlaces: chosenCarPlacesForward,
  currentCar: currentCarForward,
  index: trainIndexForward,
  train: trainForwardDir,
  bedClothes: bedClothesForward,
});

const trainBackward = combineReducers({
  cars: carsBackward,
  chosenCarPlaces: chosenCarPlacesBackward,
  currentCar: currentCarBackward,
  index: trainIndexBackward,
  train: trainBackwardDir,
  bedClothes: bedClothesBackward,
});

export default combineReducers({
  list: list,
  filteredList: filteredList,
  trainBack: trainBackward,
  trainForward: trainForward,
  trainSearch,
  trainRoute,
});

import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import { useMediaQuery } from 'react-responsive';

import TripDetails from './components/TripDetails';
import { transformDataFromBusToBusForRouteDetail } from './controller';
import YourTrip from './components/YourTrip';

import { Props } from './interfaces';
import { Container, Heading, Left, Right, Wrapper } from './styles';
import PaymentIframe from '@components/common/PaymentIframe';
import NewPassangersBlock from '@components/order/Detail/NewPassangersBlock';
import { useSelector } from 'react-redux';
import { getAddictionalServices, getDetailOrder } from '@modules/orders';
import { AddictionalServicesProvider } from '@components/booking';
import AddictionalServicesMobileNew from '@components/booking/MobileBooking/AddictionalServicesMobileNew';
import { Insurances } from '@components/order/Detail';
import { ApplicationState } from '@app/modules';
import styled from 'styled-components';
import { busBookingPlacesSelector } from '@modules/busBooking';

const MobileHeading = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #737373;
  margin-top: 40px;

  margin-left: 5px;

  @media (min-width: 767px) {
    display: none;
  }
`;

const BookingSecondStep = ({ selectedBuses }: Props): ReactElement | null => {
  if (selectedBuses === null) return null;

  const passengerNames: Record<string, string> = {
    Adult: 'Взрослый',
    Child: 'Ребенок',
    BabyWithoutPlace: 'Младенец',
  };
  const ageInfo: string[] = selectedBuses![0].passengersInfo!.flatMap((item) =>
    Array(item.count).fill(passengerNames[item.passengerType]),
  );
  const stickyContainer = useRef<HTMLDivElement | null>(null);
  const isMobileReg = useMediaQuery({ maxWidth: 767 });
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const [chosenPaymentMethod, setChosenPaymentMethod] =
    useState('Российская карта');
  const [forward, backward] =
    transformDataFromBusToBusForRouteDetail(selectedBuses);
  const places = useSelector(busBookingPlacesSelector);
  const availableInsuransesState = useSelector(
    (x: ApplicationState) => x.orders.orderDetail.availableInsurances,
  );

  const addictionalServices = useSelector(getAddictionalServices);
  const { busOrderPositions, insurances, salesChannel } =
    useSelector(getDetailOrder);
  const [
    {
      orderPosition: { passengers },
    },
  ] = busOrderPositions;

  const getUniqueItems = (addictionalServices: any): any => {
    const uniqueIds = new Set(
      addictionalServices.items.map((item: any) => item.id),
    );

    const itemsWithUniqueIds = [...uniqueIds]
      .map((id) =>
        addictionalServices.items.find((item: any) => item.id === id),
      )
      .filter(Boolean);

    return itemsWithUniqueIds;
  };

  const handleScroll = (): void => {
    if (isMobileReg) {
      setIsSticky(false);
      return;
    }

    if (!stickyContainer.current) return;

    const stickyTop = stickyContainer.current?.getBoundingClientRect().top;
    setIsSticky(stickyTop < 20);
  };

  const onInit = (): (() => void) => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  };

  useEffect(onInit, []);

  return (
    <Formik enableReinitialize initialValues={{}} onSubmit={() => {}}>
      {() => {
        return (
          <Form autoComplete={'off'}>
            <AddictionalServicesProvider>
              <Wrapper>
                <Heading>Бронирование</Heading>
                <Container>
                  <Left>
                    <TripDetails
                      isSecondStep={true}
                      forward={forward}
                      backward={backward}
                      places={places || {}}
                    />
                    {isMobileReg && (
                      <YourTrip
                        forward={forward}
                        backward={backward}
                        ageInfo={ageInfo}
                      />
                    )}
                    <NewPassangersBlock
                      isSecondStep={true}
                      // TODO: написать адаптер
                      // TODO: TTE-9176 отрефакторить типы
                      /* @ts-ignore */
                      items={passengers}
                      itemWrapper={<div />}
                    />
                    <AddictionalServicesMobileNew
                      items={getUniqueItems(addictionalServices)}
                      showExtraServices={true}
                    />
                    {(insurances.length > 0 ||
                      (availableInsuransesState &&
                        availableInsuransesState.items.length > 0)) &&
                      salesChannel !== 'Самотур (GDS тур)' && (
                        <>
                          <MobileHeading style={{ marginLeft: 0 }}>
                            Позаботьтесь о себе
                          </MobileHeading>

                          <Insurances items={insurances} isBooking={true} />
                        </>
                      )}
                    <PaymentIframe
                      chosenPaymentMethod={chosenPaymentMethod}
                      setChosenPaymentMethod={setChosenPaymentMethod}
                    />
                  </Left>
                  <Right ref={stickyContainer} isSticky={isSticky}>
                    {!isMobileReg && (
                      <YourTrip
                        forward={forward}
                        backward={backward}
                        ageInfo={ageInfo}
                      />
                    )}
                  </Right>
                </Container>
              </Wrapper>
            </AddictionalServicesProvider>
          </Form>
        );
      }}
    </Formik>
  );
};

export default BookingSecondStep;

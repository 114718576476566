import React from 'react';

import { useFormikContext, FormikProps } from 'formik';
// import * as _ from 'lodash';
// import { Helper } from '@utils';

interface Props {
  initialValues: unknown;
  event: {
    category: string;
    action: string;
    label?: string;
  };
}

const FormikGaErrorsHandler: React.FC<Props> = ({ initialValues, event }) => {
  const { errors, submitCount } =
    useFormikContext<FormikProps<typeof initialValues>>();

  React.useEffect(() => {
    // if (submitCount > 0 && !_.isEmpty(errors)) {
    //   ReactGA.event({
    //     ...event,
    //     label: Helper.createErrorLabel(errors),
    //   });
    // }
  }, [submitCount, errors]);

  return null;
};

export default FormikGaErrorsHandler;

import React from 'react';
import styled from 'styled-components';
import { InfoBlock } from '@components/common';
import { Text } from '@components/ui';
import SmsService from './SmsService';
import ReturnService from './ReturnService';
import PrintService from './PrintService';
import AviaServices from './AviaServices';
import { Enums, ItemProps, HandleClick } from './types';
import {
  addictionalServicesSelector,
  switchAddictionalServiceState,
  getBookingAncillaryServicesState,
  getSelectedFare,
  getSeatMapState,
  findEmptyPlace,
  setActiveSegment,
} from '@modules/booking';
import { useSelector, useDispatch } from 'react-redux';
import {
  ServiceRulesModal as Modal,
  SeatMapNotificationModal,
} from '@components/common/Modals';
import { useModalState } from './context';
import SeatMapService, { SeatMapModal } from './SeatMapService';
import SeatMapDetailModal from './SeatMapService/SeatMapDetailModal';

const Wrapper = styled(InfoBlock)`
  @media (max-width: 767px) {
    border: none;
    box-shadow: none;
    padding: 0;
    margin-top: 5px;
    width: 95%;
  }
`;

const Title = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: block;
  margin-bottom: 15px;
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
  }
`;

const SubTitle = styled(Text)`
  font-size: 14px;
  line-height: 18px;
  display: block;
  margin-bottom: 25px;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 20px;
  }
`;

const List = styled.div`
  & > div {
    margin-bottom: 20px;
  }
  & > :last-child {
    margin-bottom: 0;
  }
`;

export default function AddictionalServices() {
  const items = useSelector(addictionalServicesSelector);
  const services = useSelector(getBookingAncillaryServicesState);
  const seatMap = useSelector(getSeatMapState);
  const fare = useSelector(getSelectedFare);
  const dispatch = useDispatch();
  const { visibility, hideModal, showModal } = useModalState();
  const [notification, setNotification] = React.useState(false);

  const onClick: HandleClick = (index, checked) => {
    // ReactGA.event({
    //   category: 'Booking',
    //   action: checked
    //     ? 'booking_select_addictional'
    //     : 'booking_deselect_addictional',
    //   label: items[index].type,
    // });

    dispatch(
      switchAddictionalServiceState({
        index,
        value: checked,
      })
    );
  };
  const segmentsList = fare.seatMap.map((x) => x.flightNo);

  // const meals = Object.keys(services.meal);
  // const luggage = Object.keys(services.luggage);

  return !!services.length || !!items.length ? (
    <Wrapper>
      <Title>Дополнительные услуги</Title>
      <SubTitle>
        Сделайте путешествие более комфортным с помощью дополнительных услуг.
      </SubTitle>
      <List>
        {items.map((x, index) => (
          <React.Fragment key={x.id}>
            {getTemplate({ ...x, index }, onClick)}
          </React.Fragment>
        ))}
        <AviaServices />
        {fare.seatMap.length > 0 && <SeatMapService />}
      </List>
      <Modal
        open={
          visibility !== null &&
          visibility !== Enums.SeatMap &&
          visibility !== Enums.SeatMapDetails &&
          visibility !== Enums.Ancillary
        }
        onClose={hideModal}
        type={visibility}
      />
      {/* <LuggageServiceModal open /> */}
      {seatMap.available && (
        <>
          <SeatMapModal
            open={visibility === Enums.SeatMap}
            onClose={() => {
              if (
                seatMap.availableSeatCount > 0 &&
                seatMap.selectedSeatCount > 0 &&
                visibility !== Enums.SeatMapDetails &&
                visibility !== Enums.Ancillary &&
                visibility !== Enums.AncillaryDetails
              ) {
                setNotification(true);
              }
              if (visibility !== Enums.SeatMapDetails) {
                hideModal();
                dispatch(setActiveSegment(segmentsList[0]));
              }
            }}
            fare={fare}
          />
          <SeatMapDetailModal
            open={visibility === Enums.SeatMapDetails}
            onClose={() => {
              if (visibility !== Enums.SeatMap) {
                setNotification(false);

                hideModal();
              }
            }}
            fare={fare}
          />
          <SeatMapNotificationModal
            data={seatMap.segmentsAccessibility}
            title="Выбраны не все места"
            subtitle="Не забудьте выбрать ваши места на перелетах"
            buttonsText={['Выбрать места', 'Закончить выбор мест']}
            onConfirm={() => {
              setNotification(false);
              dispatch(findEmptyPlace());
              showModal(Enums.SeatMap);
            }}
            onClose={() => {
              setNotification(false);

              dispatch(setActiveSegment(segmentsList[0]));
            }}
            open={notification}
          />
        </>
      )}
    </Wrapper>
  ) : null;
}

function getTemplate(x: Omit<ItemProps, 'onClick'>, cb: HandleClick) {
  switch (x.type) {
    case Enums.SMS_Service:
      return <SmsService {...x} onClick={cb} />;
    case Enums.Return_Guarantee:
      return <ReturnService {...x} onClick={cb} />;
    case Enums.Payment_Receipt:
      return <PrintService {...x} onClick={cb} />;
    default:
      return null;
  }
}

import React from 'react';
import styled from 'styled-components';
import { FlightProps } from './types';
import Segment from './Segment';
import Text from '@components/ui/Typography';

const Body = styled.div`
  & > div {
    margin-bottom: 30px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
  }
  & > :last-child {
    border-bottom: 0;
  }
`;
const Header = styled(Text)`
  display: block;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #3c3c3c;

  & > strong {
    color: ${({ theme }) => theme.colors.main};
  }
  margin-bottom: 20px;
`;

const Flight: React.FC<FlightProps> = ({ segments, className, ...props }) => {
  return (
    <>
      <Header fontWeight="600" size={18} color="dark-gray">
        Детали маршрута:{' '}
        <strong>
          {props.departureCity?.name} — {props.arrivalCity?.name}
        </strong>
      </Header>
      <Body>
        {segments.map((segment, key) => (
          <Segment key={key} {...segment} />
        ))}
      </Body>
    </>
  );
};

export default Flight;

import styled, { keyframes } from 'styled-components';
import React, { useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { ReactComponent as TuiLogo } from '@assets/media/shared/logo.svg';
import { ReactComponent as TuiRailwayLogo } from '@assets/media/train-tickets/train-face-icon.svg';
import { ReactComponent as SeatMapIcon } from '@assets/media/addictional-services/seat-map-loader.svg';
import { ReactComponent as CurveIcon } from '@assets/media/loader/curve.svg';
import { ReactComponent as BookingLogo } from '@assets/media/shared/bookingLogo.svg';

import { useSelector } from 'react-redux';
import { ApplicationState } from '@modules/index';

const className = 'tui-loader';

const heartBeat = keyframes`
    0% {
        stroke-dashoffset: -61px;

    }
    50% {
        stroke-dashoffset: -122px;
    }
    100% {
        stroke-dashoffset: -61px;
    }
`;

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const Round = styled.svg`
  stroke-dasharray: 122px;
  stroke-dashoffset: -61px;
  animation: ${heartBeat} 1.4s linear reverse infinite,
    ${spin} 0.7s linear infinite;
  width: 100px;
  height: 100px;
  transform: rotate(180deg);
  stroke: ${({ theme: { colors } }) => colors.main};
`;

const Overlay = styled.div<{ color: string }>`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  left: 0;
  background-color: ${({ color }) => color};
  transition: opacity 200ms;
`;

const RelativeDiv = styled.div`
  position: relative;
`;

const RoundWrapper = styled.div`
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  left: 0;
  z-index: 1000;
`;

const AbsolutePosition = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  text-align: center;
`;

const TuiLoaderWrapper = styled(AbsolutePosition)`
  width: 80px;
  height: 80px;
`;

const SeatMapLoaderWrapper = styled.div`
  position: absolute;
  left: 40%;
  top: 450px;
  width: 80px;
  height: 80px;

  @media (max-width: 767px) {
    top: 250px;
  }
`;

const Circle = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  position: relative;
  animation: ${spin} 1s ease-in-out infinite;
`;

const TuiLogoStyled = styled(TuiLogo)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const BookingLogoStyled = styled(BookingLogo)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const TuiRailwayLogoStyled = styled(TuiRailwayLogo)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const SeatMapIconStyled = styled(SeatMapIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Wrapper2 = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  left: 0;
  z-index: 1000;

  @media (max-width: 1023px) {
    width: 100%;
    z-index: 9999;
    position: fixed;
  }
`;

const Curve = styled(CurveIcon)`
  position: absolute;
  top: 0;
  right: 0;
`;

const Text = styled.div`
  width: 190px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
  color: #3c3c3c;
  position: relative;
  top: 15px;
  right: 50px;
`;

const Loader: React.FC = () => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);
  return (
    <CSSTransition classNames={className} timeout={200}>
      {(state) => (
        <Wrapper>
          <Overlay color="#cccbcbad" />
          <RoundIcon />
        </Wrapper>
      )}
    </CSSTransition>
  );
};

export const RoundIcon = () => (
  <RoundWrapper>
    <Round>
      <circle
        id="c"
        fill="none"
        strokeWidth="4"
        strokeLinecap="round"
        cx="50"
        cy="50"
        r="20"
      />
    </Round>
  </RoundWrapper>
);

export const TuiIcon = () => (
  <>
    <TuiLoaderWrapper>
      <Circle>
        <Curve />
      </Circle>
      <TuiLogoStyled />
    </TuiLoaderWrapper>
  </>
);

export const BookingIcon = () => (
  <>
    <TuiLoaderWrapper>
      <Circle>
        <Curve />
      </Circle>
      <BookingLogoStyled />
      <Text>Бронируем авиабилеты</Text>
    </TuiLoaderWrapper>
  </>
);

export const RailwayIcon = () => (
  <RelativeDiv>
    <Circle>
      <Curve />
    </Circle>
    <TuiRailwayLogoStyled />
  </RelativeDiv>
);

export const SeatMapLoader = () => (
  <SeatMapLoaderWrapper>
    <Circle>
      <Curve />
    </Circle>
    <SeatMapIconStyled />
  </SeatMapLoaderWrapper>
);

export const SeatMapLoaderWithWrapper: React.FC = () => {
  return (
    <Wrapper2>
      <Overlay color="#ffffff" />
      <SeatMapLoader />
    </Wrapper2>
  );
};

export const TuiLoader: React.FC = () => {
  const booking = useSelector((state: ApplicationState) => state.booking);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);
  return (
    <>
      {!booking.ticket.bookingLoader ? (
        <CSSTransition classNames={className} timeout={200}>
          {(state) => (
            <Wrapper>
              <Overlay color="#ffffff" />
              <TuiIcon />
            </Wrapper>
          )}
        </CSSTransition>
      ) : (
        <CSSTransition classNames={className} timeout={200}>
          {(state) => (
            <Wrapper>
              <Overlay color="#ffffff" />
              <BookingIcon />
            </Wrapper>
          )}
        </CSSTransition>
      )}
    </>
  );
};

export default Loader;

import React from 'react';
import styled from 'styled-components';
import navigationArrow from '@assets/media/booking/navigationArrow.svg';
import fromToArrowSingle from '@assets/media/booking/fromToArrowSingle.svg';

import fromToArrow from '@assets/media/booking/fromToArrow.svg';
import { useSelector } from 'react-redux';
import { Hooks } from '@utils';
import TimeLimitLeft from '../TimeLimitLeft';
import { getUserDataState } from '@modules/user';

interface LinkProps {
  isActive: boolean;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 767px) {
    margin-left: 0;
    margin-bottom: 20px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 13px;
  @media (min-width: 767px) {
    margin-top: 10px;
    margin-bottom: 15px;
  }
`;

const NavigationElement = styled.div<LinkProps>`
  margin-right: 7px;
  color: ${({ isActive }) => (isActive ? '#3C3C3C' : '#c4c4c4')};
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: ${({ isActive }) => (isActive ? 'underline' : 'none')};
  text-decoration-color: #4872f2;
  text-underline-offset: 10px;
  @media (min-width: 767px) {
    font-size: 14px;
    line-height: 18px;
    text-decoration-thickness: 3px;
    text-underline-offset: 15px;
    margin-right: 15px;
  }
`;

const NavigationArrow = styled.img.attrs({ src: navigationArrow })`
  margin-right: 7px;
  @media (min-width: 767px) {
    margin-right: 15px;
  }
`;

const FromToArrow = styled.img.attrs({ src: fromToArrow })`
  margin: 0 4px;
`;

const FromToArrowSingle = styled.img.attrs({ src: fromToArrowSingle })`
  margin-right: 5px;
  margin-left: 5px;
`;

const FlightDestination = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #3c3c3c;
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  margin-top: 5px;
`;

const FlightDescription = styled.div`
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #3c3c3c;
  margin-bottom: 10px;
`;

interface Props {
  chosenScreen?: number;
  from?: string;
  to?: string;
  passengers?: number;
  fare?: string | null;
  isTwoWay?: boolean;
}

export const Navigation: React.FC<Props> = ({
  chosenScreen,
  from,
  to,
  passengers,
  fare,
  isTwoWay,
}) => {
  const mobileNavigation = [
    { name: 'Тариф и пассажиры', number: 1 },
    {
      name: 'Услуги и оплата',
      number: 3,
    },
  ];
  const { isMobile } = Hooks.useMediaSizes();
  const userData = useSelector(getUserDataState);

  function declOfNum(number?: number, titles?: string[]) {
    const cases = [2, 0, 1, 1, 1, 2];
    return (
      titles &&
      number &&
      titles[
        number % 100 > 4 && number % 100 < 20
          ? 2
          : cases[number % 10 < 5 ? number % 10 : 5]
      ]
    );
  }

  const isBookingSecondPage =
    window.location.pathname.includes('/bookingDetails');

  const displayFare = fare ? fare : '';

  return (
    <Wrapper>
      <Row>
        {mobileNavigation.map((x, i) => (
          <Row key={i}>
            <NavigationElement isActive={x.number === chosenScreen}>
              {x?.name}
            </NavigationElement>
            {i !== 1 && <NavigationArrow />}
          </Row>
        ))}
      </Row>
      {userData?.agentId && isMobile && !isBookingSecondPage && (
        <div style={{ marginBottom: 15 }}>
          <TimeLimitLeft />
        </div>
      )}

      {isMobile && (
        <>
          <FlightDestination>
            {from} {isTwoWay ? <FromToArrow /> : <FromToArrowSingle />} {to}
          </FlightDestination>

          <FlightDescription>
            {
              //@ts-ignore
              passengers +
                //@ts-ignore
                declOfNum(passengers, [
                  ' пассажир ',
                  ' пассажира ',
                  ' пассажиров ',
                ]) +
                displayFare
            }
          </FlightDescription>
        </>
      )}
    </Wrapper>
  );
};

export default Navigation;

import React, { useCallback, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { Text, Button } from '@components/ui';

import Popup from 'reactjs-popup';
import { useHistory } from 'react-router-dom';
import { urlNew } from '@modules/booking/utils';

const StyledPopup = styled(Popup)`
  &-content {
    width: auto;
    border: 1px solid #d7d7d7;
    padding: 0;
    border-radius: 8px;
  }
`;

const Comment = styled.div`
  display: flex;
  justify-content: center;
  & > span {
    text-align: center;
  }
  @media (max-width: 767px) {
    justify-content: flex-start;
    & > span {
      text-align: left;
    }
  }
`;

const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  &>: first-child {
    margin-right: 14px;
  }
  ${Text} {
    font-size: 24px;
    line-height: 28px;
  }

  @media (max-width: 767px) {
    justify-content: space-between;
    ${Text} {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    }
  }
`;

const ButtonBlock = styled.div`
  display: flex;
  justify-content: center;
  & > button {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding: 8px 20px;
  }
  & > :first-child {
    margin-right: 20px;
  }
  @media (max-width: 767px) {
    & > :first-child {
      margin-right: 0;
      width: 100%;
    }
  }
`;

const Wrapper = styled.div`
  width: 569px;
  min-height: 261px;
  padding: 40px 10px;
  box-sizing: border-box;
  border-radius: 8px;

  & > ${Comment} {
    margin-top: 25px;
  }

  & > ${ButtonBlock} {
    margin-top: 44px;
  }

  @media (max-width: 767px) {
    max-width: 335px;
    padding: 20px 14px;
    min-height: auto;
    & > ${Comment} {
      margin-top: 20px;
    }

    & > ${ButtonBlock} {
      margin-top: 30px;
    }
  }
`;
//TODO add types
interface Props {
  isShowWarning: boolean;
  setIsShowWarning: Dispatch<SetStateAction<boolean>>;
  warnings: any;
  searchRequest: any;
}

const WarningNotification: React.FC<Props> = ({
  isShowWarning,
  setIsShowWarning,
  warnings,
  searchRequest,
}) => {
  const history = useHistory();

  const handleClose = useCallback(() => {
    setIsShowWarning(false);
  }, []);

  const handleButtonClick = useCallback(() => {
    handleClose();
    history.push(`/search/${urlNew(searchRequest)}`);
  }, [history]);

  return (
    <StyledPopup defaultOpen onClose={handleClose}>
      <Wrapper>
        <Head>
          <Text bold>
            {warnings.find(
              (x: any) => x.code === 'checkavailability_price_changed'
            ) && 'Закончились места по тарифу'}
          </Text>
          {/* <StyledIcon onClick={handleClose} />
          <MobileIcon onClick={handleClose} /> */}
        </Head>
        <Comment>
          {warnings.find(
            (x: any) => x.code === 'checkavailability_price_changed'
          ) && (
            <Text size="big">
              К сожалению, места по выбранному тарифу закончились. <br />{' '}
              <span style={{ fontWeight: 'bold' }}>
                {' '}
                Стоимость перелета по новому тарифу:{' '}
              </span>
              <span style={{ color: '#4872F2', fontWeight: 'bold' }}>
                {
                  warnings.find(
                    (x: any) => x.code === 'checkavailability_price_changed'
                  ).additionalInfo.newPrice
                }{' '}
                ₽
              </span>
            </Text>
          )}
        </Comment>
        <ButtonBlock>
          <Button type="primary" onClick={handleClose}>
            Продолжить бронирование
          </Button>
          <Button type="primary" onClick={handleButtonClick}>
            Новый поиск
          </Button>
        </ButtonBlock>
      </Wrapper>
    </StyledPopup>
  );
};

export default WarningNotification;

import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Text } from '@components/ui';
import packageJson from '../../../package.json';
import { ReactComponent as ArrowTopIcon } from '@assets/media/footer/arrow-top.svg';
import { ReactComponent as RoundedArrowIcon } from '@assets/media/footer/rounded-arrow.svg';
import { ReactComponent as OrderHelpMiniIcon } from '@assets/media/main-page/order-help-mini.svg';
import { ReactComponent as DzenIcon } from '@assets/media/footer/icon-dzen.svg';
import { ReactComponent as TelegramIcon } from '@assets/media/footer/icon-telegram.svg';
import { ReactComponent as VkIcon } from '@assets/media/footer/icon-vk.svg';
import { ReactComponent as YoutubeIcon } from '@assets/media/footer/icon-youtube.svg';
import { useSelector } from 'react-redux';
import AppealWithoutOrderForm from '@components/common/FormOfAppeal/AppealWithoutOrderForm';
import { ApplicationState } from '@modules/index';

const Wrapper = styled.footer`
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  box-sizing: border-box;
    
  @media print {
    display: none;
  }
`;

const Container = styled.div`
  @media (max-width: 1170px) {
    padding: 0 20px;
  }
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e7e7e7;
  margin: 40px 0;

  @media (max-width: 767px) {
    margin: 20px 0;
  }
`;

const LastSeparator = styled(Separator)`
  @media (max-width: 767px) {
    background: transparent;
    margin: 0;
    height: 60px;
  }
`;

const MobileSeparator = styled(Separator)`
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
`;

const BottomText = styled(Text)`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #737373;
  display: block;
  margin-bottom: 8px;

  @media (max-width: 767px) {
    line-height: 18px;
    margin-bottom: 15px;
  }
`;

const BottomSmallText = styled(Text)`
  font-size: 10px;
  line-height: 12px;
  color: #737373;
`;

const GoTopButton = styled.button`
  outline: none;
  border: none;
  width: 40px;
  height: 40px;
  background: #3c3c3c;
  margin-top: 40px;
  border-radius: 50% 50% 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 7px auto;
  cursor: pointer;

  & > svg {
    pointer-events: none;
  }

  @media (max-width: 767px) {
    width: 35.5px;
    height: 36.5px;
    margin: 0 auto;
  }
`;

const Flex = styled.div`
  display: flex;

  & > div {
    margin-right: 153px;
  }

  & > :last-child {
    margin-right: 0;
  }

  @media (max-width: 1170px) {
    & > div {
      margin-right: 55px;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;

    & > :nth-child(1) {
      order: 5;
    }

    & > :nth-child(2) {
      order: 2;
    }

    & > :nth-child(3) {
      order: 1;
    }

    & > :nth-child(4) {
      order: 4;
    }

    & > :nth-child(5) {
      order: 3;
    }

    & > div {
      margin-right: 0;
    }
  }
`;

const Title = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #3c3c3c;
`;

const List = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #737373;

  a,
  span {
    text-decoration: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: #737373;

    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  a:hover {
    color: #3965ee;
    text-decoration: underline;
    text-underline-offset: 2px;
  }

  & > * {
    display: block;
    margin-bottom: 12px;
    text-decoration: none;
    color: #737373;

    @media (max-width: 767px) {
      margin-bottom: 14px;
    }
  }

  & > :last-child {
    display: block;
    margin-bottom: 0;
  }
`;

const FunAndSunLink = styled.a.attrs({ href: 'https://fstravel.com/' })`
  position: relative;

  & > svg {
    position: absolute;
    top: 2px;
    right: -21px;
  }
`;

const Block = styled.div`
  ${Title} {
    display: block;
    margin-bottom: 16px;
  }
`;

const SocialMedia = styled.div`
  display: flex;
  align-items: center;

  & > a {
    margin-right: 10px;
  }
`;

const OrderHelp = styled.div`
  display: inline-flex;
  align-items: center;
  margin-top: 16px;
  padding: 7px 23px;
  background: #edf1fe;
  border-radius: 8px;
  text-decoration: none;
  width: 239px;

  & > :first-child {
    margin-right: 7px;
  }

  &:hover {
    text-decoration: none !important;
  }
`;

const SocialLink = styled.a.attrs({ target: '_blank' })`
  display: inline-block;
  cursor: pointer;

  & > svg {
    transition: transform 0.3s;
    pointer-events: none;

    path {
      transition: fill 0.3s;
    }
  }

  &:hover {
    .vk {
      path {
        fill: rgb(0, 119, 255);
      }
    }

    .youtube {
      path {
        fill: rgb(255, 0, 0);
      }
    }

    .telegram {
      path {
        fill: #0088cc;
      }
    }

    .dzen {
      circle {
        fill: black;
      }
    }

    & > svg {
      transform: scale(1.3);
    }
  }
`;

const Footer = () => {
  const user = useSelector((x: ApplicationState) => x.user);
  const searchPanelType = useSelector(
    (state: ApplicationState) => state.ui.searchPanelType
  );
  const isAvia = searchPanelType === 'avia';
  const isTrain = searchPanelType === 'train';
  const [isCreateAppealOpen, setIsCreateAppealOpen] = React.useState(false);

  const AviaItems = () => {
    return (
      <List>
        <NavLink to="/questions/how-to-checkout">Способы оплаты</NavLink>
        <NavLink to="/questions/how-to-book-ticket">
          Как забронировать билет
        </NavLink>
        <NavLink to="/questions/how-to-exchange-or-refund-order">
          Обмен и возврат билета
        </NavLink>
        <NavLink to="/info/orderHelp">Помощь по заказу</NavLink>

        <NavLink to="/questions">Часто задаваемые вопросы</NavLink>
        <NavLink to="/info/termsOfUse">Соглашение пользователя</NavLink>
        <NavLink to="/info/agentsCooperation">Сотрудничество агентам</NavLink>
        <NavLink to="/info/contacts">Контакты</NavLink>
      </List>
    );
  };

  const TrainItems = () => {
    return (
      <List>
        <NavLink to="/questions-train/payment-methods">Оплата заказа</NavLink>
        <NavLink to="/questions-train/how-to-book-ticket-train">Как забронировать билет</NavLink>
        <NavLink to="/questions-train/">
          Обмен и возврат билета
        </NavLink>
        <NavLink to="/info/termsOfUse">Соглашение пользователя</NavLink>
        <NavLink to="/info/contacts">Контакты авиаотдела</NavLink>
        <NavLink to="/info/agentsCooperation">Сотрудничество агентам</NavLink>
      </List>
    );
  };

  return (
    <Wrapper>
      {isCreateAppealOpen && (
        <AppealWithoutOrderForm
          isCreateAppealOpen={isCreateAppealOpen}
          setIsCreateAppealOpen={setIsCreateAppealOpen}
        />
      )}
      <Separator />
      <Container>
        <Flex>
          {!user?.data?.agentId ? (
            <Block>
              <Title>Клиентам</Title>
              {isAvia && <AviaItems />}
              {isTrain && <TrainItems />}
            </Block>
          ) : (
            <Block>
              <Title>Агентам</Title>
              <List>
                <NavLink to="/info/paymentAgent">Оплата заказа</NavLink>
                <NavLink to="/info/bookingAgent">
                  Как забронировать билет
                </NavLink>
                <NavLink to="/info/refundAndExchangeAgent">
                  Обмен и возврат билета
                </NavLink>
                <NavLink to="/info/moneyManagmentAgent">
                  Управление доходом
                </NavLink>
                <NavLink to="/info/orderHelp">Помощь по заказу</NavLink>
                <NavLink to="/info/termsOfUse">Соглашение пользователя</NavLink>
                <NavLink to="/info/contacts">Контакты авиаотдела</NavLink>
                <NavLink to="/info/agentsCooperation">
                  Сотрудничество агентам
                </NavLink>
              </List>
            </Block>
          )}

          <MobileSeparator />
          <Block>
            <Title>Обратная связь</Title>
            <List>
              <span>
                E-mail:{' '}
                <a href="mailto:aviation@fstravel.com">aviation@fstravel.com</a>
              </span>
              <span>
                Ежедневная онлайн-поддержка:
                <br />
                <OrderHelp onClick={() => setIsCreateAppealOpen(true)}>
                  <OrderHelpMiniIcon style={{ width: 17 }} />{' '}
                  <span style={{ color: '#3C3C3C', fontSize: 13 }}>
                    Обращение в службу поддержки
                  </span>
                </OrderHelp>
              </span>
            </List>
          </Block>
          <MobileSeparator />
          <Block>
            <Title>Давайте дружить</Title>
            <List>
              <span>
                Перейти на сайт туроператора{' '}
                <FunAndSunLink>
                  FUN&SUN <RoundedArrowIcon />
                </FunAndSunLink>
              </span>
              <SocialMedia>
                <SocialLink href="https://vk.com/funsun_travel">
                  <VkIcon className="vk" />
                </SocialLink>
                <SocialLink href="https://t.me/funsuntoday">
                  <TelegramIcon className="telegram" />
                </SocialLink>
                <SocialLink href="https://www.youtube.com/channel/UCzhyD3w6McLE_JyCkFAG-Ow">
                  <YoutubeIcon className="youtube" />
                </SocialLink>
                <SocialLink href="https://dzen.ru/fstravel">
                  <DzenIcon className="dzen" />
                </SocialLink>
              </SocialMedia>
            </List>
          </Block>
        </Flex>
        <LastSeparator />
        <BottomText>
          Представленная на сайте информация носит справочный характер и не
          является публичной офертой.FUN&SUN — ведущий европейский туроператор в
          России и СНГ. © 2019, FUN&SUN
        </BottomText>
        {searchPanelType === 'train' && (
          <BottomText>
            Ж/д билеты предоставляются партнерами с использованием веб-систем
            ООО «Инновационная мобильность», ACR. Стоимость билетов указана с
            учетом сервисного сбора. Итоговая стоимость отображена на экране
            подтверждения покупки. Содержимое сайта является объектом авторских
            прав. ©2024 ООО "ТТ-Трэвел". Все права защищены. Запрещается
            копировать, использовать, распространять, модифицировать любое
            содержимое этого сайта. Fun&Sun не несёт ответственности за
            информацию, представленную на внешних сайтах.
          </BottomText>
        )}
        <BottomText>Правовая информация</BottomText>
        <BottomSmallText>v{packageJson.version}</BottomSmallText>
        <GoTopButton
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
        >
          <ArrowTopIcon />
        </GoTopButton>
      </Container>
    </Wrapper>
  );
};

export default Footer;

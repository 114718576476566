import React from 'react';
import styled from 'styled-components';
import { Text } from '@components/ui';
import { RefundUiData, TableByTicketsProps } from '../types';
import { Helper } from '@utils';

const Container = styled.div<{ isLastElement?: boolean }>`
  display: flex;
  flex-direction: column;
  border-bottom: ${({ isLastElement }) =>
    !isLastElement ? '1px solid #DCDCDC' : 'inherit'};
  margin-bottom: 25px;
`;

const RowText = styled(Text)`
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;
  margin-bottom: 10px;
`;

const BlueSpan = styled(RowText)`
  color: #4872f2;
  margin-bottom: 0px;
  margin-left: 3px;
  font-weight: bold;
`;

const BoldSpan = styled(RowText)`
  font-weight: bold;
  margin-bottom: 0px;
  margin-left: 3px;
`;

const Taxes = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 14px;
  margin-top: 5px;
  margin-bottom: 15px;
  background: #edf1fe;
  border-radius: 6px;

  > :last-child {
    margin-bottom: 0px;
  }
`;

const TaxRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const TaxLabel = styled(Text)`
  margin-bottom: 0px;
`;

const TaxCost = styled(RowText)`
  margin-bottom: 0px;
  color: #4872f2;
`;

const TableByTickets: React.FC<TableByTicketsProps<RefundUiData>> = ({
  data,
  index,
  isLastElement,
}) => {
  const ticket = data?.item?.selectedTicket;
  const passengengerType = ticket?.passengerType
    ? getPassengerType(ticket?.passengerType)
    : '';
  const patronymic = ticket?.patronymic ? ticket?.patronymic : '';
  const name = ticket?.lastName + ' ' + ticket?.firstName + ' ' + patronymic;

  const ticketPrice = ticket?.price
    ? Object.entries(ticket?.price).reduce((acc, x: any) => {
        const [key, value] = x;
        return key !== 'discount' ? acc + value : acc;
      }, 0)
    : 0;

  return (
    <Container
      style={{ marginTop: !index ? 'inherit' : 25 }}
      isLastElement={isLastElement}
    >
      <RowText>
        Пассажир {index + 1}, {passengengerType}: <BoldSpan>{name}</BoldSpan>
      </RowText>
      {!!ticket?.eTicketNumber && (
        <RowText>
          Номер билета: <BoldSpan>{ticket?.eTicketNumber}</BoldSpan>
        </RowText>
      )}
      <RowText>
        Стоимость билета: <BlueSpan>{`${ticketPrice}  ₽`}</BlueSpan>{' '}
      </RowText>
      <Taxes>
        {data.rows.map((x, i) => {
          return (
            !!x?.label && (
              <TaxRow key={i}>
                <TaxLabel>{x.label}</TaxLabel>
                <TaxCost>{Helper.formatPrice(x.cost)}</TaxCost>
              </TaxRow>
            )
          );
        })}
      </Taxes>
      {!!(
        data.item?.supportTicketCalculationParameter?.totalParameters &&
        data.item?.supportTicketCalculationTax?.totalTaxes
      ) && (
        <RowText>
          Cумма доплаты по билету:{' '}
          <BoldSpan>
            {Helper.formatPrice(
              Number(
                data?.item.supportTicketCalculationParameter?.totalParameters
              ) + Number(data?.item.supportTicketCalculationTax?.totalTaxes)
            )}
          </BoldSpan>
        </RowText>
      )}
    </Container>
  );
};

export default TableByTickets;

const getPassengerType = (type: string) => {
  switch (type) {
    case 'Adult':
      return 'взрослый';
    case 'Child':
      return 'ребенок';
    default:
      return 'младенец';
  }
};

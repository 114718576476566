import {
  SimpleSearchFormState,
  CitySuggestionState,
  SimpleSearchBusFormState,
} from './types';
import { FlightClassTypes } from '@modules/main';

export const simpleSearchFormInitialState: SimpleSearchFormState = {
  passengers: {
    adults: {
      min: 1,
      max: 9,
      count: 1,
    },
    children: {
      min: 0,
      max: 2,
      count: 0,
    },
    infants: {
      min: 0,
      max: 1,
      count: 0,
    },
  },
  ticketClass: {
    id: FlightClassTypes.Econom,
    description: 'эконом',
  },
  dates: {
    from: null,
    to: null,
  },
  returnTicket: true,
  from: '',
  to: '',
};

export const simpleSearchFormBusInitialState: SimpleSearchBusFormState = {
  passengers: {
    adults: {
      min: 1,
      max: 9,
      count: 1,
    },
    children: {
      min: 0,
      max: 2,
      count: 0,
    },
    infants: {
      min: 0,
      max: 1,
      count: 0,
    },
  },
  ticketClass: {
    id: FlightClassTypes.Econom,
    description: 'эконом',
  },
  dates: {
    from: null,
    to: null,
  },
  returnTicket: true,
  fromBus: '',
  toBus: '',
};

export const simpleSearchFormInitialStateForTrain: SimpleSearchFormState = {
  passengers: {
    adults: {
      min: 1,
      max: 4,
      count: 1,
    },
    children: {
      min: 0,
      max: 2,
      count: 0,
    },
    infants: {
      min: 0,
      max: 1,
      count: 0,
    },
  },
  ticketClass: {
    id: FlightClassTypes.Econom,
    description: 'эконом',
  },
  dates: {
    from: null,
    to: null,
  },
  returnTicket: true,
  from: '',
  to: '',
};

export const citySuggestionInitialState: CitySuggestionState = {
  list: {
    items: [],
    length: 0,
  },
  position: '',
  isSelectFocused: false,
  story: {},
};

import React from 'react';
import styled from 'styled-components';
import { PointProps } from './types';
import { Text } from '@components/ui';
import Tippy from '@tippyjs/react';
import { Helper } from '@utils';

interface LinkProps {
  isRed?: boolean;
}

const Container = styled.div<LinkProps>`
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: ${({ isRed }) => (isRed ? '#FFE3E3' : '#e3eaff')};

  @media (max-width: 767px) {
    width: 12px;
    height: 12px;
    border: ${({ isRed }) =>
      isRed ? '3px solid #E73C3E' : '3px solid #4872f2'};
    border-radius: 50%;
    background: #edf1fe;
    z-index: 2;
    position: relative;
    box-sizing: border-box;
  }
`;

const InnerRound = styled.div`
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background: #4872f2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  @media (max-width: 767px) {
    display: none;
  }
`;

const InnerRoundRed = styled.div<LinkProps>`
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background: #e73c3e;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  @media (max-width: 767px) {
    display: none;
  }
`;

const UpperText = styled(Text)<LinkProps>`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: ${({ isRed }) => (isRed ? '#3C3C3C' : '#4872f2')};
  position: absolute;
  top: -19px;
  left: 50%;
  transform: translate(-50%, 0);
  @media (max-width: 767px) {
    display: ${({ isRed }) => (isRed ? 'none' : 'inline')};
  }
`;

const TopText = styled(Text)<LinkProps>`
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #e73c3e;
  position: absolute;
  top: -25px;
  left: 60%;
  transform: translate(-50%, 0);
  width: 100px;
  @media (min-width: 767px) {
    display: none;
  }
`;

const MobileText = styled(Text)`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #737373;

  position: absolute;
  top: -20px;
  white-space: nowrap;
  left: 50%;
  transform: translateX(-50%);

  & > strong {
    color: #4872f2;
  }
`;

const TippyWrapper = styled.div`
  padding: 8px;
  background: white;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 12%) 0px 4px 11px 0px;
  width: 202px;
`;

const TippyText = styled(Text)`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #3c3c3c;

  & > strong {
    color: #4872f2;
  }
`;

const Point: React.FC<PointProps> = ({
  upperText,
  textSize,
  mobileProps,
  ...props
}) => (
  <Container {...props} isRed={props.color === 'red'}>
    <div>
      {upperText && <UpperText>{upperText}</UpperText>}
      {props.color === 'red' && (
        <UpperText isRed> {props?.techLanding?.city.code}</UpperText>
      )}

      {mobileProps && (
        <MobileText>
          {Helper.formatDuration(mobileProps.duration)},{' '}
          <Tippy
            render={(attrs) => (
              <TippyWrapper {...attrs}>
                <TippyText>
                  Пересадка {Helper.formatDuration(mobileProps.stopDuration)},
                  аэропорт{' '}
                  <strong>
                    {mobileProps.airport.name} {mobileProps.airport.code}
                  </strong>
                  , {mobileProps.city.name}{' '}
                </TippyText>
              </TippyWrapper>
            )}
          >
            <strong>
              {mobileProps.stopCount}{' '}
              {Helper.pluralWord(
                mobileProps.stopCount,
                'пересадка',
                'пересадки',
                'пересадок',
              )}
            </strong>
          </Tippy>
        </MobileText>
      )}
      {props.techLanding ? (
        <>
          <Tippy
            content={
              <>
                Техническая остановка в аэропорту{' '}
                <b>{props.techLanding.city.airport}</b>,{' '}
                <b>{props.techLanding.city.city}</b>. Длительность остановки{' '}
                <b>{props.techLanding.duration}</b> минут.
              </>
            }
            theme="light"
            maxWidth={245}
          >
            <InnerRoundRed />
          </Tippy>
          <Tippy
            content={
              <>
                Техническая остановка в аэропорту{' '}
                <b>{props.techLanding.city.airport}</b>,{' '}
                <b>{props.techLanding.city.city}</b>. Длительность остановки{' '}
                <b>{props.techLanding.duration}</b> минут.
              </>
            }
            theme="light"
            maxWidth={245}
          >
            <TopText>тех. остановка</TopText>
          </Tippy>
        </>
      ) : (
        <InnerRound />
      )}
    </div>
  </Container>
);

export default Point;

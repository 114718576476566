import React, { useEffect } from 'react';
import CityFields from '@components/flightsSearch/SimpleSearch/CityFields';
import { Button } from '@components/ui';
import DatePicker from './DatePicker';
import PassengerPicker from './PassengersPicker';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Helper } from '@utils';
import { useFormik } from 'formik';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useFocus } from './useFormFocus';
import { ApplicationState } from '@modules/index';
import { LOGGING_ACTION_IDS } from '@modules/logs/constants';

const Wrapper = styled.div`
  font-family: ${({ theme: { fonts } }) => fonts.regular};
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: clamp(12px, 1.2vw, 22px);
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
`;

const SearchButton = styled(Button)`
  width: 146px;
  height: 62px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  padding: 18px 30.5px;
  white-space: nowrap;
  box-sizing: border-box;

  @media (max-width: 1122px) and (min-width: 1025px) {
    width: 161px;
  }
`;

const Form = styled.form``;

const SearchForm: React.FC<{ className?: string }> = (props) => {
  const data = useSelector(
    (state: ApplicationState) => state.searchReducer.simpleSearchForm,
  );
  const dataBus = useSelector(
    (state: ApplicationState) => state.searchReducer.simpleSearchFormBus,
  );

  const searchPanelType = useSelector(
    (state: ApplicationState) => state.ui.searchPanelType,
  );
  const history = useHistory();
  const ref = React.useRef(null as null | HTMLDivElement);
  const { setFocus, focus } = useFocus();

  const isFocusActive = React.useMemo(() => !!focus, [focus]);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (ref.current) {
        if (!ref.current.contains(e.target as any)) {
          setFocus(null);
        }
      }
    };

    if (!isFocusActive) {
      document.removeEventListener('mousedown', handleClickOutside);
    } else {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isFocusActive]);

  const formik = useFormik({
    initialValues: {
      from:
        searchPanelType !== 'bus'
          ? typeof data.from === 'object'
            ? data.from.nameRus
            : data.from
          : typeof dataBus.fromBus === 'object'
            ? dataBus.fromBus.nameRu
            : dataBus.fromBus,
      to:
        searchPanelType !== 'bus'
          ? typeof data.to === 'object'
            ? data.to.nameRus
            : data.to
          : typeof dataBus.toBus === 'object'
            ? dataBus.toBus.nameRu
            : dataBus.toBus,
    },
    validate: ({ from, to }) => {
      const errors = {} as any;

      if (from === '') {
        errors.from = 'Укажите город';
      }
      if (to === '') {
        errors.to = 'Укажите город';
      }

      return errors;
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: () => {
      if (searchPanelType === 'avia') {
        history.push(
          `/search/${Helper.createSearchParams({
            fromCode: (data.from as any).iataCode,
            ToCode: (data.to as any).iataCode,
            forwardDate: data.dates.from || moment(),
            backwardDate: data.dates.to,
            adults: data.passengers.adults.count,
            children: data.passengers.children.count,
            infants: data.passengers.infants.count,
            flightClass: data.ticketClass.id,
          })}`,
        );
      } else if (searchPanelType === 'train') {
        const url = new URL('train-search', 'http://localhost:3001');
        url.searchParams.append('From', (data.from as any).iataCode);
        url.searchParams.append('To', (data.to as any).iataCode);
        url.searchParams.append(
          'DepartureDate',
          data.dates.from
            ? data.dates.from.format('YYYY-MM-DD')
            : moment().format('YYYY-MM-DD'),
        );
        // дата обратного билета
        if (data.dates.to) {
          url.searchParams.append(
            'DepartureDateBack',
            data.dates.to.format('YYYY-MM-DD'),
          );
        }
        url.searchParams.append(
          'Adults',
          data.passengers.adults.count.toString(),
        );
        url.searchParams.append(
          'Children',
          data.passengers.children.count.toString(),
        );
        url.searchParams.append(
          'Infants',
          data.passengers.infants.count.toString(),
        );
        history.push(`/train-search?${url.searchParams.toString()}`);
      } else if (searchPanelType === 'bus') {
        const url = new URL('bus-search', 'http://localhost:3001');
        url.searchParams.append('From', (dataBus.fromBus as any).guid);
        url.searchParams.append('To', (dataBus.toBus as any).guid);
        url.searchParams.append(
          'DepartureDate',
          data.dates.from
            ? data.dates.from.format('YYYY-MM-DD')
            : moment().format('YYYY-MM-DD'),
        );
        // дата обратного билета
        if (data.dates.to) {
          url.searchParams.append(
            'DepartureDateBack',
            data.dates.to.format('YYYY-MM-DD'),
          );
        }
        url.searchParams.append(
          'Adults',
          data.passengers.adults.count.toString(),
        );
        url.searchParams.append(
          'Children',
          data.passengers.children.count.toString(),
        );
        url.searchParams.append(
          'Infants',
          data.passengers.infants.count.toString(),
        );
        history.push(`/bus-search?${url.searchParams.toString()}`);
      }
    },
  });

  useEffect(() => {
    if (searchPanelType !== 'bus') {
      if (data.from !== null) {
        formik.setFieldValue('from', data.from);
        delete formik.errors['from'];
        formik.setErrors({ ...formik.errors });
      }
      if (data.to !== null) {
        formik.setFieldValue('to', data.to);
        delete formik.errors['to'];
        formik.setErrors({ ...formik.errors });
      }
    } else {
      if (dataBus.fromBus !== null) {
        formik.setFieldValue('from', dataBus.fromBus);
        delete formik.errors['from'];
        formik.setErrors({ ...formik.errors });
      }
      if (dataBus.toBus !== null) {
        formik.setFieldValue('to', dataBus.toBus);
        delete formik.errors['to'];
        formik.setErrors({ ...formik.errors });
      }
    }
  }, [data, dataBus]);

  return (
    <Form
      className={props.className}
      autoComplete="off"
      onSubmit={formik.handleSubmit}
    >
      <Wrapper>
        <InputWrapper ref={ref}>
          <CityFields onBlur={formik.handleBlur} errors={formik.errors} />

          <DatePicker />
          <PassengerPicker />
        </InputWrapper>
        <SearchButton
          loggingData={{
            screen: LOGGING_ACTION_IDS.SEARCH_PANEL.NAME,
            actionId: LOGGING_ACTION_IDS.SEARCH_PANEL.SEARCH_BTN,
          }}
          type="primary"
          htmlType="submit"
        >
          Найти
        </SearchButton>
      </Wrapper>
    </Form>
  );
};

export default SearchForm;

import React from 'react';
import { useSelector } from 'react-redux';
import NewFlightDetail from '@components/booking/FlightDetail/NewDesktop/NewFlightDetail';
import DetailButtonWrapper from '@components/booking/FlightDetail/NewDesktop/DetailButtonWrapper';
import { ApplicationState } from '@modules/index';
import { orderDetailsStateToFlightEntity } from '@modules/booking/utils';
import { Text } from '@components/ui';
import styled from 'styled-components';
import { getMinBaggageDescription } from '@modules/orders/utils';

interface Props {
  onClick?: () => void;
  isOpen?: boolean;
}

const PassengerButtonWrapper = styled.div`
  display: flex;
  margin: 10px 0;

  &:hover {
    cursor: pointer;
  }
`;

const Actions = styled.div`
  display: flex;

  & > * {
    white-space: nowrap;
  }
`;

const NewFlightDetailContainerStepTwo: React.FC<Props> = (props) => {
  const orderDetail = useSelector(
    (state: ApplicationState) => state.orders.orderDetail.data,
  );
  const data = orderDetailsStateToFlightEntity(orderDetail);
  const [isActive, setActive] = React.useState(false);

  const isDetail = window.location.pathname.includes('/orders');

  let baggageDescr = '';
  const orderPositions = orderDetail.orderPositions;
  if (orderPositions?.length > 0 && orderPositions[0].tariff.fareFamilies) {
    baggageDescr =
      getMinBaggageDescription(orderPositions[0].tariff.fareFamilies) || '';
  }

  React.useEffect(() => {
    if (isDetail) {
      if (props.isOpen) {
        setActive(true);
      }
      if (!props.isOpen) {
        setActive(false);
      }
    }
  }, [isDetail, props.isOpen]);

  return (
    <>
      <NewFlightDetail
        baggageDescr={baggageDescr}
        isActive={isActive}
        data={data}
        isDetail={isDetail}
        setActive={setActive}
      />
      {!isDetail && (
        <Actions>
          {/* <PassengerButtonWrapper
            onClick={() => {
              props.onClick && props.onClick();
            }}
          >
            <Text color={'#4872F2'}>Данные пассажиров</Text>
          </PassengerButtonWrapper> */}
          {/* <DetailButtonWrapper
            onClick={() => {
              setActive(!isActive);
            }}
            isOpen={isActive}
          /> */}
        </Actions>
      )}
    </>
  );
};

export default NewFlightDetailContainerStepTwo;

import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { useTicketProps } from '../useTicketProps';
import Text from '@components/ui/Typography';
import { Helper } from '@utils';
import { getFeatureIcon } from '../utils';

interface WrapperProps {
  isActive?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  // margin-bottom: ${({ isActive }) => (isActive ? '0px' : '22px')};
  @media (min-width: 768px) {
    justify-content: flex-end;
  }
  @media (max-width: 767px) {
    display: none;
  }
`;

const Price = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.main};
`;

const FlagsWrapper = styled.div`
  display: flex;

  & > div {
    margin-right: 8px;
  }

  & > :last-child {
    margin-right: 0;
  }
`;

const Head: React.FC = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { prices, isActive, features } = useTicketProps();

  return (
    <Wrapper isActive={isActive}>
      {isMobile && <Price>{Helper.formatPrice(prices[0])} </Price>}

      {!isMobile && (
        <FlagsWrapper>
          {features.map((feature, index) => {
            return (
              <span key={index} style={{ marginRight: 5 }}>
                {getFeatureIcon(feature)}
              </span>
            );
          })}
        </FlagsWrapper>
      )}
    </Wrapper>
  );
};

export default Head;

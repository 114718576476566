import {
  call,
  put,
  all,
  takeLatest,
  spawn,
  race,
  take,
} from 'redux-saga/effects';
import { Action } from 'redux-act';
import { select, call as call2 } from 'typed-redux-saga';
import * as FlightSearchManager from './Manager';
import { FlightSearchRequestStatus } from './types';
import { search, simpleSearchUpdateState } from '../simpleSearch';
import moment, { HTML5_FMT } from 'moment';
import {
  setFlightSearchRequestState,
  setFlightSearchData,
  setFlightSearchIntervals,
  setTimeOneWayFilter,
  setTransferDurationFilter,
  setPricesFilter,
  setAirportFilter,
  setAirlineFilter,
  setFlightTypeFilter,
  resetFilter,
  runFilter,
  setTransferFilter,
  setTimeFilter,
  setBaggageFilter,
  getNext,
  setList,
  purifySearchState,
  promotionsRequest,
  promotionsSuccess,
  promotionsFailure,
  setConnectionFilter,
  setAirGdsFilter,
} from './duck';

import { Helper } from '@utils';
import {
  connectionFilterStatus,
  getPluginToken,
  getTicketList,
} from './selectors';
import { ApplicationState } from '@modules/index';
import { getUserDataRequestWorker, getUserDataState } from '@modules/user';

function createMomentDate(str?: string | null) {
  if (!str) return null;
  return moment(str, 'DDMMYY');
}

function* flightSearchSaga(
  action: Action<{ url: string; saleChannel?: string }>
) {
  yield put(
    setFlightSearchRequestState({ status: FlightSearchRequestStatus.loading })
  );

  try {
    // parse url string

    const parsedParams = Helper.parseSearchParams(action.payload.url);
    const pluginToken = yield* select(getPluginToken);

    const paramsForRequest = {
      origin: parsedParams.origin,
      destination: parsedParams.destination,
      passengers: {
        adults: parsedParams.adults,
        children: parsedParams.children,
        infants: parsedParams.infants,
      },
      forwardDate: createMomentDate(parsedParams.forwardDate)?.format(
        HTML5_FMT.DATE
      ),
      backwardDate: createMomentDate(parsedParams.backwardDate)?.format(
        HTML5_FMT.DATE
      ),
      category: parsedParams.flightClass,
      saleChannel: action.payload?.saleChannel,
      pluginToken: pluginToken,
    };

    yield call(getUserDataRequestWorker);

    yield spawn(simpleSearchUpdateState, parsedParams);
    const user = yield* select(getUserDataState);

    const requestWithSamo: any = paramsForRequest;

    if (user && user.agentSamoCode !== null) {
      requestWithSamo['agentSamoCode'] = user.agentSamoCode;
    }

    const data: any =
      parsedParams.backwardDate === null
        ? yield* call2(
            FlightSearchManager.searchOneWayDirection as any,
            requestWithSamo as any
          )
        : yield* call2(
            FlightSearchManager.searchWithReturn as any,
            requestWithSamo as any
          );

    window.localStorage.setItem('origin', parsedParams.origin);
    window.localStorage.setItem('destination', parsedParams.destination);
    const formattedFromDate =
      moment(parsedParams.forwardDate).format('YYYY-MM-DD') + 'T00:00:00';

    const connectionStatus = yield* select(connectionFilterStatus);

    if (data?.flightsList?.items?.length && connectionStatus === false) {
      yield put(setFlightSearchData(data));

      // here
      yield put(
        setList({
          items: [...data.flightsList.items],
          visibleItems: [...data.flightsList.items].slice(0, 10),
          page: 1,
          pageCount: Math.ceil(data.flightsList.items.length / 10),
        })
      );
      yield put(
        setFlightSearchIntervals({
          data: data.references.Timetable,
          selected: formattedFromDate,
        })
      );
    } else if (connectionStatus === true) {
      yield put(setFlightSearchData(data));
      const fliteredData = data.flightsList.items.filter(
        (x: any) => x.hasConnectingFlights === false
      );

      // here
      yield put(
        setList({
          items: [...fliteredData],
          visibleItems: [...fliteredData].slice(0, 10),
          page: 1,
          pageCount: Math.ceil(fliteredData / 10),
        })
      );
      yield put(
        setFlightSearchIntervals({
          data: data.references.Timetable,
          selected: formattedFromDate,
        })
      );
      yield put(setConnectionFilter(false));
    } else {
      yield put(
        setFlightSearchRequestState({
          status: FlightSearchRequestStatus.failure,
        })
      );
    }
  } catch (e) {
    console.log(e);
    yield put(
      setFlightSearchRequestState({ status: FlightSearchRequestStatus.failure })
    );
  }
}

export function* runFilterSaga() {
  yield put(runFilter());
  const {
    mainReducer: {
      flightSearch: {
        flightsList: { items },
      },
    },
  } = yield* select((state: ApplicationState) => state);
  yield put(
    setList({
      items: [...items],
      visibleItems: [...items].slice(0, 10),
      page: 1,
      pageCount: Math.ceil(items.length / 10),
    })
  );
}

function* getNextWorker() {
  const data = yield* select(getTicketList);
  if (data.page === data.pageCount) {
    return;
  }
  const visibleItems = [...data.items].slice(0, (data.page + 1) * 10);

  yield put(
    setList({
      ...data,
      visibleItems,
      page: data.page + 1,
    })
  );
}

function* promotionsRequestFlow() {
  try {
    const response = yield* call2(FlightSearchManager.getPromitions);
    yield put(promotionsSuccess(response));
  } catch (e) {
    yield put(promotionsFailure());
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(search, function* (action: any) {
      yield race([
        call(flightSearchSaga, action),
        take(purifySearchState.getType()),
      ]);
    }),
    takeLatest(promotionsRequest.getType(), promotionsRequestFlow),
    takeLatest(
      [
        resetFilter,
        setTimeOneWayFilter,
        setTransferDurationFilter,
        setPricesFilter,
        setAirportFilter,
        setAirlineFilter,
        setTransferFilter,
        setTimeFilter,
        setBaggageFilter,
        setFlightTypeFilter,
        setAirGdsFilter,
      ],
      runFilterSaga
    ),
    takeLatest(getNext.getType(), getNextWorker),
  ]);
}

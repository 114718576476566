import React from 'react';
import { Button } from '@components/ui';
import { useSelector } from 'react-redux';
import { bookingInsuranceSelector } from '@modules/booking';
import { useFormikContext } from 'formik';

type PassengerError = {
  birthDate: string;
  name: string;
  nameEng: string;
  surname: string;
  surnameEng: string;
  secondName: string;
  secondNameEng: string;
  number: string;
  documentInfo: string;
  documentSeries: string;
  dateOfIssue: string;
  sex: string;
  documentType: string;
  citizenship: string;
  type: {
    id: string;
    description: string;
  };
};

interface Props {
  title?: string;
}

const PaymentButton: React.FC<Props> = (props) => {
  const insurance = useSelector(bookingInsuranceSelector);

  const { errors, validateForm, setFieldValue } = useFormikContext<{
    offerta: boolean;
    emailOfferta: boolean;
    isInsurancesChecked: boolean;
    offertaWithInsurances: boolean;
    charterTariffs: boolean;
    isCharter: boolean;
    passengers: Array<PassengerError | undefined>;
    healthDeclaration: boolean;
  }>();

  const isInsurancesChecked = insurance.list.offers.some(
    (item) => item.checked
  );

  React.useEffect(() => {
    setFieldValue('isInsurancesChecked', isInsurancesChecked);
  }, [isInsurancesChecked]);

  return (
    <Button
      type="primary"
      htmlType="submit"
      color="yellow"
      onClick={async () => {
        const curErrors = await validateForm();
        const curErrorsKeys = Object.keys(curErrors);

        if (curErrorsKeys.length) {
          const key = curErrorsKeys[0];
          let el;
          if (key === 'offerta') return;

          if (key === 'passengers') {
            // @ts-ignore
            const firstErrorIndex = curErrors.passengers.findIndex(
              (item: undefined | PassengerError) => item !== undefined
            );
            el = document.getElementById(`passenger.${firstErrorIndex}`);
          } else if (key === 'contacts') {
            el = document.getElementById('contacts');
          }

          if (el) {
            const offset = el.offsetTop;
            window.scrollTo({
              top: offset - 100,
              behavior: 'smooth',
            });
          }
        }
      }}
    >
      {props.title || 'Перейти к оплате'}
    </Button>
  );
};

export default PaymentButton;

export type DocumentTypeTitles =
  | 'Паспорт РФ'
  | 'Заграничный паспорт РФ'
  | 'Иностранный документ'
  | 'Свидетельство о рождении'
  | 'Военный билет военнослужащего и курсантов военных образовательных организаций'
  | 'Удостоверение личности для военнослужащих'
  | 'Свидетельство о возвращении из стран СНГ'
  | 'Дипломатический паспорт'
  | 'Служебный паспорт'
  | 'Удостоверение личности моряка'
  | 'Удостоверение личности лица без гражданства'
  | 'Вид на жительство'
  | 'Временное удостоверение личности'
  | 'Паспорт СССР'
  | 'Медицинское свидетельство о рождении (для детей не старше 1 мес)'
  | 'Справка об утере паспорта'
  | 'Справка об освобождении из мест лишения свободы'
  | 'Свидетельство о предоставлении временного убежища (водный транспорт)'
  | 'Временное удостоверение, выданное взамен военного билета'
  | 'Военный билет офицера запаса'
  | 'Загранпаспорт СССР'
  | 'Удостоверение беженца'
  | 'Свидетельство о предоставлении временного убежища на территории РФ (ЖД); Свидетельство о рассмотрении ходатайства о признании лица беженцем (водный транспорт)'
  | 'Разрешение на временное проживание в Российской Федерации'
  | 'Удостоверение личности офицера'
  | 'Паспорт Минморфлота'
  | 'Иностранное свидетельство о рождении'
  | 'Иной документ, выданный органами МВД'
  | 'Удостоверение личности осужденного'
  | 'Заключение об установлении личности иностранного гражданина или лица без гражданства'
  | 'Справка для следования в дипломатическое представительство иностранного государства в Российской Федерации'
  | 'Иной документ, установленный федеральным законом или признаваемый в соответствии с международным договором Российской Федерации в качестве документа, удостоверяющего личность иностранного гражданина и (или) лица без гражданства';

export type DocumentTypeIds =
  | 'RussianPassport'
  | 'RussianForeignPassport'
  | 'ForeignPassport'
  | 'BirthCertificate'
  | 'MilitaryCard'
  | 'MilitaryOfficerCard'
  | 'ReturnToCisCertificate'
  | 'DiplomaticPassport'
  | 'ServicePassport'
  | 'SailorPassport'
  | 'StatelessPersonIdentityCard'
  | 'ResidencePermit'
  | 'RussianTemporaryIdentityCard'
  | 'UssrPassport'
  | 'MedicalBirthCertificate'
  | 'LostPassportCertificate'
  | 'PrisonReleaseCertificate'
  | 'CertificateOfTemporaryAsylum'
  | 'MilitaryTemporaryCard'
  | 'ReserveOfficerMilitaryCard'
  | 'UssrForeignPassport'
  | 'RefugeeIdentity'
  | 'RefugeeCertificate'
  | 'RussianTemporaryLivingCertificate'
  | 'OfficerCertificate'
  | 'MinistryMarineFleetPassport'
  | 'ForeignBirthCertificate'
  | 'Other'
  | 'ConvictedPersonIdentity'
  | 'AcknowledgmentOfIdentityOfAForeignCitizenOrStatelessPerson'
  | 'CertificateIssuedForAForeignCitizenGoingToDiplomaticOffice'
  | 'AnotherDocumentOfAForeignCitizenRecognizedInTheRussianFederation';

export interface ClientDocumentType {
  id: DocumentTypeIds;
  title: DocumentTypeTitles;
  regexp: RegExp;
  passengerCategory: ('Adult' | 'Child' | 'Infant')[];
  allowedCitizenship: 'ru' | 'not-ru' | 'any' | 'foreign-special';
  charactersInFIO?: 'ru' | 'en' | 'any';
}

export const DOCUMENTS_NUMBER_REGEXPS = {
  BIRTH_CERTIFICATE: /^[IVXLCDM]{1,3}[а-яА-ЯёЁ]{2}\d{6}$/,
  USSR_PASSPORT: /^[IVXLCDM]{1,4}[а-яА-ЯёЁ]{2}\d{6}$/,
  PASSPORT_RF: /^[0-9]{4}-[0-9]{6}$/,
  RUSSIAN_FOREIGN_PASSPORT: /^[0-9]{2}[0-9]{7}$/,
  FOREIGN_PASSPORT:
    /^[а-яА-ЯA-Za-z0-9]([а-яА-ЯA-Za-z0-9-]{0,14}[а-яА-ЯA-Za-z0-9])?$/,
  FOREIGN_PASSPORT_WITH_ONLY_LETTERS: /^[A-Za-z]([A-Za-z-]{0,14}[A-Za-z])?$/,
  MILITARY_CARD: /^[а-яА-ЯёË]{2}[0-9]{6,7}$/,
  MILITARY_OFFICER_CARD: /^(?=.*\d)[A-Za-zА-Яа-яЁё0-9-]{1,16}$/,
  RETURN_TO_CIS_CERTIFICATE: /^[0-9-]{1,10}$/,
  DIPLOMATIC_PASSPORT: /^[0-9]{2}[0-9]{7}$/,
  SERVICE_PASSPORT: /^[0-9]{2}[0-9]{7}$/,
  SAILOR_PASSPORT: /^[0-9]{7}$/,
  STATELESS_PERSON_IDENTITY_CARD: /^(?=.*\d).*$/,
  RESIDENTS_PERMIT: /^(?=.*\d).*$/,
  LOST_PASSPORT_CERTIFICATE: /^(?=.*\d)[А-Яа-я0-9-]{1,16}$/,
  MEDICAL_BIRTH_CERTIFICATE: /^[0-9]{6,12}$/,
  PRISON_RELEASE_CERTIFICATE: /^(?=.*\d)[A-Za-zА-Яа-яЁё0-9-]{1,16}$/,
  RUSSIAN_TEMPORARY_IDENTITY_CARD: /^(?=.*\d)[A-Za-zА-Яа-яЁё0-9-]{1,16}$/,
  CERTIFICATE_OF_TEMPORARY_ASYLUM: /^(?=.*\d)[A-Za-zА-Яа-яЁё0-9-]{1,16}$/,
  REFUGEE_CERTIFICATE: /^(?=.*\d)[A-Za-zА-Яа-яЁё0-9-]{1,16}$/,
  DEFAULT_REGEXP: /^(?=.*\d)[A-Za-zА-Яа-яЁё0-9-]{1,16}$/,
};

export const CLIENT_DOCUMENTS_TYPES: ClientDocumentType[] = [
  {
    id: 'RussianPassport',
    title: 'Паспорт РФ',
    regexp: DOCUMENTS_NUMBER_REGEXPS.PASSPORT_RF,
    passengerCategory: ['Adult'],
    allowedCitizenship: 'ru',
    charactersInFIO: 'ru',
  },
  {
    id: 'RussianForeignPassport',
    title: 'Заграничный паспорт РФ',
    regexp: DOCUMENTS_NUMBER_REGEXPS.RUSSIAN_FOREIGN_PASSPORT,
    passengerCategory: ['Adult', 'Child'],
    allowedCitizenship: 'ru',
    charactersInFIO: 'en',
  },
  {
    id: 'ForeignPassport',
    title: 'Иностранный документ',
    regexp: DOCUMENTS_NUMBER_REGEXPS.FOREIGN_PASSPORT,
    passengerCategory: ['Adult', 'Child'],
    allowedCitizenship: 'foreign-special',
    charactersInFIO: 'any',
  },
  {
    id: 'BirthCertificate',
    title: 'Свидетельство о рождении',
    regexp: DOCUMENTS_NUMBER_REGEXPS.BIRTH_CERTIFICATE,
    passengerCategory: ['Child', 'Adult', 'Infant'],
    allowedCitizenship: 'ru',
    charactersInFIO: 'ru',
  },
  {
    id: 'MilitaryCard',
    title:
      'Военный билет военнослужащего и курсантов военных образовательных организаций',
    regexp: DOCUMENTS_NUMBER_REGEXPS.MILITARY_CARD,
    passengerCategory: ['Adult'],
    allowedCitizenship: 'ru',
    charactersInFIO: 'ru',
  },
  {
    id: 'MilitaryOfficerCard',
    title: 'Удостоверение личности для военнослужащих',
    regexp: DOCUMENTS_NUMBER_REGEXPS.MILITARY_OFFICER_CARD,
    passengerCategory: ['Adult'],
    allowedCitizenship: 'ru',
    charactersInFIO: 'ru',
  },
  {
    id: 'ReturnToCisCertificate',
    title: 'Свидетельство о возвращении из стран СНГ',
    regexp: DOCUMENTS_NUMBER_REGEXPS.RETURN_TO_CIS_CERTIFICATE,
    passengerCategory: ['Adult'],
    allowedCitizenship: 'any',
    charactersInFIO: 'any',
  },
  {
    id: 'DiplomaticPassport',
    title: 'Дипломатический паспорт',
    regexp: DOCUMENTS_NUMBER_REGEXPS.DIPLOMATIC_PASSPORT,
    passengerCategory: ['Adult'],
    allowedCitizenship: 'any',
    charactersInFIO: 'any',
  },
  {
    id: 'ServicePassport',
    title: 'Служебный паспорт',
    regexp: DOCUMENTS_NUMBER_REGEXPS.SERVICE_PASSPORT,
    passengerCategory: ['Adult'],
    allowedCitizenship: 'any',
    charactersInFIO: 'any',
  },
  {
    id: 'SailorPassport',
    title: 'Удостоверение личности моряка',
    regexp: DOCUMENTS_NUMBER_REGEXPS.SAILOR_PASSPORT,
    passengerCategory: ['Adult'],
    allowedCitizenship: 'ru',
    charactersInFIO: 'any',
  },
  {
    id: 'StatelessPersonIdentityCard',
    title: 'Удостоверение личности лица без гражданства',
    regexp: DOCUMENTS_NUMBER_REGEXPS.STATELESS_PERSON_IDENTITY_CARD,
    passengerCategory: ['Adult'],
    allowedCitizenship: 'any',
    charactersInFIO: 'any',
  },
  {
    id: 'ResidencePermit',
    title: 'Вид на жительство',
    regexp: DOCUMENTS_NUMBER_REGEXPS.RESIDENTS_PERMIT,
    passengerCategory: ['Adult'],
    allowedCitizenship: 'any',
    charactersInFIO: 'any',
  },
  {
    id: 'RussianTemporaryIdentityCard',
    title: 'Временное удостоверение личности',
    regexp: DOCUMENTS_NUMBER_REGEXPS.RUSSIAN_TEMPORARY_IDENTITY_CARD,
    passengerCategory: ['Adult'],
    charactersInFIO: 'ru',
    allowedCitizenship: 'ru',
  },
  {
    id: 'UssrPassport',
    title: 'Паспорт СССР',
    regexp: DOCUMENTS_NUMBER_REGEXPS.USSR_PASSPORT,
    passengerCategory: ['Adult'],
    allowedCitizenship: 'ru',
    charactersInFIO: 'ru',
  },
  {
    id: 'MedicalBirthCertificate',
    title: 'Медицинское свидетельство о рождении (для детей не старше 1 мес)',
    regexp: DOCUMENTS_NUMBER_REGEXPS.MEDICAL_BIRTH_CERTIFICATE,
    passengerCategory: ['Infant'],
    allowedCitizenship: 'ru',
    charactersInFIO: 'ru',
  },
  {
    id: 'LostPassportCertificate',
    title: 'Справка об утере паспорта',
    regexp: DOCUMENTS_NUMBER_REGEXPS.LOST_PASSPORT_CERTIFICATE,
    passengerCategory: ['Adult', 'Child'],
    allowedCitizenship: 'ru',
    charactersInFIO: 'ru',
  },
  {
    id: 'PrisonReleaseCertificate',
    title: 'Справка об освобождении из мест лишения свободы',
    regexp: DOCUMENTS_NUMBER_REGEXPS.PRISON_RELEASE_CERTIFICATE,
    passengerCategory: ['Adult'],
    allowedCitizenship: 'ru',
    charactersInFIO: 'ru',
  },
  {
    id: 'CertificateOfTemporaryAsylum',
    title:
      'Свидетельство о предоставлении временного убежища (водный транспорт)',
    regexp: DOCUMENTS_NUMBER_REGEXPS.CERTIFICATE_OF_TEMPORARY_ASYLUM,
    passengerCategory: ['Adult'],
    charactersInFIO: 'any',
    allowedCitizenship: 'any',
  },
  {
    id: 'MilitaryTemporaryCard',
    title: 'Временное удостоверение, выданное взамен военного билета',
    regexp: DOCUMENTS_NUMBER_REGEXPS.DEFAULT_REGEXP,
    passengerCategory: ['Adult'],
    allowedCitizenship: 'ru',
    charactersInFIO: 'ru',
  },
  {
    id: 'ReserveOfficerMilitaryCard',
    title: 'Военный билет офицера запаса',
    regexp: DOCUMENTS_NUMBER_REGEXPS.DEFAULT_REGEXP,
    passengerCategory: ['Adult'],
    allowedCitizenship: 'ru',
    charactersInFIO: 'ru',
  },
  {
    id: 'UssrForeignPassport',
    title: 'Загранпаспорт СССР',
    regexp: DOCUMENTS_NUMBER_REGEXPS.DEFAULT_REGEXP,
    passengerCategory: ['Adult'],
    allowedCitizenship: 'ru',
    charactersInFIO: 'ru',
  },
  {
    id: 'RefugeeIdentity',
    title: 'Удостоверение беженца',
    regexp: DOCUMENTS_NUMBER_REGEXPS.DEFAULT_REGEXP,
    passengerCategory: ['Adult'],
    allowedCitizenship: 'any',
    charactersInFIO: 'any',
  },
  {
    id: 'RefugeeCertificate',
    title:
      'Свидетельство о предоставлении временного убежища на территории РФ (ЖД); Свидетельство о рассмотрении ходатайства о признании лица беженцем (водный транспорт)',
    regexp: DOCUMENTS_NUMBER_REGEXPS.REFUGEE_CERTIFICATE,
    passengerCategory: ['Adult'],
    allowedCitizenship: 'any',
    charactersInFIO: 'any',
  },
  {
    id: 'RussianTemporaryLivingCertificate',
    title: 'Разрешение на временное проживание в Российской Федерации',
    regexp: DOCUMENTS_NUMBER_REGEXPS.DEFAULT_REGEXP,
    passengerCategory: ['Adult', 'Child'],
    allowedCitizenship: 'ru',
    charactersInFIO: 'ru',
  },
  {
    id: 'OfficerCertificate',
    title: 'Удостоверение личности офицера',
    regexp: DOCUMENTS_NUMBER_REGEXPS.DEFAULT_REGEXP,
    passengerCategory: ['Adult'],
    allowedCitizenship: 'ru',
    charactersInFIO: 'ru',
  },
  {
    id: 'MinistryMarineFleetPassport',
    title: 'Паспорт Минморфлота',
    regexp: DOCUMENTS_NUMBER_REGEXPS.DEFAULT_REGEXP,
    passengerCategory: ['Adult'],
    allowedCitizenship: 'ru',
    charactersInFIO: 'ru',
  },
  {
    id: 'ForeignBirthCertificate',
    title: 'Иностранное свидетельство о рождении',
    regexp: DOCUMENTS_NUMBER_REGEXPS.DEFAULT_REGEXP,
    passengerCategory: ['Child', 'Infant'],
    allowedCitizenship: 'any',
    charactersInFIO: 'any',
  },
  {
    id: 'Other',
    title: 'Иной документ, выданный органами МВД',
    regexp: DOCUMENTS_NUMBER_REGEXPS.DEFAULT_REGEXP,
    passengerCategory: ['Adult', 'Child', 'Infant'],
    allowedCitizenship: 'any',
    charactersInFIO: 'any',
  },
  {
    id: 'ConvictedPersonIdentity',
    title: 'Удостоверение личности осужденного',
    regexp: DOCUMENTS_NUMBER_REGEXPS.DEFAULT_REGEXP,
    passengerCategory: ['Adult'],
    allowedCitizenship: 'ru',
    charactersInFIO: 'ru',
  },
  {
    id: 'AcknowledgmentOfIdentityOfAForeignCitizenOrStatelessPerson',
    title:
      'Заключение об установлении личности иностранного гражданина или лица без гражданства',
    regexp: DOCUMENTS_NUMBER_REGEXPS.DEFAULT_REGEXP,
    passengerCategory: ['Adult', 'Child', 'Infant'],
    allowedCitizenship: 'any',
    charactersInFIO: 'any',
  },
  {
    id: 'CertificateIssuedForAForeignCitizenGoingToDiplomaticOffice',
    title:
      'Справка для следования в дипломатическое представительство иностранного государства в Российской Федерации',
    regexp: DOCUMENTS_NUMBER_REGEXPS.DEFAULT_REGEXP,
    passengerCategory: ['Adult'],
    allowedCitizenship: 'any',
    charactersInFIO: 'any',
  },
  {
    id: 'AnotherDocumentOfAForeignCitizenRecognizedInTheRussianFederation',
    title:
      'Иной документ, установленный федеральным законом или признаваемый в соответствии с международным договором Российской Федерации в качестве документа, удостоверяющего личность иностранного гражданина и (или) лица без гражданства',
    regexp: DOCUMENTS_NUMBER_REGEXPS.DEFAULT_REGEXP,
    passengerCategory: ['Adult', 'Child', 'Infant'],
    allowedCitizenship: 'any',
    charactersInFIO: 'any',
  },
];

import React from 'react';
import styled from 'styled-components';
import { ChipProps } from './types';

const DefaultChip = styled.div<{ bgColor: string }>`
  padding: 4px 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #3c3c3c;
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 8px;
  white-space: nowrap;
`;

const Chip: React.FC<ChipProps> = ({ children, bgColor, label, ...props }) => {
  const getBgColor = () => {
    switch (bgColor) {
      case 'default': {
        return '#f3f5fa';
      }
      case 'green': {
        return '#DAF0A2';
      }
      case 'purple': {
        return '#ECE0FE';
      }
      case 'red': {
        return '#FFD3CA';
      }
      case 'yellow': {
        return '#FFECBC';
      }
      default: {
        return '#f3f5fa';
      }
    }
  };
  return <DefaultChip bgColor={getBgColor()}>{label}</DefaultChip>;
};

export default Chip;

///TODO PROMO IN COMPONENT

import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '@modules/index';
import { AddictionalServicesProvider } from '@components/booking';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import PaymentIframe from '@components/common/PaymentIframe/index';
import {
  ancillaryServicesSelector,
  getAddictionalServices,
  getDetailOrder,
  getOrderByNumber,
  isOrdersLoading,
  promocodeApply,
  promocodeApplySuccess,
} from '@modules/orders';
import AddictionalServicesMobileNew from '@components/booking/MobileBooking/AddictionalServicesMobileNew';
import { Insurances } from '@components/order/Detail';
import { getUserDataState } from '@modules/user';
import StickyFooterNew from '@components/booking/MobileBooking/StickyFooterNew';
import { RoundIcon } from '@components/loader';

import { Hooks } from '@utils';
import { setIsMobile } from '@modules/booking';
import Navigation from '@components/booking/MobileBooking/Navigation';
import {
  setDate,
  setDestination,
  setPassengers,
  setTicketClass,
} from '@modules/simpleSearch';
import { FlightClassTypes } from '@modules/main';
import NewFlightDetailContainerStepTwo from '@components/booking/FlightDetail/NewDesktop/NewFlightDetailContainerStepTwo';
import YourFlightContainerBookingStepTwo from '@components/booking/YourFlight/YourFlightContainerBookingStepTwo';
import { Separator } from '@components/common';
import AviaPassengerPriceOrderDetails from '@components/booking/PassengerPrice/AviaPassengerPriceOrderDetails';
import { Button, Field } from '@components/ui';

import FlightBookedNotification from '@components/booking/FlightBookedNotification';
import NewPassangersBlock from '@components/order/Detail/NewPassangersBlock';
import AncillaryBookingDetails from '@components/booking/FlightDetail/AncillaryBookingDetails';

import PromocodeApply from '@components/common/PromocodeApply';
import AgentOfferBlock from '@components/booking/AddictionalServices/OfferBlocks/AgentOfferBlock';

const Body = {
  Wrapper: styled.div`
    background: white;
    padding-top: 32px;
    font-family: ${({ theme }) => theme.fonts.regular};
    color: ${({ theme }) => theme.colors.blackGray};
    @media (max-width: 767px) {
      padding-top: 20px;
    }
  `,
  MainContainer: styled.div`
    display: flex;
    max-width: 1170px;
    justify-content: space-between;
    margin: 0 auto;
    @media (max-width: 767px) {
      display: block;
    }
    @media (max-width: 1116px) {
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;
    }
  `,
  Block: styled.div`
    background: #ffffff;
    border-radius: 8px;
    width: 100%;
    padding: 32px;
    box-sizing: border-box;
  `,
  LeftSide: styled.div`
    width: 730px;

    & > div {
      margin-bottom: 10px;
    }

    & > :last-child {
      margin-bottom: 32px;
    }

    @media (max-width: 1116px) {
      width: 65%;
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  `,
  RightSide: styled.div`
    width: 350px;
    position: relative;
    top: 78px;

    @media (max-width: 767px) {
      display: none;
    }

    @media (max-width: 1116px) {
      width: 33%;
    }
  `,
};

const MobileHeading = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #737373;
  margin-top: 40px;

  margin-left: 5px;

  @media (min-width: 767px) {
    display: none;
  }
`;

const LoaderWrapper = styled.div`
  position: absolute;
  top: 100px;
  width: 100%;
  bottom: 0;
  right: 0;
  background: white;
  z-index: 998;
  height: calc(100vh - 284px);
  @media (max-width: 1024px) {
    height: calc(100vh - 38px);
  }
`;

const YourFlightBlock = styled.div`
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  padding: 24px 0;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
`;

const StickyBlock = styled.div`
  position: sticky;
  top: 40px;
  margin-top: 18px;
  margin-bottom: 45px;
`;

const PaymentDiv = styled.div`
  @media and (max-width: 767px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const StyledField = styled(Field)`
  width: 185px;
  height: 40px;
`;

const Label = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 5px;

  color: #e73c3e;
  position: absolute;
  top: 40px;
`;

const StyledButton = styled(Button)`
  min-width: 130px;
  width: 130px;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  text-align: center;
  margin-left: 15px;
`;

const BookingSecondPage: React.FC<any> = (props) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(isOrdersLoading);
  const { isMobile } = Hooks.useMediaSizes();
  const [isPassengersOpen, setIsPassengersOpen] = React.useState(false);
  const items = useSelector(ancillaryServicesSelector);
  const [promocodeValue, setPromocodeValue] = React.useState('');
  const [isPaymentLoader, setIsPaymentLoader] = React.useState(false);

  const availableInsuransesState = useSelector(
    (x: ApplicationState) => x.orders.orderDetail.availableInsurances,
  );

  const [chosenPaymentMethod, setChosenPaymentMethod] =
    React.useState('Российская карта');

  const userData = useSelector(getUserDataState);

  const { salesChannel, insurances, id, orderAmount } =
    useSelector(getDetailOrder);

  const order = useSelector(getDetailOrder);
  const availableActions = order.availableActions;

  const chosenScreen = useSelector(
    (state: ApplicationState) => state.booking.ticket?.chosenScreen,
  );
  const addictionalServices = useSelector(getAddictionalServices);

  const promocodeStatus = useSelector(
    (x: ApplicationState) => x.orders.orderDetail.promocodeApplyStatus,
  );

  const isPromocodeApplied = orderAmount.detalization.some((x) =>
    x.description.includes('Применен промокод'),
  );

  React.useEffect(() => {
    dispatch(getOrderByNumber(parseInt(props.match.params.orderNumber)));
  }, []);

  function getUniqueItems(addictionalServices: any) {
    const uniqueIds = new Set(
      addictionalServices.items.map((item: any) => item.id),
    );

    const itemsWithUniqueIds = [...uniqueIds]
      .map((id) =>
        addictionalServices.items.find((item: any) => item.id === id),
      )
      .filter(Boolean);

    return itemsWithUniqueIds;
  }

  React.useEffect(() => {
    if (isMobile) {
      dispatch(setIsMobile(true));
    } else dispatch(setIsMobile(false));
  }, [isMobile]);

  function getFlightClassDesc(str: FlightClassTypes) {
    switch (str) {
      case FlightClassTypes.Econom:
        return 'эконом';
      case FlightClassTypes.Comfort:
        return 'комфорт';
      case FlightClassTypes.Bussines:
        return 'бизнес';
      case FlightClassTypes.FirstClass:
        return 'первый класс';
    }
  }

  React.useEffect(() => {
    if (order.flightSearchParameters) {
      window.localStorage.setItem('origin', order.flightSearchParameters.from1);
      window.localStorage.setItem(
        'destination',
        order.flightSearchParameters.to1,
      );
      const formatedFromDate =
        moment(order.flightSearchParameters.date1).format('YYYY-MM-DD') +
        'T00:00:00';
      window.localStorage.setItem('forwardDate', formatedFromDate);

      dispatch(
        setDate({
          from: moment({
            month:
              parseInt(order.flightSearchParameters.date1.substring(5, 7)) - 1,
            days: parseInt(order.flightSearchParameters.date1.substring(8, 10)),
            year: parseInt(order.flightSearchParameters.date1.substring(0, 4)),
          }),
          to: order.flightSearchParameters.date2
            ? moment({
                month:
                  parseInt(order.flightSearchParameters.date2.substring(5, 7)) -
                  1,
                days: parseInt(
                  order.flightSearchParameters.date2.substring(8, 10),
                ),
                year: parseInt(
                  order.flightSearchParameters.date2.substring(0, 4),
                ),
              })
            : null,
        }),
      );
      dispatch(
        setPassengers({
          adults: order.flightSearchParameters.adults,
          children: order.flightSearchParameters.children,
          infants: order.flightSearchParameters.infants,
          source: 'avia',
        }),
      );
      dispatch(
        setTicketClass({
          id: order.flightSearchParameters.class?.toLowerCase(),
          description: getFlightClassDesc(
            order.flightSearchParameters.class?.toLowerCase(),
          ),
        }),
      );
      const city = {
        iataCode: order.flightSearchParameters.from1,
        nameEng: order.flightSearchParameters.from1NameEng,
        nameRus: order.flightSearchParameters.from1NameRus,
        isCity: true,
        id: '',
        isVisible: true,
        country: {
          id: '',
          isoCode: '',
          nameEng: '',
          nameRus: '',
          isVisible: true,
        },
      };
      const city2 = {
        iataCode: order.flightSearchParameters.to1,
        nameEng: order.flightSearchParameters.to1NameEng,
        nameRus: order.flightSearchParameters.to1NameRus,
        isCity: true,
        id: '',
        isVisible: true,
        country: {
          id: '',
          isoCode: '',
          nameEng: '',
          nameRus: '',
          isVisible: true,
        },
      };
      dispatch(
        setDestination({
          from: city,
          to: city2,
          withoutChangeQueryParams: true,
        }),
      );
    }
  }, [order]);

  React.useEffect(() => {
    if (
      getUniqueItems(addictionalServices).some((x) => x.isLoading === true) ||
      availableInsuransesState?.items.some((x) => x.loading === true)
    ) {
      setIsPaymentLoader(true);
    } else setIsPaymentLoader(false);
  }, [availableInsuransesState, getUniqueItems(addictionalServices)]);

  const fareFamiliesTitle =
    order?.orderPositions[0]?.tariff?.fareFamilies &&
    order?.orderPositions[0]?.tariff?.fareFamilies.map((fare) => fare.name);

  return isLoading ? (
    <LoaderWrapper>
      <RoundIcon />
    </LoaderWrapper>
  ) : (
    <Body.Wrapper>
      <AddictionalServicesProvider>
        <Body.MainContainer>
          <Body.LeftSide>
            <>
              <Navigation
                chosenScreen={3}
                from={order?.flightSearchParameters?.from1NameRus}
                to={order?.flightSearchParameters?.to1NameRus}
                isTwoWay={order?.flightSearchParameters?.isTwoWay}
                passengers={order?.orderPositions[0]?.passengers?.length}
                fare={fareFamiliesTitle?.join(' + ')}
              />
              <FlightBookedNotification />
              <>
                {/* {isMobile ? (
                  <OrderPositionsMobile />
                ) : ( */}
                <NewFlightDetailContainerStepTwo
                  onClick={() => {
                    setIsPassengersOpen(true);
                  }}
                />
                {/* )} */}

                <NewPassangersBlock
                  items={order?.orderPositions[0]?.passengers}
                  itemWrapper={<div />}
                />
                {(items?.luggage?.length !== 0 ||
                  items?.meal?.length !== 0 ||
                  items?.seats?.length !== 0) && <AncillaryBookingDetails />}

                {salesChannel !== 'Самотур (GDS тур)' && (
                  <>
                    <MobileHeading>Не забудьте добавить</MobileHeading>

                    <AddictionalServicesMobileNew
                      items={getUniqueItems(addictionalServices)}
                      showExtraServices={true}
                    />
                  </>
                )}

                {(insurances.length > 0 ||
                  (availableInsuransesState &&
                    availableInsuransesState.items.length > 0)) &&
                  salesChannel !== 'Самотур (GDS тур)' && (
                    <>
                      <MobileHeading style={{ marginLeft: 0 }}>
                        Позаботьтесь о себе
                      </MobileHeading>

                      <Insurances items={insurances} isBooking={true} />
                    </>
                  )}
                {isMobile && (
                  <Row>
                    <Column>
                      <StyledField
                        placeholder="% Промокод"
                        value={promocodeValue}
                        onChange={(e) => {
                          setPromocodeValue(e.target.value);
                          dispatch(promocodeApplySuccess(false));
                        }}
                      />
                      {promocodeStatus && promocodeValue !== '' && (
                        <Label style={{ fontSize: 12 }}>
                          {promocodeStatus}{' '}
                        </Label>
                      )}
                    </Column>

                    <StyledButton
                      onClick={() =>
                        dispatch(
                          promocodeApply({
                            orderId: id,
                            promocode: promocodeValue,
                          }),
                        )
                      }
                    >
                      Применить
                    </StyledButton>
                  </Row>
                )}

                {(availableActions?.includes('PAYMENT') ||
                  availableActions.includes('EXTRAPAYMENT')) && (
                  <PaymentDiv id="PaymentDiv">
                    {!userData?.agentId && (
                      <PaymentIframe
                        chosenPaymentMethod={chosenPaymentMethod}
                        setChosenPaymentMethod={setChosenPaymentMethod}
                        promocodeStatus={promocodeStatus}
                        isPaymentLoader={isPaymentLoader}
                      />
                    )}
                  </PaymentDiv>
                )}
                {isPromocodeApplied && <PromocodeApply />}
              </>
            </>
          </Body.LeftSide>
          <Body.RightSide>
            <StickyBlock>
              <YourFlightBlock>
                <YourFlightContainerBookingStepTwo />
                <Separator />
                <AviaPassengerPriceOrderDetails
                  chosenPaymentMethod={chosenPaymentMethod}
                />

                {/* {availableActions?.includes('PAYMENT') ||
                  (availableActions.includes('EXTRAPAYMENT') && (
                    <ButtonWrapper>
                      <FullWidthButton
                        color="yellow"
                        onClick={() => {
                          const el = document.getElementById('PaymentDiv');
                          if (el) {
                            const offset = el.offsetTop;
                            window.scrollTo({
                              top: offset - 100,
                              behavior: 'smooth',
                            });
                          }
                        }}
                      >
                        Оплатить
                      </FullWidthButton>
                    </ButtonWrapper>
                  ))} */}
                {/* {userData?.agentId && (
                  <GoToOrderButton onClick={payOrder}>
                    Продолжить
                  </GoToOrderButton>
                )} */}
              </YourFlightBlock>
              <AgentOfferBlock />
            </StickyBlock>
          </Body.RightSide>
        </Body.MainContainer>
        <StickyFooterNew
          chosenScreen={chosenScreen}
          chosenPaymentMethod={chosenPaymentMethod}
        />
      </AddictionalServicesProvider>
    </Body.Wrapper>
  );
};

export default BookingSecondPage;
